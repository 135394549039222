import UserEventApiService from '@/services/api/UserEventApiService'
import Store from '@/store'
import Vue from 'vue'

export const GA_EVENTS = {
  DOWNLOAD_PDF : 'DOWNLOAD_PDF',
  SHARE_URL : 'SHARE_URL',
  FILTER_BY : 'FILTER_BY'
}

export const GA_EVENT_CATEGORIES = {
  USER_INTERACTION : 'USER_INTERACTION',
}

export const GA_LABELS = {

  SUMMARY_STUDY_DOWNLOAD_PDF : 'SUMMARY_STUDY_DOWNLOAD_PDF',
  SUMMARY_STUDY_MORE_INFO : 'SUMMARY_STUDY_MORE_INFO',
  SUMMARY_STUDY_SEE_RESULTS : 'SUMMARY_STUDY_SEE_RESULTS',
  SUMMARY_HOW_TO_GET_READY : 'SUMMARY_HOW_TO_GET_READY',
  SUMMARY_SEND_ME_A_REMINDER : 'SUMMARY_SEND_ME_A_REMINDER',
  SUMMARY_CUSTOM_LINKS : 'SUMMARY_CUSTOM_LINKS',
  SUMMARY_DOWNLOAD_NEW_CONSTRUCTION_SF_IMPACT_MODEL_BETA : 'SUMMARY_DOWNLOAD_NEW_CONSTRUCTION_SF_IMPACT_MODEL_BETA',


  POLICIES_DOWNLOAD_NEW_CONSTRUCTION_SF_IMPACT_MODEL_BETA : 'POLICIES_DOWNLOAD_NEW_CONSTRUCTION_SF_IMPACT_MODEL_BETA',


  RESULTS_SHARE_URL : 'RESULTS_SHARE_URL',
  RESULTS_DOWNLOAD_PDF : 'RESULTS_DOWNLOAD_PDF',
  RESULTS_FILTER_BY : 'RESULTS_FILTER_BY',
  RESULTS_HOW_TO_GET_READY : 'RESULTS_HOW_TO_GET_READY',
  RESULTS_SEND_ME_A_REMINDER : 'RESULTS_SEND_ME_A_REMINDER',
  RESULTS_STUDY_SOURCE_INFO: 'RESULTS_STUDY_SOURCE_INFO',

  BUILDINGSTOCK_SHARE_URL : 'BUILDINGSTOCK_SHARE_URL',
  BUILDINGSTOCK_DOWNLOAD_PDF : 'BUILDINGSTOCK_DOWNLOAD_PDF',
  BUILDINGSTOCK_DOWNLOAD_CSV : 'BUILDINGSTOCK_DOWNLOAD_CSV',
  BUILDINGSTOCK_FILTER_BY : 'BUILDINGSTOCK_FILTER_BY',

  BUILDING_ESTIMATES_DOWNLOAD_PDF: 'BUILDING_ESTIMATES_DOWNLOAD_PDF',
  BUILDING_ESTIMATES_DOWNLOAD_CSV: 'BUILDING_ESTIMATES_DOWNLOAD_CSV',

  MENU_INFO_QUICK_GUIDE: 'MENU_INFO_QUICK_GUIDE',
  MENU_INFO_NEW_CONSTRUCTION_QUICK_GUIDE: 'MENU_INFO_NEW_CONSTRUCTION_QUICK_GUIDE',
  JURISDICTION_SELECTION: 'JURISDICTION_SELECTION',
  POLICY_OPTIONS_CLICK: 'POLICY_OPTIONS_CLICK',

  COLUMNS_CHANGE: 'COLUMNS_CHANGE',
  ASSUMPTIONS_CHANGED: 'ASSUMPTIONS_CHANGED',
  INFO_HELP_CLICK: 'INFO_HELP_CLICK',

  POLICY_OPTIONS_VIEW: 'POLICY_OPTIONS_VIEW',
  NEW_POLICY: 'NEW_POLICY',
}

export function gTagEvent(action, args) {
  //  || window.location.hostname === 'localhost'
  if((process.env.VUE_APP_ENV === 'production' && Store.getters['general/getterDeviceInfo'].log_events)) {
    Vue.$gtag.event(action, args)
    UserEventApiService.log({ event: args?.event_label, notes: args?.value })
  }
}

export default {  
  data: () => ({
    GA_LABELS,
    GA_EVENT_CATEGORIES,
    GA_EVENTS,
  }),
  methods: {
    gaEvent(action, args) {
      gTagEvent(action, args)
    },
    gaEventShareUrl(event_label, value = null) {
      value = value ?? window.location.href
      this.gaEvent(GA_EVENTS.SHARE_URL, {
        event_category: GA_EVENT_CATEGORIES.USER_INTERACTION,
        event_label,
        value
      })
    },
    gaEventDownloadFile(event_label, value = null) {
      this.gaEvent(GA_EVENTS.DOWNLOAD_PDF, {
        event_category: GA_EVENT_CATEGORIES.USER_INTERACTION,
        event_label,
        value
      })
    },
    gaEventFilterBy(event_label, value = null) {
      this.gaEvent(GA_EVENTS.FILTER_BY, {
        event_category: GA_EVENT_CATEGORIES.USER_INTERACTION,
        event_label,
        value
      })
    }
  },
}


