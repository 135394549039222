<template>
  <AppCard
    v-if="studyGroups.length > 0"
    class="p-8"
  >
    <div class="flex items-center justify-between mb-6">                  
      <router-link
        class="cursor-pointer text-header-5 text-bluebrand font-bold ml-2"
        tag="h2"
        :to="{ name: 'StudyResults' }"
      >
        Cost-Effectiveness Studies
      </router-link>

      <div class="psui-flex psui-items-center">
        <p class="psui-text-small text-ash-600 psui-mr-2">
          Study Versions:
        </p>
        <PsToggle 
          :items="['Latest', 'All']"
          key-label="title"
          key-value="id"
          :selected.sync="studyVersionsFilter"
        />
      </div>
    </div>
    <ul class="psui-space-y-2">
      <li
        v-for="studyGroup in studyGroups"
        :key="studyGroup.id"
        class="psui-flex bg-deepsky-100 psui-p-4 psui-rounded-lg"
      >
        <div class="flex-shrink-0 mr-3">
          <svg
            width="32"
            height="32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21 3v20H4V3h17z"
              stroke="#82C0D9"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 26h18V5.25"
              stroke="#82C0D9"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 29.5h19v-22M17 8.9H8m9 4H8m4.814 4H8"
              stroke="#82C0D9"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>

        <div class="w-full">
          <span 
            class="flex items-center transition-all -ml-2"
            :class="studyGroup.studies.length > 1 ? 'opacity-100-all-opacity-0-childrens-on-hover hover:bg-deepsky-200' : ''"
          >
            <h3 class="text-header-3 font-bold text-deepsky-800 mt-2 mb-3 pl-2">{{ studyGroup.title }} Studies</h3>
            <span class="ml-auto flex actions text-deepsky-500 space-x-4 mr-3">
              <span
                v-if="studyGroup.studies.length > 1"
                class="cursor-pointer hover:text-deepsky-700 opacity-0"
                @click="$eventBus.$emit('openStudyGroupVersionsTimelineModal', { studyGroupId: studyGroup.id })"
              >
                VERSION HISTORY
              </span>
              <span
                v-if="checkStudyGroupCanCreatePolicy(studyGroup)"
                class="cursor-pointer hover:text-deepsky-700 always-visible"
                @click="goToCreatePolicyOption(studyGroup)"
              >
                {{ 'EXPLORE POLICY OPTIONS' | policyTextRephrase('small') }}
              </span>
            </span>
          </span>                                          
          <transition-group
            name="list"
            tag="ul"
            class="transition-all"
          >
            <template v-for="(study) in studyGroup.studies">
              <template>
                <div :key="`${studyGroup.id}-${study.id}`">
                  <li
                    v-if="study.summary_recommended || studyVersionsFilter === 'All'"
                    :key="`${studyGroup.id}-${study.id}`"
                    class="flex items-center transition-all hover:bg-deepsky-200 py-2 -ml-2 pl-2 opacity-100-childrens-on-hover"
                  >
                    <span class="text-header-2 text-deepsky-700 mr-2 flex-none">{{ study.getTitle }}</span>
                    <span class="text-header-1 text-ash-400 font-bold">{{ study.title_by_prototypes }}</span>
                    <span
                      v-if="!study.published_at && study.source_pdf"
                      class="text-header-1 text-lime-400 border rounded-xs p-1 border-lime-400 font-bold mx-4 flex-none"
                    >PDF Only</span>
                    <span
                      v-if="study.summary_message_tag"
                      class="text-header-1 text-lime-400 border rounded-xs p-1 border-lime-400 font-bold mx-4 flex-none"
                    >{{ study.summary_message_tag }}</span>
                    <span class="ml-auto flex actions text-deepsky-500 space-x-4 opacity-0 mr-3 flex-none">
                      <router-link 
                        v-if="study.published_at"
                        class="cursor-pointer hover:text-deepsky-700"
                        :to="{ 
                          name: 'StudyResults', 
                          params: { 
                            jurisdiction_slug: jurisdictionSlug, 
                            climate_zone_raw: climateZoneRaw
                          }, query: getSeeResultsQueryParams(study.id)}"
                        @click.native="onClickStudySeeResults(study)"
                      >SEE RESULTS</router-link>
                      <span 
                        v-if="study.source_pdf"
                        class="cursor-pointer hover:text-deepsky-700" 
                        @click="onClickStudyDownloadPdf(study)"
                      >
                        DOWNLOAD PDF</span>
                      <span
                        v-if="study.has_content"
                        class="cursor-pointer hover:text-deepsky-700" 
                        @click="onClickStudyMoreInfo(study)"
                      >
                        MORE INFO</span>
                      <span 
                        v-for="(link, index) of study.custom_links"
                        :key="index"
                        class="cursor-pointer hover:text-deepsky-700"
                      >  <a 
                        :href="link.href" 
                        :target="link.target" 
                        :title="link.title"
                        @click="gaEvent(GA_LABELS.SUMMARY_CUSTOM_LINKS, { value: link.href, event_category: GA_EVENT_CATEGORIES.USER_INTERACTION, event_label: study.title })"
                      >
                        {{ link.title }}
                      </a>
                      </span> 
                    </span>
                  </li>
                </div>
              </template>
            </template>
          </transition-group>
        </div>
      </li>
    </ul>
  </AppCard>
  <SkeletonCostEffectivenessStudies v-else />
</template>

<script>
import AppCard from '@/components/general/AppCard'
import SkeletonCostEffectivenessStudies from './skeleton/SkeletonCostEffectivenessStudies'
import StudyGroupApiService from '@/services/api/StudyGroupApiService'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'

export default {
  name: 'SummaryCostEffectivenessStudies',
  components: { 
    AppCard,
    SkeletonCostEffectivenessStudies,    
  },
  data : () => ({
    studyGroups: [],
    STUDY_TYPES_DB_SLUGS,
    studyVersionsFilter: 'Latest',
  }),
  computed : {
    jurisdiction() {
      return this.$store.getters['lastJurisdictionVisited']
    },
    climateZoneRaw() {
      return this.jurisdiction.climate_zones?.[0]?.raw ?? false
    },
    jurisdictionSlug() {
      return this.$route.params.jurisdiction_slug
    },
  },
  mounted() {
    StudyGroupApiService.all({getPrototypes:true}).then(res => this.studyGroups = res)
  },
  methods: {
    getSeeResultsQueryParams(study_id) {      
      const study_type = this.$store.getters['globalEntities/Study/getterStudyStudyType']({ study_id })
      const studies = this.$store.getters['globalEntities/Study/getterStudiesByStudyType']({ study_type_id: study_type.id })
      const exclude_study_ids = studies.removeAllInArray({ id: study_id }).map((study) => study.id)
      return {
        only_study_type : study_type.slug,
        exclude_study_ids : exclude_study_ids.join(',')
      }
    },
    onClickStudyDownloadPdf(study) {
      this.gaEventDownloadFile(this.GA_LABELS.SUMMARY_STUDY_DOWNLOAD_PDF, window.location.href, study.id)
      window.open(study.source_pdf)
    },
    onClickStudyMoreInfo(study) {
      this.gaEvent(this.GA_LABELS.SUMMARY_STUDY_MORE_INFO, { value: study.id })
      this.$eventBus.$emit('openDrawerContent', { type: 'study', id: study.id })
    },
    onClickStudySeeResults(study) {
      this.gaEvent(this.GA_LABELS.SUMMARY_STUDY_SEE_RESULTS, { value: study.id })
    },

    checkStudyGroupCanCreatePolicy(studyGroup) {
      const getterPolicyAvailablePrototypes = this.$store.getters['policy/getterPolicyAvailablePrototypes']({}).map(prototype => prototype.study_id)
      const studyGroupCanCreatePolicy = studyGroup.studies.filter((study) => {
      return getterPolicyAvailablePrototypes.includes(study.id)
      }).length ? true : false

      return studyGroup.hasStudiesPublishedAt && studyGroupCanCreatePolicy
    },
    goToCreatePolicyOption(studyGroup) {
      this.$store.dispatch('setUserLastPolicyOptionSelected', {
        context:'updateLastPolicyOptionSelectedFromSummary',
        value: [studyGroup.study_type.slug]
      })

      this.$router.push({
        name: 'PolicyOptions',
      })      
    }

  }
  
}
</script>

<style>

</style>