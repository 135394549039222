var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PsAccordionItem',{staticClass:"display-impact-assumption-icon",class:{ 'form-dirty' : _vm.hasValueUpdated(_vm.getCurrentGrid,_vm.assumption.current_grid_renewable_level) || 
    _vm.hasValueUpdated(_vm.getCurrentKwhEmissionFactor,_vm.assumption.current_kwh_emissions_factor)
  },attrs:{"title":"Current Grid Clean Electricity Share"},scopedSlots:_vm._u([{key:"header-additionals",fn:function(){return [_c('PsIcon',{staticClass:"help psui-items-center psui-h-5 hover:psui-text-blue-60",attrs:{"icon":"help_outline","size":"16","display":"flex","icon-classes":_vm.hasValueUpdated(_vm.getCurrentGrid,_vm.assumption.current_grid_renewable_level) || 
        _vm.hasValueUpdated(_vm.getCurrentKwhEmissionFactor,_vm.assumption.current_kwh_emissions_factor) ? 'mr-5': ''},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$eventBus.$emit('openDescriptionModal', { slug: 'impact_current_grid_clean_electricity_share_assumption', ..._vm.descriptionModelObject })}}})]},proxy:true},{key:"default",fn:function(){return [_c('span',{staticClass:"psui-text-big psui-text-gray-60"},[_vm._v(" We assume that the current percentage of grid supplied electricity that is generated from renewable or zero-emission power sources is: ")]),_c('PsInput',{staticClass:"mt-4/5 w-1/2",class:{'selected':_vm.hasValueUpdated(_vm.getCurrentGrid,_vm.assumption.current_grid_renewable_level) && !_vm.inputErrorIndex },attrs:{"data-test-id":`input-assumption-${_vm.assumption.current_grid_renewable_level}`,"has-error":_vm.inputErrorIndex && !_vm.advancedMode ? _vm.inputErrorIndex : false,"layout":"mini","value":_vm.getCurrentGrid},on:{"input":_vm.onInputCurrentGrid,"blur":_vm.onBlurCurrentGrid,"change":_vm.onChangeCurrentGrid,"mouseenter":function($event){_vm.showResetButton = _vm.assumption.current_grid_renewable_level},"mouseleave":function($event){_vm.showResetButton = false}},nativeOn:{"click":function($event){return _vm.checkIfUserCanEditAssumption.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(!_vm.hasValueUpdated(_vm.getCurrentGrid,_vm.assumption.current_grid_renewable_level) || 
            _vm.showResetButton != _vm.assumption.current_grid_renewable_level)?_c('span',{staticClass:"psui-text-gray-50 psui-text-xsmall"},[_vm._v("%")]):(_vm.showResetButton == _vm.assumption.current_grid_renewable_level)?_c('PsTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('PsIcon',{attrs:{"icon":"restart_alt","icon-classes":"psui-text-green-20 cursor-pointer mt-1","size":"17"},nativeOn:{"click":function($event){_vm.updateCurrentGrid(
                _vm.getBaseValue(_vm.assumption.current_grid_renewable_level), true
              )}}})]},proxy:true},{key:"content",fn:function(){return [_c('h1',{staticClass:"mb-1"},[_vm._v(" Restore to default value ")]),_c('span',{staticClass:"psui-font-normal"},[_vm._v(_vm._s(_vm.getBaseValue(_vm.assumption.current_grid_renewable_level)))])]},proxy:true}])}):_vm._e()]},proxy:true}])}),_c('div',{staticClass:"mt-4/5"},[_c('p',{staticClass:"psui-text-small psui-text-gray-60"},[_vm._v(" Resulting in: ")]),_c('div',{staticClass:"flex place-items-start justify-between mt-2"},[_c('PsInput',{staticClass:"mr-5 w-full append-container",class:{'selected':_vm.hasValueUpdated(_vm.getCurrentKwhEmissionFactor,_vm.assumption.current_kwh_emissions_factor) && 
            !_vm.openEmissionFactorInput && !_vm.inputErrorIndex},attrs:{"data-test-id":`input-assumption-${_vm.assumption.current_kwh_emissions_factor}`,"value":_vm.getCurrentKwhEmissionFactor,"layout":"mini","has-error":_vm.inputErrorIndex && _vm.advancedMode ? _vm.inputErrorIndex : false,"hint":`${_vm.minCurrentKwhEmissionFactor} - ${_vm.maxCurrentKwhEmissionFactor}`,"disabled":_vm.openEmissionFactorInput},on:{"input":_vm.onInputCurrentKwhEmissionFactor,"blur":_vm.onBlurCurrentKwhEmissionFactor,"change":_vm.onChangeCurrentKwhEmissionFactor,"mouseenter":function($event){_vm.showResetButton = _vm.assumption.current_kwh_emissions_factor},"mouseleave":function($event){_vm.showResetButton = false}},nativeOn:{"click":function($event){return _vm.checkIfUserCanEditAssumption.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.openEmissionFactorInput || !_vm.hasValueUpdated(_vm.getCurrentKwhEmissionFactor,_vm.assumption.current_kwh_emissions_factor) || _vm.showResetButton !== _vm.assumption.current_kwh_emissions_factor)?_c('span',{staticClass:"psui-text-xsmall psui-text-gray-40"},[_vm._v("MTCO"),_c('sub',[_vm._v("2")]),_vm._v("/kWh")]):(_vm.showResetButton === _vm.assumption.current_kwh_emissions_factor && 
                _vm.hasValueUpdated(_vm.getCurrentKwhEmissionFactor,_vm.assumption.current_kwh_emissions_factor))?_c('PsTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('PsIcon',{attrs:{"icon":"restart_alt","icon-classes":"psui-text-green-20 cursor-pointer mt-1","size":"17"},nativeOn:{"click":function($event){_vm.updateCurrentKwhEmissionFactor(
                    _vm.getBaseValue(_vm.assumption.current_kwh_emissions_factor), true
                  )}}})]},proxy:true},{key:"content",fn:function(){return [_c('h1',{staticClass:"mb-1"},[_vm._v(" Restore to default value ")]),_c('span',{staticClass:"psui-font-normal"},[_vm._v(_vm._s(_vm.getBaseValue(_vm.assumption.current_kwh_emissions_factor)))])]},proxy:true}])}):_vm._e()]},proxy:true}])}),_c('PsTooltip',{attrs:{"css-class":"w-48"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('PsIcon',{attrs:{"data-test-id":"icon-open-emission-factor","icon":_vm.openEmissionFactorInput ? 'edit': 'lock_outline',"size":"17","icon-classes":"cursor-pointer mt-2 psui-text-gray-40 hover:psui-text-blue-60"},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.openCurrentElectricRenewableShare.apply(null, arguments)}}})]},proxy:true},{key:"content",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.openEmissionFactorInput ? 'Specify BOTH current electric renewable share and current electric emissions factor.' : 'Specify JUST the current electric renewable share, and we will calculate the electric emissions factor.'))])]},proxy:true}])})],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }