import BaseModel from './BaseModel'

export default class PolicyOption extends BaseModel {

  constructor(obj = {}) {
    super()
    this.id = obj.id || null
    this.title = obj.title || ''
    this.title_drawer_about = obj.title_drawer_about || ''
    this.title_drawer_create = obj.title_drawer_create || ''
    this.slug = obj.slug || ''
    this.short_description = obj.short_description || ''
    this.description = obj.description || ''
    this.alert_text = obj.alert_text || ''
    this.is_advanced = obj.is_advanced || false
    this.is_recommended = obj.is_recommended || false
    this.created_at = obj.created_at || ''
    this.updated_at = obj.updated_at || ''
    this.custom_fields = obj.custom_fields || ''
  }

}