<template>
  <div class="chart-section">
    <div
      ref="chart"
      class="chart-section-chart"
      :style="{ height: getChartHeight, minHeight: getChartHeight }"
    />
    <div class="chart-section-text-wrapper flex flex-col">
      <div class="chart-section-text-wrapper-title flex items-center">
        <span class="font-bold psui-text-xsmall psui-text-gray-70">
          {{ title }}
        </span>
        <PsIcon
          icon="info_outline"
          size="16"
          display="flex"
          class="info-icon psui-text-gray-50 cursor-pointer hover:psui-text-blue-60"
          :style="iconStyle"
          @click.stop="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: getHelperSlug })"
        />
      </div>
      <span class="chart-section-text-wrapper-percentage psui-text-p psui-text-blue-80"> {{ getPercentage }}% </span>      
      <span
        v-if="!getUnitsNotSelected"
        class="chart-section-text-wrapper-unit psui-text-gray-60 psui-text-small"
      > {{ getUnitsSelected }} {{ unitType }} </span>
      <span
        v-else
        class="chart-section-text-wrapper-unit psui-text-gray-60 psui-text-small"
      > {{ getUnitsSelected }} of {{ getTotalUnits }} {{ unitType }} </span>
    </div>
  </div>
</template>

<script>
import ApexCharts from 'apexcharts'
import { colors } from '@/business-logic/constants/chartDefaultOptions'

export default {
  name: 'BuildingEstimatesNonAndLargePieChart',
  props: ['series', 'title', 'unitType', 'slug'],
  data() {
    return {
      chartOptions: {},
      chartInstance: null,
      seriesCached: null,
      chartIsLoading: false,
    }
  },
  computed: {
    getColors() {
      return colors
    },
    rawUnitsNotSelected() {
      const [unitsNotSelected, _] = this.series
      return unitsNotSelected
    },
    rawUnitsSelected() {
      const [_, unitsSelected] = this.series
      return unitsSelected
    },
    rawTotalUnits() {
      return this.rawUnitsNotSelected + this.rawUnitsSelected
    },
    getUnitsNotSelected() {
      return this.formatNumber(this.rawUnitsNotSelected)
    },
    getUnitsSelected() {
      return this.formatNumber(this.rawUnitsSelected)
    },
    getTotalUnits() {
      return this.formatNumber(this.rawTotalUnits)
    },
    getPercentage() {
      return ((this.rawUnitsSelected / this.rawTotalUnits) * 100).toFixed(0)
    },
    getChartHeight() {
      return this.chartOptions.chart?.height
    },
    getHelperSlug() {
      return this.slug === 'buildings' ? 'bps_selected_buildings_unities' : 'bps_selected_floor_area'
    },
    iconStyle() {
      return this.getHelperSlug === 'bps_selected_buildings_unities'
        ? 'margin-left: 4px; margin-bottom: -3px'
        : 'margin-left: 4px; margin-bottom: -2px'
    },
  },
  watch: {
    chartOptions: {
      handler(newOptions) {
        this.updateChartOptions(newOptions)
      },
      immediate: true,
    },
    series: {
      handler(newSeries) {
        this.chartOptions.series = newSeries
        this.updateChartOptions(this.chartOptions)
      },
      deep: true,
    },
  },
  mounted() {
    this.chartOptions = this.getChartOptions()
    this.createChart()
  },
  beforeDestroy() {
    if (this.chartInstance) {
      this.chartInstance.destroy()
    }
  },
  methods: {
    getChartOptions() {
      const self = this
      return {
        series: self.series,
        colors: ['#E6ECF2', '#798490'],
        stroke:{
          width: 1,
        },
        chart: {
          type: 'donut',
          height: 125,
          fontFamily: 'Lato, Helvetica Neue, sans-serif',
          selection: { enabled: false },
          zoom: { enabled: false },
          width: 100,
          toolbar: { show: false },
        },
        plotOptions: {
          pie: {
            donut: {
              size: '40%',
              labels: { show: false },
            },
            offsetY: 40,
            customScale: 1.6,
            expandOnClick: false,
          },
        },
        fill: { opacity: 1 },
        states: {
          hover: { filter: { type: 'none' } },
          active: { filter: { type: 'none' } },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        labels: ['Not selected', 'Selected'],
        tooltip: {
          enabled: false,
        },
      }
    },
    createChart() {
      this.$nextTick(() => {
        if (this.chartIsLoading || !this.checkOptionsHasChanged()) return

        this.chartIsLoading = true

        if (this.chartInstance) this.chartInstance.destroy()

        setTimeout(() => {
          this.chartInstance = new ApexCharts(this.$refs.chart, this.chartOptions)
          this.chartInstance.render()
          this.chartIsLoading = false
          this.seriesCached = this.serializeSeries()

          setTimeout(() => {
            window.dispatchEvent(new Event('resize'))
          }, 500)
        }, 200)
      })
    },
    updateChartOptions(newOptions) {
      if (this.chartInstance) {
        this.chartInstance.updateOptions(newOptions, false, true, true)
      } else {
        this.createChart()
      }
    },
    checkOptionsHasChanged() {
      return this.seriesCached != this.serializeSeries()
    },
    serializeSeries() {
      return JSON.stringify(this.chartOptions.series)
    },
  },
}
</script>

<style lang="scss">
.chart-section {
  display: flex;
  flex-direction: column;

  &:nth-of-type(2) {
    @media (max-width: 1180px) {
      margin-top: 8px;
    }
  }

  &-chart {
    @media (max-width: 1180px) {
      margin-left: -16px;
    }
  }

  @media (min-width: 1180px) {
    flex-direction: row;
    margin-left: -16px;
    align-items: center;
  }

  .chart-section-text-wrapper {
    .info-icon {
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    .chart-section-text-wrapper-title {
      position: relative;

      &:hover .info-icon {
        opacity: 1;
      }
    }
  }
}
</style>
