<template>
  <PsAccordionItem
    title="Current Grid Clean Electricity Share"
    class="display-impact-assumption-icon"
    :class="{ 'form-dirty' : hasValueUpdated(getCurrentGrid,assumption.current_grid_renewable_level) || 
      hasValueUpdated(getCurrentKwhEmissionFactor,assumption.current_kwh_emissions_factor)
    }"
  >
    <template #header-additionals>
      <PsIcon
        icon="help_outline"
        size="16"
        display="flex"
        class="help psui-items-center psui-h-5 hover:psui-text-blue-60"
        :icon-classes="hasValueUpdated(getCurrentGrid,assumption.current_grid_renewable_level) || 
          hasValueUpdated(getCurrentKwhEmissionFactor,assumption.current_kwh_emissions_factor) ? 'mr-5': ''"
        @click.native.stop="$eventBus.$emit('openDescriptionModal', { slug: 'impact_current_grid_clean_electricity_share_assumption', ...descriptionModelObject })"
      />
    </template>
    <template #default>
      <span class="psui-text-big psui-text-gray-60">
        We assume that the current percentage of grid supplied electricity that is generated from renewable or zero-emission power sources is:
      </span>
      <PsInput
        :data-test-id="`input-assumption-${assumption.current_grid_renewable_level}`" 
        class="mt-4/5 w-1/2"
        :has-error="inputErrorIndex && !advancedMode ? inputErrorIndex : false"
        :class="{'selected':hasValueUpdated(getCurrentGrid,assumption.current_grid_renewable_level) && !inputErrorIndex }"
        layout="mini"
        :value="getCurrentGrid"
        @input="onInputCurrentGrid"
        @blur="onBlurCurrentGrid"
        @change="onChangeCurrentGrid"
        @click.native="checkIfUserCanEditAssumption"
        @mouseenter="showResetButton = assumption.current_grid_renewable_level"
        @mouseleave="showResetButton = false"
      >
        <template #append>
          <span
            v-if="!hasValueUpdated(getCurrentGrid,assumption.current_grid_renewable_level) || 
              showResetButton != assumption.current_grid_renewable_level"
            class="psui-text-gray-50 psui-text-xsmall"
          >%</span>
          <PsTooltip v-else-if="showResetButton == assumption.current_grid_renewable_level">
            <template #trigger>
              <PsIcon 
                icon="restart_alt"
                icon-classes="psui-text-green-20 cursor-pointer mt-1"
                size="17"
                @click.native="updateCurrentGrid(
                  getBaseValue(assumption.current_grid_renewable_level), true
                )"  
              />
            </template>
            <template #content>
              <h1 class="mb-1">
                Restore to default value
              </h1>
              <span class="psui-font-normal">{{ getBaseValue(assumption.current_grid_renewable_level) }}</span>
            </template>
          </PsTooltip>
        </template>
      </PsInput>

      <div class="mt-4/5">
        <p class="psui-text-small psui-text-gray-60">
          Resulting in:
        </p>
        <div class="flex place-items-start justify-between mt-2">
          <PsInput
            :data-test-id="`input-assumption-${assumption.current_kwh_emissions_factor}`"
            :value="getCurrentKwhEmissionFactor"
            class="mr-5 w-full append-container"
            layout="mini"
            :has-error="inputErrorIndex && advancedMode ? inputErrorIndex : false"
            :class="{'selected':hasValueUpdated(getCurrentKwhEmissionFactor,assumption.current_kwh_emissions_factor) && 
              !openEmissionFactorInput && !inputErrorIndex}"
            :hint="`${minCurrentKwhEmissionFactor} - ${maxCurrentKwhEmissionFactor}`"
            :disabled="openEmissionFactorInput"
            @input="onInputCurrentKwhEmissionFactor"
            @blur="onBlurCurrentKwhEmissionFactor"
            @change="onChangeCurrentKwhEmissionFactor"
            @click.native="checkIfUserCanEditAssumption"
            @mouseenter="showResetButton = assumption.current_kwh_emissions_factor"
            @mouseleave="showResetButton = false"
          >
            <template #append>
              <span
                v-if="openEmissionFactorInput || !hasValueUpdated(getCurrentKwhEmissionFactor,assumption.current_kwh_emissions_factor) || showResetButton !== assumption.current_kwh_emissions_factor"
                class="psui-text-xsmall psui-text-gray-40"
              >MTCO<sub>2</sub>/kWh</span>
              <PsTooltip
                v-else-if="showResetButton === assumption.current_kwh_emissions_factor && 
                  hasValueUpdated(getCurrentKwhEmissionFactor,assumption.current_kwh_emissions_factor)"
              >
                <template #trigger>
                  <PsIcon 
                    icon="restart_alt"
                    icon-classes="psui-text-green-20 cursor-pointer mt-1"
                    size="17"
                    @click.native="updateCurrentKwhEmissionFactor(
                      getBaseValue(assumption.current_kwh_emissions_factor), true
                    )"  
                  />
                </template>
                <template #content>
                  <h1 class="mb-1">
                    Restore to default value
                  </h1>
                  <span class="psui-font-normal">{{ getBaseValue(assumption.current_kwh_emissions_factor) }}</span>
                </template>
              </PsTooltip>
            </template>
          </PsInput>

          <PsTooltip css-class="w-48">
            <template #trigger>
              <PsIcon
                data-test-id="icon-open-emission-factor"
                :icon="openEmissionFactorInput ? 'edit': 'lock_outline'"
                size="17"
                icon-classes="cursor-pointer mt-2 psui-text-gray-40 hover:psui-text-blue-60"
                @click.native.stop="openCurrentElectricRenewableShare"
              />
            </template>
            <template #content>
              <span>{{ 
                openEmissionFactorInput ?
                  'Specify BOTH current electric renewable share and current electric emissions factor.' :
                  'Specify JUST the current electric renewable share, and we will calculate the electric emissions factor.'
              }}</span>
            </template>
          </PsTooltip>
        </div>
      </div>        
    </template>
  </PsAccordionItem>
</template>

<script>
import { defaultExistingBuildingsImpactAssumptionValues, defaultNewBuildingsImpactAssumptionValues } from '@/models/ImpactAssumptions'
import { STUDY_TYPES_DB_SLUGS } from '@/store/global-entities/StudyType.js'
import { ASSUMPTIONS_KEYS, ASSUMPTIONS_DRAWER_DIVISION } from '@/util/Enums.js'
import AssumptionImpactsMixin from './AssumptionImpactsMixin'

// Source : https://docs.google.com/spreadsheets/d/16PIVWUg9dK3Uz-42CvEvG6QPeiTzEsVO0lhiaq8a7yI/edit#gid=802498198
const zeroRenewableKwhEmissionFactor = 0.000381587301587302

export default {
  name: 'AssumptionsImpactsCurrentGridCleanElectricityShare',
  props:['tabSelected', 'userCanEditPolicy', 'descriptionModelObject'],
  mixins: [ AssumptionImpactsMixin ],
  data: () => ({
    advancedMode: false,
    updateDebounce: null,
    openEmissionFactorInput: true,
    localAssumption: {},
    minCurrentGridValue:0,
    maxCurrentGridValue: 100,
    minCurrentKwhEmissionFactor: 0,
    maxCurrentKwhEmissionFactor: 0.001,
    showResetButton: false,
    inputCurrentGridRaw: '',
    inputCurrentKwhEmissionFactorRaw: '',
    disable_function:true,
    isEditing: false,
    assumption:{
      current_grid_renewable_level: ASSUMPTIONS_KEYS.current_grid_renewable_level,
      current_kwh_emissions_factor: ASSUMPTIONS_KEYS.current_kwh_emissions_factor
    }
  }),
  computed: {
    currentGridBaseValue(){
      return this.tabSelected.slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS ? defaultNewBuildingsImpactAssumptionValues.current_grid_renewable_level : defaultExistingBuildingsImpactAssumptionValues.current_grid_renewable_level
    },
    currentKwhEmissionFactorBaseValue(){
      return this.tabSelected.slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS ? defaultNewBuildingsImpactAssumptionValues.current_kwh_emissions_factor : defaultExistingBuildingsImpactAssumptionValues.current_kwh_emissions_factor
    },
    simpleCurrentKwhEmissionFactor() {      
      return ((1 - (this.getCurrentGrid/100)) * zeroRenewableKwhEmissionFactor).toPrecision(5)
    },
    getCurrentGrid() {
      if(this.isEditing == this.assumption.current_grid_renewable_level) return this.inputCurrentGridRaw
      return this.getAssumptionFromPolicyByKey(this.assumption.current_grid_renewable_level) ?? this.$store.state.assumptions[this.$store.state.assumptions.drawerOptions.type][this.assumption.current_grid_renewable_level] 
    },
    getCurrentKwhEmissionFactor() {
      if(this.isEditing == this.assumption.current_kwh_emissions_factor) return this.inputCurrentKwhEmissionFactorRaw 
      return this.localAssumption[this.assumption.current_kwh_emissions_factor] ?? this.getAssumptionFromPolicyByKey(this.assumption.current_kwh_emissions_factor) ?? this.$store.state.assumptions[this.$store.state.assumptions.drawerOptions.type][this.assumption.current_kwh_emissions_factor] 
    },

  },
  watch: {
    advancedMode(value) {
      if (!value) {
        this.setValuePerPrototype(parseFloat(this.simpleCurrentKwhEmissionFactor),this.assumption.current_kwh_emissions_factor)
      }
    },
  },
  mounted() {
    if (parseFloat(this.getCurrentKwhEmissionFactor) !== parseFloat(this.simpleCurrentKwhEmissionFactor)) {
      this.advancedMode = true
    }

    this.$eventBus.$on('restoreAssumption', (key) => {
      if (key === ASSUMPTIONS_DRAWER_DIVISION.ADVANCED) {
        this.updateLocalAssumption(this.currentGridBaseValue, this.assumption.current_grid_renewable_level)
        this.updateLocalAssumption(this.currentKwhEmissionFactorBaseValue, this.assumption.current_kwh_emissions_factor)
      }
    })

  },
  methods: {
    onInputCurrentGrid(event){
      this.isEditing = this.assumption.current_grid_renewable_level
      this.inputCurrentGridRaw = event.target.value
    },

    onBlurCurrentGrid() {
      this.onChangeCurrentGrid()
      this.isEditing = false
      this.dispatchAnalyticsEvent(this.assumption.current_grid_renewable_level)
    },

    onChangeCurrentGrid() {
      if(this.inputCurrentGridRaw === '') return
      this.updateCurrentGrid(this.inputCurrentGridRaw)
      setTimeout(() => {
        this.inputCurrentGridRaw = ''
      }, 10)
    },

    updateCurrentGrid(value, dispatchGaEvent = false) {

      if(!this.validateInput(this.minCurrentGridValue, this.maxCurrentGridValue, value)) {
        this.inputErrorIndex = 'Insert a valid value'
        setTimeout(() => {
          this.inputErrorIndex = false
        }, 2000)
        return
      }

      if (!this.advancedMode) {
        this.ableToSendGaEvent.push(this.assumption.current_kwh_emissions_factor)
      }
      this.ableToSendGaEvent.push(this.assumption.current_grid_renewable_level)

      if (this.updateDebounce) {
        clearTimeout(this.updateDebounce)
      }

      this.updateDebounce = setTimeout(() => {
        if (!this.advancedMode) {
          const currentKwhEmissionFactor = (1 - (value /100)) * zeroRenewableKwhEmissionFactor
          this.setValuePerPrototype(this.precise(currentKwhEmissionFactor),this.assumption.current_kwh_emissions_factor)
          if (dispatchGaEvent) {
            this.dispatchAnalyticsEvent(this.assumption.current_kwh_emissions_factor, true)
          }
        }
        this.setValuePerPrototype(parseFloat(value),this.assumption.current_grid_renewable_level)
        if (dispatchGaEvent) {
          this.dispatchAnalyticsEvent(this.assumption.current_grid_renewable_level, true)
        }
      }, 0)
    },

    onInputCurrentKwhEmissionFactor(event) {
      this.isEditing = this.assumption.current_kwh_emissions_factor
      this.inputCurrentKwhEmissionFactorRaw = event.target.value
    },

    onBlurCurrentKwhEmissionFactor() {
      this.onChangeCurrentKwhEmissionFactor()
      this.isEditing = false
      this.dispatchAnalyticsEvent(this.assumption.current_kwh_emissions_factor)
    },
    
    onChangeCurrentKwhEmissionFactor() {
      if(this.inputCurrentKwhEmissionFactorRaw === '') return
      this.updateCurrentKwhEmissionFactor(this.inputCurrentKwhEmissionFactorRaw)
      setTimeout(() => {
        this.inputCurrentKwhEmissionFactorRaw = ''
      }, 10)
    },

    updateCurrentKwhEmissionFactor(value, dispatchGaEvent = false) {
      if(!this.validateInput(this.minCurrentKwhEmissionFactor, this.maxCurrentKwhEmissionFactor, value)) {
        this.inputErrorIndex = 'Insert a valid value'
        setTimeout(() => {
          this.inputErrorIndex = false
        }, 2000)
        return
      }

      this.ableToSendGaEvent.push(this.assumption.current_kwh_emissions_factor)
      if (dispatchGaEvent) {
        this.dispatchAnalyticsEvent(this.assumption.current_kwh_emissions_factor, true)
      }
      if (this.updateDebounce) {
        clearTimeout(this.updateDebounce)
      }

      this.updateDebounce = setTimeout(() => {
        this.setValuePerPrototype(parseFloat(this.precise(value)),this.assumption.current_kwh_emissions_factor)
      }, 250)
    },
    openCurrentElectricRenewableShare(){
      if (this.checkIfUserCanEditAssumption()) {
        this.openEmissionFactorInput = !this.openEmissionFactorInput
        this.advancedMode = !this.advancedMode        
      }
    },
    setValuePerPrototype(value,key) {
      if(!this.$route.params.policy_id) {
        this.$store.dispatch('assumptions/updateAssumptionsKeyValue', { key, value, force: true })
      } else {
        this.$store.dispatch('assumptions/updateSelectedCustomCombinationByPrototypeSelected', { custom_combinations:this.getPolicySelectedCustomCombinations, prototypes:this.getPrototypesWithinPolicySelected, value, key})
      }
      this.updateLocalAssumption(value,key)
    },
    updateLocalAssumption(value,key){
      this.$set(this.localAssumption,key,value)
      this.ableToSendGaEvent.push(key)
    },
    getAssumptionFromPolicyByKey(key){
      return this.$route.params.policy_id ? 
      this.$store.getters['assumptions/getterPolicyContextCustomCombinationByKey']({policy_id: this.$route.params.policy_id, key:key}) :
      null 
    },
    validateInput(min,max,value){ 
      return value <= max && value >= min
    },
  }
}
</script>

<style lang="scss" scoped>

.psui-el-input.status-resting.layout-mini, .psui-el-input {
    &.selected{
      ::v-deep .psui-el-input-wrapper {
        border-color: #5DB883;
      }
    }

    ::v-deep.psui-el-tooltip-dialog { 
      width: fit-content;
       .psui-el-tooltip-content {
        border-radius: 6px;
        background-color: #D6DDE5;
        color: #28323B;
        padding: 10px 16px; 
       }
    }
  }

  ::v-deep .psui-el-input.status-resting.layout-mini.selected,
  ::v-deep .psui-el-input.status-active.layout-mini.selected{
    .psui-el-input-wrapper {
      border-color: #5DB883;
    }

    .psui-el-input-append {
      margin-left: auto;
    }


  }

  ::v-deep .psui-el-input.status-error.layout-mini {
    position: relative;
    .psui-el-input-hint {
      position: absolute;
    }
    .psui-el-input-wrapper {
      .psui-el-input-append {
        display: none;
      }
    }
  }

  .psui-el-accordion-item {
    &.status-opened {
      .help {
        color: #318FAC;
      }
    }
  }

  ::v-deep .append-container .psui-el-input-append {
    justify-content: flex-end;
    width: 100%;
  }
  
</style>
