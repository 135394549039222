<template>
  <PsAccordionItem
    title="Clean Electricity Progress"
    class="display-impact-assumption-icon"
    :class="{ 'form-dirty' : hasValueUpdated(getSingleInputValue(assumption.grid_max_renewables_level), assumption.grid_max_renewables_level) ||
      hasValueUpdated(getSingleInputValue(assumption.grid_max_renewables_year), assumption.grid_max_renewables_year) 
    }"
  >
    <template #header-additionals>
      <PsIcon
        icon="help_outline"
        size="16"
        display="flex"
        class="help psui-items-center psui-h-5 hover:psui-text-blue-60"
        :icon-classes="hasValueUpdated(getSingleInputValue(assumption.grid_max_renewables_level), assumption.grid_max_renewables_level) ||
          hasValueUpdated(getSingleInputValue(assumption.grid_max_renewables_year), assumption.grid_max_renewables_year) ? 'mr-5': ''"
        @click.native.stop="$eventBus.$emit('openDescriptionModal', { slug: 'impact_card_clean_electricity_progress_assumption', ...descriptionModelObject })"
      />
    </template>
    <template #default>
      <span>
        Electricity from the grid will transition steadily away from fossil fuel sources to renewable or zero-emission power sources. We assume <b>a maximum clean energy share</b> will be achieved by <b>a certain year</b>.
      </span>
      <div class="flex justify-between mt-4/5 items-center"> 
        <span class="psui-text-small psui-text-gray-60">Leveling off at a<br> maximum of</span>
        <PsInput
          :data-test-id="`input-assumption-${assumption.grid_max_renewables_level}`"
          class="input-grid-max-renewables-level"
          layout="mini"
          :has-error="inputErrorIndex == assumption.grid_max_renewables_level ? 'Insert a valid value' : false"
          :class="{'selected':hasValueUpdated(getSingleInputValue(assumption.grid_max_renewables_level), assumption.grid_max_renewables_level) &&
            inputErrorIndex != assumption.grid_max_renewables_level}"
          :value="getSingleInputValue(assumption.grid_max_renewables_level)"
          @input.stop="onInput($event,assumption.grid_max_renewables_level)"
          @change="onChange"
          @blur="onBlur(assumption.grid_max_renewables_level)"
          @click.native="checkIfUserCanEditAssumption"
          @mouseenter="showResetButton = assumption.grid_max_renewables_level"
          @mouseleave="showResetButton = false"
        >
          <template #append>
            <span
              v-if="!hasValueUpdated(getSingleInputValue(assumption.grid_max_renewables_level), assumption.grid_max_renewables_level) ||
                showResetButton !== assumption.grid_max_renewables_level"
              class="psui-text-gray-50 psui-text-xsmall"
            >%</span>
            <PsTooltip v-else-if="showResetButton === assumption.grid_max_renewables_level">
              <template #trigger>
                <PsIcon 
                  icon="restart_alt"
                  icon-classes="psui-text-green-20 cursor-pointer mt-1"
                  size="17"
                  @click.native="_doResetAssumption(assumption.grid_max_renewables_level)"
                />
              </template>
              <template #content>
                <h1 class="mb-1">
                  Restore to default value
                </h1>
                <span class="psui-font-normal">{{ getBaseValue(assumption.grid_max_renewables_level) }}</span>
              </template>
            </PsTooltip>
          </template>
        </PsInput>
      </div>
      <div class="flex justify-between mt-5">
        <span class="psui-text-small psui-text-gray-60 mt-2">By the year</span>
        <PsInput
          :data-test-id="`input-assumption-${assumption.grid_max_renewables_year}`"
          layout="mini"
          :has-error="inputErrorIndex == assumption.grid_max_renewables_year ? 'Insert a valid value' : false"
          :class="{'selected':hasValueUpdated(getSingleInputValue(assumption.grid_max_renewables_year), assumption.grid_max_renewables_year) && 
            inputErrorIndex != assumption.grid_max_renewables_year}"
          :value="getSingleInputValue(assumption.grid_max_renewables_year)"
          :hint="`${minGridMaxRenewablesYearValue} - ${maxGridMaxRenewablesYearValue}`"
          @input.stop="onInput($event,assumption.grid_max_renewables_year)"
          @change="onChange"
          @blur="onBlur(assumption.grid_max_renewables_year)"
          @click.native="checkIfUserCanEditAssumption"
          @mouseenter="showResetButton = assumption.grid_max_renewables_year"
          @mouseleave="showResetButton = false"
        >
          <template #append>
            <PsTooltip
              v-if="hasValueUpdated(getSingleInputValue(assumption.grid_max_renewables_year), assumption.grid_max_renewables_year) && 
                showResetButton === assumption.grid_max_renewables_year"
            >
              <template #trigger>
                <PsIcon 
                  icon="restart_alt"
                  icon-classes="psui-text-green-20 cursor-pointer mt-1"
                  size="17"
                  @click.native="_doResetAssumption(assumption.grid_max_renewables_year)"
                />
              </template>
              <template #content>
                <h1 class="mb-1">
                  Restore to default value
                </h1>
                <span class="psui-font-normal">{{ getBaseValue(assumption.grid_max_renewables_year) }}</span>
              </template>
            </PsTooltip>
          </template>
        </PsInput>
      </div>
    </template>
  </PsAccordionItem>
</template>

<script>
import { ASSUMPTIONS_KEYS } from '@/util/Enums.js'
import AssumptionImpactsMixin from './AssumptionImpactsMixin'

export default {
  name: 'AssumptionsCardCleanElectricityProgress',
  props:['tabSelected','userCanEditPolicy', 'descriptionModelObject'],
  mixins: [ AssumptionImpactsMixin ],
  data:()=>({
    localAssumptionValue:{},
    minGridMaxRenewablesLevelValue: 0,
    maxGridMaxRenewablesLevelValue: 100,
    minGridMaxRenewablesYearValue: 2015,
    maxGridMaxRenewablesYearValue: 2080,
    showResetButton: false,
    inputKeySelected: '',
    gridMaxRenewLevelAndYearInputEditsAsItIs: '',
    assumption: {
      grid_max_renewables_level: ASSUMPTIONS_KEYS.grid_max_renewables_level,
      grid_max_renewables_year: ASSUMPTIONS_KEYS.grid_max_renewables_year
    }
  }),
  methods: {
    setValue(value,key){
      this.$store.dispatch('assumptions/updateAssumptionsKeyValue', { key, value, force: true })
    },
    // input events
    onInput(event, key ) {
      this.inputKeySelected = key
      this.gridMaxRenewLevelAndYearInputEditsAsItIs = event.target.value
    },
    onChange() {
      if(this.gridMaxRenewLevelAndYearInputEditsAsItIs === '') return

        this.setValuePerPrototype(
          this.gridMaxRenewLevelAndYearInputEditsAsItIs,
          this.inputKeySelected
        )

      setTimeout(() => {
        this.gridMaxRenewLevelAndYearInputEditsAsItIs = ''
      }, 10)
    },
    _doResetAssumption(assumptionKey) {
      this.setValuePerPrototype(this.getBaseValue(assumptionKey), assumptionKey)
      this.dispatchAnalyticsEvent(assumptionKey, true)
    },
    setValuePerPrototype(value, key){
      if(!this.validateInput(value,key)) {
        this.inputErrorIndex = key
        setTimeout(() => {
          this.inputErrorIndex = false
        }, 2000)
        return
      }
      this.updateLocalAssumption(value,key)

      if(!this.$route.params.policy_id) {
        this.setValue(value,key)
      } else {
        this.$store.dispatch('assumptions/updateSelectedCustomCombinationByPrototypeSelected', { custom_combinations:this.getPolicySelectedCustomCombinations, prototypes: this.getPrototypesWithinPolicySelected, value, key})
      }
    },
    updateLocalAssumption(value,key){
      this.$set(this.localAssumptionValue,key,value)
      this.ableToSendGaEvent.push(key)
    },
    onBlur(assumptionKey) {
      this.onChange()
      this.dispatchAnalyticsEvent(assumptionKey)
    },
    validateInput(value, assumptionKey) {
      let min, max
      if(assumptionKey == ASSUMPTIONS_KEYS.grid_max_renewables_level) {
        min = this.minGridMaxRenewablesLevelValue
        max = this.maxGridMaxRenewablesLevelValue
      } else {
        min = this.minGridMaxRenewablesYearValue
        max = this.maxGridMaxRenewablesYearValue
      }
      return value <= max && value >= min
    },
    getAverageValueFromPrototypesByKey(assumptionKey) {
      let AverageValue = 0
      this.getPrototypesWithinPolicySelected.forEach((prototype) => {
        AverageValue += parseFloat(this.getValuePerPrototype(assumptionKey, prototype))
      })
      return AverageValue/this.getPrototypesWithinPolicySelected.length
    },
    getSingleInputValue(assumptionKey) {
      return !this.$route.params.policy_id ? this.getValue(assumptionKey) : this.getAverageValueFromPrototypesByKey(assumptionKey)
    } 
  }
}
</script>

<style lang="scss" scoped>

  .psui-el-input.status-resting.layout-mini, .psui-el-input {
    width: 140px;
    &.selected{
      ::v-deep .psui-el-input-wrapper {
        border-color: #5DB883;
      }
    }
    ::v-deep.psui-el-tooltip-dialog { 
      width: fit-content;
       .psui-el-tooltip-content {
        border-radius: 6px;
        background-color: #D6DDE5;
        color: #28323B;
        padding: 10px 16px; 
       }
    }
  }

  .psui-el-accordion-item {
    &.status-opened {
      .help {
        color: #318FAC;
      }
    }
  }

  ::v-deep .psui-el-input.input-grid-max-renewables-level.status-error.layout-mini {
    position: relative;
      .psui-el-input-hint {
        position: absolute;
      }
      .psui-el-input-wrapper {
        .psui-el-input-append {
          display: none;
        }
      }
    }
    ::v-deep .psui-el-input.status-error.layout-mini {
      .psui-el-input-wrapper {
        .psui-el-input-append {
          display: none;
        }
      }
    }


</style>
