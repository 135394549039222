<template>
  <div>
    <BaseAppHeader 
      id="building-estimates-header"
      title="Building Estimates for"
      :has-jurisdiction-selection="true"
      @on-jurisdiction-select="onJurisdictionSelect"
    >
      <template #top-right>
        <div class="base-header__actions psui-flex-shrink-0 psui-w-full psui-flex psui-items-center psui-space-x-2">
          <PsButton
            label="Edit Assumptions"
            icon="tune"
            size="small"
            layout="onlytext"
            @click="openAssumptionsDrawer"
          />
          <PsButton
            id="share-button"
            label="Share"
            icon="share"
            size="small"
            layout="onlytext"
            class="psui-text-blue-50"
            @click="onClickShare"
          />
          <Dropdown
            ref="dropdown"
            :button-classes="'px-2 bg-transparent w-full'"
            :dropdown-dialog-classes="'left-full bg-white'"
            :run-on-top="true"
          >
            <template #buttonLabel>
              <PsButton
                label="Download"
                icon="download"
                size="small"
                layout="onlytext"
                class="psui-text-blue-60"
              />
            </template>
            <template #items>
              <DropdownMenuList
                :items="['PDF', 'CSV']"
                :alert="downloadAlert"
                @change="$refs.dropdown.close(); $emit('trigger-download', $event)"
              />
            </template>
          </Dropdown>
        </div>
      </template>
      <template #bottom-left>
        <div class="relative">
          <BuildingEstimateFilterBuildingType />
          <AppNewFeatureBadge
            v-if="!downloadAlert"
            feature="bps"
            layout="circle"
            tooltip="<p class=&quot;psui-text-xsmall psui-font-bold&quot; style='margin-bottom: 10px'>New data available!</p><p class=&quot;psui-text-xsmall&quot;>Explore new estimates for existing nonresidential buildings.</p>"
            tooltip-position="right"
            :tooltip-sum-left="-100"
            :tooltip-sum-top="55"
          />
        </div>
      </template>
      <template #bottom-right>      
        <BuildingEstimateFilterClimateZone class="psui-mb-2" />
      </template>
    </BaseAppHeader>
  </div>
</template>

<script>
import BaseAppHeader from '@/components/general/BaseAppHeader.vue'
import BuildingEstimateFilterBuildingType from './filters/BuildingEstimateFilterBuildingType'
import BuildingEstimateFilterClimateZone from './filters/BuildingEstimateFilterClimateZone'
import { mapGetters } from 'vuex'
import { copyUrlToClipboard } from '@/util/Helpers'
import { GA_LABELS } from '@/mixins/GaEventsMixin'
import { ASSUMPTIONS_PATHS } from '@/modules/app/assumptions/shared/enums.js'
import { BUILDING_TYPE_DB_SLUGS }from '@/util/Enums.js'

export default {
  name: 'BuildingEstimatesHeader',
  components: {
    BuildingEstimateFilterBuildingType,
    BuildingEstimateFilterClimateZone, BaseAppHeader
  },
  computed: {
    ...mapGetters(['lastJurisdictionVisited']),
    getRouteBuildingTypeSelected(){
      return this.$route.query.only_building_type ?? BUILDING_TYPE_DB_SLUGS.RESIDENTIAL
    },
    downloadAlert() {
      const canShowAlert = Boolean(this.$route.name === 'BuildingEstimates' &&
          this.$route.query.only_building_type === ASSUMPTIONS_PATHS.NON_RESIDENTIAL_BUILDINGS.slug)
      return canShowAlert ? `Downloads for existing nonresidential data will be available in future versions` : null
    }
  },
  watch:{
    getRouteBuildingTypeSelected: {
      handler(value) {
        const debounce = setInterval( () => {
        const shareButton = document.getElementById('share-button')
        const dropdown = document.querySelector('.dropdowndialog')
        
        if(!shareButton || !dropdown) return
        if(value == BUILDING_TYPE_DB_SLUGS.RESIDENTIAL) {
          dropdown.style.setProperty('--left-position', `unset`)
        } else {
          dropdown.style.setProperty('--left-position', `${shareButton.getBoundingClientRect().x + 10}px`)
        }

          clearInterval(debounce)
        }, 400)
      },
      immediate: true
    }
  },
  mounted(){

    // 
    
  },
  methods: {
    onJurisdictionSelect(jurisdiction) {
      this.$store.dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', { value: jurisdiction, context: 'updateLastJurisdictionVisitedFromStudyResults'})
      this.pushToParams('jurisdiction_slug', jurisdiction.slug)
    },
    onClickShare() {
      copyUrlToClipboard()
      this.$appToast({ message: 'Link copied to clipboard!', duration: 5500 })
      this.gaEventShareUrl(GA_LABELS.BUILDINGSTOCK_SHARE_URL)
    },
    openAssumptionsDrawer() {
      const getBuildingTypeSLug = {
        [BUILDING_TYPE_DB_SLUGS.RESIDENTIAL]:ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS,
        [BUILDING_TYPE_DB_SLUGS.NON_RESIDENTIAL]:ASSUMPTIONS_PATHS.NON_RESIDENTIAL_BUILDINGS
      }[this.getRouteBuildingTypeSelected]
      
      this.$store.commit('assumptions/openDrawerAtSelectedAssumptionsPath', getBuildingTypeSLug)
      this.$store.commit('assumptions/updateDrawerIsEditingFromChart', false)
    }
  }
}
</script>

<style lang="scss">
    .dropdowndialog {
      --left-position: unset;

      left: var(--left-position) !important;
    }
</style>