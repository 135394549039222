import DataAndObjectFunctions from '@/util/DataAndObjectFunctions'
import CustomBuildingStock from '@/models/CustomBuildingStock'

export default {
  
  getterDefaultJurisdictionBuildingStocks: (state, getters, rootState, rootGetters) => (filters = null) => {
      const getterFilters = {
        ...DataAndObjectFunctions.filterObjectKeys(CustomBuildingStock.filtrableKeys, filters),
        jurisdiction_id : rootGetters['lastJurisdictionVisited']?.id ?? null,
    }
    return state.jurisdiction_building_stocks.findAllInArray(getterFilters)
  },
  getterBpsDefaultJurisdictionBuildingStocks: (state, getters, rootState, rootGetters) => (filters = null) => {
    const getterFilters = {
      ...DataAndObjectFunctions.filterObjectKeys(CustomBuildingStock.filtrableKeys, filters),
      jurisdiction_id : rootGetters['lastJurisdictionVisited']?.id ?? null,
    }
    return state.bps_jurisdiction_building_stocks.findAllInArray(getterFilters)
},
    
  getterDefaultJurisdictionBuildingStocksUnits: (state, getters) => (filters = null) => {
    return getters['getterDefaultJurisdictionBuildingStocks'](filters)
      .reduce((acc, building_stock) => acc + building_stock.units, 0)
  },

  getterCustomJurisdictionBuildingStocks: (state, getters, rootState, rootGetters) => (filters = null) => {    
    const getterFilters = {
      ...DataAndObjectFunctions.filterObjectKeys(CustomBuildingStock.filtrableKeys, filters),
      jurisdiction_id : rootGetters['lastJurisdictionVisited']?.id ?? null,
      policy_id : rootState.assumptions.policy_id_selected ?? null,
    }
    return state.custom_building_stocks.findAllInArray(getterFilters)
  },

  getterCustomJurisdictionBuildingStocksUnits: (state, getters) => (filters = null) => {
    return getters['getterCustomJurisdictionBuildingStocks'](filters)
      .reduce((acc, building_stock) => acc + building_stock.units, 0)
  },

  getterAllBuildingStocks: (state, getters) => (filters = null) => {    
    
    let jurisdictionBuildingStocks = []

    const defaultJurisdictionBuildingStocks = getters['getterDefaultJurisdictionBuildingStocks'](filters)    
    const customJurisdictionBuildingStocks = getters['getterCustomJurisdictionBuildingStocks'](filters)

    if(defaultJurisdictionBuildingStocks.length) {

      for(const defaultJurisdictionBuildingStock of defaultJurisdictionBuildingStocks) {
        
        const customBuildingStock = customJurisdictionBuildingStocks.findInArray({
          climate_zone_prefix: defaultJurisdictionBuildingStock.climate_zone_prefix,
          type_prototype_id: defaultJurisdictionBuildingStock.type_prototype_id,
          type_vintage_id: defaultJurisdictionBuildingStock.type_vintage_id,
        })

        const isInArray = jurisdictionBuildingStocks.findInArray({
          climate_zone_prefix: defaultJurisdictionBuildingStock.climate_zone_prefix,
          type_prototype_id: defaultJurisdictionBuildingStock.type_prototype_id,
          type_vintage_id: defaultJurisdictionBuildingStock.type_vintage_id,
        })

        if (!isInArray) {
          if (customBuildingStock) {
            jurisdictionBuildingStocks.push(customBuildingStock)
          } else {
            jurisdictionBuildingStocks.push(defaultJurisdictionBuildingStock)
          }
        }
      }

    } else {
      jurisdictionBuildingStocks = customJurisdictionBuildingStocks
    }

    return [...jurisdictionBuildingStocks ]

  },  

  getterAllBuildingStocksUnits: (state, getters) => (filters = null) => {
    const jurisdictionBuildingStocks = getters['getterAllBuildingStocks'](filters)
    return jurisdictionBuildingStocks.length > 0 ? jurisdictionBuildingStocks.reduce((acc, building_stock) => acc + building_stock.units, 0) : null
  },

  getterCustomBuildingStockByPolicyId: (state) => (policy_id) => {
    return state.custom_building_stocks.findAllInArray({ policy_id })
  },
  
}
