<template>
  <div class="w-full h-full flex justify-center p-8">
    <div class="flex flex-col py-8 pl-4 text-center">
      <img
        width="240"
        height="180"
        src="/images/policy_options/prescriptive.svg"
        alt="There are no results for your selected filters"
        class="mb-1.5 flex self-center"
      >
      <p class="text-[16px] font-bold text-[#002A3A] mb-1.5">
        There are no results for your selected filters
      </p>
      <p class="text-sm text-[#515E6A]">
        Please change your filters to view data.
      </p>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: 'PolicyOptionContentEmpty',
  }
  </script>
  