
export function selectionDrawing({ context, zoomtype }) {
  const w = this.w || context.w
  let me = context
  let gridRectDim = this.gridRect.getBoundingClientRect()

  let startX = me.startX - 1
  let startY = me.startY
  let inversedX = false
  let inversedY = false

  let selectionWidth = me.clientX - gridRectDim.left - startX - w.globals.barWidth
  let selectionHeight = me.clientY - gridRectDim.top - startY

  let selectionRect = {}

  if (Math.abs(selectionWidth + startX) > w.globals.gridWidth) {
    // user dragged the mouse outside drawing area to the right
    selectionWidth = w.globals.gridWidth - startX
  } else if (me.clientX - gridRectDim.left < 0) {
    // user dragged the mouse outside drawing area to the left
    selectionWidth = startX
  }

  // inverse selection X
  if (startX > me.clientX - gridRectDim.left - w.globals.barWidth) {
    inversedX = true
    selectionWidth = Math.abs(selectionWidth)
  }

  // inverse selection Y
  if (startY > me.clientY - gridRectDim.top) {
    inversedY = true
    selectionHeight = Math.abs(selectionHeight)
  }

  if (zoomtype === 'x') {
    selectionRect = {
      x: inversedX ? startX - selectionWidth : startX,
      y: 0,
      width: selectionWidth,
      height: w.globals.gridHeight,
    }
  } else if (zoomtype === 'y') {
    selectionRect = {
      x: 0,
      y: inversedY ? startY - selectionHeight : startY,
      width: w.globals.gridWidth,
      height: selectionHeight,
    }
  } else {
    selectionRect = {
      x: inversedX ? startX - selectionWidth : startX,
      y: inversedY ? startY - selectionHeight : startY,
      width: selectionWidth,
      height: selectionHeight,
    }
  }
  me.drawSelectionRect(selectionRect)
  me.selectionDragging('resizing')
  return selectionRect
}