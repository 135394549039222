<template>
  <div
    v-if="custom_combination.fuel"
    v-tooltip="getTooltip()"
    class="card-fuel psui-p-4 psui-flex psui-psui-rounded-6 psui-w-full psui-rounded-md psui-bg-white"
    :class="{
      'psui-bg-blue-10 !important': isHovering, 
      'card-fuel-disabled' : !isHovering && checkIfFuelChoiceIsNotAllowed,
    }"
    @mouseenter="onHoverFuelContainer"
    @mouseleave="onHoverFuelContainer"
  >
    <div class="psui-flex psui-items-center psui-w-full">
      <div 
        class="w-14 h-14 psui-flex-shrink-0 psui-flex psui-items-center justify-center psui-rounded-md"
        :class="[
          isHovering && !checkIfFuelChoiceIsNotAllowed ? 'psui-bg-blue-20' : 'psui-bg-gray-10', 
          !isHovering && checkIfFuelChoiceIsNotAllowed ? 'psui-opacity-50 psui-bg-gray-20' : '',
          isHovering && checkIfFuelChoiceIsNotAllowed ? 'psui-bg-blue-20' : ''
        ]"
      >
        <PsIcon
          :icon="`/icons/type-fuel/${custom_combination.fuel.slug}.svg`"
          size="32"
          :color="isHovering ? 'psui-text-blue-60' : 'psui-text-gray-50'"
        />
      </div>
      <div class="psui-flex psui-h-full psui-flex-col psui-flex-grow psui-ml-4">
        <span
          class="tooltip-styles psui-flex psui-items-center psui-justify-between psui-text-gray-70 psui-font-bold psui-text-big psui-mb-1"
          :class="{
            'psui-text-blue-60': isHovering, 
            'psui-text-opacity-50' : !isHovering && checkIfFuelChoiceIsNotAllowed,
          }"
        >
          <PsRichTooltip          
            @mouseenter.native="showTooltip = false"
            @mouseleave.native="showTooltip = true" 
          >
            <template #trigger>
              {{ custom_combination.fuel.title }}
            </template>
            <template          
              #content
            >
              <p class="text-xs font-bold text-gray-700 text-left mb-1">
                {{ custom_combination.fuel.title }}
              </p>
              <p class="text-xs font-normal text-gray-700 text-left leading-4">
                {{ getCustomCombinationFuelTooltipText(custom_combination.fuel.slug) }}
              </p>
            </template>
          </PsRichTooltip>

          <PsRichTooltip
            @mouseenter.native="showTooltip = false"
            @mouseleave.native="showTooltip = true"
          >
            <template #trigger>
              <PsBadgeWithIcon
                class="self-start ml-auto"
                :class="{
                  'badge-icon opacity-60' : !isHovering && checkIfFuelChoiceIsNotAllowed,
                  'opacity-100' : isHovering && checkIfFuelChoiceIsNotAllowed,
                }"
                :message="getFuelChoice | fuelChoiceFormatter"
                :icon="getFuelChoiceIcon"
              />            
            </template>
            <template          
              #content
            >
              <p class="text-xs font-bold text-gray-700 text-left mb-1">
                {{ getFuelChoice | fuelChoiceFormatter }}
              </p>
              <p class="text-xs font-normal text-gray-700 text-left leading-4">
                {{ getFuelChoiceTooltipText() }}
              </p>
            </template>
          </PsRichTooltip>
        </span>

        <span
          v-if="!checkIfFuelChoiceIsNotAllowed"
          class="psui-flex psui-text-gray-50 psui-text-small"
          :class="{ 'psui-text-blue-60': isHovering }"
          @mouseenter="onHoverComplianceMargin"
          @mouseleave="onHoverComplianceMargin"
        >
          <span class="description">- Compliance margin:</span> 
          {{ getComplianceMarginValue ? `${formatComplianceMarginAsText(getComplianceMarginValue)}`: '0' }}
          <PsIcon
            :style="{ 'display': 'flex' }"
            icon="info_outline"
            size="16"
            class="psui-items-center psui-justify-center psui-mx-2 hover:psui-bg-blue-10 hover:psui-text-blue-60"
            :class="isHoveringComplianceMargin ? 'opacity-1' : 'opacity-0'"
            @mouseenter.native="showTooltip = false"
            @mouseleave.native="showTooltip = true"
            @click.native.stop="openDescriptionModalHelperComplianceMargin"
          />
        </span>
        <span
          v-if="isPvSystemEnabled && !checkIfFuelChoiceIsNotAllowed"
          class="psui-flex psui-text-gray-50 psui-text-small"
          :class="{ 'psui-text-blue-60': isHovering }"
          @mouseenter="onHoverLargerPVSystem"
          @mouseleave="onHoverLargerPVSystem"
        >
          <span class="description">- Larger PV system:</span>
          {{ getPvSystemRequired }}
          <PsIcon
            :style="{ 'display': 'flex' }"
            icon="info_outline"
            size="16"
            class="psui-items-center psui-justify-center psui-mx-2 hover:psui-bg-blue-10 hover:psui-text-blue-60"
            :class="isHoveringLargerPVSystem ? 'opacity-1' : 'opacity-0'"
            @mouseenter.native="showTooltip = false"
            @mouseleave.native="showTooltip = true"
            @click.native.stop="openDescriptionModalHelperLargerPVSystem"
          />
        </span>
      </div>
    </div>
  </div>
</template>
 
<script>
import { FUEL_CHOICES } from '@/business-logic/services/impact-algorithms'
import {
  FUEL_CHOICE_ALLOWED,
  FUEL_CHOICE_MANDATORY,
  FUEL_CHOICE_NOT_ALLOWED
} from '../requirements/requirements-drawer/PolicyEditRequirementsNewBuildingsSF2022/enums'
  import { TYPE_FUELS_DB_SLUGS } from '@/util/Enums.js'
import {
  GeneralPerformanceByFuelPolicyImpactAlgorithm
} from '@/business-logic/services/impact-algorithms/policy/general-performance-by-fuel'
import { createTooltipOptions } from '@/util/Functions.js'

export default {
  name: 'PolicyRequirementFuelCell',
  filters:{
    fuelChoiceFormatter: (value) => {
      switch(value) {
        case FUEL_CHOICES.NOT_ALLOWED:
          return FUEL_CHOICE_NOT_ALLOWED.label
        case FUEL_CHOICES.ALLOWED:
          return FUEL_CHOICE_ALLOWED.label
        case FUEL_CHOICES.REQUIRED:
          return FUEL_CHOICE_MANDATORY.label
      }
      return '--'
     }
  },
  props: ['custom_combination'],
  data:()=>({
    isHovering: false,
    isHoveringComplianceMargin: false,
    isHoveringLargerPVSystem: false,
    showTooltip: false
  }),
  computed: {
    getterUserCanEditPolicy() {
      return this.$store.getters['policy/getterUserCanEditPolicy'](this.$route.params.policy_id)
    },
    getFuelChoiceIcon(){
      return this.getFuelChoice === FUEL_CHOICES.ALLOWED ||
      !this.getFuelChoice ? 'check_circle_outline'
      : this.getFuelChoice === FUEL_CHOICES.REQUIRED ? 'error_outline' : 'do_disturb_alt'
    },
    checkIfFuelChoiceIsNotAllowed(){
      return this.getFuelChoice === FUEL_CHOICES.NOT_ALLOWED
    },
    getFuelChoice() {
      return this.custom_combination?.meta?.fuel_choice ?? FUEL_CHOICES.ALLOWED
    },
    getComplianceMarginValue() {
      return (this.custom_combination?.meta?.compliance_margin_value || this.custom_combination?.meta?.generate_virtual_compliance_margin) ?? 0
    },
    getPvSystemRequired() {
      return this.custom_combination?.meta?.require_pv_system ? 'Required' : 'Not Required'
    },
    getStudyComplianceMarginKey() {
      if (!this.custom_combination?.fuel?.study_id) {
        return 'compliance_margin'
      }
      const study = this.$store.getters['globalEntities/Study/getterGlobalStudy']({ id: this.custom_combination.fuel.study_id })
      return study.compliance_margin_key || 'compliance_margin'
    },
    isPvSystemEnabled() {
      return GeneralPerformanceByFuelPolicyImpactAlgorithm.pvEnabledComplianceMarginKeys.includes(this.getStudyComplianceMarginKey)
    },
  },
  watch: {
    custom_combination: {
      deep: true,
      async handler() {
        this.$forceUpdate()
      }
    },
  },
  methods:{
    onHoverFuelContainer(){
      this.isHovering = !this.isHovering
      this.showTooltip = !this.showTooltip
    },
    getCustomCombinationFuelTooltipText(customCombinationFuelSlug){
      if(customCombinationFuelSlug === TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC){
        return 'All-electric buildings are powered only by electricity.'
      } else if(customCombinationFuelSlug === TYPE_FUELS_DB_SLUGS.MIXED_FUEL){
        return 'Mixed-fuel buildings are powered by both natural gas and electricity.'
      }
    },
    getFuelChoiceTooltipText(){
      switch(this.getFuelChoice){
        case FUEL_CHOICES.ALLOWED:
          return 'Allow builders to construct both all-electric and mixed-fuel new buildings.'
        case FUEL_CHOICES.REQUIRED:
          return 'Requires that new buildings be all-electric.'
        case FUEL_CHOICES.NOT_ALLOWED:
          return 'Mixed-fuel construction is not an option for new buildings.'
      }
    },
    onHoverComplianceMargin(){
      this.isHoveringComplianceMargin = !this.isHoveringComplianceMargin
    },
    onHoverLargerPVSystem(){
      this.isHoveringLargerPVSystem = !this.isHoveringLargerPVSystem
    },
    openDescriptionModalHelperComplianceMargin() {
      this.$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: `edit_requirements_new_buildings_sf_2022_compliance_margin` })
    },
    openDescriptionModalHelperLargerPVSystem() {
      this.$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: `edit_requirements_new_buildings_sf_2022_larger_pv_system` })      
    },
    getTooltip() {
      const condition = this.showTooltip      
      const content = 'Click to view and edit requirements'
      return createTooltipOptions(condition, content)
    }
  }
}
</script>
 
<style lang="scss" scoped>
  .card-fuel {
    &,
    * {
      transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
    }

    .description {
      min-width: 140px;
    }

    &-disabled {
      background-color: rgba(255, 255, 255, .5);

      .badge-icon {
        background-color: #E6ECF2;
      }
    }
  }
 
  .icon {
    width: 56px;
    height: 56px;
  }

  .tooltip-styles {
    ::v-deep .psui-el-tooltip .psui-el-tooltip-wrapper .psui-el-tooltip-dialog .psui-el-tooltip-content.layout-gray .psui-el-tooltip-content-wrapper {
      margin-top: 0;
    }
  }
</style>