<template>
  <div class="home-benefits psui-w-full psui-flex psui-flex-col">
    <h4 class="psui-text-h5 xl:psui-text-h4 psui-text-blue-80 psui-font-bold psui-mb-6">
      Get started by developing a virtual policy for your jurisdiction...
    </h4>
    <div class="home-benefits-group psui-flex psui-flex-col">
      <div class="home-benefits-group-options psui-flex psui-flex-col xl:flex-row psui-gap-6">
        <HomeMainBenefitsPolicyOption />
        <HomeMainBPS />
        <HomeMainBenefitsSupport />
      </div>
    </div>
  </div>
</template>

<script>
import HomeMainBenefitsPolicyOption from './shared/HomeMainBenefitsPolicyOption.vue'
import HomeMainBPS from './shared/HomeMainBPS.vue'
import HomeMainBenefitsSupport from './shared/HomeMainBenefitsSupport.vue'
import { HOME_AVAILABLE_RESOURCES } from "@/modules/app/home/HomeContents.js"

export default {
  name:'HomeMainBenefitsListGroup',
  components: { 
    HomeMainBenefitsPolicyOption,
    HomeMainBPS,
    HomeMainBenefitsSupport 
  },
  data: () => ({
    HOME_AVAILABLE_RESOURCES,
  })
}
</script>