<template>
  <div class="psui-w-full block pb-7">    
    <div class="card-create-policy psui-bg-blue-20 psui-pl-6 psui-pr-8 psui-py-8">
      <div 
        class="psui-grid psui-gap-6"
        :class="{
          'psui-grid-cols-1' : getterContentContainerWidth <= 1024,
          'grid-template-columns-policies' : getterContentContainerWidth > 1024,
        }"
      >
        <div class="psui-flex psui-flex-col psui-items-start psui-space-y-6">
          <img
            class="info"
            style="width: 240px; height: 180px"
            src="/images/measure-by-measure.png"
          >
          <p class="psui-font-bold psui-text-h4 text-deepsky-900 psui-mb-2.5">
            {{ 'Create a policy and forecast the impact of reach codes in your city' | policyTextRephrase }}
          </p>
        </div>
        <ExploreStudyTypeCard
          :title="COPY.EXISTING_BUILDING_POLICY_WIDGET.TITLE"
          :description="COPY.EXISTING_BUILDING_POLICY_WIDGET.DESCRIPTION"
          :tooltip-policy-type-title="COPY.EXISTING_BUILDING_POLICY_WIDGET.TOOLTIP_TITLE"
          :prototypes-available="getPrototypesAvailableByPolicyOptionsAndStudyType(STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS)"
          @click="goToPolicyOptionsExistingBuildingsTab"  
        />
        <ExploreStudyTypeCard
          :title="COPY.NEW_BUILDING_POLICY_WIDGET.TITLE"
          :description="COPY.NEW_BUILDING_POLICY_WIDGET.DESCRIPTION"        
          :tooltip-policy-type-title="COPY.NEW_BUILDING_POLICY_WIDGET.TOOLTIP_TITLE"
          :prototypes-available="getPrototypesAvailableByPolicyOptionsAndStudyType(STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS)"
          @click="goToPolicyOptionsNewBuildingsTab"
        >
          <template #content>
            <BaseModal
              ref="baseModal"
              modal-dialog-classes="w-full max-w-sm"
              :disable-close="true"
            >
              <h1 class="font-size-title font-bold psui-text-gray-80">
                New Construction Policy Impacts (Beta)
              </h1>
              <p class="font-size-content mt-4 mb-6 psui-text-gray-50">
                Our spreadsheet based impact model is now available. Estimate impacts customized for your city. <br>
                -Covers new single family homes <br>
                -Supports electric-only and electric-preferred type policies <br>
                -Uses draft 2022 New Single Family Cost-Effectiveness study results <br>
                -Other building types coming soon
              </p>

              <div class="flex psui-flex-wrap">
                <a 
                  target="_blank"
                  href="https://docs.google.com/spreadsheets/d/12oSwfPQUuylA49PAfVolTE8E_2bWjBB057hPB0fqNIc/edit?usp=sharing"
                  @click="gaEvent(GA_LABELS.POLICIES_DOWNLOAD_NEW_CONSTRUCTION_SF_IMPACT_MODEL_BETA, { value: 'POLICIES_DOWNLOAD_NEW_CONSTRUCTION_SF_IMPACT_MODEL_BETA' })"
                >
                  <PsButton
                    label="Open Model"
                    size="medium"
                    layout="solid"
                    icon="open_in_new"
                    icon-position="left"
                    class="mr-2"
                  />
                </a>

                <PsButton
                  label="Maybe later"
                  size="medium"
                  layout="onlytext"
                  @click="closeDownloadImpactModal"
                />
              </div>
            </BaseModal>
            
            <hr class="divider psui-w-full psui-mt-3 psui-mb-4 psui-border-blue-20">

            <a 
              target="_blank"
              href="https://intercom.help/explorer-local-energy-codes/en/collections/3517138-new-construction-quick-guide"
              class="psui-text-blue-60 psui-font-bold psui-text-small hover:psui-text-blue-70 psui-transition"
            >
              Key Concepts for the 2022 Code Cycle
            </a>
          </template>
        </ExploreStudyTypeCard>
      </div>
    </div>
  </div>
</template>

<script>
import { GA_LABELS, GA_EVENT_CATEGORIES } from '@/mixins/GaEventsMixin'
import { mapGetters } from 'vuex'
import { COPY, STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import ExploreStudyTypeCard from '@/modules/app/jurisdiction/summary/shared/ExploreStudyTypeCard.vue'

export default {
  name: 'PoliciesListUserHasNoPolicies',
  components:{
    ExploreStudyTypeCard
  },
  data: () => ({
    COPY,
    GA_LABELS,
    GA_EVENT_CATEGORIES,
    STUDY_TYPES_DB_SLUGS,
  }),
  computed: {
    getPrototypesAvailableByPolicyOptions() {
      const getStudyGroupIdsByStudyType = this.$store.getters['globalEntities/StudyGroup/getterGlobalStudyGroups']({study_type_id:2})
      .map(study_group => study_group.id)

      return this.$store.getters['policy/getterPolicyOptionsWithPrototypes']()
      .filter(policy_option => policy_option.algorithms.some(alg => alg.study_type_slug === "new-buildings" )  && policy_option.prototypes.every(prototype => getStudyGroupIdsByStudyType.includes(prototype.study.study_group_id)))
      .map(policy_option => policy_option.prototypes)
      .reduce((acc,curr) => acc.concat(curr),[])
      .reduce((acc,curr) => {
        if(acc.length === 0 || !acc.includes(curr)){
          acc.push(curr)
        }
        return acc
      },[])
    },
    ...mapGetters({getterContentContainerWidth: 'general/getterContentContainerWidth'}),
  },
  methods: {
    openDownloadImpactModal() {
      this.$refs.baseModal.open()
    },
    closeDownloadImpactModal() {
      this.$refs.baseModal.showModal = false
    },
    goToPolicyOptionsExistingBuildingsTab() {
      this.$store.dispatch('setUserLastPolicyOptionSelected', {
        context:'updateLastPolicyOptionSelectedFromSummary',
        value: [STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS]
      })

      this.$router.push({ name: 'PolicyOptions' })
    },
    goToPolicyOptionsNewBuildingsTab() {
      this.$store.dispatch('setUserLastPolicyOptionSelected', {
        context:'updateLastPolicyOptionSelectedFromSummary',
        value: [STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS]
      })

      this.$router.push({ name: 'PolicyOptions' })
    },
    getPrototypesAvailableByPolicyOptionsAndStudyType(study_type_slug) {
      const getStudyTypeByStudyTypeSlug = this.$store.getters['globalEntities/StudyType/getterGlobalStudyType']({slug:study_type_slug})
      const getStudyGroupIdsByStudyType = this.$store.getters['globalEntities/StudyGroup/getterGlobalStudyGroups']({study_type_id:getStudyTypeByStudyTypeSlug.id})
      .map(study_group => study_group.id)

      return this.$store.getters['policy/getterPolicyOptionsWithPrototypes']()
      .filter(policy_option => policy_option.algorithms.some(alg => alg.study_type_slug === study_type_slug )  && policy_option.prototypes.every(prototype => getStudyGroupIdsByStudyType.includes(prototype.study.study_group_id)))
      .map(policy_option => policy_option.prototypes)
      .reduce((acc,curr) => acc.concat(curr),[])
      .reduce((acc,curr) => {
        if(acc.length === 0 || !acc.includes(curr)){
          acc.push(curr)
        }
        return acc
      },[])
    },
  }
}
</script>

<style lang="scss" scoped>

  .card-create-policy {
    border-radius: 10px;

    .divider {
      transform: translateX(-10px);
      width: calc(100% + 20px);
    }
  }

  .grid-template-columns-policies {
    grid-template-columns: 0.7fr 1fr 1fr;
  }

  ::v-deep .modal-dialog {
    padding: 32px;
    width: 440px;
  }

  .font-size-title {
    font-size: 20px;
  }

  .font-size-content {
    font-size: 16px;
  }
</style>
