<template>
  <div    
    class="app-header psui-px-8 psui-pt-3"
  >
    <resize-observer
      v-if="!isPolicyOptionsRoute"
      @notify="onResize"
    />
    <div
      ref="appHeaderTop"
      class="app-header-top"
      :class="[
        { 
          'has-no-space' : !appHeaderTopHasSpace, 
          'reverse' : !appHeaderTopHasSpace && reverseTopOnNoSpace
        },
        !isPolicyOptionsRoute ? 'with-min-height-top' : ''
      ]"
    >      
      <resize-observer
        v-if="!isPolicyOptionsRoute"
        @notify="onResize"
      />
      <div class="app-header-top-left">
        <slot name="top-left">
          <div class="psui-flex psui-items-center psui-flex-shrink-0 psui-w-auto psui-mr-auto psui-justify-self-start">
            <h1 class="psui-text-h4 psui-text-gray-80 psui-font-bold psui-mr-2 psui-flex-shrink-0">
              {{ title }}
            </h1>
            <JurisdictionSelect
              v-if="hasJurisdictionSelection"
              data-test-id="base-app-header-jurisdiction-select"
              :should-scroll="false"
              :input-class="'psui-text-h5'"
              :jurisdiction-selected="lastJurisdictionVisited"
              @closeInput="showInput = false"
              @change="$emit('on-jurisdiction-select', $event)"
            />
          </div>
        </slot>
      </div>
      <div class="app-header-top-right">
        <slot name="top-right" />
      </div>
    </div>

    <div
      ref="appHeaderBottom"
      class="app-header-bottom"
      :class="[
        { 
          'has-no-space' : !appHeaderBottomHasSpace,
          'reverse' : !appHeaderBottomHasSpace && reverseBottomOnNoSpace
        },
        !isPolicyOptionsRoute ? 'psui-mt-auto psui-pt-1' : 'mt-[17px]',
        !isPolicyOptionsRoute ? 'with-min-height-bottom' : ''
      ]"
    >      
      <div class="app-header-bottom-left">
        <slot name="bottom-left" />
      </div>
      <div class="app-header-bottom-right">
        <slot name="bottom-right" />
      </div>
    </div>
  </div>
</template>

<script>
import JurisdictionSelect from './JurisdictionSelect.vue'
export default {
  name: 'BaseAppHeader',
  components: { JurisdictionSelect },
  props: {
    reverseTopOnNoSpace : {
      type: Boolean,
      default: false
    },
    reverseBottomOnNoSpace : {
      type: Boolean,
      default: true
    },
    hasJurisdictionSelection : {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    appHeaderTopHasSpace : true,
    appHeaderBottomHasSpace : true,
  }),
  computed: {
    lastJurisdictionVisited() {
      return this.$store.getters.lastJurisdictionVisited
    },
    isPolicyOptionsRoute() {
      return this.$route.name === 'PolicyOptions'
    }
  },
  methods: {
    onResize({ width }) {
      try {
        const paddingLeft = window.getComputedStyle(this.$el, null).getPropertyValue('padding-left')
        const paddingRight = window.getComputedStyle(this.$el, null).getPropertyValue('padding-right')
        this.updateIfRowsHasSpaces({ container_width: width, paddingLeft, paddingRight })
      } catch(e) {
        this.updateIfRowsHasSpaces({ container_width: width, paddingLeft: 0, paddingRight : 0 })
        console.error(e)
      }
    },
    updateIfRowsHasSpaces({ container_width, paddingLeft, paddingRight  }) {
      const safe_space = 40

      if (this.$refs) {
        for (let ref in this.$refs) {

          let totalChildrenWidth = safe_space + parseInt(paddingLeft) + parseInt(paddingRight)
          const array = this.$refs[ref].children
          for (let index = 0; index < array.length; index++) {            
            const childrenWidth = array[index].offsetWidth
            totalChildrenWidth += parseInt(childrenWidth, 10)
          }
          
          this[`${ref}HasSpace`] = totalChildrenWidth >= container_width ? false : true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .app-header {
    display: flex;
    flex-direction: column;
    z-index: 25;

    &-top, &-bottom {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: flex-end;
      flex-wrap: wrap;
    }

    .reverse {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

  .with-min-height-top {
    min-height: 27px;
  }

  .with-min-height-bottom {
    min-height: 57px;
  }
</style>