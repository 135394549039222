<template>
  <BaseAppHeader 
    id="policy-options-header"
    :title="'Policy Options for' | policyTextRephrase('small')"
    :has-jurisdiction-selection="true"
    @on-jurisdiction-select="onJurisdictionSelect"
  >
    <template #top-right>
      <PolicyOptionsHeaderActions />
    </template>
    <template #bottom-left>
      <PolicyOptionsFilter />
    </template>
  </BaseAppHeader>
</template>

<script>
import PolicyOptionsHeaderActions from '@/modules/app/policy/options/PolicyOptionsHeaderActions.vue'
import BaseAppHeader from '@/components/general/BaseAppHeader.vue'
import PolicyOptionsFilter from '@/modules/app/policy/options/PolicyOptionsFilter.vue'

export default {
  name: 'PolicyOptionsHeader',
  components: { 
    BaseAppHeader,
    PolicyOptionsHeaderActions,
    PolicyOptionsFilter
  },
  methods: {
    onJurisdictionSelect(jurisdiction) {
      this.$store.dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', { value: jurisdiction, context: 'updateLastJurisdictionVisitedFromPolicyOptions'})
      this.pushToParams('jurisdiction_slug', jurisdiction.slug)

    },
  },    
}
</script>

<style lang="scss" scoped>
  ::v-deep .psui-el-dropdown-menu-dialog-wrapper.psui-duration-300 {
    margin-top: 11px;
  }

  .app-header {
    padding-left: 24px;
    padding-right: 24px;
  }
</style>
