<template>
  <PsButton
    :label="'Explore policy options' | policyTextRephrase('small')"
    :icon="icon"
    :layout="layout"
    size="small"
    icon-position="left"
    @click="goToPolicyOptionsTab()"
  />
</template>

<script>
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'

export default {
  name: 'AppPolicyCreateButton',
  props: {
    icon: {
      type: String,
      default: 'explore'
    },
    layout: {
      type: String,
      default: 'solid'
    },
  },
  data: () => ({
    STUDY_TYPES_DB_SLUGS
  }),
  methods: {
    goToPolicyOptionsTab(){
      if(this.$route.name === 'PoliciesList'){
        this.navigateToPolicyOptionTab(STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS)
      } else if(this.$route.name === 'StudyResults') {
        this.navigateToPolicyOptionTab(this.$route.query.only_study_type)
      }
    },
    navigateToPolicyOptionTab(studyTypeSlug){
      this.$store.dispatch('setUserLastPolicyOptionSelected', {
        context:'updateLastPolicyOptionSelectedFromPolicyMyPolicies',
        value: [studyTypeSlug]
      })

      this.$router.push({ name: 'PolicyOptions' })
    }
  }
}
</script>

<style>

</style>