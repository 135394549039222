<template>
  <div
    v-if="!isLoading"
    class="grid grid-cols-1 sm:grid-cols-2 gap-6 py-6 px-8"
  >
    <PsInput
      v-model="policy_option.title"
      label="Title"
      :value="policy_option.title"
      @input="policy_option.title = $event.target.value"
    />

    <PsInput
      v-model="policy_option.slug"
      label="Slug"
      :value="policy_option.slug"
      @input="policy_option.slug = $event.target.value"
    />

    <PsInputTextArea
      v-model="policy_option.short_description"
      label="Short Description"
      class="col-span-2"
      :rows="2"
      :value="policy_option.short_description"
      @input="policy_option.short_description = $event.target.value"
    />

    <PsInput
      v-model="policy_option.title_drawer_about"
      label="Title Drawer About"
      @input="policy_option.title_drawer_about = $event.target.value"
    />
    
    <PsInput
      v-model="policy_option.title_drawer_create"
      label="Title Drawer Create"
      @input="policy_option.title_drawer_create = $event.target.value"
    />

    <div
      class="col-span-2"
    >
      <PsCheckboxSimple
        :checked="Boolean(getNotificationCustomField)"
        label="Enable notification on policy options list?"
        @change="enableNotificationCustomField"
      />
      <div
        v-if="getNotificationCustomField"
        class="flex flex-col mt-2"
      >
        <span class="font-bold mb-1 gray-80">Notification events</span>
        <div class="flex flex-col ml-2">
          <span class="font-bold mb-1 gray-80">Policy Option</span>
          <PsInput
            v-model="custom_field.data.notification.policy_option.value"
            :label="policy_option.title"
            @input="custom_field.data.notification.policy_option.value = $event.target.value"
          />
          <PsInput
            v-model="custom_field.data.notification.policy_option.url"
            label="URL"
            @input="custom_field.data.notification.policy_option.url = $event.target.value"
          />
        </div>
        <div class="flex flex-col mt-2 ml-2">
          <span class="font-bold mb-1 gray-80">Columns</span>
          <div>
            <PsInput
              v-for="column in getNotificationItems"
              :key="column.slug"
              v-model="custom_field.data.notification.columns[column.slug].value"
              :label="column.title"
              @input="custom_field.data.notification.columns[column.slug].value = $event.target.value"
            />
          </div>
        </div>
      </div>
    </div>

    <PsCheckboxSimple
      :checked="policy_option.is_advanced"
      label="Is Advanced?"
      @change="policy_option.is_advanced = !policy_option.is_advanced"
    />

    <PsCheckboxSimple
      :checked="policy_option.is_recommended"
      label="Is Recommended?"
      @change="policy_option.is_recommended = !policy_option.is_recommended"
    />

    <div class="w-full flex flex-col col-span-2">
      <label class="font-bold mb-1 gray-80">Alert Text</label>
      <ContentEditor
        :content.sync="policy_option.alert_text"
      />
    </div>

    <div class="w-full flex flex-col col-span-2">
      <label class="font-bold mb-1 gray-80">Description</label>
      <ContentEditor
        :content.sync="policy_option.description"
      />
    </div>

    <div class="col-span-2 border-t border-gray05 py-5 mt-5">
      <PsButton
        :label="!$route.params.policy_option_id ? 'Create' : 'Update'"
        size="big"
        icon="add"
        class="psui-mt-6"
        :loading="isUpdating"
        @click="storePolicyOption()"
      />
    </div>
  </div>

  <SkeletonPolicyOptionForm v-else />
</template>

<script>
import PolicyOption from '@/models/PolicyOption'
import SkeletonPolicyOptionForm from './skeleton/SkeletonPolicyOptionForm.vue'

export default {
  name: 'AdminPolicyOptionForm',
  components: { SkeletonPolicyOptionForm },
  data() {
    return {
      policy_option: new PolicyOption(),
      isLoading: false,
      isUpdating: false,
      custom_field: {
        id: '',
        item_type: '',
        item_id: '',
        data: '{}'
      }
    }
  },
  computed:{
    getNotificationCustomField(){
      return this.custom_field.data.notification
    },
    getNotificationItems() {
      if(this.getNotificationCustomField) {
        const items = Object.keys(this.custom_field.data.notification.columns)
        .map(key => {
          const item = this.custom_field.data.notification.columns[key]
          return {  
            ...item,
            slug: key
          }
        })
        return items
      }
      return []
    }
  },
  mounted() {
    if(this.$route.params.policy_option_id) {
      this.setPolicyOption()
    }
  },
  methods: {
    async setPolicyOption() {
      this.isLoading = true
      await this.$store.dispatch('globalEntities/PolicyOption/loadGlobalPolicyOptions')

      const policy_option = this.$store.getters['globalEntities/PolicyOption/getterGlobalPolicyOption']({ id : this.$route.params.policy_option_id })
      this.policy_option = policy_option
      
      this.custom_field = await this.getCustomField(this.$route.params.policy_option_id)
      setTimeout(() => { this.isLoading = false }, 500)
    },
    enableNotificationCustomField(){
      if(this.custom_field.data.notification) {
        delete this.custom_field.data.notification
        
      } else {
        this.custom_field.data.notification = {
          columns: {
            forecast_energy_savings: {
              title: "Energy Savings",
              value: ""
            },
            forecast_initial_cost: {
              title: "Compliance Cost",
              value: ""
            },
            forecast_emissions_savings: {
              title: "Emissions Reductions",
              value: ""
            },
            forecast_lifecycle_savings: {
              title: "Lifecycle Savings",
              value: ""
            }
          },
          policy_option: {
            value: "",
            url: ""
          }
        }
      }

      this.custom_field.data = {...this.custom_field.data}
    },
    storePolicyOption() {
      this.isUpdating = true

      Promise.all([
        this.$store.dispatch('globalEntities/PolicyOption/storeGlobalPolicyOption', { policy_option : this.policy_option }),
        this.update()
      ])
      .then(() => {
          this.$toast.success(`PolicyOption successfully ${ this.policy_option.id ? 'updated' : 'created' }`)
          this.$router.push({ name: 'AdminPolicyOptionsIndex' })
          this.isUpdating = false
        })
    },
    getCustomField(id) {

    const query = `
      { 
        custom_field (
          where:[
          {column:"item_id", value: "${id}"}
          {column:"item_type", value: "policy_options"}
          ]
        ) {
          id
          item_id
          item_type
          data
        }
      }
    `
      const custom_field = this.$graphql({ query })
      .then(({ data }) => {
        return  data.custom_field
      })

    return custom_field
    },
    update() {
      this.$api.post(`/api/custom_field/update/${this.custom_field.id}`, this.custom_field, { ignoreFeedback: true })
    }
  }
}
</script>
