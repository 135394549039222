<template>
  <div 
    id="policy-index"
    class="w-full h-full flex flex-col"
    :class="{
      'bg-[#e6ecf2]': $route.name === 'PolicyOptions',
      'psui-bg-gray-10 overflow-y-auto': $route.name !== 'PolicyOptions'
    }"
  >
    <div
      :is="getHeaderComponent"
      class="psui-bg-white psui-shadow-elevation-5"
      :class="[
        { 'sticky top-0 mb-6': !isPolicyOptionsRoute },
        { 'mb-[19px]': isPolicyOptionsRoute }
      ]"
    />
    <PolicyHeaderUserWarnings />
    <router-view />
    <ModalDeletePolicy />
    <ModalDuplicatePolicy />
    <PolicyUserPermissionDuplicateAlertModal @close="$emit('close')" />
    <PolicyEditRequirementsDrawer />
    <DrawerPolicyEditColumns />
  </div>
</template>

<script>
import PolicyHeaderUserWarnings from './PolicyHeaderUserWarnings.vue'
import ModalDeletePolicy from '@/modules/app/policy/shared/ModalDeletePolicy'
import PolicyEditRequirementsDrawer from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyEditRequirementsDrawer.vue'


export default {
  name: 'PoliciesIndex',
  components: { 
    ModalDeletePolicy,
    PolicyHeaderUserWarnings,
    PolicyEditRequirementsDrawer
  },
  computed:{
    getHeaderComponent(){
      return this.$route?.meta?.header
    },
    isPolicyOptionsRoute() {
      return this.$route.name === 'PolicyOptions'
    }
  },
  metaInfo() {
    const title = this.$route.name === 'PolicyOptions' ? 'Policy Options | ' : 'My Policies | '
    const rephrasedTitle = this.$options.filters.policyTextRephrase(title)
    return {
      title: rephrasedTitle
    }
  },
  mounted() {
    this.$store.commit('general/setSidebarPolicyTooltipShouldHide', true)
  }
}
</script>