<template>
  <div class="flex flex-col px-6 gap-7 mt-6">
    <div class="flex flex-col items-center space-y-7">
      <img
        class="mx-auto"
        style="width: 160px; height: 120px"
        src="/images/building_estimates/prescriptive.png"
      >
      <p class="psui-text-blue-80 psui-text-small psui-font-bold text-center pl-4 pr-4">
        The ability to customize existing nonresidential data will be available in future versions
      </p>
    </div>
  </div>  
</template>

<script>
export default {
  name: 'AssumptionsNonResidentialExisting',
  methods:{
    takeToMailChimp(){
      window.open('https://mailchi.mp/localenergycodes/epajkrq24i','_blank')
    }
  }
}
</script>