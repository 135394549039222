<template>
  <div class="psui-flex psui-flex-col psui-gap-4 psui-w-full">
    <div
      v-for="(climate_zone, climate_zone_index) in climateZones"
      :key="`requirements-by-fuel climate-zone_${climate_zone.id}`"
      class="policy-container-card psui-w-full psui-flex psui-py-4 psui-px-6 psui-items-center psui-rounded-md border-2/3 psui-border-gray-30 psui-border-dashed transition-all"
      :class="{ 
        'psui-bg-gray-20 psui-border-0' : isCustomCombinationsByClimateZoneAreVisible({ climate_zone_raw: climate_zone.raw }),
        'psui-cursor-pointer' : !isLoading
      }"
      @click="addOrEditRequirements({ climate_zone_raw: climate_zone.raw, index: climate_zone_index })"
    >
      <div
        :class="{
          'psui-block' : getterContentContainerWidth < 768,
          'psui-flex  psui-w-full' : getterContentContainerWidth > 768,
        }"
      >
        <div
          class="psui-flex psui-flex-col psui-items-start psui-justify-center psui-flex-shrink-0"
          style="width: 190px"
        >
          <div 
            class="psui-flex psui-flex-row psui-items-center psui-justify-center psui-flex-shrink-0"
          >
            <h5 
              v-tooltip="getTooltip(climate_zone, climate_zone_index)"    
              class="psui-flex psui-text-h6 psui-font-bold psui-text-gray-70 psui-mb-2"
              @mouseenter="showTooltip = { climate_zone_index }" 
              @mouseleave="showTooltip = null"
            >
              Climate Zone {{ climate_zone.prefix }}
            </h5>
          </div>
          <button
            class="psui-text-blue-60 psui-font-bold psui-text-small block"
          >
            {{ isCustomCombinationsByClimateZoneAreVisible({ climate_zone_raw: climate_zone.raw}) ? !getterUserCanEditPolicy ? 'Open Requirements' : 'Edit Requirements' : 'Add Requirements' }}
          </button>
        </div>
        <div
          v-if="isCustomCombinationsByClimateZoneAreVisible({ climate_zone_raw: climate_zone.raw })"
          class="psui-w-full psui-flex psui-flex-grow "
          :class="{
            'psui-space-y-6 pt-6 psui-flex-wrap' : getterContentContainerWidth <= 1024,
            'psui-space-x-6  ' : getterContentContainerWidth > 1024,
          }"
        >
          <PolicyRequirementFuelCell
            v-for="(custom_combination, custom_combination_index) in getCustomCombinationsByClimateZoneSorted({ climate_zone_raw: climate_zone.raw})"
            :key="`custom_combination_${custom_combination_index}`"
            :custom_combination="custom_combination"
            :class="{
              'psui-w-full' : getterContentContainerWidth <= 1024,
              'psui-w-1/2 ' : getterContentContainerWidth > 1024,
            }"
          />
        </div>
        <PolicyRequirementFuelCellSkeleton
          v-else-if="isDeletingPolicy === climate_zone.raw"
          :container-width="getterContentContainerWidth"
        />
      </div>
      <div
        class="psui-pl-4 w-8 psui-mb-auto"
      >
        <button
          v-if="getterUserCanEditPolicy && isCustomCombinationsByClimateZoneAreVisible({ climate_zone_raw: climate_zone.raw })"
          @click.stop="deleteCustomCombinationsByClimateZone({ climate_zone_raw: climate_zone.raw })"
        >
          <PsIcon
            icon="delete_outline"
            class="psui-text-gray-40 psui-cursor-pointer leading-none hover:psui-bg-blue-10 hover:psui-text-blue-60 psui-gap-3 psui-px-5 psui-py-1 transition-all"
            size="18"
          />
        </button>
      </div>
    </div>
  </div>
</template>
 
<script>
import PolicyRequirementFuelCell from './PolicyRequirementFuelCell.vue'
import PolicyRequirementFuelCellSkeleton from '../requirements/skeleton/PolicyRequirementFuelCellSkeleton.vue'
import PolicyEditRequirementsNewBuildingsSF2022 from './requirements-drawer/PolicyEditRequirementsNewBuildingsSF2022/index.vue'
import { TYPE_FUELS_DB_SLUGS } from '@/util/Enums'
import { POLICY_TYPES } from '@/modules/app/policy/shared/enums.js'
import CustomCombination from '@/models/CustomCombination'
import CustomCombinationApiService from '@/services/api/CustomCombinationApiService'
import { mapGetters } from 'vuex'
import { createTooltipOptions } from '@/util/Functions.js'
import {
  GeneralPerformanceByFuelPolicyImpactAlgorithm
} from '@/business-logic/services/impact-algorithms/policy/general-performance-by-fuel'
 
export default {
  name:'PolicyContainerRequirementsByFuel',
  components: {
    PolicyRequirementFuelCell,
    PolicyRequirementFuelCellSkeleton
  },
  props:['policy-container','flexible_path_setup'],
  data: () =>({
    TYPE_FUELS_DB_SLUGS,
    isDeletingPolicy : false,
    isLoadingPolicyFuel: false,
    climateZonePrefixSelected: null,
    showTooltip: null
  }),
  computed: {
    ...mapGetters({getterContentContainerWidth: 'general/getterContentContainerWidth'}),
    ...mapGetters(['getterLoggedUser', 'lastJurisdictionVisited']),
    isLoading() {
      return this.isDeletingPolicy || this.isLoadingPolicyFuel
    },
    climateZones() {
      return this.getPolicy?.jurisdiction?.climate_zones ?? []
    },
    customCombinations() {
      return this.policyContainer.custom_combinations ?? []
    },
    getterUserCanEditPolicy() {
      return this.$store.getters['policy/getterUserCanEditPolicy'](this.policyContainer.policy_id)
    },
    getPolicy(){
      return this.$store.getters['policy/getterUserPolicy']({ id: this.policyContainer.policy_id })
    },
    studiesPublishedByStudyTypeAndTypePrototype() {
      return this.$store.getters['globalEntities/Study/getterLatestPublishedStudiesByStudyTypeAndTypePrototype']({
        type_prototype_id: this.policyContainer.type_prototype_id,
        study_type_id: this.policyContainer.study_type_id,
      })
    },
    getDefaultCustomCombinationPayload() {
      if (!this.studiesPublishedByStudyTypeAndTypePrototype.length) return false
      const { id : latest_published_study_id } = this.studiesPublishedByStudyTypeAndTypePrototype[0]
      const prototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({
        study_id : latest_published_study_id,
        type_prototype_id: this.policyContainer.type_prototype_id
      })
      return {
        prototype_id: prototype.id,
        jurisdiction_id : this.getPolicy.jurisdiction_id,
        policy_id: this.getPolicy.id,
        policy_container_id : this.policyContainer.id,
        study_id : latest_published_study_id
      }
    },
    getPolicyType() {
      return this.$store.getters['policy/getterPolicyType'](this.policyContainer.policy_id)
    },
  },
  methods: {
    isCustomCombinationsByClimateZoneAreVisible({ climate_zone_raw }) {
      const isOnDefaultState = (this.getCustomCombinationsByClimateZone({ climate_zone_raw }) || []).every((cc) => {
        const meta = GeneralPerformanceByFuelPolicyImpactAlgorithm.extractMetaInfoFromCustomCombination(cc)
        return meta?.isOnDefaultState === true
      })

      return (this.getCustomCombinationsByClimateZone({ climate_zone_raw }) && this.isDeletingPolicy !== climate_zone_raw && !isOnDefaultState)
    },
    getCustomCombinationsByClimateZone({ climate_zone_raw }) {      
      const ccs = this.customCombinations.findAllInArray({ climate_zone_raw })
      return ccs.length ? ccs : false
    },
    getCustomCombinationsByClimateZoneSorted({ climate_zone_raw }) {
      const custom_combinations = this.getCustomCombinationsByClimateZone({ climate_zone_raw }).map(cc => {
        const fuel = this.$store.getters['globalEntities/Fuel/getterGlobalFuel']({ id : cc.fuel_id })
        return {...cc, type_fuel_id : fuel.type_fuel_id }
      })
      const { id: all_electric_type_fuel_id } = this.$store.getters['globalEntities/TypeFuel/getterGlobalTypeFuel']({ slug: TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC })
      const { id: mixed_fuel_type_fuel_id } = this.$store.getters['globalEntities/TypeFuel/getterGlobalTypeFuel']({ slug: TYPE_FUELS_DB_SLUGS.MIXED_FUEL })
 
      const all_electric_custom_combination = custom_combinations.findInArray({ type_fuel_id : all_electric_type_fuel_id })
      const mixed_fuel_custom_combination = custom_combinations.findInArray({ type_fuel_id : mixed_fuel_type_fuel_id })
 
      return [all_electric_custom_combination, mixed_fuel_custom_combination]
 
    },
    addOrEditRequirements({ climate_zone_raw, index }) {
      if (this.isLoading) return

      const customCombinationsByClimateZone = this.getCustomCombinationsByClimateZone({ climate_zone_raw })
      if (customCombinationsByClimateZone) {
        this.openRequirementsDrawer({ climate_zone_raw })
      } else if (!customCombinationsByClimateZone && this.getPolicyType === POLICY_TYPES.SHARED.value) {
        this.$toast.info("This shared policy doesn’t have any requirements yet. Duplicate it to add requirements and explore policy options.")
      } else {

        this.isLoadingPolicyFuel = index

        this.storeInitialCustomCombinations({ climate_zone_raw })
          .then((customCombinations) => {            
            this.$eventBus.$emit('openDrawerPolicyEditRequirements', {
              component_name: PolicyEditRequirementsNewBuildingsSF2022.name ,
              custom_combination_ids : customCombinations.map(cc => cc.id).join(','),
            })
          })
      }
    },
    openRequirementsDrawer({ climate_zone_raw }) {
      const customCombinations = this.getCustomCombinationsByClimateZone({ climate_zone_raw })
      if (!customCombinations) return                
      this.$eventBus.$emit('openDrawerPolicyEditRequirements', {
        component_name: PolicyEditRequirementsNewBuildingsSF2022.name ,
        custom_combination_ids : customCombinations.map(cc => cc.id).join(','),
      })
    },
    async deleteCustomCombinationsByClimateZone({ climate_zone_raw }) {
      if (!this.getterUserCanEditPolicy || this.isDeletingPolicy) {
        return
      }
      this.isDeletingPolicy = climate_zone_raw
      const custom_combinations = this.getCustomCombinationsByClimateZone({ climate_zone_raw })
     
      await Promise.all(custom_combinations.map(cc => CustomCombinationApiService.delete(cc.id)))
      await this.$store.dispatch('policy/getPolicy', custom_combinations[0].policy_id)
     
      this.isDeletingPolicy = false
    },
    async storeInitialCustomCombinations({ climate_zone_raw }) {
      if (!this.getDefaultCustomCombinationPayload) return
     
      const { study_id : latest_published_study_id } = this.getDefaultCustomCombinationPayload
 
      // All-Electric
      const { id: all_electric_type_fuel_id } = this.$store.getters['globalEntities/TypeFuel/getterGlobalTypeFuel']({ slug: TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC })
      const all_electric_fuel = this.$store.getters['globalEntities/Fuel/getterGlobalFuel']({ study_id : latest_published_study_id, type_fuel_id: all_electric_type_fuel_id })
      const all_electric_custom_combination = new CustomCombination({ ...this.getDefaultCustomCombinationPayload, climate_zone_raw, fuel_id : all_electric_fuel.id })
     
      // Mixed Fuel
      const { id: mixed_fuel_type_fuel_id } = this.$store.getters['globalEntities/TypeFuel/getterGlobalTypeFuel']({ slug: TYPE_FUELS_DB_SLUGS.MIXED_FUEL })
      const mixed_fuel_fuel = this.$store.getters['globalEntities/Fuel/getterGlobalFuel']({ study_id : latest_published_study_id, type_fuel_id: mixed_fuel_type_fuel_id })
      const mixed_fuel_custom_combination = new CustomCombination({ ...this.getDefaultCustomCombinationPayload, climate_zone_raw, fuel_id : mixed_fuel_fuel.id })
 
      return this.$store.dispatch(
        'policy/saveCustomCombinationsToPolicy',
        [ all_electric_custom_combination, mixed_fuel_custom_combination ]
      )
    },
    getTooltip(climateZone, climateZoneIndex) {
      const customCombinations = this.getCustomCombinationsByClimateZone({ climate_zone_raw: climateZone.raw })
      const condition = customCombinations && this.showTooltip && this.showTooltip.climate_zone_index === climateZoneIndex
      const content = 'Click to view and edit requirements'
      return createTooltipOptions(condition, content)
    }
  },
}
</script>
 
<style lang="scss" scoped>
  .policy-container-card {
    min-height: 131.88px;
  }
</style>
