<template>
  <div class="svg-color flex flex-col px-4 pt-2">
    <p
      class="psui-text-p psui-text-gray-70 mb-1"
      style="font-size: 14px;"
    >
      Existing Buildings
    </p>
    <PsCollapse
      v-for="prototype in getAvailableOptions"
      :key="`prototype-${prototype.id}`"
      :title="prototype.title"
      :disabled="prototype.disabled"
      class="ps-prototype -ml-1 mb-2"
      :class="[{'has-prototype-active': hasClimateZoneSelectedByVintages(prototype)}]"
      :opened="true"
    >
      <template #content>
        <PsCollapse
          v-for="climateZone in prototype.climateZones"
          :key="`czone-${climateZone.id}`"
          :title="climateZone.title"
          :disabled="climateZone.disabled"
          :opened="true"
          class="ps-climate-zone"
          :class="[{'has-climate-zone-active': hasClimateZoneSelectedByVintages(prototype, climateZone)}]"
        >
          <template #content>
            <div
              class="collapse-content flex flex-col items-center w-full space-y-1"
            >
              <div
                v-for="vintage in climateZone.vintages"
                :key="`vintage-${vintage.id}`"
                class="collapse-content-wrapper -ml-2 flex items-center w-full rounded-r-xs"
                :class="[
                  {'active': isActiveItem(prototype.id, climateZone.raw, vintage.id)},
                  {'disabled':vintage.disabled} 
                ]"
              >
                <span
                  class="psui-text-small cursor-pointer whitespace-nowrap pl-2"   
                  @click="toggleRequirements(prototype, climateZone, vintage)"
                >
                  {{ vintage.title }}
                </span>
              </div>
            </div>
          </template>
        </PsCollapse>
      </template>
    </PsCollapse>
  </div>
</template>

<script>
  import CustomCombinationApiService from '@/services/api/CustomCombinationApiService'
  import FlexiblePathService from '@/services/api/FlexiblePathService'
  import { cloneDeep } from 'lodash'
  import CustomCombination from '@/models/CustomCombination'
  import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'

  export default {
    name:'PolicyEditRequirementsExistingBuildingsLeftSideBar',
    props: ['currentCustomCombination', 'isLoadingDrawer','flexiblePath','filters','policy'],
    data() {
      return {
        clickedCurrentCustomCombinationData: null,
        isLoading: [],
      }
    },
    computed:{
      getPolicySelectedId() {
        return this.$route.params.policy_id || null
      },
      getterUserCanEditPolicy() {
        return this.getPolicySelectedId ? this.$store.getters['policy/getterUserCanEditPolicy'](this.getPolicySelectedId) : false
      },
      getPolicySelected() {
        return this.getPolicySelectedId ? this.$store.getters['policy/getterUserPolicy']({id: this.getPolicySelectedId}) : null
      },
      getPolicyStudyType() {
        return this.$store.getters['globalEntities/StudyType/getterGlobalStudyType']({
          slug: STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS
        })
      },
      getLastJurisdictionVisited() {
        return this.$store.getters['lastJurisdictionVisited']
      },
      getAvailableOptions() {
        if (!this.getPolicyStudyType) {
          return []
        }

        let prototypes = this.$store.getters['policy/getterPolicyAvailableTypePrototypesByStudyType']({ 
          policy_id: this.getPolicySelectedId, 
          study_type_id: this.getPolicyStudyType.id,
          checkCodeCycle: false 
        })
        .map((p) => {
          return p.prototypes?.filter((p2) => p2.allow_policy_creation === true && p2.policy_option_algorithms.length > 0)
        }).flat(Infinity)

        const typeProtypeOrder = (id) => {
          const typePrototype = this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({id})
          return typePrototype?.order ?? 9999
        }

        return prototypes
        .sort((a, b) => {
          return typeProtypeOrder(a.type_prototype_id)- typeProtypeOrder(b.type_prototype_id)
        })
        .map((prototype) => {
          return {
            title: prototype?.title,
            disabled: !this.isConfigured(prototype.id),
            id: prototype?.id,
            typePrototypeId: prototype.type_prototype_id,
            climateZones: (this.getLastJurisdictionVisited?.climate_zones || []).map((climateZone) => {
              return {
                title: `Climate Zone ${climateZone.prefix}`,
                disabled: !this.isConfigured(prototype.id, climateZone.raw),
                raw: climateZone.raw,
                prefix: climateZone.prefix,
                id: climateZone?.id,
                vintages: this.$store.getters['globalEntities/Vintage/getterGlobalVintages']({study_id: +prototype.study_id}).map((vintage) => {
                  return {
                    title: vintage.title,
                    disabled: !this.isConfigured(prototype.id, climateZone.raw, vintage?.id),
                    id: vintage?.id,
                    typeVintageId: vintage.type_vintage_id
                  }
                }).filter((x) => this.getterUserCanEditPolicy || !x.disabled)
              }
            }).filter((x) => this.getterUserCanEditPolicy || !x.disabled)
          }
        }).filter((x) => this.getterUserCanEditPolicy || !x.disabled)
      }
    },
    watch: {
      getPolicySelected: {
        handler() {
          this.$forceUpdate()
        },
      },
    },
    methods: {
      hasClimateZoneSelectedByVintages(prototype, climateZone){
        if(climateZone) return climateZone.vintages.some(v => this.isActiveItem(prototype.id,climateZone.raw,v.id))
        
        return prototype.climateZones
        .reduce((acc, curr) => {
          if(curr.vintages.some(v => this.isActiveItem(prototype.id, curr.raw, v.id))) {
            acc = true
          }
          return acc
        }, false)
      }
    ,
      getVintageClass(isActive, isDisabled){

        if(isActive && !isDisabled) {
          return 'psui-text-blue-60 font-bold'
        } else if(isActive && isDisabled){
          return 'font-bold psui-text-gray-50'
        } else if(isDisabled) {
          return 'psui-text-gray-50'
        }

        return 'psui-text-gray-70'
      },
      async doAction(event, prototype, climateZone, vintage, create) {
        event.stopPropagation()
        if (!create) {
          return await this.deleteRequirement(prototype, climateZone, vintage)
        }
        return await this.addRequirement(prototype, climateZone, vintage)
      },
      isLoadingItem(prototype, climateZone, vintage) {
        const loadingItem = `${prototype?.typePrototypeId}-${climateZone?.raw}-${vintage?.title}`
        return this.isLoading.some( item => item == loadingItem)
      },
      isActiveItem(prototypeId, climateZoneRaw, vintageId) {
        const activeByLocalVar = Boolean(this.clickedCurrentCustomCombinationData && +this.clickedCurrentCustomCombinationData?.prototypeId === +prototypeId &&
            this.clickedCurrentCustomCombinationData?.climateZoneRaw === climateZoneRaw && +this.clickedCurrentCustomCombinationData?.vintageId === +vintageId)

        const activeByCustomCombination = Boolean(this.currentCustomCombination && +this.currentCustomCombination?.prototype_id === +prototypeId &&
            this.currentCustomCombination?.climate_zone_raw === climateZoneRaw && +this.currentCustomCombination?.vintage_id === +vintageId)
        return (activeByLocalVar || activeByCustomCombination) && this.filters?.custom_combination_id
      },
      isConfigured(prototypeId, climateZoneRaw, vintageId) {
        const climateZone = this.$store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({ raw:climateZoneRaw })
        const vintage = this.$store.getters['globalEntities/Vintage/getterGlobalVintage']({ id: vintageId })
        const prototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototype']({id:prototypeId})

        const tiers = this.flexiblePath?.tiers?.filter(tier => {
          return +tier?.prototype_id == +prototypeId && (!climateZoneRaw || +tier?.climate_zone_id === +climateZone.id)
        })

        const customCombinationMandatoryMeasuresCheck =  this.policy?.policy_containers
        ?.find(cc => cc.type_prototype_id == prototype?.type_prototype_id)
        ?.custom_combinations
        .filter( cc => (!climateZoneRaw || cc?.climate_zone_raw == climateZoneRaw) && (+cc?.vintage_id == vintageId || !vintageId))
        .some( cc => cc?.measures.length > 0)


        if(!vintageId) {
          return tiers && (tiers.some(tier => tier.mandatory_measures.length > 0 || tier.excluded_measures.length > 0 || tier.target_scores.some(ts => ts?.value)) || customCombinationMandatoryMeasuresCheck)
        }

        const matchExcludedMeasure = tiers?.some(tier => tier.excluded_measures.some(em => +em?.type_vintage_id === +vintage.type_vintage_id))
        const matchMandatoryMeasure = tiers?.some(tier => tier.mandatory_measures.some(mm => +mm?.type_vintage_id === +vintage.type_vintage_id)) ||
        customCombinationMandatoryMeasuresCheck

        const matchTargetScore = tiers?.some(tier => tier.target_scores.some(ts => +ts?.type_vintage_id === +vintage.type_vintage_id && ts.value))

        return matchExcludedMeasure || matchMandatoryMeasure || matchTargetScore
      },
      async addRequirement(prototype, climateZone, vintage, open=false) {
        const loadingItem = `${prototype?.typePrototypeId}-${climateZone?.raw}-${vintage?.title}`
        this.isLoading.push(loadingItem)
        if (!this.getterUserCanEditPolicy) {
          this.$eventBus.$emit('openPolicyUserPermissionDuplicateAlertModal', { policy: this.getPolicySelected })
          return
        }

        if (this.isLoadingDrawer || this.isConfigured(prototype?.id, climateZone?.raw, vintage?.id)) return

        let container = this.getPolicySelected.policy_containers.find(cc => +cc.type_prototype_id === +prototype.typePrototypeId)
        const studyTypeId = this.$store.getters['globalEntities/StudyType/getterStudyTypeExistingBuildings']?.id
        if (!container) {
          const typePrototype = this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id : prototype.typePrototypeId })
          container = await this.$store.dispatch('policy/updateOrCreatePolicyContainer', {
            policy_id: this.getPolicySelected?.id,
            study_type_id: studyTypeId,
            type_prototype_id: prototype.typePrototypeId,
            type_prototype_order: typePrototype.order
          }).catch(() => null)
        }

        const { id : latestPublishedStudyId } = [...this.$store.getters['globalEntities/Study/getterLatestPublishedStudiesByStudyTypeAndTypePrototype']({
          type_prototype_id: prototype.typePrototypeId,
          study_type_id: studyTypeId,
        })]?.shift()

        const customCombinationCommonPayload = {
          prototype_id: prototype?.id,
          jurisdiction_id : this.getPolicySelected.jurisdiction_id,
          policy_id: this.getPolicySelected?.id,
          policy_container_id : container?.id,
          study_id : latestPublishedStudyId
        }
        const climateZonesToSave = climateZone ? [climateZone] : prototype.climateZones
        const customCombinations = climateZonesToSave.map((climateZone) => {
          const vintagesToSave = vintage ? [vintage] : climateZone.vintages
          return vintagesToSave.map((vintage) => {
            return new CustomCombination({ ...customCombinationCommonPayload, climate_zone_raw: climateZone.raw, vintage_id : vintage.id })
          })
        }).flat(2)

        await this.$store.dispatch('policy/saveCustomCombinationsToPolicy', customCombinations)
        await this.$store.dispatch('policy/getUserPolicy', { policyId: container.policy_id })

        if (vintage) {
          vintage.disabled = false
        }
        if (climateZone && !vintage) {
          climateZone.disabled = false
        }
        if (prototype && !climateZone && !vintage) {
          prototype.disabled = false
        }

        this.toggleRequirements(prototype, climateZone, vintage)
      },
      async deleteRequirement(prototype, climateZone, vintage) {
        const loadingItem = `${prototype?.typePrototypeId}-${climateZone?.raw}-${vintage?.title}`
        this.isLoading.push(loadingItem)

        if (!this.getterUserCanEditPolicy) {
          this.$eventBus.$emit('openPolicyUserPermissionDuplicateAlertModal', { policy: this.getPolicySelected })
          this.isLoading = []
          return
        }

        if (this.isLoadingDrawer)  return
        const container = this.getPolicySelected.policy_containers.find(cc => +cc.type_prototype_id === +prototype.typePrototypeId)
        
        if (!container || !this.isConfigured(prototype?.id, climateZone?.raw, vintage?.id)) {
          this.isLoading = []
          return
        } 

        const removePromises = []
        const matchingContainers = container?.custom_combinations?.filter((cc) => {
          const matchClimateZone = Boolean(!climateZone?.raw || (cc.climate_zone_raw === climateZone.raw))
          const matchVintage = Boolean(!vintage?.id || (+cc.vintage_id === +vintage.id))
          return matchClimateZone && matchVintage
        })

        const [cc] = matchingContainers

        if(matchingContainers.length == 1 && cc.id == this.$route.query.per_custom_combination_id) {
          this.isLoading = []
          return this.$emit('toggleVintageRequirements')
        }

        matchingContainers.forEach((cc) => {
          removePromises.push(CustomCombinationApiService.delete(cc.id).catch(() => {}))
        })
        if (!climateZone?.raw && !vintage?.id) {
          removePromises.push(this.$store.dispatch('policy/deleteContainerFromPolicy', {container, refreshPolicy: false }))
        }


        // Delete flex
        const getPrototypesByTypePrototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototypes']({type_prototype_id: container.type_prototype_id})
        const flexiblePath = new FlexiblePathService({ policy_id : this.getPolicySelectedId })

        const setup = cloneDeep({...this.flexiblePath})
        const matchingTiers = setup?.tiers?.filter((tier) => {
          const matchPrototype = getPrototypesByTypePrototype.some(prototype => +prototype?.id === +tier.prototype_id)
          const matchClimateZone = Boolean(!climateZone?.id || +tier.climate_zone_id === +climateZone?.id)
          return matchPrototype && matchClimateZone
        })
        const clearCepPairs = []
        
        matchingTiers.forEach((tier) => {
          const filterFnc = (i) => {
            return !(!vintage?.typeVintageId || +i.type_vintage_id === +vintage.typeVintageId)
          }
          tier.excluded_measures = tier.excluded_measures.filter(filterFnc)
          tier.mandatory_measures = tier.mandatory_measures.filter(filterFnc)
          tier.target_scores = tier.target_scores.filter(filterFnc)

          if (!tier.excluded_measures.length && !tier.mandatory_measures.length && !tier.target_scores.length) {
            clearCepPairs.push({prototype_id: tier.prototype_id, climate_zone_id: tier.climate_zone_id})
          }
        })

        if (clearCepPairs.length) {
          setup.cost_effective_presets = setup.cost_effective_presets?.filter((cep) => {
            return !clearCepPairs.some((i) => +i.prototype_id === +cep.prototype_id && +i.climate_zone_id === +cep.climate_zone_id)
          }) || []
        }
        removePromises.push(flexiblePath.updateSetup(setup))
        
        if (vintage) {
          vintage.disabled = true
        }
        if (climateZone && !vintage) {
          climateZone.disabled = true
        }
        if (prototype && !climateZone && !vintage) {
          prototype.disabled = true
        }

        const customCombinationByPrototypeCzVintage = this.getPolicySelected?.policy_containers
        ?.find(cc => +cc.type_prototype_id === +prototype.typePrototypeId)
        ?.custom_combinations?.filter((cc) => {
          return  (!climateZone?.raw && !+vintage?.id ) || (cc.climate_zone_raw === climateZone?.raw && (!vintage || +cc.vintage_id === +vintage?.id)) 
        }) || null
        await Promise.all(removePromises)
        
        await this.$store.dispatch('policy/getUserPolicy', { policyId: container.policy_id })
        .then(() => {
          this.$appToast({type:'success', message: 'Requirements for your policy have successfully been deleted.' })
          this.$store.dispatch('setUserLastStudyTypeVisited', { value: container?.study_type?.slug, context: 'updateLastStudyTypeVisitedFromPolicyContainer'})
          const finded = customCombinationByPrototypeCzVintage
          .find(cc => cc.id == this.filters?.custom_combination_id)
          
          if(finded && !vintage) {
            return this.$emit('toggleRequirements', prototype, climateZone, vintage, {}, setup , false)
          }

          const currentCustomCombination = this.getPolicySelected?.policy_containers
          .map( cc => cc.custom_combinations )
          .flat(Infinity)
          .find( cc => cc.id == this.filters?.custom_combination_id)

          const deletedCustomCombination = customCombinationByPrototypeCzVintage.pop()

          this.$emit('toggleRequirements', 
            prototype, 
            climateZone, 
            vintage, 
            {...currentCustomCombination, policy: {title: this.getPolicySelected?.title}, climate_zone: climateZone, prototype, vintage}, 
            setup , 
            deletedCustomCombination?.id == currentCustomCombination?.id
          )

          this.isLoading = []
        })

      },
      async toggleRequirements(prototype, climateZone, vintage) {
        
        const customCombination = this.getPolicySelected?.policy_containers?.find(cc => +cc.type_prototype_id === +prototype.typePrototypeId)?.custom_combinations?.find((cc) => {
          return cc.climate_zone_raw === climateZone.raw && +cc.vintage_id === +vintage?.id
        }) || null

        if(!customCombination) return await this.addRequirement(prototype, climateZone, vintage)
        

        if (this.getterUserCanEditPolicy || customCombination) {
          this.clickedCurrentCustomCombinationData = {
            prototypeId: prototype?.id,
            climateZoneRaw: climateZone.raw,
            vintageId: vintage?.id,
          }
        }
        this.$emit('toggleRequirements', prototype, climateZone, vintage, {...customCombination, policy: {title: this.getPolicySelected?.title}, climate_zone: climateZone, prototype, vintage}, this.flexiblePath)
        this.isLoading = []
      }
    }
  }
</script>

<style lang="scss" scoped>

  ::v-deep.psui-el-collapse.ps-climate-zone,::v-deep.psui-el-collapse.ps-prototype {
    .psui-el-collapse-item-header {
      margin-bottom: 4px;
      .psui-el-collapse-item-header-wrapper {
        padding: 2px 0px;
      }
    }

    &:not(.disabled) {
      &:hover {
        >.psui-el-collapse-item-header  {
          .psui-el-collapse-item-icon {
            color: #318FAC;
          }
          .psui-el-collapse-item-header-wrapper {
            .psui-el-collapse-item-title {
              font-weight: 400 !important;
              color: #34404A !important;
            }
          }
        }
      }  
      
      &:not(.status-opened):is(.has-climate-zone-active),&:not(.status-opened):is(.has-prototype-active) {   
        >.psui-el-collapse-item-header  {
          .psui-el-collapse-item-header-wrapper {
            .psui-el-collapse-item-title {
              font-weight: 700;
              color: #318FAC;
            }
          }
        }

        &.disabled {
          >.psui-el-collapse-item-header  {
            .psui-el-collapse-item-header-wrapper {
              .psui-el-collapse-item-title.disabled {
                font-weight: 700;
                color: #318FAC !important;
              }
            }
          }
        }
      }
    }
    
    &:not(.status-opened):is(.has-climate-zone-active) {
        &.disabled {
          font-weight: 700;
        }
      }
  }
    


.collapse-content {
  margin-left: 4px;
  .collapse-content-wrapper {
    padding:2px 0px;
    font-weight: 400;
    color: #34404A;

    &.disabled {
      color: #798490 !important;
    }

    &:hover {
      font-weight: 700;
      color: #318FAC;

      &.disabled { 
        color: #798490;
      }
    }

    &.active {
      border-top-left-radius: 3.5px 3px;
      border-bottom-left-radius: 3.5px 3px;
      border-left-width: 2px;
      border-left-color: #318FAC;
      background-color: white;  
      >span {
        color: #318FAC;
      }

      &.disabled {
        background-color: #D6DDE5;
        border-top-left-radius: 3.5px 3px;
        border-bottom-left-radius: 3.5px 3px;
        border-left-width: 2px;
        border-left-color: #798490;
        > span {
          color: #798490;
        }
      }
    }

    ::v-deep .collapse-content-wrapper-action {
      visibility: hidden;
      cursor: pointer;
      
      svg:hover {
        fill: rgb(49 143 172);
      }
    }

    &:hover {
      .collapse-content-wrapper-action {
        visibility: visible;
      }
    }
  }
}

::v-deep .collapse-content-wrapper-action {    
    svg:hover {
      fill: rgb(49 143 172);
      cursor: pointer;
    }
  }

.rotate {
  animation: spin 2s infinite linear;
}

@keyframes spin {
    0%  {-webkit-transform: rotate(0deg);}
    100% {-webkit-transform: rotate(-360deg);}   
}
</style>