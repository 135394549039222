import newBuildingsImpactEstimatesPerQuarter from '@/business-logic/services/impact-algorithms/results/new-buildings/newBuildingsImpactEstimatesPerQuarter'

export default (
  building_estimates,
  fuel,
  {
    all_electric_shares_without_policy = 25,
    all_electric_shares_with_policy = 25,
    effective_date = null,
    termination_date = null,
    installation_effects_years = 30,
    applicability_rate = 100,
    annual_penetration_rate = 100,
    delay_installation = 1,
    likelihood_installations_first_third = 0,
    likelihood_installations_second_third = 33,
    likelihood_installations_final_third = 66,
    care_program_enrollment_rate
  },
  {
    currentThermsEmissionFactor,
    currentKwhEmissionFactor,
    kwhSavings,
    energySavings,
    thermsSavings,
    annualBillSavings,
    initialCost,
    gridMaxRenewablesYear,
    gridMaxRenewablesLevel,
    currentGridRenewableLevel,
    subsidy_needed,
    five_year_payback,
    subsidy_needed_care,
    five_year_payback_care
  }) =>
{

  const impactEstimatesPerQuarter = newBuildingsImpactEstimatesPerQuarter(building_estimates,
    fuel,
    {
      all_electric_shares_without_policy,
      all_electric_shares_with_policy,
      effective_date,
      termination_date,
      installation_effects_years,
      applicability_rate,
      annual_penetration_rate,
      delay_installation,
      likelihood_installations_first_third,
      likelihood_installations_second_third,
      likelihood_installations_final_third,
      care_program_enrollment_rate
    },
    {
      currentThermsEmissionFactor,
      currentKwhEmissionFactor,
      kwhSavings,
      energySavings,
      thermsSavings,
      annualBillSavings,
      initialCost,
      gridMaxRenewablesYear,
      gridMaxRenewablesLevel,
      currentGridRenewableLevel,
      subsidy_needed,
      five_year_payback,
      subsidy_needed_care,
      five_year_payback_care
    })

    const years = [...new Set(impactEstimatesPerQuarter.map((ie) => ie.year).filter(y => y != null))].sort((a, b) => a - b)
  const acumulative = {}
  const generateAccumulative = (key, sum) => {
    if (!acumulative[key]) {
      acumulative[key] = 0
    }

    acumulative[key] += sum
    return {
      cummulative: acumulative[key],
      current: sum
    }
  }
  const keys = {
    forecast_emissions_savings: 'ghg_savings',
    forecast_initial_cost: 'compliance_cost',
    forecast_kwh_savings: 'kwh_savings',
    forecast_energy_savings: 'forecast_energy_savings',
    forecast_lifecycle_savings: 'bill_savings',
    forecast_therms_savings: 'therms_savings',
    forecast_units_affected: 'installations_started',
    subsidy_needed:'city_wide_subsidy_needed',
    five_year_payback:'city_wide_five_year_payback',
    subsidy_needed_care:'city_wide_subsidy_needed_care',
    five_year_payback_care:'city_wide_five_year_payback_care',
  }


  return years.map((year) => {
    const result = {
      year,
    }
    const yearInfo = impactEstimatesPerQuarter.filter((i) => i.year === year)
    Object.keys(keys).forEach((key) => {
      const keySum = yearInfo.reduce((acc, item) => acc + item[keys[key]], 0)
      result[key] = generateAccumulative(key, keySum)
    })
    return result
  })
}
