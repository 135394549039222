<template>
  <div v-if="building_type && climate_zone_prefix">
    <div class="px-6">
      <div class="flex justify-between gap-1 mb-6">
        <PsButton
          :label="'Save to policy' | policyTextRephrase"
          icon="add_circle"
          size="medium"
          layout="solid"
          icon-position="left"
          @click="openAssumptionsModal()"
        />
        <PsButton
          label="Restore default"
          icon="restart_alt"
          size="medium"
          layout="ghost"
          icon-position="left"
          :disabled="!isNotDefaultState"
          @click="restoreToDefault()"
        />
      </div>

      <AssumptionsBuildingEstimatesUserSign 
        v-if="isNotDefaultState && !userIsLoggedIn"
        :session-storage-key="'assumptions-building-estimates-user-sign-residential-existing'"
      />

      <h2 class="font-bold psui-text-gray-80 mb-1">
        Residential Units as of 2020
      </h2>
      <ul class="flex justify-between psui-text-small psui-text-gray-60">
        <li>Total units in {{ climateZones.length }} climate zones</li>
        <li class="psui-text-gray-80">
          {{
            formatUnits(
              $store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
                type_prototype_id: getBuildingTypeTypePrototypesExcludingAdu.map((type_prototype) => type_prototype.id),
              })
            )
          }}
        </li>
      </ul>

      <hr class="psui-border-blue-70 opacity-10 my-7">
      <div
        v-if="lastJurisdictionVisitedClimateZonesLength > 1"
      >
        <AssumptionsBuildingEstimatesClimateZones
          :climate_zone_prefix="climate_zone_prefix"
          :climate_zones="climateZones"
          @setClimateZonePrefix="climate_zone_prefix = $event"
        />
        <ul class="flex justify-between psui-text-small psui-text-gray-60 mb-7">
          <li>Units in selected climate zone</li>
          <li class="psui-text-gray-80">
            {{
              formatUnits(
                $store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
                  type_prototype_id: getBuildingTypeTypePrototypesExcludingAdu.map((type_prototype) => type_prototype.id),
                  climate_zone_prefix: climate_zone_prefix,
                })
              )
            }}
          </li>
        </ul>
      </div>

      <div
        v-for="type_prototype in getBuildingTypeTypePrototypesExcludingAdu"
        :key="type_prototype.id"
        class="mb-7"
      >
        <h2 class="font-bold psui-text-gray-80 mb-4">
          {{ type_prototype.title }}
        </h2>

        <div
          v-for="type_vintage in type_vintages"
          :key="`${type_prototype.id}-${type_vintage.id}`"
          class="flex justify-between"
        >
          <AssumptionsBuildingStocksInput
            :label="type_vintage.title"
            :filters="{
              climate_zone_prefix: climate_zone_prefix,
              type_vintage_id: type_vintage.id,
              type_prototype_id: type_prototype.id,
            }"
          />
        </div>
        <div class="w-full flex justify-between items-center mb-2">
          <p class="psui-text-small psui-text-gray-60">
            Total
          </p>
          <PsInput
            :value="
              formatUnits(
                $store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits']({
                  type_prototype_id: type_prototype.id,
                  climate_zone_prefix: climate_zone_prefix,
                })
              )
            "
            layout="mini"
            disabled
          >
            <template #append>
              <span
                class="psui-absolute psui-text-xsmall psui-text-gray-40 psui-cursor-default psui-right-0 psui-width-0 psui-height-0 psui-pr-2"
              >
                Units
              </span>
            </template>
          </PsInput>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssumptionsBuildingStocksInput from '../AssumptionsBuildingStocksInput.vue'
import AssumptionsBuildingEstimatesClimateZones from '../AssumptionsBuildingEstimatesClimateZones.vue'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { mapGetters } from 'vuex'
import AssumptionsBuildingEstimatesUserSign from '@/modules/app/assumptions/building-estimates/AssumptionsBuildingEstimatesUserSign.vue'
import { createArrayOfBuildingEstimatesFilter }from '@/util/Helpers.js'


export default {
  name: 'AssumptionsResidentialExisting',
  components: { AssumptionsBuildingEstimatesClimateZones, AssumptionsBuildingStocksInput, AssumptionsBuildingEstimatesUserSign },
  props: ['building_type', 'type_vintages', 'getTabSelected'],
  data: () => ({
    climate_zone_prefix: false,
  }),
  computed: {
    ...mapGetters(['lastJurisdictionVisited', 'lastStudyTypeVisited', 'lastClimateZonesVisited']),
    climateZones() {
      return this.lastJurisdictionVisited?.climate_zones ?? []
    },
    userIsLoggedIn() {
      return this.$store.getters.getterLoggedUser
    },
    getterCustomJurisdictionBuildingStocks() {
      const policy_id = this.$route.params.policy_id ? this.$route.params.policy_id : null
      return this.$store.getters['assumptions/buildingStocks/getterCustomJurisdictionBuildingStocks']({ policy_id })
    },
    getterLastClimateZonesVisited() {
      return this.lastClimateZonesVisited
    },
    getBuildingTypeTypePrototypesExcludingAdu() {
      return [...this.building_type.type_prototypes].filter((type_prototype) => type_prototype.slug != 'adu')
    },
    getIfUserHasExistingBuildingPolicies(){
      return this.$store.getters['policy/getterUserPoliciesByJurisdiction'].filter(policy =>{
        const getPolicyType = this.$store.getters['policy/getterPolicyStudyTypes']({policy_id: policy.id,policy})
        return getPolicyType.includes(STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS)
      }).length > 0
    },
    lastJurisdictionVisitedClimateZonesLength(){
      return this.lastJurisdictionVisited?.climate_zones?.length
    },
    isNotDefaultState(){


      const getArrayOfFilters = createArrayOfBuildingEstimatesFilter(
        {
          climateZonePrefix:this.climate_zone_prefix,
          typePrototypeIdArray: this.getBuildingTypeTypePrototypesExcludingAdu.map(tp => tp.id),
          TypeVintageIdArray: this.type_vintages
      })

      return getArrayOfFilters.some(filter => {
        return this.$store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits'](filter) != this.$store.getters['assumptions/buildingStocks/getterDefaultJurisdictionBuildingStocksUnits'](filter)
      })
    },
    getPolicyOptionTypeUserPreference(){
      return this.$store.getters['getterUserPrefferenceByPath']('policies.policyOptions.filterByStudyType')
    }
  },
  watch: {
    climateZones: {
      handler(czs) {
        if (czs) this.climate_zone_prefix = czs[0].prefix
      },
      immediate: true,
    },
  },
  mounted() {
    this.climate_zone_prefix = this.getterLastClimateZonesVisited[0]['prefix']
  },
  methods: {
    goToPolicyOptionsExistingBuildingsTab() {
      this.$store.dispatch('setUserLastPolicyOptionSelected', {
        context:'updateLastPolicyOptionSelectedFromSummary',
        value: [STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS]
      })
      this.$store.commit('assumptions/toggleDrawer',{})

      this.$router.push({name: 'PolicyOptions'})

    },
    openAssumptionsModal() {
      if (!this.getIfUserHasExistingBuildingPolicies) {
        if (
          this.$route.name === 'PolicyOptions' &&
          this.getPolicyOptionTypeUserPreference.length == 1 && this.getPolicyOptionTypeUserPreference.includes(STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS)
        ) {
          return
        } else {
          return this.goToPolicyOptionsExistingBuildingsTab()

        }
      }

      const eventName = 'openAssumptionsModal'
      const eventPayload = this.getTabSelected.slug

      this.$eventBus.$emit(
          eventName,
          {
            eventPayload,
            userHasPolicies: this.getIfUserHasExistingBuildingPolicies
          }
      )
      this.$store.commit('general/setRedirectTo', {
        route: this.$route.path,
        event: { name: eventName, payload: eventPayload },
      })
    },
    restoreToDefault() {
      this.$store.dispatch(
        'assumptions/buildingStocks/clearCustomBuildingStocks',
        this.getterCustomJurisdictionBuildingStocks
      )
    },
  },
}
</script>
