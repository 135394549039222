import { CUSTOM_COMBINATION_PRESETS } from "@/modules/app/jurisdiction/study-results/shared/enums"
import Store from "@/store"
import FlexiblePathService from "@/services/api/FlexiblePathService"
import { parallel, try as tryit } from "radash"
import StudyDataApiService from "@/services/api/StudyDataApiService"
import StudyData from "@/models/StudyData"
import ImpactAssumptions, { defaultExistingBuildingsImpactAssumptionValues } from "@/models/ImpactAssumptions"
import { CITY_WIDE_IMPACT_COLUMNS_KEYS } from "@/business-logic/services/impact-algorithms/policy"
import { forecast_units } from "@/modules/app/shared/default-app-columns"
import requirementDrawerMixin from "@/modules/app/policy/show/requirements/requirements-drawer/RequirementDrawerMixin"
import asyncCacheResolver from '@/util/asyncCacheResolver'
import { PolicyImpactAlgorithmFactory } from "@/business-logic/services/impact-algorithms/policy/factory"
import {
    GeneralPerformanceByFuelPolicyImpactAlgorithm
} from "@/business-logic/services/impact-algorithms/policy/general-performance-by-fuel"
import * as sha1 from 'sha1'

export async function getNewBuildingsImpact(algorithm, prototypes, jurisdiction) {
    const cacheKey = `newBuildingsImpactV2:po-${algorithm.name}:jur-${jurisdiction.id}:${prototypes.sort((a, b) => +a.id - +b.id).map((i) => `all-${i.id}`).join(',')}`
    const key = sha1(cacheKey)
    return await asyncCacheResolver(key, async () => {
        const cachedDbImpacts = await StudyDataApiService.getWideStudyData(key)
        if (cachedDbImpacts?.length) {
            const allImpacts = cachedDbImpacts.map((i) => {
                return i?.data?.impacts || []
            }).flat(1)
            return generateNewResumedImpacts(allImpacts)
        }

        const impacts = (await _computeNewBuildingsImpact(algorithm, prototypes, jurisdiction)).map((i) => {
            i.context_hash = key
            return i
        })
        await tryit(parallel)(3, impacts, async (item) => {
            await StudyDataApiService.persistWideStudyData(item)
        })

        const allImpacts = impacts.map((i) => {
            return i?.data?.impacts || []
        }).flat(1)
        return generateNewResumedImpacts(allImpacts)
    })
}

async function _computeNewBuildingsImpact(algorithm, prototypes, jurisdiction) {
    const jurisdictionClimateZones = jurisdiction.climate_zones.map((cz) => cz.raw)
    const climateZonesAndPrototypes = prototypes.map((p) => {
        return { prototype_id: p.id, climate_zones: jurisdictionClimateZones }
    })
    const algInstance = (new algorithm.constructor(-1, climateZonesAndPrototypes))
    const policyContainers = (await algInstance.postInstall([await algInstance.install()], false)).map((policyContainer) => {
        return jurisdictionClimateZones.map((climateZoneRaw) => {
            return {
                climateZoneRaw,
                policyContainer
            }
        })
    }).flat(1)

    const [, result] = await tryit(parallel)(3, policyContainers, async ({policyContainer, climateZoneRaw}) => {
        const fuels = []
        const prototypes = []
        policyContainer.custom_combinations = policyContainer.custom_combinations.map((cc) => {
            const fuel = Store.getters['globalEntities/Fuel/getterGlobalFuel']({ id : cc.fuel_id })
            const prototype = Store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: cc.prototype_id })
            fuels.push(fuel)
            prototypes.push(prototype)
            return {
                ...cc,
                prototype,
                fuel,
            }
        })
        const impactAlgorithm = new GeneralPerformanceByFuelPolicyImpactAlgorithm({
            id: -1,
            jurisdiction_id: jurisdiction.id,
            code_cycle: {},
        }, policyContainer, climateZoneRaw)
        return (await impactAlgorithm.computeResumedCityWideImpact()).map((i) => {
            const prototype = prototypes.find((p) => p.type_prototype_id === policyContainer.type_prototype_id)
            return {
                climate_zone_raw: climateZoneRaw,
                study_id: prototype.study_id,
                prototype_id: prototype.id,
                fuel_id: fuels.find((f) => f.slug === i.fuel_slug).id,
                context_hash: null,
                data: { impacts: [i.data] },
            }
        })
    })

    return result.flat(1)
}

function generateNewResumedImpacts(impacts) {
    return impacts.reduce((acc, curr) => {
        CITY_WIDE_IMPACT_COLUMNS_KEYS.forEach((key) => {
            acc[key] = (acc?.[key] || 0) + (curr?.[key] || 0)
        })
        return acc
    }, {})
}

export async function getExistingBuildingsImpact(algorithm, prototypes, jurisdiction) {
    const cacheKey = `existingBuildingsImpactV2:po-${algorithm.name}:jur-${jurisdiction.id}:${prototypes.sort((a, b) => +a.id - +b.id).map((i) => `all-${i.id}`).join(',')}`
    const key = sha1(cacheKey)
    return await asyncCacheResolver(key, async () => {
        const cachedDbImpacts = await StudyDataApiService.getWideStudyData(key)
        if (cachedDbImpacts?.length) {
            const totalUnities = cachedDbImpacts.find((i) => i?.data?.total_unities)?.data?.total_unities || 0
            const allImpacts = cachedDbImpacts.map((i) => {
                return i?.data?.impacts || []
            }).flat(1)
            return generateExistingResumedImpacts(allImpacts, totalUnities)
        }

        // Compute data, store and return
        const { impacts, totalUnities} = await _computeExistingBuildingsImpact(algorithm, prototypes, jurisdiction)
        const groupCachedItems = []
        impacts.forEach((i) => {
            const studyId = Store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: i.prototypeId }).study_id
            const vintageId = i.vintageId || i.vintage_id
            let group = groupCachedItems.find((g) => g.study_id === studyId && g.climate_zone_raw === i.climateZoneRaw && g.prototype_id === i.prototypeId && g.vintage_id === vintageId)
            if (!group) {
                group = {
                    climate_zone_raw: i.climateZoneRaw,
                    study_id: studyId,
                    prototype_id: i.prototypeId,
                    vintage_id: vintageId,
                    context_hash: key,
                    data: { total_unities: totalUnities, impacts: [] },
                }
                groupCachedItems.push(group)
            }

            const itemData = { measure_id: i.measureId, flex: i.flex, target_score: i.target_score  }
            CITY_WIDE_IMPACT_COLUMNS_KEYS.forEach((key) => {
                itemData[key] = (i?.[key] || 0)
            })
            group.data.impacts.push(itemData)
        })
        await tryit(parallel)(3, groupCachedItems, async (item) => {
            await StudyDataApiService.persistWideStudyData(item)
        })

        return generateExistingResumedImpacts(impacts, totalUnities)
    })
}

function generateExistingResumedImpacts(impacts, totalUnities) {
    return impacts.reduce((acc, curr) => {
        CITY_WIDE_IMPACT_COLUMNS_KEYS.forEach((key) => {
            if (forecast_units().key !== key) acc[key] = (acc?.[key] || 0) + (curr?.[key] || 0)
        })
        return acc
    }, { [forecast_units().key]: totalUnities })
}

async function _computeExistingBuildingsImpact(algorithm, prototypes, jurisdiction) {
    const climateZonesAndPrototypes = prototypes.map((p) => {
        return {prototype_id: p.id, climate_zones: jurisdiction.climate_zones.map((cz) => cz.raw)}
    })
    const algInstance = (new algorithm.constructor(-1, climateZonesAndPrototypes))
    const config = await algInstance.postInstall([await algInstance.install()], false)

    const getMeasureItems = []
    const getOrCreateEmptyMeasureItem = (props) => {
        let item = getMeasureItems.find((i) => i.climateZoneRaw === props.climate_zone_raw && i.prototypeId === props.prototype_id)
        if (!item) {
            item = {
                prototypeId: props.prototype_id,
                climateZoneRaw: props.climate_zone_raw,
                vintageIds: [],
                measures: [],
                assumptions: {},
                impactPairs: [],
                flexPairs: [],
            }
            getMeasureItems.push(item)
        }
        return item
    };

    // Prescriptive part of config
    (config?.containersToSave || []).forEach((containerConfig) => {
        (containerConfig?.custom_combinations || []).forEach((cc) => {
            const item = getOrCreateEmptyMeasureItem(cc)
            item.vintageIds = [... new Set([...item.vintageIds, cc.vintage_id])]
            item.measures = [... new Set([...item.measures, ...(cc.measures || []).map((i) => i.id)])]
            item.assumptions = cc.meta?.assumptions || {};
            (cc.measures || []).forEach((measure) => {
                item.impactPairs.push({
                    vintageId: cc.vintage_id,
                    measureId: measure.id
                })
            })
        })
    })

    // Flexible Part of config
    const flexibleItems = (config?.flexibleToSave?.tiers || []).map((tier) => {
        const preset = config?.flexibleToSave?.cost_effective_presets?.find((i) => +i.climate_zone_id === +tier.climate_zone_id && +i.prototype_id === +tier.prototype_id)?.preset || CUSTOM_COMBINATION_PRESETS.ALL_POSSIBLE_ON_BILL.value
        return (tier.target_scores || []).map((tS) => {
            if (!tS?.value || isNaN(tS?.value) || +tS?.value <= 0) return null

            const prototype = Store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: tier.prototype_id })
            const vintage = Store.getters['globalEntities/Vintage/getterGlobalVintage']({ study_id : +prototype.study_id, type_vintage_id : +tS.type_vintage_id })
            const inputs = {
                climate_zone_raw: Store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({ id: +tier.climate_zone_id }).raw,
                prototype_id: tier.prototype_id,
                vintage_id: vintage.id,
                jurisdiction_id: +jurisdiction.id
            }
            const excludedMeasureIds = (tier.excluded_measures || []).filter((i) => +tS.type_vintage_id === +i.type_vintage_id).map((m) => +m.measure_id)
            const mandatoryMeasures = (tier.mandatory_measures || []).filter((i) => +tS.type_vintage_id === +i.type_vintage_id).map((m) => +m.measure_id)
            const targetScore = +tS?.value
            return {
                ...inputs,
                preset,
                target_score: targetScore,
                mandatory_measures: mandatoryMeasures,
                excluded_measures: excludedMeasureIds
            }
        }).filter((i) => i)
    }).flat(1)

    const flexiblePathService = new FlexiblePathService({ policy_id: null })
    await tryit(parallel)(3, flexibleItems, async (flexItem) => {
        const flexCombination = await flexiblePathService.getFlexibleCombinationsByTargetScores(flexItem)
        const withContingentMeasuresCombination = (flexCombination?.result_allowing_is_contingent?.measuresIdsOfCombinationWithLowestIncrementalCost || []).map(m => +m)
        const withoutContingentMeasuresCombination = (flexCombination?.result_not_allowing_is_contingent?.measuresIdsOfCombinationWithLowestIncrementalCost || []).map(m => +m)

        const hasError = Boolean(flexCombination !== null && ((withContingentMeasuresCombination.length === 0 || withoutContingentMeasuresCombination.length === 0)))
        if (hasError) {
            return null
        }

        const item = getOrCreateEmptyMeasureItem(flexItem)
        item.vintageIds = [... new Set([...item.vintageIds, flexItem.vintage_id])]
        item.measures = [... new Set([...item.measures, ...withContingentMeasuresCombination, ...withoutContingentMeasuresCombination])]
        item.flexPairs.push({
            ...flexItem,
            flexCombination,
            withContingentMeasuresCombination,
            withoutContingentMeasuresCombination,
        })
    })

    const impacts = []
    let totalUnities = 0
    const alreadyAddedUnities = []
    const addTotalUnities = (stocksFilter, unities) => {
        const unitiesKey = `:${stocksFilter.prototype_id}:${stocksFilter.climate_zone_prefix}:${stocksFilter.type_prototype_id}:${stocksFilter.type_vintage_id}`
        if (!alreadyAddedUnities.includes(unitiesKey)) {
            totalUnities += unities
            alreadyAddedUnities.push(unitiesKey)
        }
    }
    await tryit(parallel)(3, getMeasureItems, async (measureItem) => {
        const studyDatas = await StudyDataApiService.getAllForExisting({ climateZones: [measureItem.climateZoneRaw], measureIds: measureItem.measures, vintageIds: measureItem.vintageIds, prototypeIds: [measureItem.prototypeId] })
        const dataMapper = (mId, vintageId, stockUnities, assumptions) => {
            const studyData = (studyDatas.climateZones || []).map((cZone) => {
                return (studyDatas.studyData || []).find((studyData) => {
                    return +studyData.measure_id === +mId && cZone === studyData.climate_zone_raw && +studyData.vintage_id === +vintageId
                })
            }).filter((i) => i?.measure_id).shift()
            if (!studyData) {
                console.warn(`Couldn't find study data on policy options impact calculation for mId=${mId} and vintageId=${vintageId}`)
                return null
            }

            return new StudyData({
                study_data: studyData,
                building_stock_units: stockUnities,
                assumption: new ImpactAssumptions(assumptions || {})
            })
        }

        // Compute prescriptive impact
        measureItem.impactPairs.forEach((pair) => {
            const stocksFilter = {
                climate_zone_prefix: Store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({ raw: measureItem.climateZoneRaw }).prefix,
                type_vintage_id: Store.getters['globalEntities/Vintage/getterGlobalVintage']({ id: pair.vintageId }).type_vintage_id,
                type_prototype_id: Store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: measureItem.prototypeId }).type_prototype_id,
                prototype_id: measureItem.prototypeId
            }
            const buildingStocksUnities = Store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits'](stocksFilter)
            const studyDataForPair = dataMapper(pair.measureId, pair.vintageId, buildingStocksUnities, measureItem.assumptions)
            const commonData = {...pair, prototypeId: measureItem.prototypeId, climateZoneRaw: measureItem.climateZoneRaw}
            if (!studyDataForPair) {
                throw new Error(`Could not compute impact data for '${JSON.stringify(commonData)}'`)
            }

            impacts.push(CITY_WIDE_IMPACT_COLUMNS_KEYS.reduce((acc, key) => {
                acc[key] = studyDataForPair?.data?.[key] || 0
                return acc
            }, {...commonData}))
            addTotalUnities(stocksFilter, studyDataForPair?.data?.[forecast_units().key] || 0)
        })

        // Flexible part of config
        measureItem.flexPairs.forEach((pair) => {
            const stocksFilter = {
                climate_zone_prefix: Store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({ raw: measureItem.climateZoneRaw }).prefix,
                type_vintage_id: Store.getters['globalEntities/Vintage/getterGlobalVintage']({ id: pair.vintage_id }).type_vintage_id,
                type_prototype_id: Store.getters['globalEntities/Prototype/getterGlobalPrototype']({ id: measureItem.prototypeId }).type_prototype_id,
                prototype_id: measureItem.prototypeId
            }
            const buildingStocksUnities = Store.getters['assumptions/buildingStocks/getterAllBuildingStocksUnits'](stocksFilter)
            const impact = requirementDrawerMixin.methods.computeResumedImpacts({
                withContingentMeasuresData: pair.withContingentMeasuresCombination.map((mId) => dataMapper(mId, pair.vintage_id, buildingStocksUnities, measureItem.assumptions)?.data).filter((s) => s),
                withoutContingentMeasuresData: pair.withoutContingentMeasuresCombination.map((mId) => dataMapper(mId, pair.vintage_id, buildingStocksUnities, measureItem.assumptions)?.data).filter((s) => s),
                mandatoryMeasuresData: pair.mandatory_measures.map((mId) => dataMapper(mId, pair.vintage_id, buildingStocksUnities, measureItem.assumptions)?.data).filter((s) => s),
                customContingentPercentage: measureItem.assumptions?.flexible_contingent_percentage || defaultExistingBuildingsImpactAssumptionValues.flexible_contingent_percentage
            })

            const commonData = {...pair, prototypeId: measureItem.prototypeId, climateZoneRaw: measureItem.climateZoneRaw}
            impacts.push(CITY_WIDE_IMPACT_COLUMNS_KEYS.reduce((acc, key) => {
                acc[key] = impact?.[key] || 0
                return acc
            }, {...commonData, flex: true, target_score: pair.target_score }))
            addTotalUnities(stocksFilter, impact?.[forecast_units().key] || 0)
        })
    })

    return { impacts, totalUnities }
}