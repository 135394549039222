<template>
  <div class="w-full h-full pr-6">
    <div class="block">
      <ApexChartGeneral 
        :options="getChartOptions()"
        :palette-color-index="paletteColorIndex"
      /> 
    </div>
  </div>
</template>

<script>
import { BUILDING_ESTIMATES_BASE_YEARS, BUILDING_ESTIMATES_BASE_YEARS_TIMEFRAME, ASSUMPTIONS_PATHS } from '@/modules/app/assumptions/shared/enums'
import { combinationColors } from '@/business-logic/constants/chartDefaultOptions'
import { MathHelpers } from '@igortrindade/lazyfy'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums'
import { calculateMaxValue, roundUpToNearest } from '@/util/Functions.js'

export default {
  name: 'TypePrototypeFutureChart',
  props: [
    'getBuildingTypeSelected',
    'getClimateZoneSelected',
    'type_prototype',
    'construction_implementation_type_id',
    'building_height_id',
    'paletteColorIndex',
    'items',
    'breakingDownByConstructionImplementationTypeSelected',
    'breakingDownByBuildingHeightSelected'
  ],
  data() {
    return {
      chart: null,
      chartIsLoading: false,
      combinationColors,
      BUILDING_ESTIMATES_BASE_YEARS_TIMEFRAME,
      // breakingDownByConstructionImplementationTypeSelected : false,
      // breakingDownByBuildingHeightSelected : false,
      isToggleOpen: {ConstructionType: false, BuildingHeight: false},
      ASSUMPTIONS_PATHS
    }
  },
  computed: {
    getTotal() {
      return this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits']({
        climate_zone_prefix: this.getClimateZoneSelected.map(cz => cz.prefix),
        type_prototype_id: this.type_prototype.id,
      })
    },
    climateZones() {
      return this.$store?.getters['lastJurisdictionVisited']?.climate_zones
    },
    getChartSeries() {
      return this.getClimateZoneSelected.map((climate_zone) => {
        const climate_zone_current_index_color = this.climateZones.findIndexInArray({ raw: climate_zone.raw })
        return {
          data: BUILDING_ESTIMATES_BASE_YEARS.map((year) => {
          
            const all_building_stock_units_for_this_stack = this.getAllBuildingEstimatesUnitsForThisStack({ year })
            const all_building_stock_units_for_this_stack_section = this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits'](this.getFilters({ year, climate_zone_prefix: climate_zone.prefix,  }))
            const building_stock_units_min_percentage = MathHelpers.getValueOrMinPercentage(all_building_stock_units_for_this_stack, all_building_stock_units_for_this_stack_section, 1)
            const palette_color_index = this.paletteColorIndex
            return {
              x: year.toString(),
              y: building_stock_units_min_percentage,
              fillColor: palette_color_index[climate_zone_current_index_color],
              strokeColor: palette_color_index[climate_zone_current_index_color],
              year,
              climate_zone,
              building_estimates_units : all_building_stock_units_for_this_stack_section,
              total_building_estimate_units: this.getTotal,
              percentage: MathHelpers.getPercentageOfAmount(this.getTotal, all_building_stock_units_for_this_stack_section)
            }
          })
        }
      })
    },
    getStudyGroupByStudyType(){
      return this.$store.getters['globalEntities/StudyGroup/getterGlobalStudyGroups']({ study_type_id: 2 }).map(studyGroup =>  studyGroup.id)
    },
    tickAmountChart() {      
      return this.getBuildingTypeSelected.slug ===  this.path.RESIDENTIAL_BUILDINGS.slug ? 5 : 4
    },
    chartYAxis() {      
      const maxValue = calculateMaxValue(this.getChartSeries)
      const roundedMaxValue = roundUpToNearest(maxValue)

      return {
        min: 0,
        max: roundedMaxValue,
        tickAmount: 5,
        labels: {
          formatter: function(value) {
            const checkInfinity = isFinite(value) ? value : 0
            return this.formatUnits(checkInfinity)
          }.bind(this)
        }
      }
    }
  },
  methods: {
    getChartOptions() {
      const self = this
      return {
        chart: {
          type: 'bar',
          toolbar: { show: false, },
          events: {
            dataPointSelection: function(event, chartContext, { seriesIndex, dataPointIndex }) {
              const item = self.getChartSeries[seriesIndex].data[dataPointIndex]
              const { climate_zone } = item
              self.goToStudyResults({ 
                type_prototype_id: self.type_prototype.id,
                climate_zone_raw: climate_zone.raw,
              })
            }
          },
        },
        grid: {
          show: true,
          padding: {
            right: -12,
            left: 10,
          },
        },
        series: this.getChartSeries, 
        yaxis: this.chartYAxis,
        tooltip: {
          custom: function ({ seriesIndex, dataPointIndex }) {
            const item = self.getChartSeries[seriesIndex].data[dataPointIndex]
            const unitsOrSqft = self.getBuildingTypeSelected.slug === self.ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS.slug ? 'Units' : 'ft²'
            return `
              <div class="psui-flex" style="background-color: ${ item.fillColor } !important;">
                <div class="apexcharts-tooltip-content psui-w-full psui-bg-white psui-p-4">
                  <h4 class="psui-text-gray-80 psui-text-xsmall psui-font-bold">Climate Zone ${ item.climate_zone.prefix }</h4>
                  <div class="psui-w-full psui-flex psui-items-center">
                    <h2 class="psui-text-gray-80 psui-text-small">${ self.formatUnits(item.building_estimates_units) } ${ unitsOrSqft }</h2>
                    <span class="psui-text-gray-30 psui-mx-1">|</span>
                    <h2 class="psui-text-gray-50 psui-text-small">${ self.formatNumber(MathHelpers.getPercentageOfAmount(self.getTotal, item.building_estimates_units)) }%</h2>
                  </div>
                  <h4 class="psui-text-gray-40 psui-text-xsmall psui-font-bold">Forecast for ${ self.type_prototype.title } units in ${item.year}</h4>
                </div>
              </div>
            `
          }
        }
      }
    },
    getFilters({ climate_zone_prefix, year } = {}) {
      const filters = {
        type_prototype_id: this.type_prototype.id,
        climate_zone_prefix: climate_zone_prefix ?? this.getClimateZoneSelected.map(cz => cz.prefix)
      }
      if(year) filters.year = year      
      if(this.breakingDownByConstructionImplementationTypeSelected) filters.construction_implementation_type_id = this.breakingDownByConstructionImplementationTypeSelected.id
      if(this.breakingDownByBuildingHeightSelected) filters.building_height_id = this.breakingDownByBuildingHeightSelected.id

      return filters
    },
    getSelectedBuildingHeight(type_prototype_id) {
      return this.breakdownByBuildingHeight[type_prototype_id] ?? this.breakdownByBuildingHeight[type_prototype_id].id
    },
    getAllBuildingEstimatesUnitsForThisStack({ year }) {
      return this.$store.getters['assumptions/buildingEstimates/getterAllBuildingEstimateUnits'](this.getFilters({ year }))
    },
    toggleBreakdown(breakdownObjKey, type_prototype_id, items) {
      if(!this[breakdownObjKey][type_prototype_id]) {
        this.$set(this[breakdownObjKey], type_prototype_id, items[0].id)
      } else {
        this[breakdownObjKey][type_prototype_id] = false
      }
    },
    toggleBreakdownItem(breakdownObjKey, type_prototype_id, selected) {
      this[breakdownObjKey][type_prototype_id] = selected.id
    },
    onToggleImplementationType(event){
      if(event.id === "Break-down by construction type") {
        this.isToggleOpen.ConstructionType = event.event
      } else {
        this.isToggleOpen.BuildingHeight = event.event
      }
    },

    goToStudyResults({ type_prototype_id, climate_zone_raw }) {
      const exclude_type_prototype_ids = this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototypes']()
        .filter((item) => item.id != type_prototype_id)
        .map((item) => item.id).join(',')

      const exclude_study_ids = this.$store.getters['globalEntities/Study/getterGlobalStudies']()
        .filter(study => study.study_group_id !== this.getStudyGroupByStudyType[0] && study.study_group_id !== this.getStudyGroupByStudyType[1] )
        .map(study => study.id).join(',')  

      const route = {
        name: 'StudyResults',
        params: { 
          jurisdiction_slug: this.$route.params.jurisdiction_slug,
          climate_zone_raw: climate_zone_raw 
        },
        query: {
          only_study_type: STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS,
          exclude_type_prototype_ids,
          exclude_study_ids
        }
      }
      this.$router.push(route)
    },
  }
}
</script>
<style scoped>
  ::v-deep .apexcharts-tooltip.apexcharts-theme-light {
    border: none !important;
  }
  ::v-deep .apexcharts-bar-area:hover { 
    opacity: 0.9 !important;
    cursor: zoom-in !important;
  }
</style>