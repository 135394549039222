<template>
  <tbody
    ref="tableBody"
    :class="[
      { 'scrolling': isScrolling, 'overflow-active': isOverflowActive },
      { 'not-scrolling': !isScrolling }
    ]"
  >
    <template v-for="(studyType, index) in sortedColumns">
      <tr
        :key="`study-type-${studyType.slug}-${index}`"
        :class="[
          'sticky z-20 cursor-pointer',
          { 'first-body-row': index === 0 }
        ]"
        :style="{ top: `${headerHeight}px` }"
        @click="handleCollapse(studyType.slug)"
      >
        <td
          :class="[
            'study-type-row sticky-column-left psui-text-small bg-[#ecf7fb]',
            { 'first-body-cell min-w-[320px] lg:min-w-0 lg:w-[40%]': index === 0 }
          ]"
        >
          <div
            class="flex items-center"
            :class="[            
              { 'table-body-border': (areBothTablesCollapsed && studyType.slug == STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS) || !areBothTablesCollapsed }
            ]"
          >
            <PsIcon
              icon="chevron_right"
              type="material-icons"
              size="16"
              display="flex"
              class="transition-all psui-text-blue-60"
              :class="isTableCollapsed.includes(studyType.slug) ? 'psui-rotate-90': 'psui-rotate-0'"
            />
            <span class="psui-text-gray-60 psui-text-small">
              {{ studyType.title | omitSonStudyTitleHeaders }} Options
            </span>
            <PsIcon
              icon="info_outline"
              size="16"
              class="psui-text-gray-40 hover:psui-text-blue-60 psui-transition psui-leading-none psui-cursor-pointer psui-ml-1"
              :style="{ display: 'flex', marginTop: '1.5px' }"
              @click.stop="openDescriptionModal(studyType.slug)"
            />
          </div>
        </td>
        <td
          v-for="(_, emptyColumnIndex) in getPolicyOptionColumnsLength"
          :key="`empty-column-${emptyColumnIndex}`"
          :class="[
            'bg-[#ecf7fb] w-[12.5%]',
            { 'empty-column': index === 0 }
          ]"
        >
          <div
            :class="[            
              { 'table-body-border': (areBothTablesCollapsed && studyType.slug == STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS) || !areBothTablesCollapsed }              
            ]"
          />
        </td>
        <td class="sticky-column-right bg-[#ecf7fb]">
          <div
            :class="[            
              { 'table-body-border': (areBothTablesCollapsed && studyType.slug == STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS) || !areBothTablesCollapsed }
            ]"
          />
        </td>
      </tr>
      <template v-if="isTableCollapsed.includes(studyType.slug)">
        <tr
          v-for="(row, studyIndex) in studyType.data"
          :key="`row-${studyIndex}-${row.slug}`"          
          :class="[
            'border-b-1px policy-option-row-item',
            { 'border-b psui-border-blue-20': studyIndex !== studyType.data.length - 1 },
            { 'policy-option-row-item-with-data': !row.slug.includes('default-state') }
          ]"
          @mouseover="handleMouseOver(row.slug)"
          @mouseleave="handleMouseLeave(row.slug)"
        >
          <td
            v-for="(column, columnIndex) in row.data"
            :key="`row-${studyIndex}-column-${columnIndex}-study-${studyType.slug}`"
            :policy_option_item="column.slug"
            :class="[
              'data-row bg-white show-icon-on-hover',
              { 'data-row-selected': columnIndex === 0 && (row.slug === $route.query.policy_option || row.slug === hoveredRow) },
              { 'sticky-column-left min-w-[320px] lg:min-w-0 lg:w-[40%]': columnIndex === 0 },
              { 'w-[12.5%]': columnIndex !== 0 }
            ]"
          >
            <div
              v-if="column.algorithms"
              class="flex flex-col bg-white py-4 pl-4 pr-2"
            >
              <div class="flex space-x-[6px] psui-text-small pb-2">
                <span
                  v-for="prototype in getSortedPrototypes(column.prototypes)"
                  :key="prototype.slug"
                  class="flex items-center border psui-border-blue-20 rounded px-[6px] py-0.5 psui-text-gray-50 psui-text-xsmall space-x-1"
                >
                  <PsIcon
                    :icon="getPrototypeIcon(prototype)"
                    :disable-stroke="true"
                    width="16"
                    height="16"
                    color="psui-text-gray-50"
                    class="flex-shrink-0"
                  />
                  <span>{{ prototype.title }}</span>
                </span>
              </div>
              <span
                class="psui-text-blue-70 psui-text-small font-bold pb-1"
              >{{ column.title }}</span>
              <span
                class="psui-text-small psui-text-gray-50"
              >{{ column.short_description }}</span>
              <div
                v-if="getColumnNotification(column.custom_fields)"
                class="badge-with-icon layout-green mt-1"
              >
                <span class="badge-with-icon-message">{{ column.custom_fields.data.notification.policy_option.value }}</span>
                <PsIcon
                  v-tooltip="getTooltipIcon(row.slug, column.key, column.custom_fields.data.notification.policy_option.url)"
                  icon="info_outline"
                  size="16"
                  class="psui-text-green-20 hover:psui-opacity-70 psui-transition psui-leading-none psui-cursor-pointer psui-ml-1"
                  style="display: flex; margin-bottom: -1px;"
                  @mouseenter.native="onHoverContainer(row.slug, column.key, $event)"
                  @mouseleave.native="onHoverContainer(row.slug, column.key, $event)"
                  @click="openIntercomPage(column.custom_fields.data.notification.policy_option.url)"
                />
              </div>
            </div>
            <div
              v-else-if="isLoading"
              class="flex items-center place-content-center"
            >
              <vue-skeleton-loader
                :width="90"
                :height="27"
              />
            </div>
            <div
              v-else
              class="flex items-center place-content-center "
            >
              <div class="psui-text-blue-70 text-center font-lato text-base font-normal">
                <div
                  v-if="row.study_data[column.key]"
                  class="column-custom-tooltip flex items-center"
                  :class="{'pl-5':getColumnKeyNotification(row, column.key)}"
                >
                  <span
                    class="psui-text-small psui-text-blue-70"
                    :class="{'hover:psui-text-gray-50 cursor-pointer': getColumnKeyNotification(row, column.key)}"
                    @mouseover="toggleImpactAlert($event, true, column, row)"
                    @mouseleave="toggleImpactAlert($event, false, column, row)"
                  >
                    {{ row.study_data[column.key] | formatNumber(column.key) }}
                  </span>
                  <PsIcon
                    v-if="getColumnKeyNotification(row, column.key)"
                    v-tooltip="getTooltipIcon(row.slug, column.key, row.custom_fields.data.notification.policy_option.url)"
                    icon="info_outline"
                    size="16"
                    class="column-custom-tooltip-icon psui-text-gray-40 hover:psui-text-blue-60 cursor-pointer"
                    style="display: inline-flex; margin-left: 4px;"
                    @mouseenter.native="onHoverContainer(row.slug, column.key, $event)"
                    @mouseleave.native="onHoverContainer(row.slug, column.key, $event)"
                    @click="openIntercomPage(row.custom_fields.data.notification.policy_option.url)"
                  />
                </div>
                <div
                  v-else
                  class="column-custom-tooltip flex items-center"
                >
                  <div class="relative">
                    <span
                      :class="{'hover:psui-text-gray-50 cursor-pointer': getColumnKeyNotification(row, column.key)}"
                      @mouseover="toggleImpactAlert($event, true, column, row)"
                      @mouseleave="toggleImpactAlert($event, false, column, row)"
                    >
                      --
                    </span>
                    <span
                      v-if="canShowEmptyAlert(row, column)"
                      class="svg-position"
                      @mouseover="toggleWarningAlert($event, true, column)"
                      @mouseleave="toggleWarningAlert($event, false, column)"
                    >
                      <span class="cursor-pointer">
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                        >
                          <circle
                            cx="5"
                            cy="5"
                            r="4.5"
                            fill="#EDAB3E"
                          />
                        </svg>
                      </span>
                    </span>
                    <PsIcon
                      v-if="getColumnKeyNotification(row, column.key)"
                      v-tooltip="getTooltipIcon(row.slug, column.key, row.custom_fields.data.notification.policy_option.url)"
                      icon="info_outline"
                      size="16"
                      class="empty-state column-custom-tooltip-icon psui-text-gray-40 hover:psui-text-blue-60 cursor-pointer"
                      :class="{'has-alert': canShowEmptyAlert(row, column)}"
                      style="display: inline-flex; margin-left: 4px; margin-top: 4px;"
                      @mouseenter.native="onHoverContainer(row.slug, column.key, $event)"
                      @mouseleave.native="onHoverContainer(row.slug, column.key, $event)"
                      @click="openIntercomPage(row.custom_fields.data.notification.policy_option.url)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td class="sticky-column-right bg-[#ffffff]">
            <div
              v-if="getColumnWithAlgorithms(row)"
              class="flex flex-col space-y-2 items-center"
            >
              <div class="customize-button">
                <PsButton                  
                  label="Customize"
                  layout="solid"
                  size="small"
                  @click="openNewPolicyDrawer(getColumnWithAlgorithms(row), studyType.slug, 1, true)"
                />
              </div>
              <PsButton                  
                label="Learn more"
                size="small"
                layout="ghost"                  
                @click="openNewPolicyDrawer(getColumnWithAlgorithms(row), studyType.slug, 0)"
              />
            </div>
          </td>
        </tr>
      </template>
    </template>
  </tbody>
</template>

<script>
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import * as oldNumberFormatter from '@/formatters/formatNumber.js'
import { createTooltipOptions } from '@/util/Functions.js'
import policyOptionColumns from '@/modules/app/policy/options/options-table/policyOptionsColumns.js'
import { GA_LABELS } from '@/mixins/GaEventsMixin'

export default {
  name:"PolicyOptionsContentTableBody",
  filters:{
    omitSonStudyTitleHeaders: (text) => {
      return text.replace(/Buildings/g, 'Building')
    },
    formatNumber(value, col ) {
      if([
        'forecast_initial_cost',
        'forecast_lifecycle_savings',
      ].includes(col)) {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 1, minimumFractionDigits: 1, notation: "compact" , compactDisplay: "short" }).format(value)
      }

      return oldNumberFormatter.default(value)
    }
  },
  props:[
    'columns', 'is-loading', 'isOverflowActive', 'isScrolling'
  ],
  data: () => ({
    STUDY_TYPES_DB_SLUGS,
    isTableCollapsed: [],
    headerHeight: 0,
    hoveredRow: null,
    hoveredRowSlug: null,
    showTooltip: {}, 
    showTooltipIcon: {},
    isTooltipHovered: {},
    tooltipEl: {},
    tooltipEventHandlers: {},
  }),
  computed: {
    sortedColumns() {
      return this.columns.map(column => {
        let slugOrder = []
        if (column.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS) {
          slugOrder = [
            'prescriptive-on-bill',
            'flexible-on-bill',
            'default-state-existing'
          ]
        } else if (column.slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS) {
          slugOrder = [
            'high-performance',
            'electric-only',
            'default-state'
          ]
        }

        const slugRank = (slug) => {
          const index = slugOrder.indexOf(slug)
          return index !== -1 ? index : slugOrder.length
        }

        const sortedData = column.data.slice().sort((a, b) => {
          return slugRank(a.slug) - slugRank(b.slug)
        })

        const processedData = sortedData.map(row => {
          return {
            ...row,
            data: row.data.map(item => {
              return {
                ...item,
                prototypes: item.prototypes?.map(prototype => {                  
                  const TypePrototypeOrder = this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: prototype.type_prototype_id })?.order 
                  const newPrototype = { ...prototype }
                  newPrototype.order = TypePrototypeOrder                  
                  return newPrototype
                }),
              }
            }),
          }
        })

        return { ...column, data: processedData }
      })
    },
    getPolicyOptionTypeUserPreference(){
      return this.$store.getters['getterUserPrefferenceByPath']('policies.policyOptions.filterByStudyType')
    }, 
    getPolicyOptionColumnsLength() {
      return policyOptionColumns().length
    },
    areBothTablesCollapsed() {
      const requiredSlugs = [STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS, STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS]
      return requiredSlugs.every(slug => !this.isTableCollapsed.includes(slug))
    }
  },
  watch: {
    getPolicyOptionTypeUserPreference: {
      handler(newVal) {
        this.isTableCollapsed = [...newVal]
      },
      immediate: true
    }
  },
  mounted() {
    this.isTableCollapsed = this.getPolicyOptionTypeUserPreference
    this.updateHeaderHeightAfterDelay(100)
    window.addEventListener('resize', this.updateHeaderHeight)
  },
  updated() {    
    this.updateHeaderHeightAfterDelay(100)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateHeaderHeight)

    if (this.tooltipEl) {
      Object.keys(this.tooltipEl).forEach((key) => {
        const el = this.tooltipEl[key]
        const handlers = this.tooltipEventHandlers[key]
        if (el && handlers) {
          el?.removeEventListener('mouseenter', handlers.mouseenter)
          el?.removeEventListener('mouseleave', handlers.mouseleave)
        }
      })
    }
  },
  methods: {
    openIntercomPage(url){
      window.open(url,'_blank')
    },
    getColumnNotification(customField){
      return customField?.data?.notification?.policy_option?.value ?? false
    },
    getColumnKeyNotification(row, key){
      return row?.custom_fields?.data?.notification?.columns?.[key]?.value || false
    },
    getColumnWithAlgorithms(row) {
      return row.data.find(column => column.algorithms)
    },
    getSortedPrototypes(prototypes) {
      return prototypes.slice().sort((a, b) => {
        const orderA = a.order !== undefined ? a.order : Number.MAX_SAFE_INTEGER
        const orderB = b.order !== undefined ? b.order : Number.MAX_SAFE_INTEGER
        return orderA - orderB
      })
    },
    openDescriptionModal(studyTypeSlug){
      this.$eventBus.$emit('openDescriptionModal', { type:'helper', slug: `policy-option-${studyTypeSlug}` })
    },
    updateHeaderHeightAfterDelay(delay) {
      setTimeout(() => {
        this.updateHeaderHeight()
      }, delay)
    },
    handleCollapse(slug){
      if(this.isTableCollapsed.includes(slug)) {
        return this.isTableCollapsed = this.isTableCollapsed.filter(tableSlug => tableSlug != slug)  
      }
      this.isTableCollapsed.push(slug)
    },
    openNewPolicyDrawer(policyOption, studyTypeSlug, currentStep, setAllPrototypesSelected) {
      this.gaEvent(
          GA_LABELS.POLICY_OPTIONS_CLICK,
          {
            policy_option_slug: policyOption?.slug || null,
            jurisdiction_slug: this.$store.getters.lastJurisdictionVisited?.slug || null,
            action: currentStep === 0 ? 'learn_more' : 'customize',
          }
      )

      this.$eventBus.$emit('openPolicyOptionDrawer', {
        policy_option: policyOption,
        current_step: currentStep,
        study_type_slug: studyTypeSlug,
        policy_option_disabled_message: policyOption?.disabled_message,
        setAllPrototypesSelected
      })

      this.$router.replace({
        name:'PolicyOptions', 
        query: {
          ...this.$route.query,
          policy_option: policyOption?.slug
        }
      }, () => {})
    },
    updateHeaderHeight() {
      const body = this.$refs.tableBody
      if (body && body.parentElement) {
        const parentElement = body.parentElement
        const header = parentElement.querySelector('thead')
        if (header) {
          this.headerHeight = header.offsetHeight          
        }
      }
    },
    handleMouseOver(slug) {
      this.hoveredRow = slug
    },
    handleMouseLeave() {
      this.hoveredRow = null
    },
    handleShowTooltip(rowSlug, columnKey) {
      this.$set(this.showTooltip, `${rowSlug}-${columnKey}`, true)
    },
    handleHideTooltip(rowSlug, columnKey) {
      this.$set(this.showTooltip, `${rowSlug}-${columnKey}`, false)
    },
    getTooltip(rowSlug, columnKey) {
      const condition = this.showTooltip[`${rowSlug}-${columnKey}`] || false
      const content = '<p style="font-style: normal; font-family: Lato; font-size: 12px; color:#28323B;">There are no impact previews for this policy option, because it is a blank state with no requirements set.</p>'      
      return createTooltipOptions(condition, content)
    },
    mouseOverHandler(rowSlug, columnKey) {
      const key = `${rowSlug}-${columnKey}`
      this.hoveredRowSlug = key
    },
    mouseLeaveHandler(rowSlug, columnKey) {
      const key = `${rowSlug}-${columnKey}`

      setTimeout(() => {
        if (!this.isTooltipHovered[key]) {
          this.$set(this.showTooltipIcon, key, false)
          this.hoveredRowSlug = null
        }
      }, 100)
    },
    onHoverContainer(rowSlug, columnKey, event) {
      const key = `${rowSlug}-${columnKey}`
      if (event.type === 'mouseenter') {
        this.$set(this.showTooltipIcon, key, true)
        this.hoveredRowSlug = key

        this.$nextTick(() => {
          this.tooltipEl[key] = document.querySelector(`.tooltip.custom-tooltip-link-${key}`)

          if (this.tooltipEl[key]) {
            const tootltipMouseEnterHandler = () => this.onTooltipMouseEnter(key)
            const tooltipMouseLeaveHandler = () => this.onTooltipMouseLeave(key)
            this.tooltipEventHandlers[key] = {
              mouseenter: tootltipMouseEnterHandler,
              mouseleave: tooltipMouseLeaveHandler,
            }

            this.tooltipEl[key].addEventListener('mouseenter', tootltipMouseEnterHandler)
            this.tooltipEl[key].addEventListener('mouseleave', tooltipMouseLeaveHandler)
          }
        })
      } else if (event.type === 'mouseleave') {
        setTimeout(() => {
          if (!this.isTooltipHovered[key]) {
            this.$set(this.showTooltipIcon, key, false)
            // this.hoveredRowSlug = null
          }
        }, 100)
      }
    },
    onTooltipMouseEnter(key) {
      if (this.tooltipEl[key]) {
        this.$set(this.isTooltipHovered, key, true)
        this.tooltipEl[key].setAttribute('aria-hidden', 'false')
        this.hoveredRowSlug = key
      }
    },
    onTooltipMouseLeave(key) {
      if (this.tooltipEl[key]) {
        this.tooltipEl[key].setAttribute('aria-hidden', 'true')
        this.$set(this.showTooltipIcon, key, false)
        this.$set(this.isTooltipHovered, key, false)
        this.hoveredRowSlug = null
      }
    },
    getTooltipIcon(rowSlug, columnKey, url) {
      const key = `${rowSlug}-${columnKey}`
      const condition = this.showTooltipIcon[key]
      const size = 16
      const displayClasses = 'psui-text-gray-30 hover:psui-opacity-70 psui-transition psui-leading-none psui-cursor-pointer'
      const displayStyles = 'display: flex; margin-bottom: -1px;'

      const createInlineSvg = (size, displayClasses, displayStyles) => {
        return `
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="${size}"
            height="${size}"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="${displayClasses}"
            style="${displayStyles}"
          >
            <path d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"/>
          </svg>
        `
      }

      const content = `
        <span style="display: inline-flex; align-items: center;">
          <span class="mr-1" style="font-family: Lato; font-size: 12px; font-style: normal; font-weight: 700; line-height: 130%;">Click to learn more</span>
          <a href="${url}" target="_blank">
            ${createInlineSvg(size, displayClasses, displayStyles)}
          </a>
        </span>
      `

      return createTooltipOptions(condition, content, {
        classes: `custom-tooltip-link custom-tooltip-link-${key}`,
      })
    },
    getPrototypeIcon(prototype) {
      return this.getTypePrototype(prototype.type_prototype_id).icon
    },
    getTypePrototype(typePrototypeId) {
      return this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: typePrototypeId })
    },
    canShowEmptyAlert(row) {
      const isNewBuildings = row?.algorithms?.some((i) => i.study_type_slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS)
      const getter = isNewBuildings ? 'assumptions/buildingEstimates/getterDefaultJurisdictionBuildingEstimateUnits' : 'assumptions/buildingStocks/getterDefaultJurisdictionBuildingStocksUnits'
      const filters = { jurisdiction_id: this.$store.getters.lastJurisdictionVisited.id, type_prototype_id: row.prototypes.map((p) => p.type_prototype_id) }
      const unities = this.$store.getters[getter](filters)
      return Boolean((isNaN(unities) || +unities <= 0) && !row.slug.includes('default-state'))
    },
    toggleImpactAlert(event, show, column, row) {
      if (!this.getColumnKeyNotification(row, column.key)) {
        return
      }

      if (!show) {
        this.$eventBus.$emit('closeConfirmPopover', { preventWhenHovering: true })
        return
      }
      const parentNode = event.target?.parentNode?.parentNode?.parentNode?.parentNode
      const parentWidth = ((parentNode?.getBoundingClientRect()?.width || 288) / 2)
      this.$eventBus.$emit('openConfirmPopover', {
        targetElem: event.target,
        text: `<p class="psui-text-small">${this.getColumnKeyNotification(row, column.key)}</p>`,
        textAsHtml: true,
        side: 'right',
        buttons: [],
        disableFooter: true,
        repositionWhenOff: true,
        maxWidth: 230,
        sumTop: 40,
        sumLeft: -parentWidth,
        layout: 'gray-alert',
      })
    },
    toggleWarningAlert(event, show) {
      if (!show) {
        this.$eventBus.$emit('closeConfirmPopover', { preventWhenHovering: true })
        return
      }
      this.$eventBus.$emit('openConfirmPopover', {
        targetElem: event.target,
        text: `This jurisdiction has no default new construction projections to estimate impacts. To see impacts for this policy option, create this policy and edit your building assumptions.`,
        textAsHtml: true,
        side: 'right',
        buttons: [],
        disableFooter: true,
        maxWidth: 200,
        sumTop: -7,
        sumLeft: 10
      })
    },
  }
}
</script>

<style lang="scss" scoped>
$blue-30: #bedceb;
$blue-60: #318fac;
$gray-20: #e6ecf2;

tbody {
  tr {
    td {
      padding: 0;
    }
    &.policy-option-row-item {
      td {
        > div {
          height: 100%;
          justify-content: center;
        }
        &:not(:first-child):not(:nth-child(5)) {
          border-right: $gray-20 solid 1px;
        }

        &:first-child {
          > div {
            border-right: $gray-20 solid 1px;
          }
        }

        &:last-child {
          box-shadow: inset 1px 0 0 $gray-20 !important;
        }
      }
    }
    &:not(.policy-option-row-item) {
      td {
        > div {
          height: 33.2px;
          padding: 6px 1px;
          border-top: $gray-20 solid 1px;

          &.table-body-border {
            border-bottom: 2px solid $blue-30;
          }
        }
      }
    }
  }
}

.not-scrolling {
  tr {
    &.policy-option-row-item {
      &:hover {
        td:first-child,
        th:first-child {
          > div {
            box-shadow: inset 2px 0 0 $blue-60 !important;
          }
        }
      }
    }
    td:first-child,
    th:first-child {
      padding: 0;
      > div {
        box-shadow: inset 2px 0 0 $blue-30 !important;
      }
    }
  }
}

.scrolling {
  tr {
    &.policy-option-row-item {
      &:hover {
        td:first-child,
        th:first-child {
          > div {
            box-shadow: inset 2px 0 0 $blue-60, 6px 0px 6px -3px rgba(238, 242, 243, 1);
          }
        }
      }
    }

    td:first-child,
    th:first-child {
      padding: 0;
      > div {
        border-right: $gray-20 solid 1px;
        box-shadow: inset 2px 0px 0 $blue-30, 6px 0px 6px -3px rgba(238, 242, 243, 1);
      }
    }
  }
}

.overflow-active {
  tr {
    td:last-child,
    th:last-child {
      padding: 0;
      box-shadow: inset 1px 0 0 $gray-20 !important;

      @media (min-width: 910px) {
        > div {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          box-shadow: -6px 0px 6px -3px rgba(238, 242, 243, 1) !important;
        }
      }
    }
  }
}

.first-body-cell::before {
  right: 0;
  border-top: 1px solid #e6ecf2;
}

.study-type-row {
  position: relative;
}

.data-row-selected {
  position: relative;
}

.empty-column {
  position: relative;
}

.data-row {
  position: relative;
}

.data-row::after {
  border-right: 1px solid #e6ecf2;
}

.border-b-1px:last-child {
  border-bottom: 2px solid $blue-30;
}

.customize-button {
  :deep(.psui-el-button) {
    width: fit-content;

    &.size-small {
      padding-right: calc(0.5rem + 4.641px);
    }
  }
}

@media (min-width: 910px) {
  .sticky-column-left {
    position: sticky;
    left: 0;
    z-index: 2;
  }

  .sticky-column-right {
    position: sticky;
    right: 0;
    z-index: 2;
  }
}

.column-custom-tooltip {
  ::v-deep {
    .psui-el-tooltip .psui-el-tooltip-wrapper .psui-el-tooltip-dialog .psui-el-tooltip-content .psui-el-tooltip-content-wrapper {
      font-weight: 400;
      text-align: left;
    }
    .psui-el-tooltip .psui-el-tooltip-wrapper .psui-el-tooltip-dialog .psui-el-tooltip-content {
      background-color: #D6DDE5;
      color: #28323B;
      padding: 10px 16px 12px 16px;
    }
  }

  .column-custom-tooltip-icon {
    visibility: hidden;

    &.empty-state {
      position: absolute;
      left: 15px;
      top: 3px;
    }

    &.has-alert {
      position: absolute;
      left: 30px;
      top: 3px;
    }
  }

  &:hover {
    .column-custom-tooltip-icon {
      visibility: visible;
    }
  }
}

.show-icon-on-hover:hover {
  .column-custom-tooltip-icon {
    visibility: visible !important;
  }
}

.badge-with-icon {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.25rem;
  padding-right: 0.5rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  border-radius: 0.25rem;

  &:hover {
    > div {
      visibility: visible;
    }
  }
  //> div {
  //  visibility: hidden;
  //}

  &.layout-green {
    background-color: #def8e8;
    color: #44a06a;
  }

  .badge-with-icon-message {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 130%;
  }
}
.svg-position {
  position: absolute;
  right: calc(-1rem);
  top: calc(0.5rem + 2px);
}
</style>
