export const colors = [
  "#518BE2",
  "#43CEC2",
  "#8CCA82",
  "#E9CF74",
  "#FF906D",
  "#FF77B8",
  "#9278C9",
  "#518BE2",
  "#43CEC2",
  "#8CCA82",
  "#E9CF74",
  "#FF906D",
  "#FF77B8",
  "#9278C9",
  "#518BE2",
  "#43CEC2",
  "#8CCA82",
  "#E9CF74",
  "#FF906D",
  "#FF77B8",
  "#9278C9",
]

export const alternativeColor = [
  '#2DA5CC',
  '#8DDF64',
  '#D1E577',
  '#DD9C77',
  '#FFB5A5',
  '#D987FF',

  '#0D759C',
  '#5DAF34',
  '#A1B547',
  '#AD6C47',
  '#CF8575',
  '#A957CF',

  '#0D456C',
  '#2D7F04',
  '#718517',
  '#7D3C17',
  '#9F5545',
  '#79279F',
]

export const combinationColors = [
  //Source
  [
    "#10B981",
    "#FBBD4C",
    "#FF795B",
    "#518BE2",
    "#FF77B8",
    "#43CEC2",

    "#008951",
    "#CB8D1C",
    "#CF492B",
    "#215BB2",
    "#CF4788",
    "#139E92",

    "#005921",
    "#9B5D0C",
    "#9F190B",
    "#012B82",
    "#9F1758",
    "#036E62",
  ],
  ["#885FCD", "#FF77B8", "#FBBD4C", "#43CEC2", "#518BE2", "#FF795B" ],
  ["#10B981", "#FF795B", "#518BE2", "#FBBD4C", "#FF77B8", "#43CEC2" ],
  ["#518BE2", "#43CEC2", "#FF77B8", "#FBBD4C", "#885FCD", "#10B981" ],
]

export const buildingSizeChartColors = [
  '#518be2',
  '#43cec2',
  '#fbbd4c',
  '#10b981',
  '#dd9c77',
  '#d1e577',
  '#d987ff',
  '#2da5cc',
  '#8ddf64',
  '#885fcd',
  '#ff795b',
  '#ffb5a5',
  '#ff77b8',
]

export const apexChartBarDefaultOptions = {
  chart: {
    type: 'bar',
    width: '100%',
    height: '340px',
    stacked: true,
    fontFamily: 'Lato, Helvetica Neue, sans-serif',
    foreColor: '#798490',
    offsetX: 6,
    toolbar: { show: false },
    selection: { enabled: false },
    zoom: { enabled: false }
  },
  fill: { opacity: 1, },
  grid: {
    borderColor: '#D6DDE5',
    strokeDashArray: 4,
  },
  states: {
    hover: { filter: { type: 'none' } }, 
    active: { filter: { type: 'none' } },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: { 
    axisTicks: { show: false, },
    labels: {
      style: {
        colors: ['#798490'],
        fontSize: '12px',
        fontFamily: 'Lato, Helvetica Neue, sans-serif',
        fontWeight: 700,
        cssClass: 'apexcharts-xaxis-label',
      },
    }
  },
  yaxis: {
    axisBorder: {
      show: true,
      color: '#D4D8D9',
      offsetX: -1,
      offsetY: 0
    },
    labels: {
      style: {
        colors: ['#798490'],
        fontSize: '12px',
        fontFamily: 'Lato, Helvetica Neue, sans-serif',
        fontWeight: 700,
        cssClass: 'apexcharts-xaxis-label psui-text-left',
      },
      offsetX: -15,
    }
  },
  legend: {
    show: false
  },
}

export const apexChartDonutsDefaultOptions = {
  chart: {
    type: 'donut',
    height: '100%',
    fontFamily: 'Lato, Helvetica Neue, sans-serif',
    toolbar: { show: false },
    selection: { enabled: false },
    zoom: { enabled: false },
  },
  plotOptions: {
    pie: {
      donut: {
        size: '40%',
        labels: { show: false,  }
      },      
    }
  },
  fill: { opacity: 1, },
  states: {
    hover: { filter: { type: 'none' } }, 
    active: { filter: { type: 'none' } },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false
  },
}

export const apexChartLineDefaultOptions = {}