import Api from '@/util/Api'
import GraphQL from '@/util/GraphQL'

export default class PolicyOptionApiService {
  
  static get() {
    const query = `
      {
        policy_options {
          id
          title
          title_drawer_about
          title_drawer_create
          slug
          short_description
          description
          alert_text
          is_advanced
          is_recommended
          created_at
          updated_at
          custom_fields {
            item_id
            data
          }
        }
      }
    `

    return GraphQL({ query, caller: `PolicyOptionApiService.get`})
      .then(({ data }) => data )
  }

  static updateOrCreate(policyOption) {
    return Api.post(`/api/admin/policy_option/update_or_create`, policyOption)
    .then(({ data }) => data )
  }

  static delete(policyOptionId) {
    return Api.delete(`/api/admin/policy_option/delete/${policyOptionId}`)
  }

}
