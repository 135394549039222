import { extractAssumptionValuesFromPolicy, getAssumptionsLevel, ASSUMPTION_LEVEL } from '@/util/AssumptionsHelpers'
import { defaultNewBuildingsImpactAssumptionValues,defaultExistingBuildingsImpactAssumptionValues } from '@/models/ImpactAssumptions'
import { TYPE_FUELS_DB_SLUGS, ASSUMPTIONS_DRAWER_DIVISION } from '@/util/Enums'
import { FUEL_CHOICES } from '@/business-logic/services/impact-algorithms'
import {
  GeneralPerformanceByFuelPolicyImpactAlgorithm
} from '@/business-logic/services/impact-algorithms/policy/general-performance-by-fuel'
import { basicAssumptionKeys, advancedAssumptionKeys } from '@/modules/app/assumptions/shared/defaultAssumptions'
import { GA_LABELS } from '@/mixins/GaEventsMixin'
import AssumptionsGlobalMixin from '../shared/AssumptionsGlobalMixin'

export const RESIDENTIAL_NEW_SPECIFICATIONS = {
  BUILDING_TYPE : {
    label: 'Building Type',
    key: 'BUILDING_TYPE',
  },
  CLIMATE_ZONE : {
    label: 'Climate Zone',
    key: 'CLIMATE_ZONE',
  },
}

export default {
  mixins: [ AssumptionsGlobalMixin ],
  data() {
    return {
      RESIDENTIAL_NEW_SPECIFICATIONS,
      defaultNewBuildingsImpactAssumptionValues,
      defaultExistingBuildingsImpactAssumptionValues,
      specifyBy: [],
      climate_zone: null,
      showResetButtonOnInput: null,
      showErrorInfoOnInput: null,
      debouncers: {},
      localAssumptionValues: {},
      isUpdating: false,
      firstLoaded: false,
      // Data that should be redefined on component
      assumptionKey: null,
      minValue: 0,
      maxValue: 100,
    }
  },
  computed: {
    // This method should be always overridden inside assumptions component to return the correct baseValue
    getBaseValue() {
      return null
    },
    getLastJurisdictionVisited() {
      return this.$store.getters['lastJurisdictionVisited']
    },
    getPolicySelectedId() {
      return this.$route.params.policy_id || null
    },
    getPolicySelected() {
      return this.getPolicySelectedId ? this.$store.getters['policy/getterUserPolicy']({id: this.getPolicySelectedId}) : null
    },
    isPolicyAssumption() {
      return !!this.getPolicySelectedId
    },
    assumptionsLevel() {
      return this.isPolicyAssumption ? getAssumptionsLevel(this.valuesByCustomCombinations) : ASSUMPTION_LEVEL.BASE
    },
    valuesByCustomCombinations() {
      if (!this.getPolicySelected) {
        return null
      }
      return extractAssumptionValuesFromPolicy(this.getPolicySelected, this.assumptionKey, this.getBaseValue)
    },
    resultsAssumptionsValues() {
      return this.$store.state.assumptions[this.$store.state.assumptions.drawerOptions.type] || {}
    },
    getPrototypesWithinPolicySelected() {
      return this.getPolicySelectedCustomCombinations
        ?.map(custom_combination => custom_combination.prototype)
        ?.reduce((acc,curr) => {
          if(acc.length === 0 || acc[acc.length-1]['id'] !== curr.id) {
            acc.push(curr)
          }
          return acc
        }, []) || null
    },
    getPolicySelectedCustomCombinations() {
      return this.getPolicySelectedId ? this.$store.getters['policy/getterUserPolicy']({ id: this.getPolicySelectedId })?.policy_containers
        ?.map(policy_container => policy_container?.custom_combinations || [])
        ?.reduce((acc,curr)=> acc.concat(curr)) || [] : null
    },
    isSpecifyByClimateZoneEnabled() {
      return !!this.specifyBy.find(el => el === RESIDENTIAL_NEW_SPECIFICATIONS.CLIMATE_ZONE.key)
    },
    isSpecifyByBuildingTypeEnabled() {
      return !!this.specifyBy.find(el => el === RESIDENTIAL_NEW_SPECIFICATIONS.BUILDING_TYPE.key)
    },
    getClimateZonesWithinPolicySelected() {
      return this.getPolicySelectedId ? this.$store.getters['policy/getterClimateZonesWithinPolicySelected']({ policy_id: this.getPolicySelectedId }) : null
    },
    getClimateZoneSelected() {
      return this.isSpecifyByClimateZoneEnabled ? this.climate_zone ?? this.getClimateZonesWithinPolicySelected?.[0] : null
    },
    canSpecifyByPrototype() {
      return Boolean(this.getPrototypesWithinPolicySelected?.length > 1)
    },
    canSpecifyByClimateZone() {
      return Boolean(this.getClimateZonesWithinPolicySelected?.length > 1)
    },
    getSingleSpecifyByEnabled() {
      if (this.canSpecifyByPrototype && !this.canSpecifyByClimateZone) {
        return RESIDENTIAL_NEW_SPECIFICATIONS.BUILDING_TYPE
      } else if (this.canSpecifyByClimateZone) {
        return RESIDENTIAL_NEW_SPECIFICATIONS.CLIMATE_ZONE
      }
      return null
    },
    canHaveMultipleSpecifyBy() {
      return this.canSpecifyByPrototype && this.canSpecifyByClimateZone
    },
  },
  mounted() {
    this.initLocalAssumptionsByPolicySelected()
    this.$eventBus.$on('restoreAssumption', (key) => {
      const enabledToResetKeys = key === ASSUMPTIONS_DRAWER_DIVISION.BASIC ? basicAssumptionKeys : advancedAssumptionKeys
      // ToDo: Maybe if we have components with more than 1 keys, we need to add some data/computed to return resetAllKeys
      if (enabledToResetKeys.includes(this.assumptionKey)) {
        const { prototypeIds, climateZoneRaws } = this.getInputPrototypeIdsAndClimateZoneRaws(null, null)
        const hasChangedData = !this.isPolicyAssumption ?
            this.hasValueUpdated(null, null, this.assumptionKey) :
            prototypeIds?.some((prototypeId) => {
              return climateZoneRaws?.some((climateZoneRaw) => {
                return this.hasValueUpdated({ id: prototypeId }, { raw: climateZoneRaw }, this.assumptionKey)
              })
            }) || false
        if (hasChangedData) {
          this.updateLocalAssumption(prototypeIds, climateZoneRaws, this.getBaseValue)
        }
      }
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('restoreAssumption')
  },
  watch: {
    resultsAssumptionsValues: {
      deep: true,
      immediate: true,
      handler() {
        this.initLocalAssumptionsByPolicySelected()
      }
    },
    valuesByCustomCombinations: {
      deep: true,
      immediate: true,
      handler() {
        this.initLocalAssumptionsByPolicySelected()
        this.adjustSpecifyBy()
      }
    },
    getPrototypesWithinPolicySelected() {
      this.initLocalAssumptionsByPolicySelected()
      this.adjustSpecifyBy()
    },
    getClimateZonesWithinPolicySelected() {
      this.initLocalAssumptionsByPolicySelected()
      this.adjustSpecifyBy()
    },
  },
  methods: {
    // This method should be overridden if the input is not about number range
    sanitizeInputValue(value) {
      if (value === '') return value
      value = +(Math.round(Number(value)))
      if (isNaN(value)) value = this.getBaseValue
      if (value > this.maxValue) value = this.maxValue
      if (value < this.minValue) value = this.minValue
      return value
    },
    initLocalAssumptionsByPolicySelected() {
      this.debounce(() => {
        this.localAssumptionValues = {}
        if (!this.isPolicyAssumption) {
          this.updateLocalAssumption(null, null, this.getAssumptionValue(null, null), null, true)
          return
        }
        const prototypes = this.getPrototypesWithinPolicySelected || []
        const climateZones = this.getClimateZonesWithinPolicySelected || []
        prototypes.forEach((prototype) => {
          climateZones.forEach((climateZone) => {
            const value = this.valuesByCustomCombinations.find((x) => {
              return x.prototype_id === prototype.id && x.climate_zone_raw === climateZone.raw
            })?.value ?? this.getBaseValue
            this.updateLocalAssumption([prototype.id], [climateZone.raw], value, null, true)
          })
        })
      }, 'initLocalAssumptionsByPolicySelected')
    },
    updateLocalAssumption(prototypeIds, climateZoneRaws, value, assumptionKey = null, ignoreGaEvent = false) {
      const oldValue = value
      value = this.sanitizeInputValue(value)
      assumptionKey = assumptionKey || this.assumptionKey
      prototypeIds = prototypeIds || [null]
      climateZoneRaws = climateZoneRaws || [null]
      const newAssumptionValues = { ...(this.localAssumptionValues || {}) }
      if (prototypeIds.length === this.getPrototypesWithinPolicySelected?.length) {
        prototypeIds.push(null) // threats case of all prototypes inputs
      }
      if (climateZoneRaws.length === this.getClimateZonesWithinPolicySelected?.length) {
        climateZoneRaws.push(null) // threats case of all climate zones inputs
      }
      prototypeIds.forEach((prototypeId) => {
        climateZoneRaws.forEach((climateZoneRaw) => {
          const localAssumptionKey = this.getInputKey(prototypeId, climateZoneRaw, assumptionKey)
          newAssumptionValues[localAssumptionKey] = value
        })
      })
      this.localAssumptionValues = newAssumptionValues

      if (!ignoreGaEvent) {
        this.gaEvent(
            GA_LABELS.ASSUMPTIONS_CHANGED,
            {
              assumption: assumptionKey,
              is_policy_assumption: this.isPolicyAssumption,
              jurisdiction_slug: this.getLastJurisdictionVisited.slug
            }
        )
      }

      // Trigger show error on input if the value was sanitized
      if (value !== '' && oldValue != value) {
        let prototypeIdToShowError = prototypeIds.filter((p) => p != null)
        prototypeIdToShowError = prototypeIdToShowError.length > 1 ? null : prototypeIdToShowError.pop()
        let climateZoneRawToShowError = climateZoneRaws.filter((p) => p != null)
        climateZoneRawToShowError = climateZoneRawToShowError.length > 1 ? null : climateZoneRawToShowError.pop()
        this.showErrorInfoOnInput = this.getIdentifierNameBasedOnSpecs(prototypeIdToShowError, climateZoneRawToShowError, null, assumptionKey)
        this.debounce(() => {
          this.showErrorInfoOnInput = null
        }, 'showErrorInfoOnInput', 2000)
      }
    },
    valuesByCustomCombinationsOfAssumptionKey(assumptionKey = null) {
      if (!this.getPolicySelected) {
        return null
      }
      return extractAssumptionValuesFromPolicy(this.getPolicySelected, assumptionKey || this.assumptionKey, this.getBaseValue)
    },
    getLocalAssumptionValue(prototypeId, climateZoneRaw, assumptionKey = null) {
      const localAssumptionKey = this.getInputKey(prototypeId, climateZoneRaw, assumptionKey)
      return this.localAssumptionValues?.[localAssumptionKey] ?? null
    },
    getSavedAssumptionValue(prototypeId, climateZoneRaw, assumptionKey = null) {
      assumptionKey = assumptionKey || this.assumptionKey
      if (!this.isPolicyAssumption) {
        return this.resultsAssumptionsValues?.[assumptionKey] ?? this.getBaseValue
      }
      return this.valuesByCustomCombinationsOfAssumptionKey(assumptionKey)?.find((x) => {
        const matchByPrototypeId = !prototypeId || x.prototype_id === prototypeId
        const matchByClimateZone = !climateZoneRaw || x.climate_zone_raw === climateZoneRaw
        return matchByPrototypeId && matchByClimateZone
      })?.value ?? this.getBaseValue
    },
    getAssumptionValue(prototype, climate_zone, assumptionKey = null) {
      assumptionKey = assumptionKey || this.assumptionKey
      return this.getLocalAssumptionValue(prototype?.id, climate_zone?.raw) ??
        this.getSavedAssumptionValue(prototype?.id, climate_zone?.raw, assumptionKey)
    },
    hasValueUpdated(prototype, climate_zone, assumptionKey = null) {
      const onBaseCheckChanged = Boolean(!climate_zone && !prototype && this.assumptionsLevel !== ASSUMPTION_LEVEL.BASE)
      return onBaseCheckChanged || this.getBaseValue !== this.getAssumptionValue(prototype, climate_zone, assumptionKey)
    },
    getInputKey(prototypeId, climateZoneRaw, assumptionKey = null) {
      assumptionKey = assumptionKey || this.assumptionKey
      return `${ assumptionKey }${ prototypeId ? '-' + prototypeId : '-all-prototypes' }${ climateZoneRaw ? '-' + climateZoneRaw : '-all-climate-zones' }`
    },
    getInputPrototypeIdsAndClimateZoneRaws(prototypeSelected, climateZoneSelected) {
      if (!this.isPolicyAssumption) {
        return { prototypeIds: null, climateZoneRaws: null }
      }
      const prototypes = !prototypeSelected ? this.getPrototypesWithinPolicySelected || [] : [prototypeSelected]
      const prototypeIds = prototypes.map((p) => p.id)
      const climateZones = !climateZoneSelected ? this.getClimateZonesWithinPolicySelected || [] : [climateZoneSelected]
      const climateZoneRaws = climateZones.map((c) => c.raw)
      return { prototypeIds, climateZoneRaws }
    },
    onInput(prototype, climateZone, value) {
      if (!this.checkIfUserCanEditAssumption()) return
      const { prototypeIds, climateZoneRaws } = this.getInputPrototypeIdsAndClimateZoneRaws(prototype, climateZone)
      this.updateLocalAssumption(prototypeIds, climateZoneRaws, value, undefined, true)
    },
    getIdentifierNameBasedOnSpecs(prototypeId, climateZoneRaw, prefix = null, assumptionKey = null) {
      return `${prefix ? prefix + '-' : ''}${this.getInputKey(prototypeId, climateZoneRaw, assumptionKey)}`
    },
    canShowResetButton(prototype, climateZone, prefix = null, assumptionKey = null) {
      return this.showResetButtonOnInput === this.getIdentifierNameBasedOnSpecs(prototype?.id, climateZone?.raw, prefix, assumptionKey)
    },
    onMouseEnterInsideInput(prototype, climateZone, prefix = null, assumptionKey = null) {
      this.showResetButtonOnInput = this.getIdentifierNameBasedOnSpecs(prototype?.id, climateZone?.raw, prefix, assumptionKey)
    },
    onMouseLeaveInsideInput() {
      this.showResetButtonOnInput = null
    },
    canShowErrorInfoInput(prototype, climateZone, prefix = null, assumptionKey = null) {
      return this.showErrorInfoOnInput === this.getIdentifierNameBasedOnSpecs(prototype?.id, climateZone?.raw, prefix, assumptionKey)
    },
    checkCanAutoToggleAssumptionLevel(toggleLevel, value) {
      const needClimateZoneConfirmation = [ASSUMPTION_LEVEL.PROTOTYPE_AND_CLIMATE_ZONE, ASSUMPTION_LEVEL.CLIMATE_ZONE].includes(this.assumptionsLevel) && toggleLevel === RESIDENTIAL_NEW_SPECIFICATIONS.CLIMATE_ZONE.key
      const needPrototypeConfirmation = [ASSUMPTION_LEVEL.PROTOTYPE_AND_CLIMATE_ZONE, ASSUMPTION_LEVEL.PROTOTYPE].includes(this.assumptionsLevel) && toggleLevel === RESIDENTIAL_NEW_SPECIFICATIONS.BUILDING_TYPE.key
      const canAutoCheck = Boolean(value || (!needClimateZoneConfirmation && !needPrototypeConfirmation))
      if (!canAutoCheck) {
        this.openConfirmUncheckPopOver(toggleLevel)
      }
      return canAutoCheck
    },
    onAssumptionLevelAutoToggle(toggleLevel, value) {
      if(value) {
        this.specifyBy.push(toggleLevel)
      } else {
        this.specifyBy = this.specifyBy.filter(spec => spec !== toggleLevel)
      }
    },
    toggleSingleSpecifyBy(value, specification = null) {
      specification = specification || this.getSingleSpecifyByEnabled
      if (this.checkCanAutoToggleAssumptionLevel(specification.key, value)) {
        if(value && !this.specifyBy.includes(specification.key)) {
          this.specifyBy.push(specification.key)
        } else {
          this.specifyBy.shift()
        }
      }
    },
    canPrototypeInputBeVisible(prototype) {
      if(this.specifyBy.length !== 2) {
        return true
      }
      return Boolean(this.getPolicySelectedCustomCombinations?.filter((cc) => {
        return cc?.climate_zone_raw === this.getClimateZoneSelected?.raw && cc?.prototype_id === prototype?.id
      })?.length)
    },
    openConfirmUncheckPopOver(toggleLevel) {
      const newSpecifyBy = [...this.specifyBy].filter((k) => k !== toggleLevel)
      let text = `Are you sure? This will revert the assumption to an average for all${
        this.isSpecifyByClimateZoneEnabled ? ' climate zones' : ''
      }${this.isSpecifyByClimateZoneEnabled && this.isSpecifyByBuildingTypeEnabled ? ' and' : ''}${this.isSpecifyByBuildingTypeEnabled ? ' building types' : ''}.`
      if (newSpecifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.CLIMATE_ZONE.key)) {
        text = `Are you sure? This will revert the assumption to an average for all building types inside each of climate zones.`
      } else if (newSpecifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.BUILDING_TYPE.key)) {
        text = `Are you sure? This will revert the assumption to an average for all climate zones inside each of building types.`
      }

      this.$eventBus.$emit('openConfirmPopover', {
        targetElem: this.$refs.switchItem.$el,
        text,
        neverAskUserProp: `assumptions_break_level_${this.assumptionKey}`,
        okButtonText: 'Ok, please proceed',
        side: 'left',
        onConfirmCallback: () => {
          const averageReducer = (acc, curr, idx, list) => acc + (curr.value / list.length)
          if(newSpecifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.CLIMATE_ZONE.key)){
            [... new Set(this.valuesByCustomCombinations.map((a) => a.climate_zone_raw))].forEach(cz =>
            {
              const averageValue = this.valuesByCustomCombinations.filter( el => el.climate_zone_raw === cz).reduce(averageReducer, 0)
              const getClimateZone = this.$store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({raw:cz})
              this.updateAssumption(null, getClimateZone, averageValue, this.assumptionKey)
            })
          } else if(newSpecifyBy.includes(RESIDENTIAL_NEW_SPECIFICATIONS.BUILDING_TYPE.key)){
            [... new Set(this.valuesByCustomCombinations.map((a) => a.prototype_id))].forEach(pt =>
            {
              const averageValue = this.valuesByCustomCombinations.filter( el => el.prototype_id === pt).reduce(averageReducer, 0)
              const getPrototype = this.getPrototypesWithinPolicySelected.find(prototype => prototype.id === pt)
              this.updateAssumption(getPrototype, null, averageValue, this.assumptionKey)
            })
          } else {
            const averageValue = this.valuesByCustomCombinations.reduce(averageReducer, 0)
            this.updateAssumption(null, null, averageValue, this.assumptionKey)
          }
          this.specifyBy = newSpecifyBy
        },
      })
    },
    getCustomCombinationsByClimateZoneAndPrototype(prototypeIds, climateZoneRaws) {
      if (!this.isPolicyAssumption) return []
      return this.getPolicySelectedCustomCombinations?.filter((cc) => {
        return climateZoneRaws.includes(cc.climate_zone_raw) && prototypeIds.includes(cc.prototype_id)
      }) || []
    },
    updateAssumption(prototype, climate_zone, value, assumptionKey = null, mergeWithOtherAssumptions = null) {
      this.isUpdating = true
      setTimeout(() => {
        this.isUpdating = false
      }, 2000)

      let dispatchSavePersist = true
      assumptionKey = assumptionKey || this.assumptionKey
      value = this.sanitizeInputValue(value)
      if (value === '') {
        value = this.getSavedAssumptionValue(prototype?.id, climate_zone?.raw, assumptionKey)
        dispatchSavePersist = false
      }
      const partialAssumption = { ...(mergeWithOtherAssumptions || {}), [assumptionKey]: value }
      if(!this.isPolicyAssumption) {
        if (dispatchSavePersist) {
          this.$store.dispatch('assumptions/updateAssumptionsKeyValue',{ assumptions: partialAssumption, force: true })
        }
        this.updateLocalAssumption(null, null, value)
        return
      }

      const { prototypeIds, climateZoneRaws } = this.getInputPrototypeIdsAndClimateZoneRaws(prototype, climate_zone)
      if (dispatchSavePersist) {
        const custom_combinations = this.getCustomCombinationsByClimateZoneAndPrototype(prototypeIds, climateZoneRaws)
        this.$store.dispatch('assumptions/updateCustomCombinationsAssumptionsPartially', {
          custom_combinations,
          partialAssumption
        })
      }
      this.updateLocalAssumption(prototypeIds, climateZoneRaws, value)
    },
    isOnDefaultState(prototype, climateZone, some = false) {
      if (!this.isPolicyAssumption) return false
      const { prototypeIds, climateZoneRaws } = this.getInputPrototypeIdsAndClimateZoneRaws(prototype, climateZone)
      const customCombinations = this.getCustomCombinationsByClimateZoneAndPrototype(prototypeIds, climateZoneRaws).reduce((acc, cc) => {
        const item = acc.find((x) => x.prototype_id === cc.prototype_id && x.climate_zone_raw === cc.climate_zone_raw)
        const isAllElectricCC = cc?.fuel?.slug === TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC
        const isMixedFuelCC = cc?.fuel?.slug === TYPE_FUELS_DB_SLUGS.MIXED_FUEL
        const metaInfo = GeneralPerformanceByFuelPolicyImpactAlgorithm.extractMetaInfoFromCustomCombination(cc)
        const key = isAllElectricCC ? 'allElectricMetaInfo' : isMixedFuelCC ? 'mixedFuelMetaInfo' : 'unknown'
        if (!item) {
          acc.push({
            prototype_id: cc.prototype_id,
            climate_zone_raw: cc.climate_zone_raw,
            [key]: metaInfo
          })
        } else {
          item[key] = metaInfo
        }
        return acc
      }, [])
      const compareFnc = some ? 'some' : 'every'
      return customCombinations?.[compareFnc]((compiledCC) => {
        return Boolean(compiledCC?.allElectricMetaInfo?.isOnDefaultState && compiledCC?.mixedFuelMetaInfo?.isOnDefaultState)
      }) || false
    },
    isAllElectricMandatory(prototype, climateZone, some = false) {
      if (!this.isPolicyAssumption) return false
      const { prototypeIds, climateZoneRaws } = this.getInputPrototypeIdsAndClimateZoneRaws(prototype, climateZone)
      const customCombinations = this.getCustomCombinationsByClimateZoneAndPrototype(prototypeIds, climateZoneRaws)
      const compareFnc = some ? 'some' : 'every'
      return customCombinations?.[compareFnc]((cc) => {
        const metaInfo = GeneralPerformanceByFuelPolicyImpactAlgorithm.extractMetaInfoFromCustomCombination(cc)
        const isAllElectricCC = cc?.fuel?.slug === TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC
        const isMixedFuelCC = cc?.fuel?.slug === TYPE_FUELS_DB_SLUGS.MIXED_FUEL
        return Boolean((!isAllElectricCC && !isMixedFuelCC) ||
          (isAllElectricCC && metaInfo.fuelChoice === FUEL_CHOICES.REQUIRED) ||
          (isMixedFuelCC && metaInfo.fuelChoice === FUEL_CHOICES.NOT_ALLOWED)
        )
      }) || false
    },
    adjustSpecifyBy() {
      this.debounce(() => {
        const newSpecifyByLevel = []
        if ([ASSUMPTION_LEVEL.PROTOTYPE, ASSUMPTION_LEVEL.PROTOTYPE_AND_CLIMATE_ZONE].includes(this.assumptionsLevel)) {
          newSpecifyByLevel.push(RESIDENTIAL_NEW_SPECIFICATIONS.BUILDING_TYPE.key)
        }
        if ([ASSUMPTION_LEVEL.CLIMATE_ZONE, ASSUMPTION_LEVEL.PROTOTYPE_AND_CLIMATE_ZONE].includes(this.assumptionsLevel)) {
          newSpecifyByLevel.push(RESIDENTIAL_NEW_SPECIFICATIONS.CLIMATE_ZONE.key)
        }

        // Use case of enabling level by disabled state of inputs
        const isBaseDisabled = this.isInputDisabled && this.isInputDisabled(null, null)
        if (!this.firstLoaded && !this.isUpdating && this.assumptionsLevel === ASSUMPTION_LEVEL.BASE && isBaseDisabled) {
          if (this.canSpecifyByClimateZone && !newSpecifyByLevel.includes(RESIDENTIAL_NEW_SPECIFICATIONS.CLIMATE_ZONE.key)) {
            newSpecifyByLevel.push(RESIDENTIAL_NEW_SPECIFICATIONS.CLIMATE_ZONE.key)
          }
          if (this.canSpecifyByPrototype && !newSpecifyByLevel.includes(RESIDENTIAL_NEW_SPECIFICATIONS.BUILDING_TYPE.key)) {
            newSpecifyByLevel.push(RESIDENTIAL_NEW_SPECIFICATIONS.BUILDING_TYPE.key)
          }
        }

        if (newSpecifyByLevel.length > this.specifyBy.length) {
          this.specifyBy = newSpecifyByLevel
        }

        // Final adjustments based on specifyBy that can be or not enabled
        if(!this.canSpecifyByPrototype && this.isSpecifyByBuildingTypeEnabled) {
          const index = this.specifyBy.findIndex(element => element === RESIDENTIAL_NEW_SPECIFICATIONS.BUILDING_TYPE.key)
          this.specifyBy = this.specifyBy.splice(index,0)
        }
        if(!this.canSpecifyByClimateZone && this.isSpecifyByClimateZoneEnabled) {
          const index = this.specifyBy.findIndex(element => element === RESIDENTIAL_NEW_SPECIFICATIONS.CLIMATE_ZONE.key)
          this.specifyBy = this.specifyBy.splice(index,0)
        }
        this.firstLoaded = true
      }, 'adjustSpecifyBy')
    },
    debounce(func, debouncerName = 'default', timeout = 500) {
      if (this.debouncers[debouncerName]) {
        clearTimeout(this.debouncers[debouncerName])
      }
      this.debouncers[debouncerName] = setTimeout(async () => {
        this.debouncers[debouncerName] = null
        func()
      }, timeout)
    },
    startTransition(el) {
      el.style.height = el.scrollHeight + 'px'
      el.style.padding = el.scrollPaddingBottom + 'px'
    },
    endTransition(el) {
      el.style.height = ''
      el.style.padding = ''
    },
  }
}
