<template>
  <BaseModal
    ref="modalSelectJurisdiction"
    :disable-close="false"
  >
    <div class="modal-jurisdiction-selection">
      <HomeJurisdictionSelect 
        icon="search"
        icon-class="psui-text-blue-60"
        label="Type your city or county’s name"
        :should-scroll="true"
        :jurisdiction-selected="lastJurisdictionVisited"
        :disable-button="false"
        data-test-id="modal-jurisdiction-selection-jurisdiction-select"
        @click="onClick"
        @change="onChange"
      />
    </div>
  </BaseModal>
</template>

<script>
import { mapGetters } from 'vuex'
import HomeJurisdictionSelect from '@/modules/app/home/HomeJurisdictionSelect.vue'
import RRC from '@/router/rrc'
import { ROUTE_EXCEPTIONS } from '@/util/Enums.js'

export default {
  name: 'ModalJurisdictionSelection',
  components:{
    HomeJurisdictionSelect
  },
  data: () => ({
    customRedirect: null,
    customQuery: null,
    skipRedirect: false,
  }),
  computed: {
    ...mapGetters(['lastJurisdictionVisited']),
    getIfUserHasJurisdictionSelected(){
      return this.lastJurisdictionVisited ? false : true
    },
    canModalBeClosed() {
      return Object.values(ROUTE_EXCEPTIONS).includes(this.$route.name) || this.lastJurisdictionVisited ? true : false
    }
  },
  created() {
    this.$eventBus.$on('openModalJurisdictionSelection', ({customRedirect, skipRedirect, customQuery} = {customRedirect: null, skipRedirect: false}) => {
      this.customRedirect = customRedirect
      this.customQuery = customQuery
      this.skipRedirect = skipRedirect
      this.$refs.modalSelectJurisdiction.showModal = true
    })
  },
  methods: {
    onChange(jurisdiction) {
      this.$store.dispatch('setUserLastJurisdictionAndFirstClimateZoneVisited', { value: jurisdiction, context: 'updateLastJurisdictionVisitedFromModalJurisdictionSelection' })
    },
    onClick() {
      if (!this.canModalBeClosed) return 
      this.$refs.modalSelectJurisdiction.close()
      if (!this.skipRedirect) {

        let route = {
          name: this.customRedirect || 'PolicyOptions', 
          params: { rrc: RRC.MODAL_JURISDICTION_SELECTION_1 }, 
        }

        if(this.customQuery) {
          route = {
            ...route,
            query: this.customQuery
          }
        }
        
        this.$router.push({ 
          ...route
        })
      }
     }
  }
}
</script>

<style scoped lang="scss">
  ::v-deep .modal-dialog {
    padding: 0 0;
    width: 100%;
    max-width: 760px;
    background: url('/images/home_page/home_page_background.jpg');
    background-position: center bottom;
    background-repeat: no-repeat;
    background-color: #F3F6F9;
    background-size: 100%;
    border-radius: 6px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05), 0px 30px 60px rgba(0, 0, 0, 0.2);
    padding: 72px 32px 100px 32px;

    &-close {
      padding-right: 2rem;
    }
  }


  .modal-jurisdiction-selection {
    ::v-deep .jurisdiction--select {
      width: 100%;
      .input-wrapper {
        .width-machine {
          width: 100%;
        }
      }
    }
    
  } 
  
</style>
