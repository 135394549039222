<template>
  <div
    class="flex items-center"
    @mouseleave="toggleWarningAlert($event, false)"
  >
    <div class="mr-1/2 psui-text-small psui-font-bold psui-text-gray-80 whitespace-nowrap">
      {{ climateZonesTitle }}
      <span
        v-if="showBpsWarn"
        class="svg-position"
        @mouseover="toggleWarningAlert($event, true)"
      >
        <span class="cursor-pointer">
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <circle
              cx="5"
              cy="5"
              r="4.5"
              fill="#EDAB3E"
            />
          </svg>
        </span>
      </span>
    </div>
    <div 
      class="psui-flex psui-flex-wrap psui-gap-1"
      @click="IsTooltipOn($event)"
      @mouseleave="IsTooltipOn($event)"
    >
      <PsChips 
        v-for="climate_zone in climateZones"
        :key="`dropdown-climate_zone-${climate_zone.id}`"
        v-tooltip="{
          content: checkClimateZoneCouldBeToggled(climate_zone), 
          trigger: 'manual',
          show: isOpen
        }"
        type="checkbox"
        :disabled="layout === 'disabled' ? true : checkClimateZoneCouldBeToggled(climate_zone) ? true : false"
        :layout="layout === 'disabled' ? 'disabled' : 'simple'"
        :label="`${climate_zone.prefix}`"
        :disabled-text="disabledText"
        :checked="isChecked(climate_zone.prefix)"
        @change="onUpdateClimateZone(climate_zone)"
      />
    </div>
  </div>
</template>

<script>
import { ASSUMPTIONS_PATHS } from "@/util/Enums"

export default {
  name: 'BuildingEstimateFilterClimateZone',
  props: {
    operationalMode: {
      type: String, 
      default: 'query_params',
      validator: value => ['query_params', 'event'].includes(value)
    }, 
    layout: {
      type: String,
      default: 'simple'
    },
    disabledText: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      isOpen: false,
      climateZonePrefixesExcluded: []
    }
  },
  computed: {
    showBpsWarn() {
      return this.$route.name === 'BuildingEstimates' &&
          this.$route.query.only_building_type === ASSUMPTIONS_PATHS.NON_RESIDENTIAL_BUILDINGS.slug &&
          this.getQueryKeyItems('exclude_climate_zone_prefix')?.length > 0
    },
    climateZones() {
      if(this.$store?.getters['lastJurisdictionVisited']?.climate_zones) {
        const climateZones = [...this.$store?.getters['lastJurisdictionVisited']?.climate_zones]
        return climateZones.length > 0 ? climateZones.sort((a,b) => a.prefix - b.prefix) : []
      } else {
        return []
      }
    },
    climateZonesTitle() {
      return (this.climateZones.length === 1) ? 'Climate Zone' : 'Climate Zones'
    }
  },
  mounted() {
    this.updateLastClimateZonesVisited()
  },
  methods: {
    toggleWarningAlert(event, show) {
      if (!show) {
        this.$eventBus.$emit('closeConfirmPopover', { preventWhenHovering: true })
        return
      }
      this.$eventBus.$emit('openConfirmPopover', {
        targetElem: event.target,
        text: `Existing Nonresidental building data is not available on a climate zone level.`,
        textAsHtml: true,
        side: 'right',
        buttons: [],
        disableFooter: true,
        sumTop: 50,
        sumLeft: -120,
        maxWidth: 180,
        layout: 'gray-alert'
      })
    },
    isChecked(climateZonePrefix) {
      return this.operationalMode === 'query_params' ? !this.checkQueryKeyValue('exclude_climate_zone_prefix', climateZonePrefix) : !this.climateZonePrefixesExcluded.includes(climateZonePrefix)
    },
    onUpdateClimateZone(climate_zone) {
      if (this.operationalMode === 'query_params') {
        if (!this.checkClimateZoneCouldBeToggled(climate_zone)) {
          this.toggleQueryKeyValue('exclude_climate_zone_prefix', climate_zone.prefix)
          this.updateLastClimateZonesVisited()        
        }  
      } else {
        if (this.climateZonePrefixesExcluded.includes(climate_zone.prefix)) {
          this.climateZonePrefixesExcluded = this.climateZonePrefixesExcluded.filter(c => c != climate_zone.prefix)
        } else {
          this.climateZonePrefixesExcluded.push(climate_zone.prefix)
        }      
        this.$emit('climateZonesChanged', this.climateZonePrefixesExcluded)
      }
    },
    updateLastClimateZonesVisited() {
      const activeClimateZones = this.climateZones.filter(cz => {
        return !this.checkQueryKeyValue('exclude_climate_zone_prefix', cz.prefix)
      })
      this.$store.dispatch('setUserLastClimateZonesVisited', { value: activeClimateZones, context: 'updateLastClimateZonesVisitedFromBuildingEstimates'})
    },
    checkClimateZoneCouldBeToggled(climate_zone) {
      if (this.operationalMode === 'event') {
        return this.climateZonePrefixesExcluded.length < this.climateZones.length - 1 || this.climateZonePrefixesExcluded.includes(climate_zone.prefix) ? false : `You must have at least one Climate Zone selected.`
      }

      if(this.checkQueryKeyValue('exclude_climate_zone_prefix', climate_zone.prefix)) return false
      if(this.getQueryKeyItems('exclude_climate_zone_prefix').length < this.climateZones.length-1) return false
      return `You must have at least one Climate Zone selected.`
    },
    IsTooltipOn(event){
      if(event.type === 'mouseleave') this.isOpen = false
      if(event.type === 'click') this.isOpen = !this.isOpen
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-position {
  position: relative;
  display: inline-flex;
  align-self: center;
  justify-self: center;
  right: calc(-1rem + 15px);
  top: 0;
  ::v-deep .psui-el-tooltip-trigger {
    margin-right: calc(0.313rem + 1px); // 5px
    margin-bottom: 0.125rem; // 2px
    margin-left: -0.094rem; // 1.5px
  }
}
</style>