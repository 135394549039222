import { render, staticRenderFns } from "./EditRequirementsNewBuildingsSD2022AdminInfo.vue?vue&type=template&id=1984c783&scoped=true"
import script from "./EditRequirementsNewBuildingsSD2022AdminInfo.vue?vue&type=script&lang=js"
export * from "./EditRequirementsNewBuildingsSD2022AdminInfo.vue?vue&type=script&lang=js"
import style0 from "./EditRequirementsNewBuildingsSD2022AdminInfo.vue?vue&type=style&index=0&id=1984c783&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1984c783",
  null
  
)

export default component.exports