<template>
  <div
    :style="{ display: display }"
    @click="emit('click', $event)"
  >
    <span
      v-if="getIconType === 'material-icons'"
      class="material-icons-round"
      :class="[getIconClasses]"
      :style="{ fontSize: `${size}px` }"
    >
      {{ getIcon }}
    </span>
    <img
      v-else-if="getIconType === 'url'"
      :src="icon"
      :class="[iconClasses]"
    >
    <inline-svg
      v-else
      :src="icon"
      :width="width ? width : size"
      :height="height ? height : size"
      :stroke="stroke ? stroke : disableStroke ? null : getColor"
      :fill="getColor"
    />
  </div>
</template>

<script setup>
import tailwindConfig from '../../../tailwind.config.js'
import imageLoader from '../../util/imageLoader.js'

import { ref, computed, watch, onMounted } from 'vue'

const props = defineProps({
  /**
   * It sets the text key to get the svg icon in Google Fonts. Make sure to get the correct description of your icon on https://fonts.google.com/.
   */
  icon: {
    type: String,
    default: 'more_horiz',
  },
  /**
   * It sets the type of the icon.
   */
  type: {
    type: String,
    default: '',
  },
  /**
   * It sets the style of the icon.
   */
  iconClasses: {
    type: String,
    default: null,
  },
  /**
   * It sets the size of the icon.
   */
  size: {
    type: [Number, String],
    default: 24,
  },
  /**
   * It sets fill property of the icon.
   */
  stroke: {
    type: String,
    default: null,
  },
  /**
   * It sets fill property display of the icon.
   */
  display: {
    type: String,
    default: 'contents',
  },
  /**
   * It sets the color of the icon.
   */
  color: {
    type: String,
    default: null,
    validator: (value) => {
      return (
        (value?.includes('psui-text-') && typeof tailwindConfig.theme.colors[value.replace('psui-text-', '')] != 'undefined') ||
        typeof tailwindConfig.theme.colors[value] != 'undefined'
      )
    },
  },
  /**
   * It set a animation icon if needed.
   */
  loaderIcon: {
    type: String,
    default: 'hourglass_top',
  },

  /**
   * It sets a error when the icon is not available.
   */
  loaderErrorIcon: {
    type: String,
    default: 'more_horiz',
  },
  disableStroke: {
    type: Boolean,
    default: false,
  },
  width: {
    type: [Number, String],
    default: null,
  },
  height: {
    type: [Number, String],
    default: null,
  },
})

const finishedImageLoad = ref(false)
const imageLoadError = ref(false)

const emit = defineEmits(['click'])

const getIconType = computed(() => {
  if (props.type) return props.type
  if (!props.icon?.includes('/')) return 'material-icons'
  if (!props.icon?.includes('.svg')) return 'url'
  return 'svg'
})

const getIcon = computed(() => {
  if (!props.icon?.includes('/')) return props.icon ? props.icon : props.loaderErrorIcon
  if (!finishedImageLoad.value && !imageLoadError.value && props.loaderIcon) return props.loaderIcon
  if (imageLoadError.value) return props.loaderErrorIcon
  return props.icon ? props.icon : props.loaderErrorIcon
})

const getIconClasses = computed(() => {
  if (props.iconClasses) return props.iconClasses
  return props.color ? `${props.color}` : ''
})

const getColor = computed(() => {
  if (getIconType.value === 'material-icons') return props.color
  return tailwindConfig.theme.colors[props.color.replace('psui-text-', '')] || tailwindConfig.theme.colors[props.color]
})

watch(
  () => props.icon,
  () => {
    loadImage()
  }
)

onMounted(() => {
  if (props.icon?.includes('/')) loadImage()
})

const loadImage = () => {
  if(getIconType.value != 'material-icons') {
    finishedImageLoad.value = false
    imageLoadError.value = false
    imageLoader({ imageUrl: props.icon, returnsBase64: false })
    .then(() => {
      finishedImageLoad.value = true
    })
    .catch(() => {
      imageLoadError.value = true
    })
  }
}
</script>
