<template>
  <div class="card-create-policy psui-bg-blue-20 psui-pl-6 psui-pr-8 psui-py-8">
    <div 
      class="psui-grid psui-gap-6"
      :class="{
        'psui-grid-cols-1' : getterContentContainerWidth <= 1024,
        'grid-template-columns-summary' : getterContentContainerWidth > 1024,
      }"
    >
      <div 
        class="psui-flex psui-flex-col psui-items-start psui-space-y-6"
      >
        <img
          class="info"
          style="width: 240px; height: 180px"
          src="/images/comparison.png"
        >
        <p class="psui-font-bold psui-text-h4 text-deepsky-900 psui-mb-2.5">
          {{ getText }}
        </p>
      </div>
      
      <ExploreStudyTypeCard
        :title="COPY.EXISTING_BUILDING_POLICY_WIDGET.TITLE"
        :description="COPY.EXISTING_BUILDING_POLICY_WIDGET.DESCRIPTION"
        :tooltip-policy-type-title="COPY.EXISTING_BUILDING_POLICY_WIDGET.TOOLTIP_TITLE"
        :prototypes-available="getPrototypesAvailableByPolicyOptionsAndStudyType(STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS)"
        @click="goToPolicyOptionsExistingBuildingsTab"
      >
        <template #content>
          <hr class="divider psui-w-full psui-mt-6 psui-mb-4 psui-border-blue-20">

          <router-link
            class="cursor-pointer psui-text-blue-60 psui-font-bold psui-text-small hover:psui-text-blue-70 psui-transition"
            :to="getExistingBuildingsExploreStudyResultsRoute()"
          >
            Explore study results
          </router-link>
        </template>
      </ExploreStudyTypeCard>
      <ExploreStudyTypeCard
        :title="COPY.NEW_BUILDING_POLICY_WIDGET.TITLE"
        :description="COPY.NEW_BUILDING_POLICY_WIDGET.DESCRIPTION"        
        :tooltip-policy-type-title="COPY.NEW_BUILDING_POLICY_WIDGET.TOOLTIP_TITLE"
        :prototypes-available="getPrototypesAvailableByPolicyOptionsAndStudyType(STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS)"
        @click="goToPolicyOptionsNewBuildingsTab"
      >
        <template #content>
          <BaseModal
            ref="baseModal"
            modal-dialog-classes="w-full max-w-sm"
            :disable-close="true"
          >
            <h1 class="font-size-title font-bold psui-text-gray-80">
              New Construction Policy Impacts (Beta)
            </h1>
            <p class="font-size-content mt-4 mb-6 psui-text-gray-50">
              Our spreadsheet based impact model is now available. Estimate impacts customized for your city. <br>
              -Covers new single family homes <br>
              -Supports electric-only and electric-preferred type policies <br>
              -Uses draft 2022 New Single Family Cost-Effectiveness study results <br>
              -Other building types coming soon
            </p>

            <div class="flex psui-flex-wrap">
              <a 
                target="_blank"
                href="https://docs.google.com/spreadsheets/d/12oSwfPQUuylA49PAfVolTE8E_2bWjBB057hPB0fqNIc/edit?usp=sharing"
                @click="gaEvent(GA_LABELS.SUMMARY_DOWNLOAD_NEW_CONSTRUCTION_SF_IMPACT_MODEL_BETA, { value: 'SUMMARY_DOWNLOAD_NEW_CONSTRUCTION_SF_IMPACT_MODEL_BETA' })"
              >
                <PsButton
                  label="Open Model"
                  size="medium"
                  layout="solid"
                  icon="open_in_new"
                  icon-position="left"
                  class="mr-2"
                />
              </a>
              <PsButton
                label="Maybe later"
                size="medium"
                layout="onlytext"
                @click="closeDownloadImpactModal"
              />
            </div>
          </BaseModal>

          <hr class="divider psui-w-full psui-mt-3 psui-mb-4 psui-border-blue-20">

          <a 
            target="_blank"
            href="https://intercom.help/explorer-local-energy-codes/en/collections/3517138-new-construction-quick-guide"
            class="psui-text-blue-60 psui-font-bold psui-text-small hover:psui-text-blue-70 psui-transition"
          >
            Key Concepts for the 2022 Code Cycle
          </a>
            
          <hr class="divider psui-w-full psui-mt-3 psui-mb-4 psui-border-blue-20">
          
          <div class="flex items-center">
            <router-link
              class="cursor-pointer psui-text-blue-60 psui-font-bold psui-text-small hover:psui-text-blue-70 psui-transition"              
              :to="getNewBuildingsExploreStudyResultsRoute()"
            >
              Explore study results
            </router-link>
            <ReleaseAlertNewNonResidential2022Study />
          </div>
        </template>
      </ExploreStudyTypeCard>
    </div>
  </div>
</template>

<script>
import ReleaseAlertNewNonResidential2022Study from '../../shared/ReleaseAlertNewNonResidential2022Study.vue'
import { COPY, STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { mapGetters } from 'vuex'
import ExploreStudyTypeCard from '@/modules/app/jurisdiction/summary/shared/ExploreStudyTypeCard.vue'
export default {
  name : 'SummaryWidgetStudyTypes',
  components:{
    ExploreStudyTypeCard, ReleaseAlertNewNonResidential2022Study
  },
  data:()=>({
    COPY,
    STUDY_TYPES_DB_SLUGS
  }),
  computed: {
    jurisdiction() {
      return this.$store.getters['lastJurisdictionVisited']
    },
    getPrototypesAvailableByPolicyOptions() {
      const getStudyGroupIdsByStudyType = this.$store.getters['globalEntities/StudyGroup/getterGlobalStudyGroups']({study_type_id:2})
      .map(study_group => study_group.id)

      return this.$store.getters['policy/getterPolicyOptionsWithPrototypes']()
      .filter(policy_option => policy_option.algorithms.some(alg => alg.study_type_slug === "new-buildings" )  && policy_option.prototypes.every(prototype => getStudyGroupIdsByStudyType.includes(prototype.study.study_group_id)))
      .map(policy_option => policy_option.prototypes)
      .reduce((acc,curr) => acc.concat(curr),[])
      .reduce((acc,curr) => {
        if(acc.length === 0 || !acc.includes(curr)){
          acc.push(curr)
        }
        return acc
      },[])
    },
    getText() {
      return `Forecast the impact of reach codes for ${this.jurisdiction?.type === 'City' || this.jurisdiction?.type === 'State' ? 'the' : ''} ${ this.$options.filters.editJurisdictionTitleTypeBasedOnJurisdictionType(this.jurisdiction, 'title_type') } using these available resources: `
    },
    ...mapGetters({getterContentContainerWidth: 'general/getterContentContainerWidth'}),
  },
  methods: {
    openDownloadImpactModal() {
      this.$refs.baseModal.open()
    },
    closeDownloadImpactModal() {
      this.$refs.baseModal.showModal = false
    },
    getExistingBuildingsExploreStudyResultsRoute() {
      return {
        name: 'StudyResults',
        params : { jurisdiction_slug : this.jurisdiction.slug }, 
        query : { 
          exclude_study_ids: '22,19,1,2,3',
          only_study_type: STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS
        } 
      }
    },    
    getNewBuildingsExploreStudyResultsRoute() {
      return {
        name: 'StudyResults',
        params : { jurisdiction_slug : this.jurisdiction.slug }, 
        query : { 
          exclude_study_ids: '22,19,1,2,3',
          only_study_type: STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS
        }
      }
    },
    setPolicyOptionTab(studyTypeSlug){
      this.$store.dispatch('setUserPreferences', 
        { 
          payload: { 
            path: 'policies.policyOptions.filterByStudyType',
            value: [studyTypeSlug]
          } 
      })
    },
    goToPolicyOptionsExistingBuildingsTab() {
      this.$store.dispatch('setUserLastPolicyOptionSelected', {
        context:'updateLastPolicyOptionSelectedFromSummary',
        value: [STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS]
      })

      this.$router.push({name: 'PolicyOptions'})
    },
    setUserLastStudyTypeExistingBuildingsVisited() {
      this.$store.dispatch('setUserLastStudyTypeVisited', {
        value: STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS,
        context: 'updateLastStudyTypeVisitedFromSummaryWidgetStudyTypes',
      })
    },
    goToPolicyOptionsNewBuildingsTab() {
      this.$store.dispatch('setUserLastPolicyOptionSelected', {
        context:'updateLastPolicyOptionSelectedFromSummary',
        value: [STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS]
      })
      
      this.$router.push({name: 'PolicyOptions'})
    },
    setUserLastStudyTypeNewBuildingsVisited() {
      this.$store.dispatch('setUserLastStudyTypeVisited', {
        value: STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS,
        context: 'updateLastStudyTypeVisitedFromSummaryWidgetStudyTypes',
      })
    },
    getPrototypesAvailableByPolicyOptionsAndStudyType(study_type_slug) {
      const getStudyTypeByStudyTypeSlug = this.$store.getters['globalEntities/StudyType/getterGlobalStudyType']({slug:study_type_slug})
      const getStudyGroupIdsByStudyType = this.$store.getters['globalEntities/StudyGroup/getterGlobalStudyGroups']({study_type_id:getStudyTypeByStudyTypeSlug.id})
      .map(study_group => study_group.id)

      return this.$store.getters['policy/getterPolicyOptionsWithPrototypes']()
      .filter(policy_option => policy_option.algorithms.some(alg => alg.study_type_slug === study_type_slug )  && policy_option.prototypes.every(prototype => getStudyGroupIdsByStudyType.includes(prototype.study.study_group_id)))
      .map(policy_option => policy_option.prototypes)
      .reduce((acc,curr) => acc.concat(curr),[])
      .reduce((acc,curr) => {
        if(acc.length === 0 || !acc.includes(curr)){
          acc.push(curr)
        }
        return acc
      },[])
    },
  }
}
</script>

<style lang="scss" scoped>
  .card-create-policy {
    border-radius: 10px;

    .divider {
      transform: translateX(-10px);
      width: calc(100% + 20px);
    }
  }
  .grid-template-columns-summary {
    grid-template-columns: 0.7fr 1fr 1fr;
  }
    
  .font-size-title {
    font-size: 20px;
  }

  .font-size-content {
    font-size: 16px;
  }
</style>
