
/**
 * projections / city wide impact columns
 */
export default [
  'forecast_units_affected', 
  'forecast_initial_cost',
  'forecast_kwh_savings',
  'forecast_therms_savings',
  'forecast_emissions_savings',
  'forecast_lifecycle_savings',
  'forecast_energy_savings',
  'city_wide_subsidy_needed',
  'city_wide_five_year_payback',
  'city_wide_subsidy_needed_care',
  'city_wide_five_year_payback_care',
]