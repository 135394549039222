<template>
  <div class="rounded-6 psui-bg-white">
    <PsAccordion>
      <template
        #default
      >
        <PsAccordionItem
          :has-custom-header="true"
          :opened="true"
        >
          <template #custom-header="{toggle}">
            <div
              class="additional-tab-header"
            >
              <div
                class="flex items-center psui-cursor-pointer psui-text-gray-80 hover:psui-text-blue-60"
                @click="toggle"
              >              
                <PsIcon
                  icon="expand_more"
                  size="20"
                  class="icon-transition"
                />
                <h1 class="psui-text-h6 psui-font-bold">
                  {{ policyHasPolicyContainers ? 'Building types that you can also add to your policy' : 
                    'Time to start crafting your policy! Choose a building type to start:' | policyTextRephrase }}
                </h1>
              </div>

              <PsTabHeader
                v-if="!policyHasPolicyContainers"
                :items="getStudyTypes"
                key-label="title"
                key-value="slug"
                :selected.sync="getStudyTypeSelected"
              />
            </div>
          </template>
          <template
            #default
          >
            <div 
              v-if="getTypePrototypes.length !== 0"
              class="psui-flex psui-flex-col psui-gap-3"
            >
              <div
                v-for="(typePrototype, index) in getTypePrototypes"
                :key="typePrototype.slug"
                class="psui-flex psui-justify-between border-2/3 psui-border-dashed psui-px-6 py-3/4 rounded-6 psui-border-gray-30 mx-6 hover:psui-bg-blue-10 psui-cursor-pointer"
                @click="addRequirements(typePrototype, index)"
                @mouseenter="onMouseHover(index)"
                @mouseleave="onMouseHover(false)"
              >
                <div class="psui-flex items-center">
                  <PsIcon
                    :icon="typePrototype.icon"
                    size="20"
                    :color="isHovering === index ? 'psui-text-blue-60': 'psui-text-gray-60'"
                  />
                  <span
                    class="ml-2 psui-text-h6 psui-text-gray-80 psui-font-bold"
                    :class="isHovering === index ? 'psui-text-blue-60': 'psui-text-gray-80'"
                  >{{ typePrototype.title }}</span>
                </div>
                <div
                  class="psui-flex psui-items-center psui-text-blue-60 psui-cursor-pointer"
                >
                  <AppLoader
                    v-if="loadingIndex === index"
                    :key="getIndex"
                  />
                  <span
                    v-else 
                    class="psui-text-small psui-font-bold flex-shrink-0"
                  >
                    Add requirements
                  </span>
                </div>
              </div>
            </div>
            <div
              v-else
              class="psui-flex psui-pl-10"
            >
              <p class="psui-text-p psui-text-gray-60 psui-ml-1">
                {{ 'Currently there are no other building types that can be included in this policy.' | policyTextRephrase }}
              </p>
            </div>
          </template>
        </PsAccordionItem>
      </template>
    </PsAccordion>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PolicyRequirementsAvailableTypePrototypes',
  props:['typePrototypes', 'policy','loadingIndex'],
  data:()=>({
    isHovering: false,
    getIndex: false,
    studyTypeSelected: false,
  }),
  computed: {    
    getStudyTypeSelected: {
      get() {
        return  this.$store.getters['globalEntities/StudyType/getterGlobalStudyType']({ slug: this.lastStudyTypeVisited })
      },
      set(tab) {
        let studyTypeSlug
        if(typeof tab === 'string') studyTypeSlug = tab
        if(typeof tab === 'object') studyTypeSlug = tab.slug

        this.studyTypeSelected = this.$store.getters['globalEntities/StudyType/getterGlobalStudyType']({ slug: tab })
        this.$store.dispatch('setUserLastStudyTypeVisited', { value: studyTypeSlug, context: 'updateLastStudyTypeVisitedFromPOolicyRequirementsAvailableTypePrototypes'})
      }
    },
    getClimateZonesFromPolicy(){
      return this.policy.jurisdiction.climate_zones.map(cz => cz.id)
    },
    getStudyTypes(){
      return this.$store.getters['globalEntities/StudyType/getterGlobalStudyTypes']() ?? []
    },
    policyHasPolicyContainers() {
      return this.policy.policy_containers.length !== 0 ? true : false
    },
    getStudyTypeIdByTabSelected(){
      const study_type_slug = this.getStudyTypeSelected.slug
      return this.$store.getters['globalEntities/StudyType/getterGlobalStudyType']({ slug: study_type_slug })
    },
    getTypePrototypes(){
      const payload = { policy_id:this.policy.id, study_type_id:this.getStudyTypeIdByTabSelected.id }
      const sortedTypePrototypes = [...this.typePrototypes].sort((a,b) => {
        return a.order - b.order
      })
      return this.policyHasPolicyContainers ? sortedTypePrototypes : this.$store.getters['policy/getterPolicyAvailableTypePrototypesByStudyType'](payload)
    },
    getPolicyFirstContainerStudyType(){
      return this.policy.policy_containers[0].study_type_id
    },
    ...mapGetters(['lastStudyTypeVisited'])
  },
  methods: {
    onMouseHover(value){
      this.isHovering = value
    },
    addRequirements(typePrototype, index){
      const studyTypeIdSelected = this.policyHasPolicyContainers ? this.getPolicyFirstContainerStudyType : this.getStudyTypeIdByTabSelected.id
      this.$emit('add-requirements', {typePrototype, index, studyTypeId: studyTypeIdSelected})
    }
  }
}
</script>

<style lang="scss" scoped>
  .additional-tab-header {
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .psui-el-tab-header.status-resting.layout-standard {
      button {
        span {
          white-space: nowrap;
        }
      }
    }
    .icon-transition {
      ::v-deep span {
        transition: transform 0.4s ease-in-out;
      }
    }
  }

  .psui-el-accordion.layout-medium {
    .psui-el-accordion-item {
      &.status-opened {
        ::v-deep .icon-transition {
          span {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .psui-el-accordion-item {
    border-bottom: none;
  }
</style>
