import CustomBuildingStockApiService from "@/services/api/CustomBuildingStockApiService"
import { initClassData } from '@/util/Functions'
export default class CustomBuildingStock extends CustomBuildingStockApiService {

  static get fillable() {
    return [
      { key: 'id', default: null },
      { key: 'policy_id', default: null },
      { key: 'jurisdiction_id', default: null },
      { key: 'climate_zone_prefix', default: null },
      { key: 'type_prototype_id', default: null },
      { key: 'type_vintage_id', default: null },
      { key: 'units', default: 0 },
      { key: 'user_id', default: null },
      { key: 'floor_area', default: null }
    ]
  }

  static get filtrableKeys() {
    return ['policy_id', 'jurisdiction_id', 'climate_zone_prefix', 'type_prototype_id', 'type_vintage_id']
  }

  constructor(data) {
    super()
    initClassData(CustomBuildingStock.fillable, this, data)
  }

}
