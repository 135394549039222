<template>
  <div class="svg-color flex flex-col px-4 pt-2">
    <p
      class="psui-text-p psui-text-gray-70 mb-1"
      style="font-size: 14px;"
    >
      New Buildings
    </p>
    <PsCollapse
      v-for="typePrototype in type_prototypes"
      :key="typePrototype.id"
      :title="typePrototype.title"
      :disabled="typePrototype.disabled"
      :opened="true"
      class="-ml-1 mb-2"
      :class="[{'has-selected-item':hasPrototypeSelectedCz(typePrototype)}]"
    >
      <template #content>
        <div 
          class="collapse-content flex flex-col items-center w-full space-y-1"
        > 
          <div 
            v-for="climateZone in typePrototype.climate_zones" 
            :key="climateZone.id" 
            class="collapse-content-wrapper py-1 -ml-2 rounded-r-xs flex items-center w-full"
            :class="[
              { 'disabled-selected': climateZone.disabled && isRequirementSelected(typePrototype, climateZone)},
              { 'disabled': climateZone.disabled }, 
              { 'selected': !climateZone.disabled && isRequirementSelected(typePrototype, climateZone) }
            ]"
          >
            <span 
              class="psui-text-small cursor-pointer whitespace-nowrap pl-2"
              
              @click="$emit('toggleRequirement', typePrototype, climateZone )"                
            >
              {{ `Climate Zone ${climateZone.prefix}` }}
            </span>
            <!-- <div
              
              class="flex w-full"
            >
              <PsTooltip
                v-if="!climateZone.disabled && !isLoadingItem(typePrototype, climateZone)"
                class="ml-auto"
              >
                <template #trigger>
                  <PsIcon
                    icon="/icons/material-icons-downloaded/delete.svg"
                    size="16"
                    class="collapse-content-wrapper-action ml-auto cursor-pointer svg-color-active"
                    display="flex"
                    color="psui-text-gray-50"
                    @click="setRequirement($event ,typePrototype, [climateZone], climateZone.disabled)"
                  />
                </template>
                <template #content>
                  {{ climateZone.disabled ? 'Click to add this vintage to your policy': 'Click to remove this vintage to your policy' }}
                </template>
              </PsTooltip>
              <PsIcon
                v-else-if="isLoadingItem(typePrototype, climateZone)"
                icon="sync"
                size="16"
                display="flex"
                class="rotate ml-auto psui-text-gray-50"
              />
            </div> -->
          </div>
        </div>
      </template>
      <!-- <template #header-action>
        <PsTooltip v-if="!typePrototype.disabled && !isLoadingItem(typePrototype, null)">
          <template #trigger>
            <PsIcon
              icon="/icons/material-icons-downloaded/delete.svg"  
              size="16"
              class="cursor-pointer svg-color-active"
              display="flex"
              color="psui-text-gray-50"
              @click="setRequirement($event, typePrototype, typePrototype.climate_zones, typePrototype.disabled)"
            />              
          </template>
          <template #content>
            {{ typePrototype.disabled ? 'Click to add this prototype to your policy': 'Click to remove this prototype to your policy' }}
          </template>
        </PsTooltip>
        <PsIcon
          v-else-if="isLoadingItem(typePrototype, null)"
          icon="sync"
          size="16"
          display="flex"
          class="rotate ml-auto psui-text-gray-50"
        />
      </template> -->
    </PsCollapse>
  </div>
</template>

<script>
  export default {
    name:'PolicyEditRequirementsNewBuildingsLeftSideBar',
    props:['type_prototypes','custom_combinations','isLoading'],
    computed:{
      getPrototypeIdAndClimateZoneRawFromCustomCombination() {
        return {
            climate_zone_raw: this.custom_combinations?.[0]?.climate_zone_raw,
            prototype_id: this.custom_combinations?.[0]?.prototype_id
        }
      },
      
    },
    methods: {
      hasPrototypeSelectedCz(typePrototype){
        return this.type_prototypes.reduce((acc, curr) =>{
          if(curr.slug == typePrototype.slug) {
            const validate = curr.climate_zones.some(cz => this.isRequirementSelected(typePrototype, cz)) 
            acc = validate
          }  
          return acc
        }, false)
      },
      isRequirementSelected(typePrototype, climateZone) {
        return this.getPrototypeIdAndClimateZoneRawFromCustomCombination.climate_zone_raw == climateZone.raw && 
        this.getPrototypeIdAndClimateZoneRawFromCustomCombination.prototype_id == typePrototype.prototypes[0].id 
      },
      setRequirement(event ,typePrototype, climateZones, isCreating) {
        event.stopPropagation()
        this.$emit('setRequirements',event,typePrototype, climateZones, isCreating)
      },
      isLoadingItem(typePrototype, climateZone) {
        const loadingItem = `${typePrototype?.slug}-${climateZone?.raw}`
        return this.isLoading == loadingItem
      }
    }
  }
</script>

<style lang="scss" scoped>

  ::v-deep .psui-el-collapse {
    .psui-el-collapse-item-header {
      margin-bottom: 4px;
      .psui-el-collapse-item-header-wrapper {
        padding: 2px 0px;
      }
    }

    &:hover {
      .psui-el-collapse-item-header {
        .psui-el-collapse-item-icon.psui-icon {
          color: #318FAC;
        }
        .psui-el-collapse-item-header-wrapper {
          .psui-el-collapse-item-title {
            font-weight: 400 !important;
            color: #34404A !important;
          }
        }
      }
    }
  }

  ::v-deep .psui-el-collapse:is(.disabled) {
    &:hover {
      .psui-el-collapse-item-header {
        .psui-el-collapse-item-icon.psui-icon {
          color: #34404A;
        }
        .psui-el-collapse-item-header-wrapper {
          .psui-el-collapse-item-title {
            font-weight: 400 !important;
            color: #798490 !important;
          }
        }
      }
    }
  }
    
  ::v-deep .psui-el-collapse:is(.has-selected-item):not(.status-opened, .disabled) {

   .psui-el-collapse-item-header {
    .psui-el-collapse-item-header-wrapper {
      .psui-el-collapse-item-title {
        font-weight: 700;
        color: #318FAC;
      }
    }
   } 
  }

  ::v-deep .psui-el-collapse:is(.has-selected-item):is(.disabled):not(.status-opened) {
   .psui-el-collapse-item-header {
    .psui-el-collapse-item-header-wrapper {
      .psui-el-collapse-item-title {
        font-weight: 700;
      }
    }
   } 
  }


.collapse-content {
  margin-left: 4px;
  

  .collapse-content-wrapper {
    color: #34404A;
    padding: 2px 0px;
    &:hover {
      font-weight: 700;
      color: #318FAC;
      &.disabled {
        color: #798490;
      }
    }

    &.disabled {
      span {
        color: #798490;
      }
    }

    &.selected {
      border-top-left-radius: 3.5px 3px;
      border-bottom-left-radius: 3.5px 3px;
      border-left-width: 2px;
      border-left-color: #318FAC;
      background-color: white;  
      >span {
        color: #318FAC;
      }
    }

    &.disabled-selected {
        background-color: #D6DDE5;
        border-top-left-radius: 3.5px 3px;
        border-bottom-left-radius: 3.5px 3px;
        border-left-width: 2px;
        border-left-color: #798490;
        > span {
          color: #798490
        }
      }

    

    font-weight: 400;
    .active {
      font-weight: 700 !important;
    }

    .collapse-content-wrapper-action {
      visibility: hidden;
      &:hover {
        opacity: 10 !important;
      }
    }

    &:hover {
      .collapse-content-wrapper-action {
        visibility: visible;
      }
    }
  }
}

.svg-color {
  :deep(.psui-el-collapse) {
    .svg-color-active {
      svg {
        &:hover {
          fill: #318FAC;
        }
      }
    }
  }
}

.rotate {
  animation: spin 2s infinite linear;
}

@keyframes spin {
    0%  {-webkit-transform: rotate(0deg);}
    100% {-webkit-transform: rotate(-360deg);}   
}
</style>