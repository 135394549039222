<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="w-full max-w-sm"
    title="Caution"
  >
    <div
      v-if="customCombination"
      class="w-full flex flex-col items-start mt-6"
    >
      <p class="text-base text-gray04">
        {{ getText }}
      </p>

      <AppButton
        class="mt-6"
        label="Confirm delete"
        size="big"
        @click="deletePolicy()" 
      />
    </div>
  </BaseModal>
</template>

<script>
import CustomCombinationApiService from '@/services/api/CustomCombinationApiService'
import {checkIfVintageHasTargetScoreSet} from '@/util/Helpers.js'
import FlexiblePathService from '@/services/api/FlexiblePathService.js'

export default {
  name: 'ModalDeleteCustomCombination',
  data: () => ({
    customCombination: false, 
    hasVintageMeasures: false, 
    hasVintageTargetScoreSet: false,
    typePrototypeId: null,
    flexiblePathSetup: null,
    climateZoneRaw: null,
    typeVintage: null
  }),
  computed: {
    vintage() {
      return this.customCombination ? this.$store.getters['globalEntities/Vintage/getterGlobalVintage']({ id: this.customCombination.vintage_id }) : false
    }, 
    getText(){
      const measureCount = this.customCombination?.measures?.length
      const measureText = measureCount > 1 ? 'measures' : 'measure'
      const prefixText = measureCount > 1 ? `Are you sure you want to delete all ${measureCount} mandatory` : `Are you sure you want to delete ${measureCount} mandatory`

      if(this.hasVintageMeasures && !this.hasVintageTargetScoreSet) {
        return `${prefixText} ${measureText} for vintage ${ this.vintage.title }?`
      } else if(!this.hasVintageMeasures && this.hasVintageTargetScoreSet) {
        return `Are you sure you want to delete the requirements for vintage ${ this.vintage.title }?`
      } else if(this.hasVintageMeasures && this.hasVintageTargetScoreSet) {
        return `${prefixText} ${measureText} and required target score for vintage ${ this.vintage.title }?`
      } else {
        return `${prefixText} ${measureText} for vintage ${ this.vintage.title }?`
      }
    }
  },
  mounted() {
    this.$eventBus.$on('openModalDeleteCustomCombination', ({ custom_combination_id, type_vintage, climate_zone_raw, flexible_path_setup, type_prototype_id }) => {
      this.customCombination = false
      this.typePrototypeId = type_prototype_id

      this.flexiblePathSetup = flexible_path_setup
      this.climateZoneRaw = climate_zone_raw
      this.typeVintage = type_vintage
      CustomCombinationApiService.get(custom_combination_id).then(custom_combination => {
        this.customCombination = custom_combination

        this.hasVintageMeasures = custom_combination && custom_combination.measures.length > 0 
        this.hasVintageTargetScoreSet = checkIfVintageHasTargetScoreSet({type_vintage, climate_zone_raw, flexible_path_setup, type_prototype_id})
      })

      this.$refs.baseModal.showModal = true
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openModalDeleteCustomCombination')
  },
  methods: {
    deletePolicy() {

      const flexiblePath = new FlexiblePathService({ policy_id : this.$route.params.policy_id })
      
      const climateZone = this.$store.getters['globalEntities/ClimateZone/getterGlobalClimateZone']({raw: this.climateZoneRaw}) 

      const prototypes = this.$store.getters['globalEntities/Prototype/getterGlobalPrototypes']({type_prototype_id:this.typePrototypeId})      
      const cleanedFlexiblePathSetup = {
        ...this.flexiblePathSetup,

        tiers: this.flexiblePathSetup
        .tiers.map((tier) => {
            if(tier.climate_zone_id == climateZone.id && prototypes.find(prototype => prototype.id == tier.prototype_id)) {
              const functionFilter = (items) => {
                return items.filter( item => {
                  return item.type_vintage_id != this.typeVintage.id
                })
              }  
              
              return {
                ...tier,
                excluded_measures: functionFilter(tier.excluded_measures),
                mandatory_measures: functionFilter(tier.mandatory_measures),
                target_scores: functionFilter(tier.target_scores),
              }
            }
            return tier
        }),
      }
      Promise.all([
        flexiblePath.updateSetup(cleanedFlexiblePathSetup),
        this.$api.delete(`/api/custom_combinations/delete/${this.customCombination.id}`)
      ])    
      .then(() => {
        this.$eventBus.$emit('getPolicy')
        this.$refs.baseModal.showModal = false
      })
    }
  }
}
</script>