import PolicyApiService from '@/services/api/PolicyApiService'
import Policy from '@/models/Policy'
import Store from '@/store'
import { jurisdictionTitleTypeBasedOnJurisdictionType } from '@/util/Helpers.js'
import { POLICY_OPTIONS_DB_SLUGS } from '@/util/Enums.js'
import Vue from 'vue'

function checkIfTheFirstLetterIsAVowel(value) {
  const vowels = 'aeiou'
  return vowels.includes(value.charAt(0).toLowerCase())
}
export default [
  //STEP 1
  {
    id:'policy-option-information',
    component:'PolicyOptionAbout',
    title: (option) => {
      if(!option) return null
      const title = `About the '${option?.title_drawer_about || option?.title}' Policy Option`
      return Vue.options.filters.policyTextRephrase(title)    
    },
    drawerTitle: (option) => {
      if(!option) return null
      const title = `Policy Option for ${option?.jurisdiction?.type === 'City' || option?.jurisdiction?.type === 'State' ? 'the' : ''} ${jurisdictionTitleTypeBasedOnJurisdictionType(option?.jurisdiction, 'title_type')}`
      return Vue.options.filters.policyTextRephrase(title)
    },
    guide:'STEP 1 OF 2',
    getNextStep:()=>{
      return 'policy-option-type-prototype'
    },
  },
  //STEP 2
  {
    id:'policy-option-type-prototype',
    component:'PolicyOptionCreate',
    title: (option) => {
      if (!option) return null
      const title = option?.title_drawer_create !== null && option?.title_drawer_create === '' ? '' : ` ${option?.title_drawer_create || option?.title}`
      const article = checkIfTheFirstLetterIsAVowel(option.title) ? 'an' : 'a'
      const policyOptionTitle = `${title} Policy for the ${option.jurisdiction.type} of ${option.jurisdiction.title}`
      const finalTitle = option.slug !== POLICY_OPTIONS_DB_SLUGS.DEFAULT_STATE 
        ? `Create ${article} ${policyOptionTitle}` 
        : policyOptionTitle
      return Vue.options.filters.policyTextRephrase(finalTitle)
    },
    drawerTitle: (option) => {
      const title = `Policy Option for ${option?.jurisdiction?.type === 'City' || option?.jurisdiction?.type === 'State' ? 'the' : ''} ${jurisdictionTitleTypeBasedOnJurisdictionType(option?.jurisdiction, 'title_type')}`
      return Vue.options.filters.policyTextRephrase(title)
    },
    guide:'STEP 2 OF 2',
    beforeNext: async (policy) => {
      policy.loading = true
      const policyGenerated = await PolicyApiService.store(policy)
      policy.false = true
      const newPolicy = new Policy({...policy,...policyGenerated})
      Store.dispatch('policy/addOrUpdatePolicyToUserOrSharedLists', { policy: newPolicy }, { root: true })
      return newPolicy
    },
  },
]