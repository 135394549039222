<template>
  <BaseModal
    ref="baseModal"
    modal-dialog-classes="w-auto psui-bg-gray-10"
    :show-modal-parent.sync="showModalParent"
    @close="close"
  >
    <PolicyOptionWizard
      ref="wizard"
      :policy="policy"
      :policy-option-title="policyOptionTitle"
      :building-type="buildingType"
      :policy-option="policyOption"
      @register-success="onRegisterSuccess"
    />
  </BaseModal>
</template>

<script>
import PolicyOptionWizard from '../wizard/PolicyOptionWizard.vue'
export default {
  name: 'PolicyOptionWizardModal',
  components: { 
    PolicyOptionWizard
  },
  data: () => ({
    showModalParent: false,
    policy: null,
    policyOptionTitle: null,
    buildingType: null,
    policyOption: null
  }),
  computed: {
    isUserLoggedIn() {
      return this.$store.state.auth.loggedUser
    }
  },
  mounted() {
    this.$eventBus.$on('openPolicyOptionWizardModal', ({policy, policyOption, buildingType}) => {
      this.open({policy, policyOption, buildingType})
    })
    this.$eventBus.$on('closePolicyOptionWizardModal', ()=>{
      this.close()
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openPolicyOptionWizardModal')
    this.$eventBus.$off('closePolicyOptionWizardModal')
  },
  methods: {
    open({policy, policyOption, buildingType}) {
      this.policy = policy || null
      this.policyOptionTitle = policyOption?.title_drawer_create || policyOption?.title || null
      this.buildingType = buildingType || null
      this.policyOption = policyOption || null
      this.showModalParent = true
    },
    close(shouldNavigateToPolicyOptions = true) {
      this.showModalParent = false
      this.$eventBus.$emit('closePolicyOptionDrawer', shouldNavigateToPolicyOptions)
    },
    onRegisterSuccess() {
      this.close(false)
      this.policy = null
    }
  }
}
</script>
