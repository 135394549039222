import {
  annual_bill_savings,
  annual_bill_savings_avg,
  baseline_fuel_type,
  compliance_margin,
  edr1_total_margin,
  emission_savings,
  energy_savings_combined,
  forecast_emissions_savings,
  forecast_initial_cost,
  forecast_kwh_savings,
  forecast_lifecycle_savings,
  forecast_therms_savings,
  forecast_units,
  initial_cost,
  on_bill_cost_ratio_2025,
  lsc_2025_benefit_to_cost_ratio,
  kwh_savings,
  lifecycle_savings,
  on_bill_cost_ratio,
  tdv2022_benefit_to_cost_ratio,
  tdv_benefit_to_cost_ratio,
  therms_savings,
  simple_payback,
  annual_bill_savings_care,
  lifecycle_savings_care,
  subsidy_needed,
  on_bill_cost_ratio_care,
  on_bill_cost_ratio_2025_care,
  simple_payback_care,
  five_years_payback,
  subsidy_needed_care,
  five_years_payback_care,
  city_wide_subsidy_needed,
  city_wide_five_year_payback,
  city_wide_subsidy_needed_care,
  city_wide_five_year_payback_care,
  forecast_energy_savings,
} from '@/modules/app/shared/default-app-columns'

export const POLICY_IMPACT_ALGORITHMS = {
  GENERAL_PRESCRIPTIVE_BY_MEASURE: 'GENERAL_PRESCRIPTIVE_BY_MEASURE',
  GENERAL_PERFORMANCE_BY_FUEL: 'GENERAL_PERFORMANCE_BY_FUEL',
}

export const COST_RATIOS_COLUMNS_KEYS = [
  on_bill_cost_ratio(),
  tdv_benefit_to_cost_ratio(),
  simple_payback(),
  tdv2022_benefit_to_cost_ratio(),
  on_bill_cost_ratio_care(),
  simple_payback_care(),
  on_bill_cost_ratio_2025(),
  lsc_2025_benefit_to_cost_ratio(),
  on_bill_cost_ratio_2025_care(),
].map(c => c.key || null).filter(c => c !== null)

export const PER_HOME_IMPACT_COLUMNS_KEYS = [
  initial_cost(),
  annual_bill_savings_avg(),
  annual_bill_savings(),
  emission_savings(),
  { key: 'emissions_savings_pct' }, // this one is just to make impacts copy the attr
  kwh_savings(),
  therms_savings(),
  lifecycle_savings(),
  compliance_margin(),
  edr1_total_margin(),
  baseline_fuel_type(),
  energy_savings_combined(),
  annual_bill_savings_care(),
  lifecycle_savings_care(),
  // Maybe those must be on cost-effective??
  subsidy_needed(),
  subsidy_needed_care(),
  five_years_payback(),
  five_years_payback_care(),
].map(c => c.key || null).filter(c => c !== null)

export const CITY_WIDE_IMPACT_COLUMNS_KEYS = [
  forecast_units(),
  forecast_emissions_savings(),
  forecast_therms_savings(),
  forecast_kwh_savings(),
  forecast_lifecycle_savings(),
  forecast_initial_cost(),
  forecast_energy_savings(),
  city_wide_subsidy_needed(),
  city_wide_five_year_payback(),
  city_wide_subsidy_needed_care(),
  city_wide_five_year_payback_care(),
].map(c => c.key || null).filter(c => c !== null)

export class BasePolicyImpactAlgorithm {
  constructor(policy, policyContainer, climateZoneRaw) {
    this.policy = policy
    this.policyContainer = policyContainer
    this.climateZoneRaw = climateZoneRaw
  }

  async computeTotalImpact() {
    throw new Error(`computeTotalImpact not implemented on ${this.constructor.name}`)
  }

  async computeResumedCityWideImpact() {
    throw new Error(`computeResumedCityWideImpact not implemented on ${this.constructor.name}`)
  }

  async computeYearlyCityWideImpact() {
    throw new Error(`computeYearlyCityWideImpact not implemented on ${this.constructor.name}`)
  }

  async computeQuarterlyCityWideImpact() {
    throw new Error(`computeQuarterlyCityWideImpact not implemented on ${this.constructor.name}`)
  }

  async computePerHomeImpact() {
    throw new Error(`computePerHomeImpact not implemented on ${this.constructor.name}`)
  }
}
