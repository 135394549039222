<template>
  <div class="always-gray-border title-wrapper flex flex-col py-2 space-y-1 mr-3">
    <div class="title flex items-center">
      <span class="font-bold psui-text-blue-80 psui-text-small my-2">Minimum Building Size ft²</span>
      <PsIcon
        icon="info_outline"
        size="16"
        display="flex"
        class="info-icon psui-text-gray-50 cursor-pointer hover:psui-text-blue-60"
        style="margin-left: 4px; margin-bottom: -1px"
        @click.stop="$eventBus.$emit('openDescriptionModal', { type: 'helper', slug: 'bps_minimum_building_size' })"
      />
    </div>

    <PsInputSelect
      :items="getChartSelectItems"
      :value="getValue"
      layout="mini"
      key-label="title"
      key-value="slug"
      @input="moveToElement"
    />
  </div>
</template>

<script>
function debounce(func, delay) {
  let timeout
  return function (...args) {
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(this, args), delay)
  }
}

export default {
  name: 'BuildingEstimatesNonAndLargeBuildingSizeComparison',
  props: ['categories', 'selectedArea'],
  data: () => ({
    debouncedMax: null,
  }),
  computed: {
    getChartSelectItems() {
      return this.categories.map((cat, index) => {
        if (index == this.categories.length - 1) {
          return {
            title: 'none',
            slug: cat,
          }
        }
        const match = cat.match(/(>?<?\d+(\.\d+)?)/)
        return {
          title: match[0] + 'k',
          slug: cat,
        }
      })
    },
    getValue() {
      const elements = [...document.querySelectorAll('.apexcharts-xaxis > .apexcharts-xaxis-texts-g .apexcharts-text tspan')]

      return elements[this.debouncedMax]?.textContent
    },
  },
  watch: {
    'selectedArea.max': {
      handler(value) {
        this.debounceFunction(value)
      },
      immediate: true,
    },
  },
  methods: {
    getPositionFromSelectedValue(elementInnerText) {
      return [...document.querySelectorAll('.apexcharts-text tspan')]
        .find((element) => element.textContent.trim() === elementInnerText)
        .getBoundingClientRect().right
    },
    moveToElement(elementInnerText) {
      const getBoundingLabelXvalue = this.getPositionFromSelectedValue(elementInnerText)
      this.$eventBus.$emit('moveChartRightAxis', getBoundingLabelXvalue, elementInnerText)
    },
    debounceFunction: debounce(function (value) {
      this.debouncedMax = value
    }, 120),
  },
}
</script>

<style lang="scss" scoped>
$gray-30: #e6ecf2;
$gray-60: #515E6A;

.title-wrapper {
  .info-icon {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  .title {
    position: relative;

    &:hover .info-icon {
      opacity: 1;
    }
  }
}

.always-gray-border {
  ::v-deep {
    .psui-el-input-select {
      &.layout-mini {
        select {
          color: $gray-60;

          &:hover,
          &:active {
            border-color: $gray-30;
          }

          &:focus {
            border-color: $gray-30;
          }
        }

        &.selected {
          .psui-el-input-select-wrapper {
            select {
              border-color: $gray-30 !important;
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
//we have to fix this on ui
.psui-el-input-select.layout-mini .psui-el-input-select-wrapper::after {
  z-index: 10 !important;
  pointer-events: none;
}
</style>
