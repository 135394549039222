var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full h-full flex flex-col",class:{
    'bg-[#e6ecf2]': _vm.$route.name === 'PolicyOptions',
    'psui-bg-gray-10 overflow-y-auto': _vm.$route.name !== 'PolicyOptions'
  },attrs:{"id":"policy-index"}},[_c(_vm.getHeaderComponent,{tag:"div",staticClass:"psui-bg-white psui-shadow-elevation-5",class:[
      { 'sticky top-0 mb-6': !_vm.isPolicyOptionsRoute },
      { 'mb-[19px]': _vm.isPolicyOptionsRoute }
    ]}),_c('PolicyHeaderUserWarnings'),_c('router-view'),_c('ModalDeletePolicy'),_c('ModalDuplicatePolicy'),_c('PolicyUserPermissionDuplicateAlertModal',{on:{"close":function($event){return _vm.$emit('close')}}}),_c('PolicyEditRequirementsDrawer'),_c('DrawerPolicyEditColumns')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }