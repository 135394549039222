<template>
  <div
    data-test-id="sidebar-default-navigation-main"
    class="psui-w-full psui-flex psui-flex-col"
    :class="!isFeatureFlagEnabled(['PS_TEMPLATE_DESIGN']) ? 'pt-6' : 'pt-3'"
  >
    <ul
      class="app-navigation--main "
      :class="{'md:psui-mb-8':!isFeatureFlagEnabled(['PS_TEMPLATE_DESIGN'])}"
    >
      <li
        v-feature-flag="['PS_TEMPLATE_DESIGN']"
        class="psui-font-bold psui-text-accentSmall psui-uppercase psui-text-blue-50 psui-px-5 psui-mb-2 psui-hidden md:psui-block"
      >
        Explore Data
      </li>

      <li v-feature-flag="['PS_TEMPLATE_DESIGN']">
        <router-link 
          :to="{ name: 'Summary', params: { jurisdiction_slug } }"
          :class="$route.name === 'Summary' ? 'router-link-exact-active' : ''"
          class="psui-flex psui-items-center psui-space-x-2 psui-transition psui-duration-300 psui-ease-in-out psui-text-blue-20 hover:psui-text-blue-50"
        >
          <PsIcon
            icon="location_on"
            size="22"
            class="icon"
          />
          <span>Summary</span>
        </router-link>
      </li>
        
      <li>
        <router-link 
          :to="{ name: 'BuildingEstimates', params: { jurisdiction_slug } }"
          :class="$route.name === 'BuildingEstimates' ? 'router-link-exact-active' : ''"
          class="psui-flex psui-items-center psui-space-x-2 psui-transition psui-duration-300 psui-ease-in-out psui-text-blue-20 hover:psui-text-blue-50"
        >
          <PsIcon
            icon="equalizer"
            size="22"
            class="icon"
          />
          <span>Building Estimates</span>
        </router-link>
      </li>

      <li>
        <router-link 
          :to="getStudyResultsRoute({ jurisdiction_slug, queryRoute })" 
          :class="$route.name === 'StudyResults' ? 'router-link-exact-active' : ''"
          class="psui-flex psui-items-center psui-space-x-2 psui-transition psui-duration-300 psui-ease-in-out psui-text-blue-20 hover:psui-text-blue-50 forecast-results"
        >
          <PsIcon
            icon="document_scanner"
            size="22"
            class="icon"
          />
          <span>{{ isFeatureFlagEnabled(['PS_TEMPLATE_DESIGN']) ? 'Results' : 'Study Results' }}</span>
        </router-link>
      </li>
    </ul>

    <ul class="app-navigation--main md:psui-mb-8">
      <li
        v-feature-flag="['PS_TEMPLATE_DESIGN']"
        class="psui-font-bold psui-text-accentSmall psui-uppercase psui-text-blue-50 psui-px-5 psui-mb-2 psui-hidden md:psui-block"
      >
        {{ 'Policy Design' | policyTextRephrase('small') }}
      </li>
      <li>
        <router-link
          data-test-id="sidebar-default-navigation-my-policies-link"
          :to="{ path: '/policies', query: {...$route.query} }"
          :class="$route.name === 'PoliciesList' || $route.name === 'PolicyShow' ? 'router-link-exact-active' : ''"
          class="psui-flex psui-items-center psui-space-x-2 psui-transition psui-duration-300 psui-ease-in-out psui-text-blue-20 hover:psui-text-blue-50"
        >
          <PsIcon
            icon="topic"
            size="22"
            class="icon"
          />
          <span>{{ 'My Policies' | policyTextRephrase('small') }}</span>
        </router-link>
      </li>
      <li>
        <router-link
          data-test-id="sidebar-default-navigation-policy-option-link"
          :to="{ name: 'PolicyOptions' }"
          :class="$route.name === 'PolicyOptions' ? 'router-link-exact-active' : ''"
          class="psui-flex psui-items-center psui-space-x-2 psui-transition psui-duration-300 psui-ease-in-out psui-text-blue-20 hover:psui-text-blue-50 forecast-results"
        >
          <PsIcon
            icon="explore"
            size="22"
            class="icon"
          />
          <span>{{ 'Policy Options' | policyTextRephrase('small') }}</span>
        </router-link>
      </li>

      <li v-feature-flag="['PS_TEMPLATE_DESIGN']">
        <router-link
          data-test-id="sidebar-default-navigation-comparison-link"
          :to="{
            name: 'ComparisonList',
          }"
          :class="($route.name === 'ComparisonList' || $route.name == 'ComparisonShow') ? 'router-link-exact-active' : ''"
          class="psui-flex psui-items-center psui-space-x-2 psui-transition psui-duration-300 psui-ease-in-out psui-text-blue-20 hover:psui-text-blue-50"
        >
          <PsIcon
            icon="dashboard"
            size="22"
            class="icon"
          />
          <span>{{ 'Policy Comparison' | policyTextRephrase('small') }}</span>
        </router-link>
      </li>

      <li
        v-check-env="['development']"
        class="psui-w-full psui-flex-col md:psui-mt-6"
      >
        <p class="psui-text-accentSmall psui-uppercase text-red psui-mb-2 psui-pl-5 psui-hidden md:psui-flex">
          Available only in dev
        </p>
        <router-link 
          to="/admin/login"
          class="psui-flex psui-items-center psui-space-x-2 psui-transition psui-duration-300 psui-ease-in-out psui-text-blue-20 hover:psui-text-blue-50"
        >
          <PsIcon
            icon="admin_panel_settings"
            size="22"
            class="icon"
          />
          <span>Login Admin</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { isFeatureFlagEnabled } from '@/util/Functions.js'

export default {
  name: 'SidebarDefaultNavigationMain',
  data:()=>({
    STUDY_TYPES_DB_SLUGS,
    isFeatureFlagEnabled
  }),
  computed: {
    jurisdiction_slug() {
      if (this.$route.params.jurisdiction_slug) {
        return this.$route.params.jurisdiction_slug
      } else if (this.lastJurisdictionVisited && this.lastJurisdictionVisited?.slug) {
        return this.lastJurisdictionVisited.slug
      } else {
        return null
      }
    },
    sidebarPolicyTooltipShouldHide() {
      return this.$store.state.general.sidebarPolicyTooltipShouldHide
    },
    getUser() {
      return this.$store.state.auth.loggedUser
    },
    queryRoute(){
      return !this.$route.query.only_study_type && !this.lastStudyTypeVisited ? STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS : this.lastStudyTypeVisited
    },
    ...mapGetters(['lastJurisdictionVisited','lastStudyTypeVisited']),
  },
}
</script>

<style lang="scss" scoped>
  ul {
    > li {
      width: 100%;
      white-space: nowrap;

      > a {
        padding: 7px 10px 7px 20px;
        font-size: 15px;

        span { 
          display: none;

          @media (min-width: 768px) {
            display: block;
          }
        }
      }
      
      a.router-link-exact-active {
        font-weight: bold;
        background-color: #003548;
        color: #64B5CE;

        .icon {
          color: #64B5CE;
        }
      }
      
      ul {
        padding-left: 30px;
        padding-top: 12px;

        li {
          min-height: 21px;
          padding-bottom: 6px;
        }
      }
    }
  }
</style>
