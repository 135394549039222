<template>
  <div
    v-if="climateZones"
    class="policy-container-requirements-by-vintage psui-space-y-4"
  >
    <div
      v-for="(climate_zone, climate_zone_index) in climateZones"
      :key="`climate_zone_${climate_zone.id}`"
      class="psui-w-full psui-flex psui-items-center psui-bg-gray-20 psui-py-4 psui-px-6 psui-rounded-md"
    >
      <div
        style="min-width: 190px"
        class="psui-flex-shrink-0"
      >
        <h5 class="psui-text-h6 psui-text-gray-70 psui-font-bold">
          Climate Zone {{ climate_zone.prefix }}
        </h5>
      </div>
      <div class="w-full flex flex-wrap gap-6">
        <div
          v-for="(type_vintage, type_vintage_index) in typeVintages"
          :key="`type_vintage_${type_vintage.id}`"
          class="flex-1 flex flex-col space-y-3"
          :class="{'hidden':!checkTypeVintage(type_vintage)}"
        >
          <div                 
            v-if="checkTypeVintage(type_vintage) && checkPolicyVintages({custom_combinations:policyContainer.custom_combinations,type_vintage,climate_zone_raw:climate_zone.raw,flexible_path_setup, type_prototype_id:policyContainer.type_prototype_id})"            
            v-tooltip="getTooltip(climate_zone_index, type_vintage_index)"
            class="vintage-card psui-w-full psui-h-auto psui-text-gray-70 psui-rounded-md psui-bg-white psui-shadow-elevation-5 psui-relative psui-transition-all opacity-100-all-childrens-on-hover"
            :class="{ 'hover:text-deepsky-450 cursor-pointer': getterUserCanEditPolicy }"
            @mouseenter="showTooltip = { climate_zone_index, type_vintage_index }" 
            @mouseleave="showTooltip = null"
          >
            <div
              class="w-full h-full justify-center px-6 py-7 psui-cursor-pointer"
              @click="openExistingBuildingRequirementsDrawer({ climate_zone_raw: climate_zone.raw, type_vintage })"
            >
              <div class="w-8 float-left">
                <div class="flex items-center justify-center h-6 w-6 rounded-full bg-deepsky-200">
                  <feather
                    v-tooltip="{ content: 'This vintage is in your policy.', placement: 'bottom-center' }"
                    class="text-deepsky-450"
                    type="check"
                    size="16"
                    @mouseenter="showTooltip = null" 
                    @mouseleave="showTooltip = { climate_zone_index, type_vintage_index }"
                  />
                </div>
              </div>
              <div class="psui-shrink-0 text-14 font-bold float-left half-width">
                {{ type_vintage.title }}
              </div>
            </div>

            <div
              class="absolute right-0 top-0 h-full flex flex-col justify-center space-y-1 pr-4"
            >
              <button
                v-if="getterUserCanEditPolicy"
                class="text-ash-300 transition-all hover:text-deespky-500 action-button cursor-pointer"
                @mouseenter="showTooltip = null" 
                @mouseleave="showTooltip = { climate_zone_index, type_vintage_index }"
                @click="openExistingBuildingRequirementsDrawer({ climate_zone_raw: climate_zone.raw, type_vintage })"
              >
                <feather
                  type="edit"
                  size="16"
                />
              </button>
              <button
                v-if="getterUserCanEditPolicy"
                class="text-ash-300 transition-all hover:text-deespky-500 action-button cursor-pointer"
                @mouseenter="showTooltip = null" 
                @mouseleave="showTooltip = { climate_zone_index, type_vintage_index }"
                @click="openPolicyDeleteCustomCombination({ climate_zone_raw: climate_zone.raw, type_vintage, flexible_path_setup, type_prototype_id: policyContainer.type_prototype_id })"
              >
                <feather
                  type="trash"
                  size="16"
                />
              </button>
            </div>
          </div>
          <ExistingBuildingsPolicyScopeEmptyCell
            v-else-if="checkTypeVintage(type_vintage)"
            :type_prototype_id="policyContainer.type_prototype_id"
            :type_vintage="type_vintage"
            :climate_zone="climate_zone"
            :policy-container="policyContainer"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExistingBuildingsPolicyScopeEmptyCell from './ExistingBuildingsPolicyScopeEmptyCell.vue'
import PolicyExistingBuildingsWithFlexiblePathRequirements from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyExistingBuildingsWithFlexiblePathRequirements.vue'
import {checkPolicyVintages} from '@/util/Helpers.js'
import { createTooltipOptions } from '@/util/Functions.js'

export default {
  name: 'PolicyContainerRequirementsByVintage',
  components: { ExistingBuildingsPolicyScopeEmptyCell },
  props: ['policyContainer','flexible_path_setup'], 
  data:()=>({
    disabled: true,
    checkPolicyVintages,
    showTooltip: null
  }),
  computed: {
    climateZones() {
      return this.getPolicy?.jurisdiction?.climate_zones ?? null
    },
    getPolicy() {
      return this.$store.getters['policy/getterUserPolicy']({ id: this.policyContainer.policy_id })
    },
    customCombinations() {
      return this.policyContainer.custom_combinations ?? []
    },
    typeVintages() {
      return this.$store.getters['globalEntities/TypeVintage/getterGlobalTypeVintages']()
    },
    getterUserCanEditPolicy() {
      return this.$store.getters['policy/getterUserCanEditPolicy'](this.$route.params.policy_id)
    },
  },
  methods: {
    checkTypeVintage(type_vintage){
      return !!this.getLatestPublishedStudWithValidPrototypeAndVintageByTypePrototypeTypeVintageAndStudyType({ type_prototype_id: this.policyContainer.type_prototype_id, type_vintage: type_vintage})
    },
    getCustomCombination({ type_vintage, climate_zone_raw }) {
      const vintage_ids_from_type_vintage = this.$store.getters['globalEntities/Vintage/getterGlobalVintages']({ type_vintage_id : type_vintage.id }).map(v => v.id)

      return this.policyContainer.custom_combinations.findInArray({ climate_zone_raw, vintage_id: vintage_ids_from_type_vintage  })
    }, 
    openExistingBuildingRequirementsDrawer({ climate_zone_raw, type_vintage }) {
      const customCombination = this.getCustomCombination({ climate_zone_raw, type_vintage })     
      this.$eventBus.$emit('openDrawerPolicyEditRequirements', { 
        component_name: PolicyExistingBuildingsWithFlexiblePathRequirements.name,
        custom_combination_id : customCombination.id,
      })
    },
    async openPolicyDeleteCustomCombination({ climate_zone_raw, type_vintage, flexible_path_setup, type_prototype_id }) {
      if(!this.getterUserCanEditPolicy) return

      const customCombination = this.getCustomCombination({ climate_zone_raw, type_vintage })
      this.$eventBus.$emit('openModalDeleteCustomCombination', { custom_combination_id: customCombination.id, type_vintage, climate_zone_raw, flexible_path_setup, type_prototype_id })
    },
    getTooltip(climateZoneIndex, typeVintageIndex) {
      const condition = this.showTooltip && this.showTooltip.climate_zone_index === climateZoneIndex && this.showTooltip.type_vintage_index === typeVintageIndex      
      const content = 'Click to view and edit requirements'
      return createTooltipOptions(condition, content)
    }
  }
}
</script>

<style lang="scss" scoped>
.vintage-card {
  min-height: 75px;
}

.action-button:hover {
  color:rgba(105, 167, 191, 1);
}
</style>