var render = function render(){var _vm=this,_c=_vm._self._c;return _c('PsAccordionItem',{staticClass:"display-impact-assumption-icon",class:{ 'form-dirty' : _vm.hasValueUpdated(_vm.getSingleInputValue(_vm.assumption.grid_max_renewables_level), _vm.assumption.grid_max_renewables_level) ||
    _vm.hasValueUpdated(_vm.getSingleInputValue(_vm.assumption.grid_max_renewables_year), _vm.assumption.grid_max_renewables_year) 
  },attrs:{"title":"Clean Electricity Progress"},scopedSlots:_vm._u([{key:"header-additionals",fn:function(){return [_c('PsIcon',{staticClass:"help psui-items-center psui-h-5 hover:psui-text-blue-60",attrs:{"icon":"help_outline","size":"16","display":"flex","icon-classes":_vm.hasValueUpdated(_vm.getSingleInputValue(_vm.assumption.grid_max_renewables_level), _vm.assumption.grid_max_renewables_level) ||
        _vm.hasValueUpdated(_vm.getSingleInputValue(_vm.assumption.grid_max_renewables_year), _vm.assumption.grid_max_renewables_year) ? 'mr-5': ''},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$eventBus.$emit('openDescriptionModal', { slug: 'impact_card_clean_electricity_progress_assumption', ..._vm.descriptionModelObject })}}})]},proxy:true},{key:"default",fn:function(){return [_c('span',[_vm._v(" Electricity from the grid will transition steadily away from fossil fuel sources to renewable or zero-emission power sources. We assume "),_c('b',[_vm._v("a maximum clean energy share")]),_vm._v(" will be achieved by "),_c('b',[_vm._v("a certain year")]),_vm._v(". ")]),_c('div',{staticClass:"flex justify-between mt-4/5 items-center"},[_c('span',{staticClass:"psui-text-small psui-text-gray-60"},[_vm._v("Leveling off at a"),_c('br'),_vm._v(" maximum of")]),_c('PsInput',{staticClass:"input-grid-max-renewables-level",class:{'selected':_vm.hasValueUpdated(_vm.getSingleInputValue(_vm.assumption.grid_max_renewables_level), _vm.assumption.grid_max_renewables_level) &&
          _vm.inputErrorIndex != _vm.assumption.grid_max_renewables_level},attrs:{"data-test-id":`input-assumption-${_vm.assumption.grid_max_renewables_level}`,"layout":"mini","has-error":_vm.inputErrorIndex == _vm.assumption.grid_max_renewables_level ? 'Insert a valid value' : false,"value":_vm.getSingleInputValue(_vm.assumption.grid_max_renewables_level)},on:{"input":function($event){$event.stopPropagation();return _vm.onInput($event,_vm.assumption.grid_max_renewables_level)},"change":_vm.onChange,"blur":function($event){return _vm.onBlur(_vm.assumption.grid_max_renewables_level)},"mouseenter":function($event){_vm.showResetButton = _vm.assumption.grid_max_renewables_level},"mouseleave":function($event){_vm.showResetButton = false}},nativeOn:{"click":function($event){return _vm.checkIfUserCanEditAssumption.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(!_vm.hasValueUpdated(_vm.getSingleInputValue(_vm.assumption.grid_max_renewables_level), _vm.assumption.grid_max_renewables_level) ||
              _vm.showResetButton !== _vm.assumption.grid_max_renewables_level)?_c('span',{staticClass:"psui-text-gray-50 psui-text-xsmall"},[_vm._v("%")]):(_vm.showResetButton === _vm.assumption.grid_max_renewables_level)?_c('PsTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('PsIcon',{attrs:{"icon":"restart_alt","icon-classes":"psui-text-green-20 cursor-pointer mt-1","size":"17"},nativeOn:{"click":function($event){return _vm._doResetAssumption(_vm.assumption.grid_max_renewables_level)}}})]},proxy:true},{key:"content",fn:function(){return [_c('h1',{staticClass:"mb-1"},[_vm._v(" Restore to default value ")]),_c('span',{staticClass:"psui-font-normal"},[_vm._v(_vm._s(_vm.getBaseValue(_vm.assumption.grid_max_renewables_level)))])]},proxy:true}])}):_vm._e()]},proxy:true}])})],1),_c('div',{staticClass:"flex justify-between mt-5"},[_c('span',{staticClass:"psui-text-small psui-text-gray-60 mt-2"},[_vm._v("By the year")]),_c('PsInput',{class:{'selected':_vm.hasValueUpdated(_vm.getSingleInputValue(_vm.assumption.grid_max_renewables_year), _vm.assumption.grid_max_renewables_year) && 
          _vm.inputErrorIndex != _vm.assumption.grid_max_renewables_year},attrs:{"data-test-id":`input-assumption-${_vm.assumption.grid_max_renewables_year}`,"layout":"mini","has-error":_vm.inputErrorIndex == _vm.assumption.grid_max_renewables_year ? 'Insert a valid value' : false,"value":_vm.getSingleInputValue(_vm.assumption.grid_max_renewables_year),"hint":`${_vm.minGridMaxRenewablesYearValue} - ${_vm.maxGridMaxRenewablesYearValue}`},on:{"input":function($event){$event.stopPropagation();return _vm.onInput($event,_vm.assumption.grid_max_renewables_year)},"change":_vm.onChange,"blur":function($event){return _vm.onBlur(_vm.assumption.grid_max_renewables_year)},"mouseenter":function($event){_vm.showResetButton = _vm.assumption.grid_max_renewables_year},"mouseleave":function($event){_vm.showResetButton = false}},nativeOn:{"click":function($event){return _vm.checkIfUserCanEditAssumption.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.hasValueUpdated(_vm.getSingleInputValue(_vm.assumption.grid_max_renewables_year), _vm.assumption.grid_max_renewables_year) && 
              _vm.showResetButton === _vm.assumption.grid_max_renewables_year)?_c('PsTooltip',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('PsIcon',{attrs:{"icon":"restart_alt","icon-classes":"psui-text-green-20 cursor-pointer mt-1","size":"17"},nativeOn:{"click":function($event){return _vm._doResetAssumption(_vm.assumption.grid_max_renewables_year)}}})]},proxy:true},{key:"content",fn:function(){return [_c('h1',{staticClass:"mb-1"},[_vm._v(" Restore to default value ")]),_c('span',{staticClass:"psui-font-normal"},[_vm._v(_vm._s(_vm.getBaseValue(_vm.assumption.grid_max_renewables_year)))])]},proxy:true}],null,false,2371101405)}):_vm._e()]},proxy:true}])})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }