<template>
  <div class="psui-flex psui-flex-col psui-h-full">
    <RenderHtmlExtended
      :html="policyOption.description | policyTextRephrase"
      class="psui-flex-grow psui-overflow-y-auto psui-w-full psui-px-12 psui-text-small psui-text-gray-50 psui-space-y-4"
    />
    <div class="psui-w-full psui-py-4 psui-px-12 psui-bg-white psui-shadow-elevation-30 psui-border psui-border-gray-20">
      <PsTooltip
        position="top"
        class="psui-flex psui-flex-row"
        :ignore-dialog="!policyOptionDisabledMessage"
      >
        <template #trigger>
          <PsButton
            label="Customize"
            size="big"
            icon="keyboard_double_arrow_right"
            icon-position="right"
            class="customize-about-button"
            :disabled="policyOptionDisabledMessage != null"
            @click="nextStep"
          />
        </template>
        <template
          v-if="policyOptionDisabledMessage != null"
          #content
        >
          <div class="disabled-message-wrapper">
            {{ policyOptionDisabledMessage | policyTextRephrase }}
          </div>
        </template>
      </PsTooltip>
    </div>
  </div>
</template>

<script>
import RenderHtmlExtended from '@/components/general/RenderHtmlExtended.vue'

export default {
  name: 'PolicyOptionAbout',
  components:{RenderHtmlExtended},
  props:['policyOption', 'policyOptionDisabledMessage'],
  methods:{
    nextStep(){
      this.$emit('nextStep', true)
    }
  }
}
</script>
<style lang="scss" scoped>
  .disabled-message-wrapper {
    max-width: 180px;
    word-wrap: break-word;
  }
</style>