<template>
  <div class="loader-screen absolute flex top-0 left-0 items-center place-content-center w-full h-full z-60">
    <LoaderSpinner />
  </div>
</template>

<script>
import LoaderSpinner from '@/components/general/LoaderSpinner.vue'

export default {
    name: 'BuildingEstimatesLoadingScreen',
    components:{LoaderSpinner},
  }
</script>

<style lang="scss">
  .loader-screen {
    background: rgba(255, 255, 255, 0.5);
  }
</style>