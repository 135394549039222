<template>
  <BaseModal
    ref="baseModal"
    title="Specify assumptions by building type and climate zone"
    @close="close"
  >
    <div class="flex flex-col mt-4 psui-text-gray-50 gap-6">
      <span class="psui-text-p">
        {{ description }}
      </span>
      <div class="flex items-center">
        <PsCheckboxSimple label="Don't show me this next time" />
      </div>  
      <div class="flex gap-1">
        <PsButton
          label="Explore Policy Options"
          size="big"
          @click="goToExplorePolicyOptions"
        />
        <PsButton
          label="Cancel"
          size="big"
          layout="onlytext"
          @click="close()"
        />
      </div>
    </div>
  </BaseModal>
</template>

<script>

export default {
    name:"AssumptionsCreatePolicyModal",
    data:()=>({
      study_type:null,
      description: null
    }),
    mounted(){
        this.$eventBus.$on('openAssumptionsCreatePolicyModal',({study_type, description})=>{
            this.description = description
            this.study_type = study_type
            this.$refs.baseModal.showModal = true
        })
        this.$eventBus.$on('closeAssumptionsCreatePolicyModal', this.close)
    },
    beforeDestroy(){
        this.$eventBus.$off('openAssumptionsCreatePolicyModal')
        this.$eventBus.$off('closeAssumptionsCreatePolicyModal')
    },
    methods:{
      close(){
          this.$refs.baseModal.showModal = false
      },
      goToExplorePolicyOptions(){
        this.$store.dispatch('setUserLastPolicyOptionSelected', {
          context:'updateLastPolicyOptionSelectedFromPolicyOptions',
          value: [this.$route.query.only_study_type]
        })

        this.$router.push({ name: 'PolicyOptions' })

        this.close()
      }
    }
}
</script>

<style>

</style>