<template>
  <div
    v-if="studyTypes.length > 0"
    class="flex items-center mb-2 space-x-2"
  >
    <span class="psui-text-small psui-text-blue-70 font-normal">Show :</span>
    <div
      v-for="studyType in studyTypes"
      :key="`study-type-${studyType.id}`"
      :class="[
        'check-box flex h-[26px] py-0 pr-2 pl-1.5 items-center gap-2 rounded',
        isStudyTypeSelected(studyType.slug) ? 'bg-[#e0eff6]' : 'bg-[#f3f6f9]',
        !isStudyTypeSelected(studyType.slug) ? 'not-selected' : ''
      ]"
    >
      <PsCheckboxSimple
        :input-attrs="{
          id: `policy-option-filter-study-type-${studyType.id}`,
          class: 'w-[14px] h-[14px]',
        }"
        size="small"
        :checked="isStudyTypeSelected(studyType.slug)"
        @change="toggleStudyTypeSelection(studyType, $event)"
      >
        <template #content>
          <span
            :class="[
              'text-sm font-normal leading-tight',
              isStudyTypeSelected(studyType.slug) ? 'text-[#318FAC]' : 'text-[#798490]'
            ]"
          >
            {{ studyType.title }}
          </span>
        </template>
      </PsCheckboxSimple>
    </div>
  </div>
</template>

<script>
import { studyTypes } from '@/util/Enums'

export default {
  name: 'PolicyOptionsFilter',
  computed: {
    studyTypes() {
      const originalStudyTypes = this.$store.getters['globalEntities/StudyType/getterGlobalStudyTypes']()

      return originalStudyTypes.map((studyType) => {
        let newTitle = studyType.title

        if (studyType.title === studyTypes.EXISTING_BUILDINGS) {
          newTitle = 'Existing Building Options'
        } else if (studyType.title === studyTypes.NEW_BUILDINGS) {
          newTitle = 'New Building Options'
        }

        return {
          ...studyType,
          title: newTitle,
        }
      })
    },
    getPolicyOptionTypeUserPreference() {
      return this.$store.getters['getterUserPrefferenceByPath']('policies.policyOptions.filterByStudyType')
    },
  },
  methods: {
    isStudyTypeSelected(studyTypeSlug) {
      return this.getPolicyOptionTypeUserPreference.includes(studyTypeSlug)
    },
    toggleStudyTypeSelection(studyType, event) {
      let filter = [...this.getPolicyOptionTypeUserPreference]
      const index = filter.indexOf(studyType.slug)

      if (event.target.checked) {
        if (index === -1) {
          filter.push(studyType.slug)
        }
      } else {
        if (index !== -1) {
          filter.splice(index, 1)
        }
      }

      this.$store.dispatch('setUserLastPolicyOptionSelected', {
        context: 'updateLastPolicyOptionSelectedFromSummary',
        value: filter,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.check-box {
  ::v-deep .psui-el-checkbox.size-small .psui-el-checkmark span {
    margin-left: 6px;
  }

  &.not-selected {
    ::v-deep .psui-el-checkbox.size-small .psui-el-checkmark::before {
      color: #d6dde5;
    }
  }
}
</style>
