<template>
  <div>
    <p class="jurisdiction psui-text-p text-accent-2 uppercase color psui-text-blue-60 absolute top-0 left-0 pt-6">
      {{ lastJurisdictionVisited | editJurisdictionTitleTypeBasedOnJurisdictionType('title_type') }}
    </p>
    <h1 class="text-center mt-6 psui-text-h5 font-bold psui-text-gray-80">
      {{ 'Save custom building data to a policy' | policyTextRephrase }}
    </h1>
    <h2 class="psui-text-gray-50 psui-text-small text-center mt-3 mb-6">
      {{ 'Policy impact forecasts will reflect the updated building data you provided.' | policyTextRephrase }}
    </h2>

    <div class="table-container">
      <table class="table">
        <thead>
          <tr>
            <th class="psui-text-gray-80 psui-text-small font-bold">
              Policy name
            </th>
            <th class="psui-text-gray-80 psui-text-small font-bold">
              Last updated
            </th>
            <th class="psui-text-gray-80 psui-text-small font-bold">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="policy in getUserPoliciesByJurisdictionAndFilteredByOpenedFrom"
            :key="policy.id"
          >
            <td
              class="psui-text-small"
              :class="checkPolicyEquality(policy) ? 'psui-text-gray-40': 'psui-text-gray-60'"
            >
              {{ policy.title }}
            </td>
            <td class="psui-text-gray-60 psui-text-small">
              {{ formatDate({ date: policy.updated_at}) }}
            </td>
            <td>
              <div class="flex space-x-2 justify-center relative has-icon">
                <div 
                  v-if="loadings[policy.id] === true"
                  class="loader"
                />
                <PsIcon
                  v-if="loadings[policy.id] === undefined && isPolicyWithCurrentCustomData(policy)"
                  icon="done"
                  color="psui-text-green-20"
                  class="inline h-7 icon-center"
                />
                <PsButton
                  v-if="isPolicyWithCurrentCustomData(policy) || !isPolicyWithCustomData(policy)"
                  :disabled="loadings[policy.id] === true || isPolicyWithCurrentCustomData(policy)"
                  label="Save"
                  size="small"
                  class="pl-4 pr-4 inline"
                  @click="save(policy)"
                />
                <PsButton
                  v-else
                  label="Update"
                  size="small"
                  class="pl-4 pr-4 inline"
                  :disabled="loadings[policy.id] === true"
                  @click="save(policy)"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="footer flex space-x-4 justify-between mt-6 items-center">
      <div>
        <h3 class="psui-text-gray-50 psui-text-small font-bold">
          You may also <span
            class="inline-block ml-1 psui-text-blue-60 cursor-pointer"
            @click="goToPolicyOptionsPage"
          >{{ 'Create a new policy' | policyTextRephrase }}</span>
        </h3>
      </div>
      <div>
        <PsButton 
          label="Finish and close"
          size="medium"
          @click="closeModalAssumptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DataAndObjectFunctions from '@/util/DataAndObjectFunctions'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'

export default {
  name: 'AssumptionsModalSaveToPolicy',
  props: ['opened_from'],
  data: () => ({
    loadings: {}
  }),
  computed: {
    ...mapGetters(['lastJurisdictionVisited']),
    ...mapGetters('policy', ['getterUserPoliciesByJurisdiction']),
    getCurrentCustomBuildingStockOrBuildingEstimates() {
      const policy_id = (this.$route.params.policy_id) ? this.$route.params.policy_id : null
      return this.getAllCustomBuildingStockOrBuildingEstimates.filter((cbs) => cbs.policy_id == policy_id )
    },
    getAllCustomBuildingStockOrBuildingEstimates() {
      return this.opened_from == STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS ?
        this.$store.state.assumptions.buildingStocks.custom_building_stocks :
        this.$store.state.assumptions.buildingEstimates.custom_building_estimates
    },
    getUserPoliciesByJurisdictionAndFilteredByOpenedFrom(){
      const policiesFilteredAndSorteByPolicySelected = this.getterUserPoliciesByJurisdiction.filter( policy => {
        const getPolicyType = this.$store.getters['policy/getterPolicyStudyTypes']({policy_id: policy.id,policy})
        return getPolicyType.includes(this.opened_from) 
      }).sort((a,b)=>{
        if(a.id == this.getPolicySelected?.id || b.id == this.getPolicySelected?.id) {
          return -1
        }
        return  0
      })

      return policiesFilteredAndSorteByPolicySelected
    },
    getPolicySelected() {
      return this.$store.getters['assumptions/getterPolicySelected'] 
    },
  },
  methods: {
    ...mapActions('assumptions/buildingStocks',['addCustomBuildingStocksToPolicy']),
    ...mapActions('assumptions/buildingEstimates',['addCustomBuildingEstimatesToPolicy']),
    getPolicyCustomDataByOpenedFrom(policy) {
      return this.opened_from == STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS ? 
      this.$store.getters['assumptions/buildingStocks/getterCustomBuildingStockByPolicyId'](policy.id) :
      this.$store.getters['assumptions/buildingEstimates/getterCustomBuildingEstimateByPolicyId'](policy.id)
    },
    isPolicyWithCustomData(policy) {
      return this.getPolicyCustomDataByOpenedFrom(policy).length > 0
    },
    isPolicyWithCurrentCustomData(policy) {
      
      const policyCustomData = this.getPolicyCustomDataByOpenedFrom(policy)
      const checkData = () => {
        let allCustomDataIsSame = true
        this.getCurrentCustomBuildingStockOrBuildingEstimates.forEach((item) => {
          const cbs_keys = this.opened_from == STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS ? 
          ['climate_zone_prefix', 'jurisdiction_id', 'type_prototype_id', 'type_vintage_id', 'units'] :
          ['climate_zone_prefix', 'jurisdiction_id', 'type_prototype_id', 'year']
          
          const filters = DataAndObjectFunctions.filterObjectKeys(cbs_keys, item)
          if (!policyCustomData.findInArray(filters)) {
            allCustomDataIsSame = false
          }
        })
        return allCustomDataIsSame
      }
      
      return this.getCurrentCustomBuildingStockOrBuildingEstimates.length === policyCustomData.length && checkData()
    },
    save(policy) {
      this.loadings[policy.id] = true
      this.$forceUpdate()

      const getActionByOpenedFrom = {
        [STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS]: this.addCustomBuildingStocksToPolicy,
        [STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS]: this.addCustomBuildingEstimatesToPolicy
      }
      const getObjectByOpenedFrom = {
        [STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS]:'current_custom_building_stocks',
        [STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS]:'current_custom_building_estimates'
      }

      getActionByOpenedFrom[this.opened_from]({
        [getObjectByOpenedFrom[this.opened_from]]:this.getCurrentCustomBuildingStockOrBuildingEstimates,
        policy_id:policy.id
      })
      .then(() => {
        setTimeout(() => {
          delete this.loadings[policy.id]
          this.$forceUpdate()
        }, 1000)
      })
    },
    goToPolicyOptionsPage() {
      if (
        this.$route.name !== 'PolicyOptions'
      ) {

        this.$store.dispatch('setUserLastPolicyOptionSelected', {
          context:'updateLastPolicyOptionSelectedFromSummary',
          value: [this.opened_from]
        })

        this.$store.commit('assumptions/toggleDrawer',{})

        this.$router.push({ name: 'PolicyOptions' })

        this.closeModalAssumptions()
      } 
    },
    setUserLastStudyTypeVisited() {
      this.$store.dispatch('setUserLastStudyTypeVisited', {
        value: this.opened_from,
        context: 'updateLastStudyTypeVisitedFromAssumptionsResidentialExisting',
      })
    },
    closeModalAssumptions() {
      this.$eventBus.$emit('closeModalAssumptions')
    },
    checkPolicyEquality(policy){
      return this.getPolicySelected?.id == policy?.id
    }
  },
}
</script>

<style lang="scss" scoped>
$border-color: rgba(0, 70, 95, 0.1);

.table-container {
  width: 100% !important;
  overflow: hidden;
  overflow-y: scroll;
  max-height: calc(100vh - 9rem - 205px);
  position: relative;
  border-radius: 10px;
  border: 1px solid $border-color;
  z-index: 100;
 
  &::-webkit-scrollbar {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }


  .table {
    margin-bottom: 0 !important;
    thead {
      background: white;
      position: -webkit-sticky;
      z-index: 50;
      position: sticky;
      top: 0;
      border: none !important;

      tr {
        border: none !important;

        th {
          border: none !important;
          border-bottom: none !important;
          box-shadow: inset 0 -1px 0 $border-color;
          padding-top: 15px;
          padding-bottom: 15px;

          &:first-of-type {
            padding-left: 1rem;
          }

          &:nth-child(2) {
            width: 120px;
          }

          &:last-of-type {
            padding-right: 1rem;
            text-align: center !important;
            width: 150px !important;
          }
        }
      }
    }

    tbody {

      tr {
        td {
          border-top: none !important;
          border-bottom: 1px solid $border-color;
          background-color: #f3f6f9;
          vertical-align: middle;
          
          &:first-of-type {
            padding-left: 18px;
          }

          &:last-of-type {
            padding-right: 0;
          }
        }

        &:last-of-type {
          td {
            border-bottom: none !important;
          }
        }
      }
    }
  }
}

.jurisdiction {
  font-weight: 700;
  padding-left: 2.5rem;
}

.footer {
  margin-top: 2.5rem;
}

.loader {
  border: 2px solid $border-color;
  border-top: 2px solid #318fac;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.icon-center::v-deep,
.loader {
  position: absolute;
  justify-content: center;
  left: -5px;
  top: 4px;
  display: block !important;

  span {
    display: flex;
    align-items: center;
    font-size: 18px;
  }

  &.icon-center {
    width: 100%;
  }
}

td > div > .psui-el-button::v-deep {
  min-width: 70px;
  margin-left: 0;
}
</style>
