import { render, staticRenderFns } from "./ModalJurisdictionSelection.vue?vue&type=template&id=6243bbb1&scoped=true"
import script from "./ModalJurisdictionSelection.vue?vue&type=script&lang=js"
export * from "./ModalJurisdictionSelection.vue?vue&type=script&lang=js"
import style0 from "./ModalJurisdictionSelection.vue?vue&type=style&index=0&id=6243bbb1&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6243bbb1",
  null
  
)

export default component.exports