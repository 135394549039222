<template>
  <div class="flex items-center space-x-2">
    <PsButton
      label="View Assumptions"
      icon="tune"
      size="small"
      layout="onlytext"
      class="psui-text-blue-50"
      @click="handleAssumptionsIntercom"
    />
    <PsButton
      label="Share"
      icon="share"
      size="small"
      layout="onlytext"
      class="psui-text-blue-50"
      @click="copyLink"
    />
  </div>
</template>

<script>
import { GA_LABELS } from '@/mixins/GaEventsMixin'
import { copyUrlToClipboard } from '@/util/Helpers'

export default {
  name: 'PolicyOptionsHeaderActions',
  methods: {
    copyLink() {
      copyUrlToClipboard()
      this.$appToast({ message: 'Link copied to clipboard!', duration: 5500 })
      this.gaEventShareUrl(GA_LABELS.RESULTS_SHARE_URL)
    },
    handleAssumptionsIntercom(){
      window.open('https://intercom.help/explorer-local-energy-codes/en/articles/9704187', '__blank')
    }
  },    
}
</script>

