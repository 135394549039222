import {
  annual_bill_savings,
  annual_bill_savings_care,
  city_wide_five_year_payback,
  city_wide_five_year_payback_care,
  city_wide_subsidy_needed,
  city_wide_subsidy_needed_care,
  compliance_margin,
  edr1_total_margin,
  emission_savings,
  energy_savings_combined,
  five_years_payback,
  five_years_payback_care,
  forecast_emissions_savings,
  forecast_energy_savings,
  forecast_initial_cost,
  forecast_kwh_savings,
  forecast_lifecycle_savings,
  forecast_therms_savings,
  forecast_units,
  initial_cost,
  kwh_savings,
  lifecycle_savings,
  lifecycle_savings_care,
  lsc_2025_benefit_to_cost_ratio,
  on_bill_cost_ratio,
  on_bill_cost_ratio_2025,
  on_bill_cost_ratio_2025_care,
  simple_payback,
  subsidy_needed,
  subsidy_needed_care,
  tdv2022_benefit_to_cost_ratio,
  tdv_benefit_to_cost_ratio,
  therms_savings,
} from '@/modules/app/shared/default-app-columns'

export const PER_HOME_IMPACT_KEYS = [initial_cost(), kwh_savings(), therms_savings(), annual_bill_savings(), emission_savings(),
  lifecycle_savings(), compliance_margin(), edr1_total_margin(), annual_bill_savings_care(), lifecycle_savings_care(), simple_payback(), energy_savings_combined(),
  on_bill_cost_ratio(), tdv_benefit_to_cost_ratio(), tdv2022_benefit_to_cost_ratio(), on_bill_cost_ratio_2025(), on_bill_cost_ratio_2025_care(), lsc_2025_benefit_to_cost_ratio(),
  subsidy_needed(), five_years_payback(), subsidy_needed_care(), five_years_payback_care()].map(c => c.key || null).filter(c => c !== null)

export const FUEL_CHOICES = {
  ALLOWED: 'allowed',
  REQUIRED: 'required',
  NOT_ALLOWED: 'not_allowed'
}
export const FORECAST_MAP_TO_INSTALLATION_MECHANICS_KEYS = {
  // Common forecast keys mapped to mechanics
  [forecast_emissions_savings().key]: 'ghg_savings',
  [forecast_initial_cost().key]: 'compliance_cost',
  [forecast_kwh_savings().key]: 'kwh_savings',
  [forecast_energy_savings().key]: forecast_energy_savings().key,
  [forecast_lifecycle_savings().key]: 'bill_savings',
  [forecast_therms_savings().key]: 'therms_savings',
  [forecast_units().key]: 'installations_started',
  // Subsidy columns
  [city_wide_subsidy_needed().key]: city_wide_subsidy_needed().key,
  [city_wide_five_year_payback().key]: city_wide_five_year_payback().key,
  [city_wide_subsidy_needed_care().key]: city_wide_subsidy_needed_care().key,
  [city_wide_five_year_payback_care().key]: city_wide_five_year_payback_care().key,
}
