<template>
  <div class="card psui-flex psui-flex-col psui-shadow-elevation-5 psui-bg-white psui-rounded-lg psui-w-full xl:psui-w-1/3">
    <div class="card-header psui-px-5 2xl:psui-px-8 psui-py-6 psui-space-y-4 sm:psui-space-y-0 xl:psui-space-y-4 psui-flex psui-items-start">
      <img 
        src="images/home_page/home_benefits.png" 
        alt="See a list of policy options" 
        class="flex-shrink-0"
      >

      <div class="content psui-flex-grow xl:psui-flex-grow-0 psui-space-y-4">
        <h2 class="psui-text-h4 psui-text-blue-80 psui-font-bold">
          See a list of policy options
        </h2>

        <p class="psui-text-p psui-text-gray-60">
          Start by choosing from a menu of energy policy options created by our experts.
        </p>
      </div>
    </div>

    <div class="card-footer psui-flex psui-flex-row psui-border-t psui-border-gray-20 psui-px-5 2xl:psui-px-8 psui-py-4 psui-mt-auto">
      <PsButton
        data-test-id="home-explorer-policy-options-button"
        label="Explore policy options"
        size="medium"
        class="psui-flex-shrink-0"
        @click="$router.push({name:'PolicyOptions'})"
      />
      <PsButton
        label="Learn more"
        layout="onlytext"
        size="medium"
        @click="$eventBus.$emit('openMainBenefitsModal', { type: 'helper', slug: 'home-page-policy-options' })"
      />
    </div>
  </div>
</template>

<script>
export default {
  name:'HomeMainBenefitsPolicyOption',
}
</script>

<style lang="scss" scoped>
  .card {
    &-header {
      flex-direction: column;

      @media (min-width: 640px) {
        flex-direction: row;

        .content {
          margin-left: 1.5rem;
        }
      }
      
      @media (min-width: 1200px) {
        flex-direction: column;
        .content {
          margin-left: 0;
        }
      }
      
      img {
        max-height: 90.45px;
      }
    }

    &-footer {
      flex-direction: column;
      align-items: flex-start;

      button,
      a {
        &:not(:last-child) {
          margin-bottom: 0.5rem;

          @media (min-width: 432px) {
            margin-bottom: 0;
            margin-right: 0.5rem;
          }
        }
      }

      @media (min-width: 432px) {
        align-items: center;
        flex-direction: row;
      }
    }
  }
</style>