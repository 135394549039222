/**
 * COST-EFFECTIVENESS columns
 */
export const simple_payback = (({ isActive } = {}) => {
  return {
    isActive: isActive ?? false,
    key: 'simple_payback',
    title:'Simple Payback',
    description: '(years)',
    hasHelper: {
      type: 'helpers',
      id: 3,
      slug: 'simple_payback',
    }
  }
})

export const on_bill_cost_ratio = (({ isActive } = {}) => {
  return {
    isActive: isActive ?? true,
    key: 'on_bill_cost_ratio',
    title:'On-Bill (2022 Esc)',
    description: '≥ 1.0 is cost effective',
    isChart: true,
    hasHelper: {
      type: 'helpers',
      id: 5,
      slug: 'on_bill_cost_ratio',
    }
  }
})

export const on_bill_cost_ratio_2025 = (({ isActive } = {}) => {
  return {
    isActive: isActive ?? true,
    key: 'on_bill_cost_ratio_2025',
    title:'On-Bill (2025 Esc)',
    description: '≥ 1.0 is cost effective',
    isChart: true,
    hasHelper: {
      type: 'helpers',
      slug: 'on_bill_cost_ratio_2025',
    }
  }
})

export const tdv_benefit_to_cost_ratio = (({ isActive } = {}) => {
  return {  
    isActive: isActive ?? true,
    key: 'tdv_benefit_to_cost_ratio',
    title:'TDV',
    description: '≥ 1.0 is cost effective',
    isChart: true,
    hasHelper: {
      type: 'helpers',
      slug: 'tdv_benefit_to_cost_ratio',
    }
  }
})

export const tdv2022_benefit_to_cost_ratio = (({ isActive } = {}) => {
  return {  
    isActive: isActive ?? true,
    key: 'tdv2022_benefit_to_cost_ratio',
    title:'2022 TDV',
    description: '≥ 1.0 is cost effective',
    isChart: true,
    hasHelper: {
      type: 'helpers',
      slug: 'tdv2022_benefit_to_cost_ratio',
    }
  }
})

export const lsc_2025_benefit_to_cost_ratio = (({ isActive } = {}) => {
  return {
    isActive: isActive ?? true,
    key: 'lsc_2025_benefit_to_cost_ratio',
    title:'LSC',
    description: '≥ 1.0 is cost effective',
    isChart: true,
    hasHelper: {
      type: 'helpers',
      slug: 'lsc_2025_benefit_to_cost_ratio',
    }
  }
})

/**
 * PER HOME RESULTS columns
 */
export const initial_cost = (({ isActive } = {}) => {
  return { 
    isActive: isActive ?? true,
    key: 'initial_cost',
    title: 'Incremental Cost',
    label: 'Incremental Cost',
    hasHelper: {
      type: 'helpers',
      id: 1,
      slug: 'initial_cost',
    }
  }
})

export const annual_bill_savings = (({ isActive } = {}) => {
  return { 
    isActive: isActive ?? true,
    key: 'annual_bill_savings',
    title:'Annual Bill Savings',
    description: '(on-bill)',
    hasHelper: {
      type: 'helpers',
      id: 2,
      slug: 'annual_bill_savings',
    }
  }
})

export const annual_bill_savings_avg = (({ isActive } = {}) => {
  return { 
    isActive: isActive ?? true,
    key: 'annual_bill_savings_avg',
    title:'Annual Bill Savings AVG',
    description: '',
    hasHelper: {
      type: 'helpers',
      id: 2,
      slug: 'annual_bill_savings_avg',
    }
  }
})

export const emission_savings = (({ isActive } = {}) => {
  return {
    isActive: isActive ?? false,
    key: 'emissions_savings',
    title: 'Emissions Reductions',
    description: '(MTCO₂e/year)',
    hasHelper: {
      type: 'helpers',
      id: 4,
      slug: 'emissions_savings',
    }
  }
})

export const kwh_savings = (({ isActive } = {}) => {
  return { 
    isActive: isActive ?? false,
    key: 'kwh_savings',
    title:'Electricity Savings',
    description: '(kWh/year)',
    hasHelper: {
      type: 'helpers',
      id: 6,
      slug: 'kwh_savings',
    }
  }
})

export const therms_savings = (({ isActive } = {}) => {
  return {
    isActive: isActive ?? false,
    key: 'therms_savings',
    title:'Gas Savings',
    description: '(therms/year)',
    hasHelper: {
      type: 'helpers',
      id: 7,
      slug: 'therms_savings',
    }
  }
})

export const lifecycle_savings = (({ isActive } = {}) => {
  return {
    isActive: isActive ?? false,
    key: 'lifecycle_savings',
    title: 'Lifecycle Savings',
    description: '(on-bill)',
    hasHelper: {
      type: 'helpers',
      id: 13,
      slug: 'lifecycle_savings',
    }
  }
})

export const baseline_fuel_type = (({ isActive } = {}) => {
  return { 
    isActive: isActive ?? true,
    key: 'baseline_fuel_type',
    title:'Baseline Fuel Type',
    description: '',
    hasHelper: {
      type: 'helpers',
      id: 14,
      slug: 'baseline_fuel_type',
    }
  }
})

export const compliance_margin = (({ isActive, isDisabled } = {}) => {
  return { 
    isActive: isActive ?? true,
    key: 'compliance_margin',
    title:'EDR2 Efficiency Margin',
    description: '(EDR2eff)',
    isDisabled: isDisabled ?? false,
    hasHelper: {
      type: 'helpers',
      id: 15,
      slug: 'compliance_margin',
    }
  }
})

export const edr1_total_margin = (({ isActive, isDisabled } = {}) => {
  return { 
    isActive: isActive ?? true,
    key: 'edr1_total_margin',
    title:'EDR1 Compliance Margin',
    description: '(EDR1)',
    isDisabled: isDisabled ?? false,
    hasHelper: {
      type: 'helpers',
      slug: 'edr1_total_margin'
    },
  }
})

export const flexible_score = ({ isActive } = {}) => {
  return {
    isActive: isActive ?? true,
    key: 'flexible_score', // Its value is replaced by energy_savings_combined inside FormatStudyData.js
    title: 'Flexible Score',
    description: 'annual energy savings',
    hasHelper: {
      type: 'helpers',
      slug: 'flexible_score',
    },
  }
}

export const mandatory = ({isActive} = {})=>{
  return {
    isActive: isActive ?? true,
    key: 'mandatory',
    title: 'Mandatory',
    description: '',
    isSwitch: true,
    hasHelper:{
      type: 'helpers',
      slug: 'mandatory'
    }
  }
}

/**
 * City wide estimates columns
 */
export const forecast_units = (({ isActive } = {}) => {
  return { 
    isActive: isActive ?? true,
    key: 'forecast_units_affected',
    title:'Affected Units',
    description: '(lifecycle)',
    hasProjections: true,
    hasHelper: {
      type: 'helpers',
      id: 17,
      slug: 'forecast_units_affected',
    },
    chartProjection: {
      title: 'Number of Affected Units',
      subtitle: ''
    }
  }

})
export const forecast_emissions_savings = (({ isActive } = {}) => {
  return { 
    isActive: isActive ?? true,
    key: 'forecast_emissions_savings',
    title:'Emissions Reductions',
    description: '(lifecycle MTCO₂e)',
    hasProjections: true,
    hasHelper: {
      type: 'helpers',
      id: 18,
      slug: 'forecast_emissions_savings',
    },
    chartProjection: {
      title: 'Emissions Reductions',
      subtitle: 'MTCO₂e'
    }
  }
})

export const forecast_lifecycle_savings = (({ isActive } = {}) => {
  return { 
    isActive: isActive ?? true,
    key: 'forecast_lifecycle_savings',
    title:'Lifecycle Savings',
    description: '(on-bill)',
    hasProjections: true,
    hasHelper: {
      type: 'helpers',
      id: 19,
      slug: 'forecast_lifecycle_savings',
    },
    chartProjection: {
      title: 'Bill Savings',
      subtitle: ''
    },
  }
})

export const forecast_initial_cost = (({ isActive } = {}) => {
  return { 
    isActive: isActive ?? false,
    key: 'forecast_initial_cost',
    title:'Compliance Cost',
    description: '(lifecycle)',
    hasProjections: true,    
    hasHelper: {
      type: 'helpers',
      id: 20,
      slug: 'forecast_initial_cost',
    },
    chartProjection: {
      title: 'Compliance Cost',
      subtitle: ''
    },
  }
})

export const forecast_kwh_savings = (({ isActive } = {}) => {
  return { 
    isActive: isActive ?? false,
    key: 'forecast_kwh_savings',
    title:'Electricity Savings',
    description: '(lifecycle kWh)',
    hasProjections: true,
    hasHelper: {
      type: 'helpers',
      id: 21,
      slug: 'forecast_kwh_savings',
    },
    chartProjection: {
      title: 'Electricity Savings',
      subtitle: 'kWh'
    },
  }
})

export const forecast_therms_savings = (({ isActive } = {}) => {
  return { 
    isActive: isActive ?? false,
    key: 'forecast_therms_savings',
    title:'Gas Savings',
    description: '(lifecycle therms)',
    hasProjections: true,
    hasHelper: {
      type: 'helpers',
      id: 22,
      slug: 'forecast_therms_savings',
    },
    chartProjection: {
      title: 'Gas Savings',
      subtitle: 'therms'
    },
  }
})

export const forecast_energy_savings = (({ isActive } = {}) => {
  return {
    isActive: isActive ?? false,
    key: 'forecast_energy_savings',
    title:'Energy Savings',
    description: '(MMBtu)',
    // hasProjections: true,
    hasHelper: {
      type: 'helpers',
      id: 162,
      slug: 'forecast_energy_savings',
    },
    // chartProjection: {
    //   title: 'Energy Savings',
    //   subtitle: 'MMBtu'
    // },
  }
})

export const energy_savings_combined = (({ isActive } = {}) => {
  return { 
    isActive: isActive ?? false,
    key: 'energy_savings_combined',
    title:'Energy Savings',
    description: 'site MMBtu/year',
    hasHelper: {
      type: 'helpers',
      id: 62,
      slug: 'energy_savings_combined',
    },
  }
})

// CARE RATE COLUMNS
export const annual_bill_savings_care = (({ isActive } = {}) => {
  return { 
    isActive: isActive ?? false,
    key: 'annual_bill_savings_care',
    title:'Annual Bill Savings (CARE)',
    description: '(on-bill)',
    hasHelper: {
      type: 'helpers',
      slug: 'annual_bill_savings_care'
    },
  }
})

export const lifecycle_savings_care = (({ isActive } = {}) => {
  return { 
    isActive: isActive ?? false,
    key: 'lifecycle_savings_care',
    title:'Lifecycle Savings (CARE)',
    description: '(on-bill)',
    hasHelper: {
      type: 'helpers',
      slug: 'lifecycle_savings_care'
    },
  }
})

export const on_bill_cost_ratio_care = (({ isActive } = {}) => {
  return {
    isActive: isActive ?? false,
    key: 'on_bill_cost_ratio_care',
    title:'On-Bill (2022 Esc, CARE)',
    description: '≥ 1.0 is cost effective',
    isChart: true,
    hasHelper: {
      type: 'helpers',
      slug: 'on_bill_cost_ratio_care'
    },
  }
})

export const on_bill_cost_ratio_2025_care = (({ isActive } = {}) => {
  return {
    isActive: isActive ?? false,
    key: 'on_bill_2025_care',
    title: 'On-Bill (2025 Esc, CARE)',
    description: '≥ 1.0 is cost effective',
    isChart: true,
    hasHelper: {
      type: 'helpers',
      slug: 'on_bill_cost_ratio_2025_care',
    }
  }
})

export const simple_payback_care = (({ isActive } = {}) => {
  return {
    isActive: isActive ?? false,
    key: 'simple_payback_care',
    title:'Simple Payback (CARE)',
    description: '(years)',
    hasHelper: {
      type: 'helpers',
      slug: 'simple_payback_care'
    },
  }
})

// Subsidy columns
export const subsidy_needed = (({ isActive } = {}) => {
  return {
    isActive: isActive ?? false,
    key: 'subsidy_needed',
    title:'On-bill Cost-Effective',
    description: '(per home)',
    hasHelper: {
      type: 'helpers',
      slug: 'per_home_subsidy_column_subsidy_needed',
      showOnEditHideColumns: true,
      customButton: {
        title: 'Learn More',
        url: 'https://intercom.help/explorer-local-energy-codes/en/articles/6805213'
      }
    },
  }
})

export const five_years_payback = (({ isActive } = {}) => {
  return {
    isActive: isActive ?? false,
    key: 'five_year_payback',
    title:'Payback in 5 Years',
    description: '(per home)',
    hasHelper: {
      type: 'helpers',
      slug: 'per_home_subsidy_column_five_years_payback',
      showOnEditHideColumns: true,
      customButton: {
        title: 'Learn More',
        url: 'https://intercom.help/explorer-local-energy-codes/en/articles/6805213'
      }
    },
  }
})

export const subsidy_needed_care = (({ isActive } = {}) => {
  return {
    isActive: isActive ?? false,
    key: 'subsidy_needed_care',
    title:'On-bill Cost-Effective (CARE)',
    description: '(per home)',
    hasHelper: {
      type: 'helpers',
      slug: 'per_home_subsidy_column_subsidy_needed_care',
      showOnEditHideColumns: true,
      customButton: {
        title: 'Learn More',
        url: 'https://intercom.help/explorer-local-energy-codes/en/articles/6805213'
      }
    },
  }
})

export const five_years_payback_care = (({ isActive } = {}) => {
  return {
    isActive: isActive ?? false,
    key: 'five_year_payback_care',
    title:'Payback in 5 Years (CARE)',
    description: '(per home)',
    hasHelper: {
      type: 'helpers',
      slug: 'per_home_subsidy_column_five_years_payback_care',
      showOnEditHideColumns: true,
      customButton: {
        title: 'Learn More',
        url: 'https://intercom.help/explorer-local-energy-codes/en/articles/6805213'
      }
    },
  }
})

// City/County wide subsidy columns
export const city_wide_subsidy_needed = (({ isActive } = {}) => {
  return {
    isActive: isActive ?? false,
    key: 'city_wide_subsidy_needed',
    title:'On-bill Cost-Effective Subsidy',
    description: '(city-wide)',
    hasHelper: {
      type: 'helpers',
      slug: 'city_wide_subsidy_column_subsidy_needed',
      showOnEditHideColumns: true,
      customButton: {
        title: 'Learn More',
        url: 'https://intercom.help/explorer-local-energy-codes/en/articles/6805213'
      }
    },
    hasProjections: true,
    chartProjection: {
      title: 'On-bill Cost-Effective Subsidy',
      subtitle: '(city-wide)'
    },
  }
})

export const city_wide_five_year_payback = (({ isActive } = {}) => {
  return {
    isActive: isActive ?? false,
    key: 'city_wide_five_year_payback',
    title:'Payback in 5 Years Subsidy',
    description: '(city-wide)',
    hasHelper: {
      type: 'helpers',
      slug: 'city_wide_subsidy_column_five_years_payback',
      showOnEditHideColumns: true,
      customButton: {
        title: 'Learn More',
        url: 'https://intercom.help/explorer-local-energy-codes/en/articles/6805213'
      }
    },
    hasProjections: true,
    chartProjection: {
      title: 'Payback in 5 Years Subsidy',
      subtitle: '(city-wide)'
    },
  }
})

export const city_wide_subsidy_needed_care = (({ isActive } = {}) => {
  return {
    isActive: isActive ?? false,
    key: 'city_wide_subsidy_needed_care',
    title:'On-bill Cost-Effective Subsidy (CARE)',
    description: '(city-wide)',
    hasHelper: {
      type: 'helpers',
      slug: 'city_wide_subsidy_column_subsidy_needed_care',
      showOnEditHideColumns: true,
      customButton: {
        title: 'Learn More',
        url: 'https://intercom.help/explorer-local-energy-codes/en/articles/6805213'
      }
    },
    hasProjections: true,
    chartProjection: {
      title: 'On-bill Cost-Effective Subsidy (CARE)',
      subtitle: '(city-wide)'
    },
  }
})

export const city_wide_five_year_payback_care = (({ isActive } = {}) => {
  return {
    isActive: isActive ?? false,
    key: 'city_wide_five_year_payback_care',
    title:'Payback in 5 Years Subsidy (CARE)',
    description: '(city-wide)',
    hasHelper: {
      type: 'helpers',
      slug: 'city_wide_subsidy_column_five_years_payback_care',
      showOnEditHideColumns: true,
      customButton: {
        title: 'Learn More',
        url: 'https://intercom.help/explorer-local-energy-codes/en/articles/6805213'
      }
    },
    hasProjections: true,
    chartProjection: {
      title: 'Payback in 5 Years Subsidy (CARE)',
      subtitle: '(city-wide)'
    },
  }
})
