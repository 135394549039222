<template>
  <div class="side-bar border-[#d6dde5] w-full psui-border-gray-30">
    <div class="side-bar-wrapper flex-col">
      <div class="side-bar-wrapper-text flex flex-col space-y-1 pt-4 pb-2 pr-4">
        <span class="side-bar-wrapper-text-header psui-text-blue-80 psui-text-small font-bold">Selection:</span>
        <span class="side-bar-wrapper-text-description psui-text-small psui-text-gray-60  ">Change the minimum building size below, or drag the selector on the chart to refine your selection.</span>
      </div>
      <BuildingEstimatesNonAndLargeBuildingSizeComparison
        :categories="categories"
        :selected-area="selectedArea"
      />
      <div class="side-bar-wrapper-chart">
        <BuildingEstimatesNonAndLargePieChart
          v-if="getBuildingData.length"
          :series="getBuildingData"
          unit-type="Buildings"
          title="Selected Buildings"
          class="side-bar-wrapper-chart-first-chart"
          :slug="slugs.BUILDINGS"
        />
        <BuildingEstimatesNonAndLargePieChart
          v-if="getFloorData.length"
          :series="getFloorData"
          unit-type="ft²"
          title="Selected Floor Area"
          class="side-bar-wrapper-chart-second-chart"
          :slug="slugs.FLOOR_AREA"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BuildingEstimatesNonAndLargePieChart from '@/components/charts/building-estimates/BuildingEstimatesNonAndLargePieChart.vue'
import BuildingEstimatesNonAndLargeBuildingSizeComparison from '@/components/charts/building-estimates/BuildingEstimatesNonAndLargeBuildingSizeComparison.vue'
export default {
  name: 'BuildingEstimatesNonAndLargeSideBar',
  components: {
    BuildingEstimatesNonAndLargePieChart,
    BuildingEstimatesNonAndLargeBuildingSizeComparison,
  },
  props: ['data', 'slugs', 'chartSeries','categories', 'selectedArea'],
  computed: {
    getBuildingSizeData() {
      const [first, ...rest] = this.data
      let last = rest[rest.length - 1]

      if (!last) last = first

      return [first, last]
    },
    getBuildingData() {
      const buildingData = this.chartSeries.find((item) => item.slug == this.slugs.BUILDINGS)

      if (!buildingData) return []

      return this.generateValues(buildingData.data)
    },
    getFloorData() {
      const floorData = this.chartSeries.find((item) => item.slug == this.slugs.FLOOR_AREA)
      if (!floorData) return []

      return this.generateValues(floorData.data)
    },
  },
  methods: {
    generateValues(data) {
      const unitsSelected = data.reduce((acc, curr) => {
        curr.data.forEach((c) => (acc += c))
        return acc
      }, 0)

      const totalUnits = data.reduce((acc, curr) => {
        curr.total.forEach((c) => (acc += c))
        return acc
      }, 0)

      const unitsNotSelected = totalUnits - unitsSelected

      return [unitsNotSelected, unitsSelected]
    },
  },
}
</script>

<style lang="scss">
  .side-bar {
    padding: 0px 8px;
    @media (min-width: 1180px) {
      padding: 0px 16px;
    }

    &-wrapper {
      &-text {
        &-description {
          display: none;
          @media (min-width: 1180px) { 
            display: block;
          }
        }
      }

      &-chart {
        @media (min-width: 1180px) {
          padding-top: 8px;
        }
        &-first-chart, &-second-chart {
          @media (min-width: 1180px) {
            height: 81px;
          }
        }
      }
    }
  }
</style>