import editRequirementsNewBuildingsSF2022Columns from '@/modules/app/policy/show/requirements/requirements-drawer/PolicyEditRequirementsNewBuildingsSF2022/edit-requirements-new-buildings-sf-2022-columns.js'
import { STUDY_TYPES_DB_SLUGS } from '@/util/Enums'

export const initialState = () => ({
  loggedUser: null,
  loggedUserType: null,
  loggedUserToken: null,
  user_preferences: {
    lastJurisdictionVisited: false,
    lastClimateZonesVisited: false,
    lastStudyTypeVisited: false,
    policies: {
      myPolicies: {
        filterByAuthorV3: [],
        filterByJurisdictionV2: [],
        filterByStudyTypeV3: [],
        orderByColumn: 'title',
        orderByDirection: 'asc',
      },
      comparison: {
        showOnlyDifferentAssumptionColumns: false,
        filterByJurisdiction: [],
        filterByAuthor: []
      },
      policyOptions: {
        filterByStudyType: [STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS, STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS],
      }
    },
    modulesVisited: [],
    columnsEditRequirementsNewBuildingsSF2022 : {
      version : 2,
      data: editRequirementsNewBuildingsSF2022Columns
    },
  }
})

export default {
  ...initialState()
}

