var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.studyTypes.length > 0)?_c('div',{staticClass:"flex items-center mb-2 space-x-2"},[_c('span',{staticClass:"psui-text-small psui-text-blue-70 font-normal"},[_vm._v("Show :")]),_vm._l((_vm.studyTypes),function(studyType){return _c('div',{key:`study-type-${studyType.id}`,class:[
      'check-box flex h-[26px] py-0 pr-2 pl-1.5 items-center gap-2 rounded',
      _vm.isStudyTypeSelected(studyType.slug) ? 'bg-[#e0eff6]' : 'bg-[#f3f6f9]',
      !_vm.isStudyTypeSelected(studyType.slug) ? 'not-selected' : ''
    ]},[_c('PsCheckboxSimple',{attrs:{"input-attrs":{
        id: `policy-option-filter-study-type-${studyType.id}`,
        class: 'w-[14px] h-[14px]',
      },"size":"small","checked":_vm.isStudyTypeSelected(studyType.slug)},on:{"change":function($event){return _vm.toggleStudyTypeSelection(studyType, $event)}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{class:[
            'text-sm font-normal leading-tight',
            _vm.isStudyTypeSelected(studyType.slug) ? 'text-[#318FAC]' : 'text-[#798490]'
          ]},[_vm._v(" "+_vm._s(studyType.title)+" ")])]},proxy:true}],null,true)})],1)})],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }