import EventBus from '@/util/EventBus'
import { GA_LABELS, gTagEvent } from '@/mixins/GaEventsMixin'

export default (Store) => {
  Store.subscribe((mutation) => {
    const mutationsToWatch = [
      'assumptions/updateAssumptionsKeyValue', 
      'assumptions/clearPolicyImpactAssumptions', 
      'assumptions/clearResultsImpactAssumptions', 
      'assumptions/buildingEstimates/clearCustomBuildingEstimates', 
      'assumptions/buildingEstimates/setCustomBuildingEstimates', 
      'assumptions/buildingStocks/clearCustomBuildingStocks', 
      'assumptions/buildingStocks/setCustomBuildingStocks', 
    ]
    if(mutationsToWatch.includes(mutation.type)) {
      EventBus.$emit('editAssumptionsChanged', mutation.type)
    }

    // Send GA Events on building stocks/estimates changes:
    const buildingsMutationsToWatch = ['assumptions/buildingEstimates/clearCustomBuildingEstimates',
      'assumptions/buildingEstimates/setCustomBuildingEstimates',
      'assumptions/buildingStocks/clearCustomBuildingStocks',
      'assumptions/buildingStocks/setCustomBuildingStocks']
    if (buildingsMutationsToWatch.includes(mutation?.type) && mutation?.payload?.ignoreGaEvent !== true) {
      let customItems = Array.isArray(mutation.payload) ? mutation.payload : mutation.payload?.custom_building_stocks || mutation.payload?.custom_building_estimates
      customItems = Array.isArray(customItems) ? customItems : []
      if (customItems?.length) {
        const policyId = customItems.find((i) => !!i.policy_id)?.policy_id || null
        const isPolicyEvent = !!policyId
        const isBuildingStock = customItems.some((i) => !!i.type_vintage_id)
        gTagEvent(
          GA_LABELS.ASSUMPTIONS_CHANGED,
          {
            assumption: isBuildingStock ? 'building_stocks' : 'building_estimates',
            is_policy_assumption: isPolicyEvent,
            jurisdiction_slug: Store.getters['lastJurisdictionVisited'].slug
          }
        )
      }
    }
  })
}