<template>
  <div
    ref="apexChartGeneral"
    :style="{ height: computedChartHeight, minHeight: computedChartHeight, marginTop: marginTop }"
    class="psui-flex psui-items-center psui-justify-center"
  />
</template>

<script>
import { apexChartBarDefaultOptions, apexChartDonutsDefaultOptions, apexChartLineDefaultOptions } from '@/business-logic/constants/chartDefaultOptions'
import ApexCharts from 'apexcharts'
import { deepMergeObject } from '@igortrindade/lazyfy'
import { selectionDrawing } from '@/components/charts/ApexChartUtils.js'
export default {
  name: 'ApexChartGeneral',
  props: {
    options: {
      type: Object,
      required: false,
    },
    avoidDestroy: {
      type: Boolean,
      default: false,
    },
    overrideSelectionFunction: {
      type: Boolean,
      default: false,
    },
    disableUpdates: {
      type: Boolean,
      default: false,
    },
    marginTop: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      chartInstance: null,
      chartIsLoading: false,
      seriesCached: null,
      manualOptions: null,
    }
  },
  computed: {
    getOptions() {
      if (this.manualOptions) {
        return this.manualOptions
      }
      const options = this.manualOptions || this.options
      if (!options) return apexChartLineDefaultOptions

      const chartTypes = {
        donut: apexChartDonutsDefaultOptions,
        bar: apexChartBarDefaultOptions,
        line: apexChartLineDefaultOptions,
      }

      const chartType = options.chart.type

      const chartDefaultOptions = chartTypes[chartType] || {}

      return deepMergeObject(JSON.parse(JSON.stringify(chartDefaultOptions)), options)
    },
    computedChartHeight() {
      return this.getOptions.chart?.height || '350px'
    }
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.mountChart()
      }
    },
    manualOptions: {
      deep: true,
      handler() {
        this.mountChart()
      }
    },
  },
  mounted() {
    this.mountChart()
  },
  beforeDestroy() {
    if (this.chartInstance) this.chartInstance.destroy()
  },
  methods: {
    setOptions(value) {
      this.manualOptions = value
    },
    mountChart() {
      this.$nextTick(() => {
        if (this.chartIsLoading || !this.checkOptionsHasChanged()) return
        this.chartIsLoading = true

        if (this.chartInstance && this.avoidDestroy) {
          const params = this.disableUpdates ? [this.getOptions, false, false, false] : [this.getOptions, false, true, true]

          this.chartInstance.updateOptions(...params)
          this.chartIsLoading = false
          this.seriesCached = this.serializeSeries()
          return
        }

        if (this.chartInstance) this.chartInstance.destroy()

        setTimeout(() => {
          this.chartInstance = new ApexCharts(this.$refs.apexChartGeneral, this.getOptions)

          this.chartInstance.render().then(() => {
            if (this.overrideSelectionFunction) this.overrideSelectionDrawing()
          })

          this.chartIsLoading = false
          this.seriesCached = this.serializeSeries()

          setTimeout(() => {
            window.dispatchEvent(new Event('resize'))
          }, 500)
        }, 200)
      })
    },
    overrideSelectionDrawing() {
      const checkZoomPanSelection = setInterval(() => {
        if (this.chartInstance.zoomPanSelection) {
          Object.getPrototypeOf(this.chartInstance.zoomPanSelection)
          .selectionDrawing = selectionDrawing
          clearInterval(checkZoomPanSelection)
        }
      }, 100)
    },
    checkOptionsHasChanged() {
      return this.seriesCached != this.serializeSeries()
    },
    serializeSeries() {
      return JSON.stringify(this.getOptions.series)
    },
  },
}
</script>
