<template>
  <div class="dropdown-menu-container">
    <ul
      class="dropdown-menu-list py-2 overflow-y-auto"
    >
      <li
        v-for="item in getItems"
        :key="getKeyValue(item)"
        :data-test-id="`dropdown-menu-list-${item.title_type}`"
        :class="{ 'is-selected': getSelected === getKeyValue(item) }"
        class="text-header-2 cursor-pointer transition-all hover:psui-bg-blue-10 hover:psui-text-blue-60"
        @click="selectItem(item)"
      >
        {{ getKeyLabel(item) }}
      </li>
    </ul>
    <div
      v-if="alert"
      class="alert-container mt-2"
    >
      <PsIcon
        icon="info"
        size="16"
        display="flex"
        class="psui-text-gray-50"
      />
      <p class="psui-text-xsmall psui-text-gray-50 psui-font-bold ml-1">
        {{ alert }}
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DropdownMenuList',
  props: {
    items: {
      type: Array,
      required: true
    },
    selected: {},
    keyLabel: {
      type: [String, Function],
      default: 'label'
    },
    keyValue: {
      type: [String, Function],
      default: 'value'
    },
    alert: {
      type: String,
      default: null,
    }
  },
  computed: {
    getSelected() {
      if (this.selected) {
        if (typeof this.selected === 'object' && this.selected[this.keyValue] ) {
          return this.selected[this.keyValue]        
        } else {
          return this.selected
        }
      } else {
        return false
      }
    },
    getItems() {
      return this.items
    },
  },
  methods: {
    selectItem(item) {
      this.$emit('update:selected', this.getKeyValue(item) )
      this.$emit('change', item )
    },

    getKeyLabel(item) {
      if(typeof this.keyLabel == 'function') return this.keyLabel(item)
      if(typeof item === 'string') return item
      return item[this.keyLabel]
    },
    getKeyValue(item) {
      if(typeof this.keyValue == 'function') return this.keyValue(item)
      if(typeof item === 'string') return item
      return item[this.keyValue]
    },
  }
}
</script>

<style lang="scss" scoped>
$gray-10: #F3F6F9;

.dropdown-menu-container {
  .dropdown-menu-list {
    max-height: 60vh;
    li {
      padding: 10px 24px;
      border-bottom: 1px solid #E4EEF2;
      color: #002A3A;
      &:last-of-type {
        border-bottom: 0;
      }
      &:hover, &.is-selected {
        color: #76848A;
      }
    }
  }

  &:has(.alert-container) {
    .dropdown-menu-list {
      padding-bottom: 8px;
    }
  }

  .alert-container {
    margin: 0 8px 8px;
    padding: 6px;
    max-width: 164px;
    align-self: start;
    justify-self: start;
    border-radius: 4px;
    background-color: $gray-10;
    display: inline-flex;
  }
}
</style>