import { BasePolicyImpactAlgorithm, CITY_WIDE_IMPACT_COLUMNS_KEYS } from '../index'
import { FUEL_CHOICES, PER_HOME_IMPACT_KEYS } from '../../index'
import { TYPE_FUELS_DB_SLUGS } from '@/util/Enums'
import { getImpactEstimatesFromQuarterInstallations } from '@/business-logic/services/impact-algorithms/common/mechanics'
import StudyDataApiService from '@/services/api/StudyDataApiService'
import ImpactAssumptions, { defaultNewBuildingsImpactAssumptionValues } from '@/models/ImpactAssumptions'
import newBuildingsInstallations from '@/business-logic/services/impact-algorithms/common/newBuildingsInstallations'
import generateAccumulativeYearsByQuarter
  from '@/business-logic/services/impact-algorithms/common/generateAccumulativeYearsByQuarter'
import dayjs from "dayjs"
import Store from '@/store'

const cachedStudyData = {}
export class GeneralPerformanceByFuelPolicyImpactAlgorithm extends BasePolicyImpactAlgorithm {
  static pvOnlySlug = 'pv-only'
  static allElectricMinimumSlug = 'all-electric-performance-minimum'
  static mixedFuelMinimumSlug = 'mixed-fuel-performance-minimum'
  static pvEnabledComplianceMarginKeys = ['compliance_margin']
  commonKeys = ['title', 'fuel_slug', 'min_compliance_margin', 'per_home_data']

  constructor(policy, policyContainer, climateZoneRaw) {
    if (!policy || !policy.jurisdiction_id || !policyContainer || !policyContainer.type_prototype_id)
    {
      throw new Error(`Could not use GeneralPerformanceByFuel. Check if you correctly passed policy with jurisdiction and container with type prototype`)
    }
    super(policy, policyContainer, climateZoneRaw)
    if(this.policy) this.policy.code_cycle.end_at = "12/31/2025"// ToDO: remove it...
    this.customCombinations = (Array.isArray(policyContainer.custom_combinations) ? policyContainer.custom_combinations : [])
      .filter((c) => c.climate_zone_raw === climateZoneRaw)
    this.updateBuildings()
  }

  updateBuildings() {
    const prototypeId = this.customCombinations?.map((x) => x.prototype_id)?.filter((x) => x)?.pop() || undefined
    this.buildings = Store.getters['assumptions/buildingEstimates/getterAllBuildingEstimates']({
      jurisdiction_id: this.policy.jurisdiction_id,
      type_prototype_id: this.policyContainer.type_prototype_id,
      climate_zone_prefix: this.climateZoneRaw.split('-')?.[0],
      prototype_id: prototypeId,
      // policy_id: this.policy.id
    })

    this.totalBuildingUnits = this.buildings.reduce((acc, item) => acc + (item && item.units && !isNaN(item.units) ? item.units : 0), 0)
  }

  getAssumptions() {
    const {
      [TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC]: allElectricCustomCombination,
    } = this.getCustomCombinationsByFuel()
    return new ImpactAssumptions({
      ...this.policy, // ToDO: Removes it when is safe
      ...(allElectricCustomCombination?.meta?.assumptions || {})
    }, defaultNewBuildingsImpactAssumptionValues)
  }

  async computeTotalImpact() {
    return (await this.computeResumedCityWideImpact()).reduce((acc, fuelImpact) => {
      Object.keys(fuelImpact).forEach((key) => {
        if (!CITY_WIDE_IMPACT_COLUMNS_KEYS.includes(key)) {
          return
        }
        const sumValue = !isNaN(fuelImpact[key]) ? Number(fuelImpact[key]) : 0
        acc[key] = acc[key] ? acc[key] + sumValue : sumValue
      })
      return acc
    }, {})
  }

  async computeResumedCityWideImpact() {
    return (await this.computeYearlyCityWideImpact()).map((accumulatedByFuelAndYearImpact) => {
      const lastInfo = accumulatedByFuelAndYearImpact.years.pop()
      return {
        ... this.getCommonKeys(accumulatedByFuelAndYearImpact),
        data: CITY_WIDE_IMPACT_COLUMNS_KEYS.map((key) => {
          return lastInfo?.[key] ? {
            key,
            value: !isNaN(lastInfo?.[key]?.cummulative) ? Number(lastInfo?.[key]?.cummulative) : 0
          } : null
        })
          .filter(k => k !== null)
          .reduce((curr, acc) => {
            curr[acc.key] = acc.value
            return curr
          }, {})
      }
    })
  }

  async computeYearlyCityWideImpact() {
    return (await this.computeQuarterlyCityWideImpact()).map((quarterlyImpactByFuel) => {
      return {
        ... this.getCommonKeys(quarterlyImpactByFuel),
        years: generateAccumulativeYearsByQuarter(quarterlyImpactByFuel.quarters)
      }
    })
  }

  async computeQuarterlyCityWideImpact() {
    const {
      [TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC]: allElectricCustomCombination,
      [TYPE_FUELS_DB_SLUGS.MIXED_FUEL]: mixedFuelCustomCombination
    } = this.getCustomCombinationsByFuel()
    const {
      [TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC]: allElectricMinimumStudyData,
    } = await this.getMinimumStudyData()
    if (!allElectricCustomCombination || !mixedFuelCustomCombination) {
      throw new Error(`Could not execute for GeneralPerformanceByFuelPolicyImpactAlgorithm because there is no custom combination for both fuels set`)
    }

    const assumptions = this.getAssumptions()
    const studyId = this.customCombinations?.map((x) => x?.prototype?.study_id)?.filter((x) => x)?.pop() || undefined
    const study = studyId ? Store.getters['globalEntities/Study/getterGlobalStudy']({ id: studyId }) : undefined

    const allElectricMetaInfo = this.getCustomCombinationMetaInfo(allElectricCustomCombination)
    const mixedFuelMetaInfo = this.getCustomCombinationMetaInfo(mixedFuelCustomCombination)
    const isFullAllElectricWithPolicy = Boolean(allElectricMetaInfo.fuelChoice === FUEL_CHOICES.REQUIRED || mixedFuelMetaInfo.fuelChoice === FUEL_CHOICES.NOT_ALLOWED)
    const isBothOnDefaultState = Boolean(allElectricMetaInfo.isOnDefaultState && mixedFuelMetaInfo.isOnDefaultState)
    const withoutPolicyAllElectricShares = assumptions.all_electric_shares_without_policy
    const withPolicyAllElectricShares = isFullAllElectricWithPolicy ? 100 : isBothOnDefaultState ? withoutPolicyAllElectricShares : assumptions.all_electric_shares_with_policy

    const computeQuarterInfo = (fuelSlug, perHomeImpactData) => {
      const installationsPerQuarter = newBuildingsInstallations(this.buildings, fuelSlug, {
        all_electric_shares_without_policy: withoutPolicyAllElectricShares,
        all_electric_shares_with_policy: withPolicyAllElectricShares,
        effective_date: assumptions.start_at,
        termination_date: assumptions.terminationDate || assumptions.termination_date || this.policy.code_cycle.end_at,
        installation_effects_years: study?.impact_period && !isNaN(study?.impact_period) ? Number(study?.impact_period) : assumptions.years,
        applicability_rate: assumptions.applicability_rate,
        annual_penetration_rate: assumptions.annual_penetration_rate,
        delay_installation: assumptions.delay_installation,
        likelihood_installations_first_third: assumptions.likelihood_installations_first_third,
        likelihood_installations_second_third: assumptions.likelihood_installations_second_third,
        likelihood_installations_final_third: assumptions.likelihood_installations_final_third,
      })
      let unitsNormalization = 1
      if (this.buildings?.length) {
        const typePrototype = Store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: this.buildings[0].type_prototype_id })
        unitsNormalization = typePrototype?.units_normalization || 1
      }
      const startAtDate = installationsPerQuarter.length ? dayjs(installationsPerQuarter[0].quarter).startOf('year') :
        dayjs().startOf('year')
      return getImpactEstimatesFromQuarterInstallations(installationsPerQuarter, startAtDate, assumptions.care_program_enrollment_rate, {
        currentThermsEmissionFactor: assumptions.current_therms_emissions_factor,
        currentKwhEmissionFactor: assumptions.current_kwh_emissions_factor,
        kwhSavings: perHomeImpactData.kwh_savings,
        energySavings: perHomeImpactData.energy_savings_combined,
        thermsSavings: perHomeImpactData.therms_savings,
        annualBillSavings: perHomeImpactData.annual_bill_savings,
        initialCost: perHomeImpactData.initial_cost,
        gridMaxRenewablesYear: assumptions.grid_max_renewables_year,
        gridMaxRenewablesLevel: assumptions.grid_max_renewables_level,
        currentGridRenewableLevel: assumptions.current_grid_renewable_level,
        subsidy_needed: perHomeImpactData.subsidy_needed,
        five_year_payback: perHomeImpactData.five_year_payback,
        subsidy_needed_care: perHomeImpactData.subsidy_needed_care,
        five_year_payback_care: perHomeImpactData.five_year_payback_care
      }, unitsNormalization)
    }

    const disabledSumKeys = ['quarter', 'year']
    return (await this.computePerHomeImpact()).map((perHomeImpactByFuel) => {
      let quarterInfo = []
      if (perHomeImpactByFuel.fuel_slug === TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC) {
        const minimumData = this.generateDataFromRawStudyData(allElectricMinimumStudyData.study_data)
        const allElectricOnlyData = this.mergeData(perHomeImpactByFuel.data, minimumData, true)
        const electrificationQuarterInfo = computeQuarterInfo(TYPE_FUELS_DB_SLUGS.ELECTRIFICATION, perHomeImpactByFuel.data)
        const allElectricQuarterInfo = computeQuarterInfo(TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC, allElectricOnlyData)
        quarterInfo = electrificationQuarterInfo.map((value, index) => {
          const newValue = {... value}
          Object.keys(newValue).forEach((key) => {
            if (disabledSumKeys.includes(key)) {
              return
            }
            newValue[key] += allElectricQuarterInfo && allElectricQuarterInfo[index] && allElectricQuarterInfo[index][key] && !isNaN(allElectricQuarterInfo[index][key]) ?
              Number(allElectricQuarterInfo[index][key]) : 0
          })
          return newValue
        })
      } else {
        quarterInfo = computeQuarterInfo(perHomeImpactByFuel.fuel_slug, perHomeImpactByFuel.data)
      }

      return {
        ... this.getCommonKeys(perHomeImpactByFuel),
        per_home_data: perHomeImpactByFuel?.data || null,
        quarters: quarterInfo
      }
    })
  }

  async computePerHomeImpact({ bringMinimumCost } = { bringMinimumCost: false }) {
    const {
      [TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC]: allElectricCustomCombination,
      [TYPE_FUELS_DB_SLUGS.MIXED_FUEL]: mixedFuelCustomCombination
    } = this.getCustomCombinationsByFuel()
    const {
      [TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC]: allElectricFuelStudyData,
      [TYPE_FUELS_DB_SLUGS.MIXED_FUEL]: mixedFuelStudyData
    } = await this.getFuelStudyData()

    const minimumCosts = (bringMinimumCost === true) ? (await this.getMinimumStudyData()) : null
    return [
      (await this.adjustLargePvStudyData(allElectricCustomCombination, allElectricFuelStudyData)),
      (await this.adjustLargePvStudyData(mixedFuelCustomCombination, mixedFuelStudyData)),
    ].filter(studyData => studyData).map((studyData) => {
      const perHomeImpactByFuel = {
        title: studyData.study_data.fuel.title,
        fuel_slug: studyData.study_data.fuel.slug,
        min_compliance_margin: studyData.min_compliance_margin,
        data: this.generateDataFromRawStudyData(studyData.study_data),
        studyData
      }
      if (bringMinimumCost === true && minimumCosts[studyData.study_data.fuel.slug]) {
        perHomeImpactByFuel.minimumCostData = this.generateDataFromRawStudyData(minimumCosts[studyData.study_data.fuel.slug].study_data)
      }
      return perHomeImpactByFuel
    })
  }

  getCustomCombinationMetaInfo(customCombination) {
    return GeneralPerformanceByFuelPolicyImpactAlgorithm.extractMetaInfoFromCustomCombination(customCombination)
  }

  getCustomCombinationsByFuel() {
    return {
      [TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC]: this.customCombinations.find((c) => c.fuel.slug === TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC) || null,
      [TYPE_FUELS_DB_SLUGS.MIXED_FUEL]: this.customCombinations.find((c) => c.fuel.slug === TYPE_FUELS_DB_SLUGS.MIXED_FUEL) || null,
    }
  }

  async getFuelStudyData() {
    const {
      [TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC]: allElectricCustomCombination,
      [TYPE_FUELS_DB_SLUGS.MIXED_FUEL]: mixedFuelCustomCombination
    } = this.getCustomCombinationsByFuel()
    if (!allElectricCustomCombination || !mixedFuelCustomCombination) {
      throw new Error(`Could not get study data for GeneralPerformanceByFuelPolicyImpactAlgorithm because there is no custom combination for both fuels set`)
    }

    return {
      [TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC]: await this.getStudyDataByCustomCombination(allElectricCustomCombination),
      [TYPE_FUELS_DB_SLUGS.MIXED_FUEL]: await this.getStudyDataByCustomCombination(mixedFuelCustomCombination),
    }
  }

  async getMinimumStudyData() {
    const {
      [TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC]: allElectricCustomCombination,
      [TYPE_FUELS_DB_SLUGS.MIXED_FUEL]: mixedFuelCustomCombination
    } = this.getCustomCombinationsByFuel()
    if (!allElectricCustomCombination || !mixedFuelCustomCombination) {
      throw new Error(`Could not get study data for GeneralPerformanceByFuelPolicyImpactAlgorithm because there is no custom combination for both fuels set`)
    }
    const checkRequiredKeys = (customCombination) => {
      return customCombination.prototype && customCombination.prototype.study_id && customCombination.prototype_id &&
        customCombination.fuel && customCombination.fuel.id
    }
    if (!checkRequiredKeys(allElectricCustomCombination) || !checkRequiredKeys(mixedFuelCustomCombination)) {
      throw new Error(`Could not get study data for GeneralPerformanceByFuelPolicyImpactAlgorithm because the custom combination is missing required properties`)
    }

    const getStudyData = async (customCombination, minimumMeasureSlug) => {
      const measureStudyData = await this.getStudyDataByMeasureSlug(customCombination.prototype.study_id, customCombination.prototype_id, customCombination.fuel.id, minimumMeasureSlug)
      if (!measureStudyData) {
        return await this.getStudyDataByMinComplianceMargin(
          customCombination.prototype.study_id, customCombination.prototype_id, customCombination.fuel.id, 0
        )
      }
      return measureStudyData
    }
    return {
      [TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC]: await getStudyData(allElectricCustomCombination, GeneralPerformanceByFuelPolicyImpactAlgorithm.allElectricMinimumSlug),
      [TYPE_FUELS_DB_SLUGS.MIXED_FUEL]: await getStudyData(mixedFuelCustomCombination, GeneralPerformanceByFuelPolicyImpactAlgorithm.mixedFuelMinimumSlug),
    }
  }

  async getStudyDataByCustomCombination(customCombination) {
    if (!customCombination || !customCombination.prototype_id || !customCombination.prototype || !customCombination.prototype.study_id) {
      const customCombinationId = customCombination && customCombination.id ? customCombination.id : null
      throw new Error(`Could not get study data for GeneralPerformanceByFuelPolicyImpactAlgorithm on custom combination: ${customCombinationId || 'unknown'}`)
    }

    const savedComplianceMeasureId = customCombination && customCombination.meta && customCombination.meta.compliance_margin_measure_id ? customCombination.meta.compliance_margin_measure_id : null
    const minComplianceMargin = customCombination && customCombination.meta && customCombination.meta.compliance_margin_value ? customCombination.meta.compliance_margin_value : 0
    const virtualComplianceMargin = customCombination && customCombination.meta && customCombination.meta.generate_virtual_compliance_margin ? customCombination.meta.generate_virtual_compliance_margin : null
    if (savedComplianceMeasureId) {
      return await this.getStudyDataByMeasureId(
        customCombination.prototype.study_id, customCombination.prototype_id, customCombination.fuel.id, [savedComplianceMeasureId]
      )
    } else if(virtualComplianceMargin) {
      return await this.generateVirtualStudyData(customCombination, virtualComplianceMargin)
    } else {
      return await this.getStudyDataByMinComplianceMargin(
        customCombination.prototype.study_id, customCombination.prototype_id, customCombination.fuel.id, minComplianceMargin
      )
    }
  }

  async getStudyDataByMeasureId(studyId, prototypeId, fuelId, measureIds) {
    const cachedKey = `c:${this.climateZoneRaw}_fuel_${fuelId}_${studyId}_${prototypeId}_measures_${measureIds.join('-')}`
    if (!cachedStudyData[cachedKey]) {
      const studyData = await StudyDataApiService.getForNewBuildingsByMeasureId(
        studyId, prototypeId, this.climateZoneRaw, fuelId, measureIds
      )
      if (!studyData) {
        return null
      }

      const complianceMarginKey = this.getStudyComplianceMarginKey(studyId)
      cachedStudyData[cachedKey] = studyData
      cachedStudyData[cachedKey].min_compliance_margin = (studyData && studyData.study_data && studyData.study_data[complianceMarginKey]) ? studyData.study_data[complianceMarginKey] : 0
    }
    return cachedStudyData[cachedKey]
  }

  async getStudyDataByMeasureSlug(studyId, prototypeId, fuelId, measureSlug) {
    const measures = Store.getters['globalEntities/Measure/getterGlobalMeasures']({ slug: measureSlug, study_id: studyId }) || []
    const measureIds = Array.isArray(measures) ? measures.map(m => m?.id || null).filter(m => m !== null) : []
    return (measureIds && measureIds.length) ? await this.getStudyDataByMeasureId(studyId, prototypeId, fuelId, measureIds) : null
  }

  async getStudyDataByMinComplianceMargin(studyId, prototypeId, fuelId, minComplianceMargin) {
    const cachedKey = `c:${this.climateZoneRaw}_fuel_${fuelId}_${studyId}_${prototypeId}_${minComplianceMargin}`
    if (!cachedStudyData[cachedKey]) {
      cachedStudyData[cachedKey] = await StudyDataApiService.getForNewBuildingsByMinComplianceMargin(
        studyId, prototypeId, this.climateZoneRaw, fuelId, minComplianceMargin
      )
      if (cachedStudyData[cachedKey]) {
        cachedStudyData[cachedKey].min_compliance_margin = minComplianceMargin
      }
    }
    return cachedStudyData[cachedKey]
  }

  async getPvAdjustmentStudyData(studyId, prototypeId, fuelId, selectedNonPvSlug = null) {
    if (selectedNonPvSlug) {
      const pvAdjustedSd = await this.getStudyDataByMeasureSlug(studyId, prototypeId, fuelId, selectedNonPvSlug + '-pv')
      if (pvAdjustedSd) {
        return pvAdjustedSd
      }
    }

    const pvStudyData = await this.getStudyDataByMeasureSlug(studyId, prototypeId, fuelId, GeneralPerformanceByFuelPolicyImpactAlgorithm.pvOnlySlug)
    if (!pvStudyData) {
      throw new Error(`Could not find any PVOnly Measure by studyId ${studyId} and slug ${GeneralPerformanceByFuelPolicyImpactAlgorithm.pvOnlySlug}`)
    }
    return pvStudyData
  }

  getStudyComplianceMarginKey(studyId) {
    const study = Store.getters['globalEntities/Study/getterGlobalStudy']({ id: studyId })
    return study.compliance_margin_key || 'compliance_margin'
  }

  async adjustLargePvStudyData(customCombination, fuelStudyData) {
    const isPvEnabledOnCustomCombination = customCombination && customCombination.meta && customCombination.meta.require_pv_system === true
    const isPvEnabledOnStudyData = fuelStudyData && fuelStudyData.measure && fuelStudyData.measure.has_larger_pv_included === true
    const complianceMarginKey = this.getStudyComplianceMarginKey(customCombination.prototype.study_id)

    // disabled/enabled is the same, doesn't need to adjust...
    if (!fuelStudyData || !GeneralPerformanceByFuelPolicyImpactAlgorithm.pvEnabledComplianceMarginKeys.includes(complianceMarginKey) || isPvEnabledOnCustomCombination === isPvEnabledOnStudyData) {
      return fuelStudyData
    }
    
    const sumOperation = Boolean(isPvEnabledOnCustomCombination && !isPvEnabledOnStudyData)
    const adjustmentStudyData = await this.getPvAdjustmentStudyData(
      customCombination.prototype.study_id, customCombination.prototype_id, customCombination.fuel.id,
      (fuelStudyData?.measure?.slug || null)
    )
    
    const fuelStudyBaseKwh = fuelStudyData.study_data?.base_kwh || 0

    const adjustmentBaseKwh = adjustmentStudyData.study_data?.base_kwh || 0
    if (!adjustmentStudyData || !adjustmentBaseKwh || !fuelStudyBaseKwh) {
      return fuelStudyData
    }

    if (adjustmentStudyData?.measure?.slug && adjustmentStudyData?.measure?.slug !== GeneralPerformanceByFuelPolicyImpactAlgorithm.pvOnlySlug) {
      return adjustmentStudyData
    }

    const assumptions = this.getAssumptions()
    const changedInfo = { ... fuelStudyData };
    ['study_data', 'data'].forEach((subset) => {
      changedInfo[subset] = { ... changedInfo[subset] }
      const kwhRatio = (fuelStudyBaseKwh - changedInfo[subset].kwh_savings) / adjustmentBaseKwh;
      
      ['initial_cost', 'annual_bill_savings', 'kwh_savings', 'lifecycle_savings', 'annual_bill_savings_care', 'lifecycle_savings_care'].forEach((key) => {
        if (sumOperation) {
          changedInfo[subset][key] += (kwhRatio * adjustmentStudyData[subset][key])
        } else {
          changedInfo[subset][key] -= (kwhRatio * adjustmentStudyData[subset][key])
        }
      })
      changedInfo[subset].energy_savings_combined = ((changedInfo[subset]?.kwh_savings || 0) * 0.003412) +
        ((changedInfo[subset]?.therms_savings || 0) * 0.1)
      changedInfo[subset].emissions_savings = ((changedInfo[subset]?.therms_savings || 0) * assumptions.current_therms_emissions_factor) +
        ((changedInfo[subset]?.kwh_savings || 0) * assumptions.current_kwh_emissions_factor)
    })
    return changedInfo
  }

  async generateVirtualStudyData(customCombination, virtualComplianceMargin) {
    const complianceMarginKey = this.getStudyComplianceMarginKey(customCombination.prototype.study_id)
    let closestMin = null
    let closestMax = null
    const allStudyDatas = await StudyDataApiService.getForNewBuildingsByMinComplianceMargin(
        customCombination.prototype.study_id, customCombination.prototype_id, this.climateZoneRaw, customCombination.fuel.id, 0, true
    )

    allStudyDatas?.forEach((studyData) => {
      if (+studyData?.study_data?.[complianceMarginKey] <= virtualComplianceMargin && (!closestMin || +studyData?.study_data?.[complianceMarginKey] > +closestMin?.study_data?.[complianceMarginKey])) {
        closestMin = studyData
      }
      if (+studyData?.study_data?.[complianceMarginKey] >= virtualComplianceMargin && (!closestMax || +studyData?.study_data?.[complianceMarginKey] < +closestMax?.study_data?.[complianceMarginKey])) {
        closestMax = studyData
      }
    })

    if (!closestMin || !closestMax) {
      console.error(`Could not generate virtual study data because we could't find closest min/max study data to base virtual`)
      return null
    }

    if (+closestMin.study_data?.[complianceMarginKey] === virtualComplianceMargin) {
      return closestMin
    }

    let virtualStudyData = {
      ...closestMin,
      virtualData: {
        min: closestMin,
        max: closestMax
      }
    };
    ['study_data', 'data'].forEach((subset) => {
      virtualStudyData[subset] = { ... virtualStudyData[subset] }
      virtualStudyData[subset][complianceMarginKey] = virtualComplianceMargin
      PER_HOME_IMPACT_KEYS.forEach((key) => {
        if (['compliance_margin', 'edr1_total_margin'].includes(key) || isNaN(virtualStudyData[subset][key])) {
          return
        }
        const sumPerUnit = (closestMax?.[subset]?.[key] - closestMin?.[subset]?.[key]) / ((+closestMax?.[subset]?.[complianceMarginKey] - +closestMin?.[subset]?.[complianceMarginKey]) || 1)
        const unitDiff =  virtualComplianceMargin - +closestMin?.[subset]?.[complianceMarginKey]
        virtualStudyData[subset][key] += sumPerUnit * unitDiff
      })
    })
    delete virtualStudyData.measure
    virtualStudyData.measure_id = -1
    virtualStudyData.id = -1
    virtualStudyData.is_max = false
    virtualStudyData.study_row_number = -1
    virtualStudyData.min_compliance_margin = virtualComplianceMargin
    return virtualStudyData
  }

  mergeData(a, b, subtract = false) {
    const newObj = {...a}
    PER_HOME_IMPACT_KEYS.forEach((key) => {
      if (['compliance_margin', 'edr1_total_margin'].includes(key)) {
        return
      }
      if (subtract) {
        newObj[key] -= b && b[key] && !isNaN(b[key]) ? b[key] : 0
      } else {
        newObj[key] += b && b[key] && !isNaN(b[key]) ? b[key] : 0
      }
    })
    return newObj
  }

  getCommonKeys(a) {
    return this.commonKeys.reduce((acc, curr) => {
      acc[curr] = a[curr] || null
      return acc
    }, {})
  }

  generateDataFromRawStudyData(rawStudyData) {
    return PER_HOME_IMPACT_KEYS.reduce((acc, curr) => {
      acc[curr] = rawStudyData?.[curr] || undefined
      return acc
    }, {})
  }

  static extractMetaInfoFromCustomCombination(customCombination) {
    const fuelChoice = customCombination && customCombination.meta && customCombination.meta.fuel_choice ? customCombination.meta.fuel_choice : FUEL_CHOICES.ALLOWED
    const minComplianceMargin = customCombination && customCombination.meta && customCombination.meta.compliance_margin_value ? customCombination.meta.compliance_margin_value : 0
    const complianceMeasureId = minComplianceMargin !== 0 && customCombination && customCombination.meta && customCombination.meta.compliance_margin_measure_id ? customCombination.meta.compliance_margin_measure_id : null
    const isPvEnabled = Boolean(customCombination && customCombination.meta && customCombination.meta.require_pv_system === true)
    const virtualComplianceMargin = customCombination && customCombination.meta && customCombination.meta.generate_virtual_compliance_margin ? customCombination.meta.generate_virtual_compliance_margin : null
    const hasVirtualComplianceMarginSet = Boolean(virtualComplianceMargin)
    return {
      fuelChoice,
      complianceMeasureId,
      minComplianceMargin: minComplianceMargin || virtualComplianceMargin || 0,
      isPvEnabled,
      isOnDefaultState: Boolean(fuelChoice === FUEL_CHOICES.ALLOWED && !complianceMeasureId && !minComplianceMargin && !isPvEnabled && !hasVirtualComplianceMarginSet)
    }
  }
}
