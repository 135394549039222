import {
  forecast_emissions_savings,
  forecast_initial_cost,
  forecast_lifecycle_savings,
  forecast_energy_savings
} from '@/modules/app/shared/default-app-columns.js'

export default (firstColumn) => {
  let defaultColumns = [
    {
      ...forecast_energy_savings(),
    },
    {
      ...forecast_initial_cost(),
      icon: "/icon_compliance_cost.svg"
    },
    {
      ...forecast_emissions_savings(),
      icon: "/icon_emissions.svg",
    },
    {
      ...forecast_lifecycle_savings(),
      icon: "/icon_lifecycle_savings.svg",
    }
  ]
  if (firstColumn) defaultColumns = [firstColumn,...defaultColumns] 
  return defaultColumns
}