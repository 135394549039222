<template>
  <div class="psui-w-full">
    <PolicyShowContentHeader
      :title="'Policy Requirements' | policyTextRephrase"
      :subtitle="'Review and edit your policy requirements' | policyTextRephrase"
    >
      <template #right>
        <PsButton 
          v-if="policyHasCustomCombinations && getPolicyNewOrExisting === 'new'"
          label="Download summary"
          size="small"
          layout="ghost"
          icon="download"
          icon-classes="psui-ml-1"
          :loading="isDownloadingPolicyRequirementsSummaryPdf"
          @click="downloadRequirementsSummary()"
        />

        <PsButton 
          v-else-if="policyHasCustomCombinations && getPolicyNewOrExisting === 'existing'"
          label="Download summary"
          size="small"
          layout="ghost"
          icon="download"
          icon-position="right"
          icon-classes="psui-ml-1"
          :loading="isDownloadingPolicyRequirementsSummaryPdf || isDownloadingFlexibleCompliancePdf"
          @click="downloadPdf()"
        />
      </template>
    </PolicyShowContentHeader>

    <div
      class="psui-w-full psui-flex psui-flex-col psui-space-y-8 psui-mb-20"
    >
      <template
        v-if="getPolicyContainersOrderedByTypePrototypeOrder.length > 0"
      >
        <PolicyContainer
          v-for="policyContainer of getPolicyContainersOrderedByTypePrototypeOrder" 
          :key="policyContainer.id"
          :policy-container="policyContainer"
          :flexible_path_setup="flexible_path_setup"
          :policy="getPolicy"
        />
      </template>
      <PolicyRequirementsAvailableTypePrototypes
        v-if="userCanEditPolicy"
        :type-prototypes="getTypePrototypesIgnoringTypePrototypesWithinPolicySelected"
        :policy="getPolicy"
        :loading-index="loadingIndex"
        @add-requirements="addPolicyContainerToPolicy"
      />
    </div>

    <PolicyFlexiblePathDownloadModal :policy="getPolicy" />
  </div>
</template>

<script>
import PolicyContainer from './PolicyContainer.vue'
import PolicyRequirementsAvailableTypePrototypes from './PolicyRequirementsAvailableTypePrototypes.vue'
import PolicyShowContentHeader from '../PolicyShowContentHeader.vue'
import PolicyDocumentsApiService from '@/services/api/PolicyDocumentsApiService'
import {STUDY_TYPES, STUDY_TYPES_DB_SLUGS, TYPE_FUELS_DB_SLUGS} from '@/util/Enums.js'
import PolicyFlexiblePathDownloadModal from '../flexible-path/PolicyFlexiblePathDownloadModal.vue'
import PolicyRequirementsSummaryPdf from '@/services/pdf/PolicyRequirementsSummaryPdf'
import PolicyFlexibleCompliancePdf from '@/services/pdf/PolicyFlexibleCompliancePdf'
import { mapGetters } from 'vuex'
import FlexiblePathService from '@/services/api/FlexiblePathService.js'
import { ExistingBuildingsPOBuilder } from "@/business-logic/services/policy-options/builders/existing-buildings"
import {
  GeneralPerformanceByFuelPolicyImpactAlgorithm
} from "@/business-logic/services/impact-algorithms/policy/general-performance-by-fuel";
import CustomCombination from "@/models/CustomCombination";
import PolicyEditRequirementsNewBuildingsSF2022
  from "@/modules/app/policy/show/requirements/requirements-drawer/PolicyEditRequirementsNewBuildingsSF2022/index.vue";
import PolicyExistingBuildingsWithFlexiblePathRequirements
  from "@/modules/app/policy/show/requirements/requirements-drawer/PolicyExistingBuildingsWithFlexiblePathRequirements.vue";
import Store from "@/store";

export default {
  name: 'PolicyRequirements',
  components: {
    PolicyContainer,
    PolicyRequirementsAvailableTypePrototypes,
    PolicyShowContentHeader, 
    PolicyFlexiblePathDownloadModal
  },
  props: ['policy','flexible_path_setup'],
  data:()=>({
    loadingIndex: false,
    isLoading: false,
    isDownloadingSummary: false,
    isDownloadingPolicyRequirementsSummaryPdf: false,
    isDownloadingFlexibleCompliancePdf: false,
    STUDY_TYPES_DB_SLUGS,
  }),
  computed: {
    getPolicyContainersOrderedByTypePrototypeOrder() {
      let policyContainers = this.getPolicy?.policy_containers ?? []
      policyContainers = policyContainers.map((policy_container) => {
        const { order } = this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: policy_container.type_prototype_id })
        policy_container.type_prototype_order = order

        // lets validate if the policy container is not on default state
        if (policy_container.study_type.slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS) {
          const prototypesByTypePrototype = Store.getters['globalEntities/Prototype/getterGlobalPrototypes']({ type_prototype_id: policy_container.type_prototype_id })?.map((i) => i.id) || []
          const shouldIgnoreContainerByMeasures = (policy_container?.custom_combinations || []).every((cc) => {
            return !cc?.measures?.length
          })
          const shouldIgnoreContainerByFlexSetup = (this.flexible_path_setup?.tiers || [])?.filter((t) => prototypesByTypePrototype.includes(t.prototype_id))?.every((tier) => {
            return !tier.excluded_measures?.length && !tier?.mandatory_measures?.length && !tier?.target_scores?.some((i) => !isNaN(i.value) && +i.value > 0)
          })

          if (shouldIgnoreContainerByFlexSetup && shouldIgnoreContainerByMeasures) {
            return null
          }
        } else if (policy_container.study_type.slug === STUDY_TYPES_DB_SLUGS.NEW_BUILDINGS) {
          const shouldIgnoreContainer = (policy_container?.custom_combinations || []).reduce((acc, curr) => {
            let item = acc.find((i) => +i.prototype_id === +curr.prototype_id && i.climate_zone_raw === curr.climate_zone_raw)
            if (!item) {
              item = {
                prototype_id: curr.prototype_id,
                climate_zone_raw: curr.climate_zone_raw,
                custom_combinations: [],
              }
              acc.push(item)
            }
            item.custom_combinations.push(curr)
            return acc
          }, []).every((item) => {
            return item.custom_combinations.every((cc) => {
              return GeneralPerformanceByFuelPolicyImpactAlgorithm.extractMetaInfoFromCustomCombination(cc).isOnDefaultState
            })
          })

          if (shouldIgnoreContainer) {
            return null
          }
        }

        return policy_container
      }).filter((i) => i)

      policyContainers.sort((a, b) => a.type_prototype_order - b.type_prototype_order)
      return policyContainers
    },
    getTypePrototypeByPolicyContainers(){
      return this.getPolicyContainersOrderedByTypePrototypeOrder?.map(pc => pc.type_prototype_id) 
    },
    getStudyTypes(){
      return this.$store.getters['globalEntities/StudyType/getterGlobalStudyTypes']()
    },
    getPolicy(){
      return this.$store.getters['policy/getterUserPolicy']({ id: this.$route.params.policy_id })
    },
    // TODO - Check how this can not broke if policy has multiple study types
    getPolicyStudyTypeId(){
      const study_type_slug = this.$store.getters['policy/getterPolicyStudyTypes']({ key: 'slug', policy_id: this.$route.params.policy_id })[0] ?? null
      const studyTypeSelected = this.$store.getters['globalEntities/StudyType/getterGlobalStudyType']({ slug: study_type_slug })
      if(studyTypeSelected) return studyTypeSelected.id
      return false
    },
    getPolicyAvailableTypePrototypesByStudyType() {
      return this.$store.getters['policy/getterPolicyAvailableTypePrototypesByStudyType']({
        policy_id: this.getPolicy.id,
        study_type_id: this.getPolicyStudyTypeId,
        checkCodeCycle: false 
      })
    },
    getTypePrototypesIgnoringTypePrototypesWithinPolicySelected(){

      return this.getPolicyAvailableTypePrototypesByStudyType.filter(tp => !this.getTypePrototypeByPolicyContainers.includes(tp.id))
    },
    policyHasContainers(){
      return this.getPolicy?.policy_containers?.length !== 0 ?? false
    },
    policyHasCustomCombinations() {
      return this.$store.getters['policy/getterPolicyCustomCombinations'](this.$route.params.policy_id)?.length > 0 ?? false
    },
    userCanEditPolicy(){
      return this.$store.getters['policy/getterUserCanEditPolicy'](this.$route.params.policy_id)
    },
    getPolicyXlsType() {
      const study_type_slug = this.$store.getters['policy/getterPolicyStudyTypes']({ key: 'slug', policy_id: this.$route.params.policy_id })[0] ?? null
      if(!study_type_slug) return false
      if(study_type_slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS) return 'ExistingResidentialComplianceXls'
      return 'NewSingleFamilyComplianceXls'
    },
    getPolicyNewOrExisting() {
      const study_type_slug = this.$store.getters['policy/getterPolicyStudyTypes']({ key: 'slug', policy_id: this.$route.params.policy_id })[0] ?? null
      if(!study_type_slug) return false
      if(study_type_slug === STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS) return 'existing'
      return 'new'
    },
    getPolicyHasFlexiblePathSetUp() {
      return Boolean(this.flexible_path_setup?.tiers?.some((tier) => {
        return tier?.excluded_measures?.length || tier?.target_scores?.some((ts) => ts?.value != null && ts?.value > 0)
      }))
    },
    ...mapGetters(['lastJurisdictionVisited']),
  },
  methods:{
    getFlexiblePathByTypePrototype(prototype){
      return this.flexible_path_setup
      .cost_effective_presets
      .some( cost_effective_preset => cost_effective_preset.prototype_id ==  prototype.id)
    },
    createBaseFlexiblePathSetup(prototype){

      const getVintagesByStudyIdAndPrototype = this.$store.getters['globalEntities/Vintage/getterGlobalVintages']({study_id:prototype.study_id})

      return this.lastJurisdictionVisited.climate_zones.map((climate_zone) => {
        const commonProperties = {
          climate_zone_id: climate_zone.id,
          prototype_id : prototype.id
        }
        return {
          cost_effective_presets: [
            commonProperties
          ],
          tiers: [
            {
              ...commonProperties,
              mandatory_measures: [],
              excluded_measures: [],
              target_scores: getVintagesByStudyIdAndPrototype.map((vintage) => {
                return {
                  type_vintage_id: vintage.type_vintage_id,
                  value: 0
                }
              })
            }
          ],
          version:3
        }
      }).pop()

    },
    async addPolicyContainerToPolicy({typePrototype, index, studyTypeId}){
      let policyContainer = (this.getPolicy?.policy_containers ?? []).find((container) => {
        return +container.type_prototype_id === +typePrototype.id
      })

      const lastAvailablePrototype = this.$store.getters['globalEntities/Prototype/getterGlobalPrototypes']({
        type_prototype_id: typePrototype.id,
        allow_policy_creation: true,
      }).map((i) => {
        i.study = this.$store.getters['globalEntities/Study/getterGlobalStudy']({ id: i.study_id })
        i.study_type_id = this.$store.getters['globalEntities/StudyGroup/getterGlobalStudyGroup']({ id: i.study.study_group_id })?.study_type_id
        return i
      }).filter(prototype => prototype.policy_option_algorithms.length > 0 && +prototype.study_type_id === +studyTypeId).pop()

      if (!policyContainer) {
        const newContainer = {
          policy_id: this.getPolicy.id,
          study_type_id: studyTypeId,
          type_prototype_id: typePrototype.id,
          type_prototype_order: typePrototype.order
        }
        this.loadingIndex = index
        policyContainer = (await this.$store.dispatch('policy/updateOrCreatePolicyContainer', newContainer)
            .then(async (data) => {
              if(!this.getFlexiblePathByTypePrototype(lastAvailablePrototype)) {
                const baseFlexiblePathSetup = this.createBaseFlexiblePathSetup(lastAvailablePrototype)
                const setup = ExistingBuildingsPOBuilder.mergeFlexConfigs([this.flexible_path_setup, baseFlexiblePathSetup])
                const updatedFlexiblePath = new FlexiblePathService({policy_id:this.policy.id})
                await updatedFlexiblePath.updateSetup(setup)
              }

              this.loadingIndex = false
              return data
            })
            .catch(err => {
              if (err?.response?.data?.message) {
                window.$vueInstance.$appToast({ message: err?.response?.data?.message })
              } else {
                window.$vueInstance.$appToast({type:'error', message: 'Something went wrong, please try again.' })
              }
              this.loadingIndex = false
              return null
            }))
      }

      if (policyContainer) {
        const firstClimateZoneRaw = this.getPolicy.jurisdiction?.climate_zones?.[0]?.raw
        let customCombinations = policyContainer?.custom_combinations?.filter((i) => i.prototype_id && i.climate_zone_raw === firstClimateZoneRaw)
        if (!customCombinations?.length) {
          const fnc = studyTypeId === STUDY_TYPES.NEW_BUILDINGS ? this.initNewBuildingsCustomCombinations : this.initExistingBuildingsCustomCombinations
          customCombinations = await fnc(lastAvailablePrototype, policyContainer, firstClimateZoneRaw)
        }

        this.$eventBus.$emit('openDrawerPolicyEditRequirements', studyTypeId === STUDY_TYPES.NEW_BUILDINGS ? {
          component_name: PolicyEditRequirementsNewBuildingsSF2022.name ,
          custom_combination_ids : customCombinations.map(cc => cc.id).join(','),
        } : {
          component_name: PolicyExistingBuildingsWithFlexiblePathRequirements.name,
          custom_combination_id : [...customCombinations].shift().id,
        })
      }
    },

    async initNewBuildingsCustomCombinations(prototype, policyContainer, climateZoneRaw) {
      const commonCCPayload = {
        prototype_id: prototype.id,
        jurisdiction_id : this.getPolicy.jurisdiction_id,
        policy_id: this.getPolicy.id,
        policy_container_id : policyContainer.id,
        study_id : prototype.study_id,
      }

      // All-Electric
      const { id: all_electric_type_fuel_id } = this.$store.getters['globalEntities/TypeFuel/getterGlobalTypeFuel']({ slug: TYPE_FUELS_DB_SLUGS.ALL_ELECTRIC })
      const all_electric_fuel = this.$store.getters['globalEntities/Fuel/getterGlobalFuel']({ study_id : prototype.study_id, type_fuel_id: all_electric_type_fuel_id })
      const all_electric_custom_combination = new CustomCombination({ ...commonCCPayload, climate_zone_raw: climateZoneRaw, fuel_id : all_electric_fuel.id })

      // Mixed Fuel
      const { id: mixed_fuel_type_fuel_id } = this.$store.getters['globalEntities/TypeFuel/getterGlobalTypeFuel']({ slug: TYPE_FUELS_DB_SLUGS.MIXED_FUEL })
      const mixed_fuel_fuel = this.$store.getters['globalEntities/Fuel/getterGlobalFuel']({ study_id : prototype.study_id, type_fuel_id: mixed_fuel_type_fuel_id })
      const mixed_fuel_custom_combination = new CustomCombination({ ...commonCCPayload, climate_zone_raw: climateZoneRaw, fuel_id : mixed_fuel_fuel.id })

      return await this.$store.dispatch(
          'policy/saveCustomCombinationsToPolicy',
          [ all_electric_custom_combination, mixed_fuel_custom_combination ]
      )
    },
    async initExistingBuildingsCustomCombinations(prototype, policyContainer, climateZoneRaw) {
      const commonCCPayload = {
        prototype_id: prototype?.id,
        jurisdiction_id : this.getPolicy.jurisdiction_id,
        policy_id: this.getPolicy.id,
        policy_container_id : policyContainer.id,
        study_id : prototype.study_id,
      }

      const vintageIds = [...this.$store.getters['globalEntities/Vintage/getterGlobalVintages']({ study_id: prototype.study_id })
          .map((vintage) => vintage.id)]
      const firstVintageId = vintageIds.shift()
      return await this.$store.dispatch(
          'policy/saveCustomCombinationsToPolicy',
          [ new CustomCombination({ ...commonCCPayload, climate_zone_raw: climateZoneRaw, vintage_id : firstVintageId }) ]
      )
    },

    downloadRequirementsTablesXls() {
      this.isLoading = true
      PolicyDocumentsApiService.downloadRequirementsTableXls({ 
        type: this.getPolicyXlsType, 
        policy_id: this.$route.params.policy_id,
        policy: this.getPolicy
      })
        .then(() => {
          this.isLoading = false
        })
    },

    downloadRequirementsSummary(is_flexible_set_up) {
      if (this.isDownloadingPolicyRequirementsSummaryPdf) return
      this.isDownloadingPolicyRequirementsSummaryPdf = true 
      new PolicyRequirementsSummaryPdf({ policy_id: this.$route.params.policy_id, policyType: this.getPolicyNewOrExisting, policy_title: this.policy.title, is_flexible_set_up }).then(() => {
        this.isDownloadingPolicyRequirementsSummaryPdf = false
      })
    },

    downloadFlexibleCompliancePdf(is_flexible_set_up) {
      if (this.isDownloadingFlexibleCompliancePdf) return
      this.isDownloadingFlexibleCompliancePdf = true 
      new PolicyFlexibleCompliancePdf({ policy_id: this.$route.params.policy_id, policyType: this.getPolicyNewOrExisting, policy_title: this.policy.title, is_flexible_set_up }).then(() => {
        this.isDownloadingFlexibleCompliancePdf = false
      })
    },

    downloadPdf(){
      const is_flexible_set_up = this.getPolicyHasFlexiblePathSetUp
      if(is_flexible_set_up) this.downloadFlexibleCompliancePdf(is_flexible_set_up)
      else this.downloadRequirementsSummary(is_flexible_set_up)
    },
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    .psui-el-dropdown-menu-dialog-wrapper {
      width: 290px;
      margin-top: 8px;
    }

    .psui-el-button {
      &:focus {
        box-shadow: none;
      }
    }

    .menu-download {
      .psui-el-button {
        justify-content: space-between;
        
        &:not(.disabled) {
          color: #515E6A;
          min-height: 38px;

          i {
            color: #A2ACB7;
          }

          &:hover {
            color: #318FAC;
            background-color: #ECF7FB;

            i {
              color: #318FAC;
            }
          }
        }

        &.disabled {
          i {
            color: #D6DDE5;
          }
        }
      }
    }
  }
  .menu-download {
    &-helper,
    .psui-el-button {
      padding: 0.438rem 1rem 0.438rem .5rem;
    }
  }
</style>