<template>
  <div
    id="selection"
    class="selection"
  >
    <div
      id="selection-left-axis"
      class="selection-left-axis"
      :style="{ left: `${relativeLeftBarLimitPosition}px` }"
    />
    <div
      id="selection-right-axis"
      class="selection-right-axis"
    >
      <AppNewFeatureBadge
        v-if="canShowNewFeatureBadge"
        feature="bps"
        layout="circle"
        tooltip="<p class=&quot;psui-text-xsmall psui-font-bold&quot;>Drag the handle to change the minimum building size.</p>"
        tooltip-position="right"
        :tooltip-sum-left="-105"
        :tooltip-sum-top="-67"
      />
    </div>
    <div
      id="selection-right-backdrop"
      class="selection-right-backdrop"
    />
  </div>
</template>

<script>
export default {
  name: "ApexSelectionReworked",
  props:['isCumulativeShareActive'],
  data: () => ({
    isSelecting: false,
    mouseMoveHandler: null,
    canShowNewFeatureBadge: true,
    newFeatureBadgeSessionStorageKey: 'bps-chart-selection-badge-disable',
    oldBodyCssText: '',
    selectedMinimumBuildingSizeCategory: null,
    categoriesPositions: [],
    limitSeparatorSize: 0,
    relativeLeftBarLimitPosition: 0,
    relativeRightBarMaxLimitPosition: 0,
    leftLimit: 0,
    rightLimit: 0,
    containerElem: null,
    spaceWidth: 0,
    barWidth: 0,
  }),
  computed: {
    assumptionsDrawerOptions() {
      return this.$store.getters['assumptions/getterAssumptionsDrawerOptions']
    },
  },
  watch:{
    // isCumulativeShareActive: {
    //   handler:function() {
    //       this.init()
    //   }
    // },
    assumptionsDrawerOptions: {
      handler:function() {
          this.init() 
      }
    }
  },
  mounted() {
    this.canShowNewFeatureBadge = window.sessionStorage.getItem(this.newFeatureBadgeSessionStorageKey) !== 'true'
    this.init()
    this.$eventBus.$on('moveChartRightAxis', (getBoundingLabelXvalue, label) => {
      this.selectedMinimumBuildingSizeCategory = label
      this.computeAndChangeSelectorPosition('right', getBoundingLabelXvalue, true, true)
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('moveChartRightAxis')
  },
  methods: {
    redefineChartVariablesAndSizes() {
      const chartElem = document.querySelector('.apexcharts-inner.apexcharts-graphical')
      const firstBarElem = document.querySelector('.apexcharts-bar-area')
      const selectionLeftAxis = document.getElementById('selection-left-axis')
      selectionLeftAxis.style.setProperty('--bps-chart-width', `${chartElem.getBoundingClientRect()?.width || 0}px`)

      this.barWidth = firstBarElem?.getBoundingClientRect()?.width || (this.$parent.$refs?.apexChart?.chartInstance?.w?.globals?.barWidth - 1) // with some tests, we discovered bar width has this conf - 1 in practice
      this.categoriesPositions = [...[...document.querySelectorAll('.apexcharts-xaxis')].shift().querySelectorAll('.apexcharts-xaxis-texts-g .apexcharts-text tspan')].map((i) => {
        const positions = i?.getBoundingClientRect()
        const label = i.textContent.trim()
        const matchPositionNormalizer = (this.barWidth / 2) * 1.66 // we are considering 1.66 because space width is configured to have 40% of total space on chart, so 0.4/0.6 = 0.66
        const centerBarPosition = ((positions?.right || 0) - ((positions?.width || 0) / 4))
        return {
          label,
          position: centerBarPosition,
          matchChangePosition: centerBarPosition + matchPositionNormalizer
        }
      })

      this.containerElem = document.getElementById('middle-side')
      this.limitSeparatorSize = (firstBarElem?.getBoundingClientRect()?.left || 0) - (chartElem?.getBoundingClientRect()?.left || 0)
      this.limitSeparatorSize = !isNaN(this.limitSeparatorSize) && this.limitSeparatorSize > 0 ? this.limitSeparatorSize : 0
      this.leftLimit = (chartElem?.getBoundingClientRect()?.left || 0) + (this.limitSeparatorSize / 2) - 1
      this.rightLimit = (chartElem?.getBoundingClientRect()?.right || 0) - (this.limitSeparatorSize / 2) + 2
      this.relativeLeftBarLimitPosition = this.leftLimit - (this.containerElem?.getBoundingClientRect()?.left || 0)
      this.relativeRightBarMaxLimitPosition = this.getRightBarRelativeRightPosition(this.rightLimit)
      this.spaceWidth = (chartElem.getBoundingClientRect().width - (this.barWidth * this.categoriesPositions.length) - (this.limitSeparatorSize * 2)) / (this.categoriesPositions.length - 1)
    },
    getRightBarRelativeRightPosition(windowXPosition) {
      return (this.containerElem?.getBoundingClientRect()?.right || 0) - windowXPosition
    },
    init(resyncPosition = false) {
      let debounce
      let updated = false
      const adjustFnc = () => {
        if (updated) {
          if (debounce) {
            clearInterval(debounce)
          }
          return
        }
        const apexChartInstance = this.$parent.$refs?.apexChart?.chartInstance
        const barWidth = apexChartInstance?.w?.globals?.barWidth

        if (apexChartInstance && barWidth) {
          this.redefineChartVariablesAndSizes()
          const selectionRightAxis = document.getElementById("selection-right-axis")
          const selectionLeftAxis = document.getElementById("selection-left-axis")
          const backdropElement = document.getElementById("selection-right-backdrop")
          if (selectionRightAxis?.style) {
            selectionRightAxis.style.right = `${this.relativeRightBarMaxLimitPosition}px`
          }

          setTimeout(()=> {
            const currentWidth = selectionRightAxis.getBoundingClientRect().x - selectionLeftAxis.getBoundingClientRect().x
            selectionLeftAxis.style.setProperty('--pseudo-width', `${currentWidth}px`)
            backdropElement.style.setProperty('--backdrop-width', selectionRightAxis?.style?.right || `0px`)
          }, 100)

          this.initMoveEventListeners('right')

          if (debounce) {
            clearInterval(debounce)
          }
          updated = true
          if (resyncPosition === true && this.selectedMinimumBuildingSizeCategory) {
            const categoryPosition = this.categoriesPositions.find((i) => i.label === this.selectedMinimumBuildingSizeCategory)?.position
            if (categoryPosition) {
              this.computeAndChangeSelectorPosition('right', categoryPosition, true, true)
            }
          }
        }
      }

      adjustFnc()
      debounce = setInterval(adjustFnc, 400)
    },
    onMouseDown() {
      this.oldBodyCssText = document.body.style.cssText
      let newCssText = 'user-select: none; -moz user-select: none; -webkit-user-select: none; -ms-user-select: none;'
      if (this.oldBodyCssText.endsWith(';')) {
        newCssText = `${this.oldBodyCssText} ${newCssText}`
      } else if (this.oldBodyCssText.length) {
        newCssText = `${this.oldBodyCssText}; ${newCssText}`
      }
      document.body.style.cssText = newCssText
      this.isSelecting = true
    },
    onMouseUp() {
      if (!this.isSelecting) return
      this.isSelecting = false
      document.body.style.cssText = this.oldBodyCssText
    },
    computeAndChangeSelectorPosition(cssControlVariable, xPosition, ignoreIsSelecting = false, notChangeFeatureBadge = false) {
      if (!this.isSelecting && !ignoreIsSelecting) return

      let selectedCategory = this.categoriesPositions?.map((categoryPosition, idx) => {
        return { idx, relativePosition: Math.abs(xPosition - categoryPosition.matchChangePosition) }
      }).sort((a, b) => {
        return a.relativePosition - b.relativePosition
      }).shift().idx
      selectedCategory = selectedCategory >= 0 ? selectedCategory : 0

      const categoryPosition = this.categoriesPositions[selectedCategory].position
      this.moveTo(categoryPosition, cssControlVariable, notChangeFeatureBadge)
    },
    moveTo(xPosition, cssControlVariable, notChangeFeatureBadge = false) {
      let snappedX = xPosition + (this.barWidth / 2) + (this.spaceWidth / 2)
      if (snappedX < this.leftLimit) {
        snappedX = this.leftLimit
      } else if (snappedX > this.rightLimit) {
        snappedX = this.rightLimit
      }

      requestAnimationFrame(() => {
        const selectionRightAxis = document.getElementById("selection-right-axis")
        const selectionLeftAxis = document.getElementById("selection-left-axis")
        const backdropElement = document.getElementById("selection-right-backdrop")

        const relativeSnappedX = this.getRightBarRelativeRightPosition(snappedX)
        if (selectionRightAxis?.style) {
          selectionRightAxis.style[cssControlVariable] = `${relativeSnappedX}px`
          backdropElement.style.setProperty('--backdrop-width', `${relativeSnappedX}px`)
        }

        const currentWidth = selectionRightAxis.getBoundingClientRect().x - selectionLeftAxis.getBoundingClientRect().x
        selectionLeftAxis.style.setProperty('--pseudo-width', `${currentWidth}px`)

        if (this.canShowNewFeatureBadge && !notChangeFeatureBadge) {
          this.canShowNewFeatureBadge = false
          window.sessionStorage.setItem(this.newFeatureBadgeSessionStorageKey, 'true')
        }

        this.$emit('onXAxis', {
          min: selectionLeftAxis.getBoundingClientRect().x,
          max: selectionRightAxis.getBoundingClientRect().x,
        })
      })
    },
    initMoveEventListeners(cssControlVariable) {
      if (this.mouseMoveHandler) {
        document.removeEventListener('mousemove', this.mouseMoveHandler)
        document.removeEventListener('touchmove', this.mouseMoveHandler)
      }

      this.mouseMoveHandler = (e) => {
        if (e.which !== 1 || !this.isSelecting) return

        if(e.stopPropagation) e.stopPropagation()
        if(e.preventDefault) e.preventDefault()
        e.cancelBubble = true
        e.returnValue = false

        this.computeAndChangeSelectorPosition(cssControlVariable, e.pageX)
      }

      document.addEventListener('mousemove', this.mouseMoveHandler)
      document.addEventListener('touchmove', this.mouseMoveHandler)

      const selectionRightAxis = document.getElementById("selection-right-axis")
      selectionRightAxis.removeEventListener('mouseDown', this.onMouseDown)
      selectionRightAxis.removeEventListener('touchstart', this.onMouseDown)
      selectionRightAxis.addEventListener('mousedown', this.onMouseDown)
      selectionRightAxis.addEventListener('touchstart', this.onMouseDown)
      document.removeEventListener('mouseup', this.onMouseUp)
      document.removeEventListener('touchend', this.onMouseUp)
      document.addEventListener('mouseup', this.onMouseUp)
      document.addEventListener('touchend', this.onMouseUp)
    }
  }
}


</script>

<style lang="scss" scoped>
  $right: 0;
  $left: 0;
  $gray-40: #A2ACB7;
  $gray-50: #798490;  

  .selection {
    position: absolute;
    display: contents;
    z-index: 10;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &-right-backdrop {
      --backdrop-width: 0px;
      pointer-events: none;
      position: absolute;
      top: 64px;
      right: 0;
      height: calc(76% - 15px);
      width: calc(var(--backdrop-width) - 73px);
      background-color: rgba(255, 255, 255, 0.5);
      backdrop-filter: opacity(50%);
      margin-right: 73px;
      z-index: 5;
    }

    &-left-axis {
      --pseudo-width: 1200px;

      transition: left 0.1s;
      padding: 4px 0;
      position: absolute;
      width: 2px;
      top: 13px;
      height: 87%;
      background-color: $gray-40;
      left: $left;
      z-index: 1;
      &:hover {
        cursor: pointer;
      }

      &::after {
        content: "";
        cursor: default;
        position: absolute;
        top: 19px;
        height: 1px;
        width: var(--pseudo-width);
        max-width: calc(var(--bps-chart-width, 4000) - 15px);
        //border: $gray-40 dashed 1px;
        background-image: linear-gradient(to right, $gray-40 50%, rgba(255,255,255,0) 0%);
        background-position: bottom;
        background-size: 8px 1px;
        background-repeat: repeat-x;
      }

      &::before {
        content: "Selection";
        cursor: default;
        position: absolute;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        top: 10px;
        left: calc(var(--pseudo-width) / 2);
        transform: translateX(-50%);
        width: calc(var(--pseudo-width) / 3);
        max-width: 66px;
        min-width: 30px;
        color: white;
        background-color: $gray-50;
        z-index: 80;
        padding: 2px 8px;
        border-radius: 4px;
        font-size: 12px;
        line-height: 130%;
        font-weight: bold;
        display: inline-block; 
        text-align: center;
      }

    }

    &-right-axis {
      position: absolute;
      top: 13px;
      height: 87%;
      width: 2px;
      background-color: $gray-40;
      right: $right;
      z-index: 1;
      &:hover {
        cursor: pointer;
      }

      &::after {
        content: url('/handle_control.svg');
        position: absolute;
        top: 0;
        left: -10px;
        width: 19px;
        height: 36px;
        z-index: 999;
      }

      ::v-deep .new-feature-badge {
        z-index: 1000;
        top: -4px !important;
        right: -19px !important;
      }
    }
}
</style>
