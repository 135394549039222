<template>
  <BaseDrawer
    :id="id"
    ref="baseDrawer"
    :title="title"
    :subtitle="subtitle"
    :layout="layout"
    :breadcrumbs="breadcrumbs"
    :hierarchy="DRAWER_HIERARCHY.SECONDARY"
    :class="{ 'is-helper z-20': type === 'helper' || type === 'helpers' }"
  >
    <div class="app-drawer-content-styles w-full">
      <div
        v-if="!isLoading && getterLoggedUser && $store.getters.getterLoggedUserType == 'admin'"
        v-go-to-admin-edit="{ name: 'AdminHelperEdit', params: { helper_id: id } }"
        class="bg-violet py-1 px-2 my-1"
      >
        Edit this helper at Admin
      </div>
      <div
        v-if="isLoading"
        class="w-full flex mt-6 max-w-full overflow-hidden"
      >
        <AppLoader />
      </div>
      <RenderHtmlExtended
        v-else-if="!isLoading && content"
        :html="$options.filters.policyTextRephrase(content)"
      />
      <div
        v-else
        class="rounded bg-gray06 p-3"
      >
        <p class="text-gray">
          No content available
        </p>
      </div>
    </div>
  </BaseDrawer>
</template>

<script>
import { mapGetters } from 'vuex'
import RenderHtmlExtended from './RenderHtmlExtended.vue'
import { DRAWER_HIERARCHY } from '@/util/Enums.js'
import HelperApiService from '@/services/api/HelperApiService.js'

function stateInitial() {
  return {
    isLoading: true,
    id: null,
    title: '',
    subtitle: '',
    content: '',
    breadcrumbs: null,
    layout: undefined,
    slug: null,
    DRAWER_HIERARCHY,
    type: false,
  }
}

export default {
  name: 'DrawerContent',
  components: { RenderHtmlExtended },
  data() {
    return stateInitial()
  },
  computed: {
    ...mapGetters(['getterLoggedUser']),
  },
  mounted() {
    this.$eventBus.$on('openDrawerContent', ({ type, id, slug, layout, breadcrumbs, avoidAnalyticsEvent }) => {
      this.stateReset()
      this.$refs.baseDrawer.openDrawer()
      this.layout = layout ? layout : undefined
      this.breadcrumbs = breadcrumbs ? breadcrumbs : null
      this.getDrawerContent({ type, id, slug, avoidAnalyticsEvent })
      this.type = type
    })
  },
  beforeDestroy() {
    this.$eventBus.$off('openDrawerContent')
  },
  methods: {
    stateReset() {
      Object.assign(this.$data, stateInitial())
    },

    closeDrawer() {
      this.showDrawer = false
      this.isLoading = true
      setTimeout(() => {
        this.title = ''
        this.content = ''
      }, 500)
    },

    applyVariablesInString(string) {
      if (string) {
        const matchs = string.match(/{{(.*?)}}/g)
        if (matchs) {
          matchs.forEach((source) => {
            const variableName = source.match(/((?!\})(?!\{).)+/g)
            if (this[variableName]) {
              string = string.replace(source, this[variableName])
            }
          })
        }
      }
      return string
    },

    async getDrawerContent({ type, id = null, slug = null, avoidAnalyticsEvent }) {
      this.showDrawer = true
      this.type = type
      this.id = id
      this.slug = slug

      const result = await HelperApiService.get({ type, id, slug, avoidAnalyticsEvent })

      if (!result || result.length === 0) {
        this.$toast.error(`Sorry, we can't find the requested resource :(`)
        this.$refs.baseDrawer.closeDrawer()
        return
      }

      const dataType = Array.isArray(result) ? result : [result]

      const dataBySlug = dataType?.find((item) => {
        return item?.slug === this.slug
      })

      const dataById = dataType?.find((item) => {
        return item?.id === this.id
      })

      const helper = dataBySlug || dataById || null
      this.title = helper?.title
      const subtitle = helper?.subtitle ? helper?.subtitle : helper?.description
      this.subtitle = this.applyVariablesInString(subtitle)
      this.content = helper?.content
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss">
  .is-helper .app-drawer-content-styles {
    max-width: 860px;

    .psui-el-dialog {
      margin-bottom: 12px;
    }
  }

  .app-drawer-content-styles {
    @import '@/styles/vendor/tiny_mce_fixes.scss';
  }

</style>
