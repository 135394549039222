import { getImpactEstimatesFromQuarterInstallations } from '@/business-logic/services/impact-algorithms/common/mechanics'
import newBuildingsInstallations from '@/business-logic/services/impact-algorithms/common/newBuildingsInstallations'
import Store from '@/store'
import dayjs from 'dayjs'

export default (
  building_estimates,
  fuel,
  {
    all_electric_shares_without_policy = 25,
    all_electric_shares_with_policy = 25,
    effective_date = null,
    termination_date = null,
    installation_effects_years = 30,
    applicability_rate = 100,
    annual_penetration_rate = 100,
    delay_installation = 1,
    likelihood_installations_first_third = 0,
    likelihood_installations_second_third = 33,
    likelihood_installations_final_third = 66,
    care_program_enrollment_rate
  },
  {
    currentThermsEmissionFactor,
    currentKwhEmissionFactor,
    kwhSavings,
    energySavings,
    thermsSavings,
    annualBillSavings,
    initialCost,
    gridMaxRenewablesYear,
    gridMaxRenewablesLevel,
    currentGridRenewableLevel,
    subsidy_needed,
    five_year_payback,
    subsidy_needed_care,
    five_year_payback_care
  }) =>
{

  const installationsPerQuarter = newBuildingsInstallations(building_estimates, fuel, {
    all_electric_shares_without_policy,
    all_electric_shares_with_policy,
    effective_date,
    termination_date,
    installation_effects_years,
    applicability_rate,
    annual_penetration_rate,
    delay_installation,
    likelihood_installations_first_third,
    likelihood_installations_second_third,
    likelihood_installations_final_third,
  }, true)

  let unitsNormalization = 1
  if (building_estimates?.length) {
    const typePrototype = Store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({ id: building_estimates[0].type_prototype_id })
    unitsNormalization = typePrototype?.units_normalization || 1
  }
  const startAtDate = installationsPerQuarter.length ? dayjs(installationsPerQuarter[0].quarter).startOf('year') :
    dayjs().startOf('year')

  return getImpactEstimatesFromQuarterInstallations(installationsPerQuarter, startAtDate, care_program_enrollment_rate, {
    currentThermsEmissionFactor,
    currentKwhEmissionFactor,
    kwhSavings,
    energySavings,
    thermsSavings,
    annualBillSavings,
    initialCost,
    gridMaxRenewablesYear,
    gridMaxRenewablesLevel,
    currentGridRenewableLevel,
    subsidy_needed,
    five_year_payback,
    subsidy_needed_care,
    five_year_payback_care
  }, unitsNormalization)
}
