<template>
  <PsAccordionItem
    title="Installation Time"
    class="display-impact-assumption-icon"
    :class="{ 'form-dirty' : hasValueUpdated(getSingleInputValue(assumption.key), assumption.key) }"
  >
    <template #header-additionals>
      <PsIcon
        icon="help_outline"
        size="16"
        display="flex"
        class="help psui-items-center psui-h-5 hover:psui-text-blue-60"
        :icon-classes="hasValueUpdated(getSingleInputValue(assumption.key), assumption.key) ? 'mr-5': ''"
        @click.native.stop="$eventBus.$emit('openDescriptionModal', { slug: 'impact_installation_time_assumption', ...descriptionModelObject })"
      />
    </template>
    <span>
      Installing the requirements takes time. We assume that, on average, it will take <b>this amount of time</b> to get the requirements operational for an individual building, starting from when the requirement is triggered.
    </span>
    <PsInput
      :data-test-id="`input-assumption-${assumption.key}`"
      class="mt-4/5 w-1/2"
      :has-error="inputErrorIndex === assumption.key ? 'Insert a valid value' : false"
      :hint="formattedHint"
      :class="{'selected': hasValueUpdated(getSingleInputValue(assumption.key), assumption.key) && inputErrorIndex !== assumption.key}"
      layout="mini"
      :value="getSingleInputValue(assumption.key)"
      @click.native="checkIfUserCanEditAssumption"
      @input="onInputAvg($event.target.value, assumption.key)"
      @change="onChangeAvg(assumption.key)"
      @blur="onBlurAvg(assumption.key)"
      @mouseenter="onMouseEnter(assumption.key)"
      @mouseleave="onMouseLeave()"
    >
      <template #append>
        <span
          v-if="!hasValueUpdated(getSingleInputValue(assumption.key), assumption.key) || !checkShowResetButton(assumption.key)"
          class="psui-text-gray-50 psui-text-xsmall"
        >Year</span>
        <PsTooltip v-else-if="checkShowResetButton(assumption.key)">
          <template #trigger>
            <PsIcon 
              icon="restart_alt"
              icon-classes="psui-text-green-20 cursor-pointer mt-1"
              size="17"
              @click.native="restoreAssumptionToDefault()"  
            />
          </template>
          <template #content>
            <h1 class="mb-1">
              Restore to default value
            </h1>
            <span class="psui-font-normal">{{ getBaseValue() }}</span>
          </template>
        </PsTooltip>
      </template>
    </PsInput>
  </PsAccordionItem>
</template>

<script>
import { ASSUMPTIONS_KEYS } from '@/util/Enums.js'
import AssumptionImpactsMixin from './AssumptionImpactsMixin'

export default {
  name: 'AssumptionsCardInstallationTime',
  props:['tabSelected', 'userCanEditPolicy', 'descriptionModelObject'],
  mixins: [ AssumptionImpactsMixin ],
  data:()=>({
    localAssumptionValue: {},
    minInstallationTimeValue: 0,
    maxInstallationTimeValue: 3,
    assumption: {
      key: ASSUMPTIONS_KEYS.delay_installation,
    }
  }),
  computed: {
    formattedHint() {
      const minValue = this.minInstallationTimeValue.toFixed(2)
      const maxValue = this.maxInstallationTimeValue.toFixed(2)
      return `${minValue} - ${maxValue}`
    },
  },
  methods: {
    setValue(value) {
      this.$store.dispatch('assumptions/updateAssumptionsKeyValue', { key: 'delay_installation', value, force: true })
    },
    restoreAssumptionToDefault() {
      if(!this.$route.params.policy_id) {
        this.setValue(this.getBaseValue(this.assumption.key),this.assumption.key)
      } else {
        this.setValuePerPrototype(this.getBaseValue(this.assumption.key), this.assumption.key)
      }
      this.dispatchAnalyticsEvent(this.assumption.key, true)
    }, 
    validateInput(value){ 
      return value <= this.maxInstallationTimeValue && value >= this.minInstallationTimeValue
    },
  }
}
</script>

<style lang="scss" scoped>

.psui-el-input.status-resting.layout-mini, .psui-el-input {
    width: 140px;
    &.selected{
      ::v-deep .psui-el-input-wrapper {
        border-color: #5DB883;
      }
    }
    ::v-deep.psui-el-tooltip-dialog { 
      width: fit-content;
       .psui-el-tooltip-content {
        border-radius: 6px;
        background-color: #D6DDE5;
        color: #28323B;
        padding: 10px 16px; 
       }
    }
  }

  ::v-deep .psui-el-input.status-resting.layout-mini.selected {
    .psui-el-input-wrapper {
      border-color: #5DB883;
    }
  }

  ::v-deep .psui-el-input.status-error.layout-mini {
    .psui-el-input-wrapper {
      .psui-el-input-append {
        display: none;
      }
    }
  }

  .psui-el-accordion-item {
    &.status-opened {
      .help {
        color: #318FAC;
      }
    }
  }

</style>
