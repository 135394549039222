var render = function render(){var _vm=this,_c=_vm._self._c;return _c('thead',{class:[
    'sticky z-20 top-0 rounded-t-sm',
    { 'scrolling': _vm.isScrolling, 'overflow-active': _vm.isOverflowActive },
    { 'not-scrolling': !_vm.isScrolling }
  ]},[_c('tr',[_vm._m(0),_c('td',{staticClass:"impact-row psui-text-small bg-[#c2deec] psui-text-blue-70",attrs:{"colspan":_vm.columns.length - 1}},[_c('div',{staticClass:"impact-preview-wrapper flex items-center place-content-center font-bold h-[23px]"},[_c('span',[_vm._v("City-wide Impact Preview")]),_c('PsIcon',{staticClass:"impact-preview-wrapper-icon psui-text-gray-40 hover:psui-text-blue-60 psui-transition leading-none cursor-pointer ml-1",attrs:{"icon":"info_outline","size":"16","display":"flex"},on:{"click":function($event){return _vm.$eventBus.$emit('openDescriptionModal', _vm.impactPreviewHelper)}}})],1)]),_c('td',{staticClass:"sticky-column-right bg-[#e6ecf2] h-[23px]"})]),_c('tr',[_vm._l((_vm.columns),function(item,index){return _c('th',{key:`${item.key}-${index}`,class:[
        'psui-text-small bg-[#ecf7fb] h-[43px]',
        { 'first-header-row sticky-column-left': index === 0 },
      ]},[(index === 0)?_c('div',{staticClass:"border-r border-[#e6ecf2] h-full",class:[
          'pt-1 pb-1 pr-[23px] text-[#00465F]',
          { 'pl-4': index === 0 },
        ]},[_c('span',[_vm._v(" Requirements for permit applicants ")])]):_c('div',{staticClass:"table-header items-center px-4/5"},[_c('span',{staticClass:"psui-text-small psui-text-blue-70 mx-auto text-center w-fit"},[_vm._v(_vm._s(item.title))]),_c('PsIcon',{staticClass:"table-header-info-icon psui-text-gray-40 cursor-pointer hover:psui-text-blue-60 w-fit ml-[2px] mt-[1px]",attrs:{"icon":"info_outline","size":"16","display":"flex"},on:{"click":function($event){return _vm.$eventBus.$emit('openDescriptionModal', item.hasHelper)}}}),(item.description)?_c('span',{staticClass:"psui-text-gray-50 psui-text-xsmall font-normal text-center"},[_vm._v(_vm._s(item.description))]):_vm._e()],1)])}),_vm._m(1)],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticClass:"sticky-column-left rounded-tl-sm z-20 bg-[#c2deec] h-full"},[_c('div',{staticClass:"w-full block h-[23px]"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{staticClass:"sticky-column-right bg-[#e6ecf2]"},[_c('div')])
}]

export { render, staticRenderFns }