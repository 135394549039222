import Api from '@/util/Api'
import Policy from '@/models/Policy'
import Graphql from '@/util/GraphQL'
import UserEventApiService from '@/services/api/UserEventApiService'
import Store from '@/store'
import asyncCacheResolver from '@/util/asyncCacheResolver'
import { ERRORS } from '@/util/Enums'
import * as sha1 from 'sha1'
import { GA_LABELS, gTagEvent } from '@/mixins/GaEventsMixin' 

export default class PolicyApiService {

  static completeBatchStore(policyData, policyContainers, flexPathSetup) {
    return Api.post('/api/policy_batch/store', {
      policy: policyData,
      policy_containers: policyContainers,
      flexible_path_setup: flexPathSetup
    }).then(({ data }) => {
      UserEventApiService.log({ event: 'POLICY_CREATE', table: 'policies', table_item_id: data.id })
      return Store.dispatch('policy/getUserPolicy', { policyId: data.id, forceGet: true }, { root: true })
    }).catch(err => {
      if (err?.response?.data?.message) {
        window.$vueInstance.$appToast({ message: err?.response?.data?.message })
      } else {
        window.$vueInstance.$appToast({type:'error', message: 'Something went wrong, please try again.' })
      }
    })
  }

  static async store(payload, config) {
    try {
      const { data } = await Api.post('/api/policies/store', new Policy(payload), config)
      Store.dispatch('policy/getAllUserPolicies')
      UserEventApiService.log({ event: 'POLICY_CREATE', table: 'policies', table_item_id: data.id })
      const policyOption = Store.getters['globalEntities/PolicyOption/getterGlobalPolicyOption']({id: data?.policy_option_id})
      
      gTagEvent(GA_LABELS.NEW_POLICY, {
        policy_id: data.id,
        policy_option_slug: policyOption?.slug || null,
        jurisdiction_slug: data.jurisdiction?.slug || null,
      })

      return data
    } catch (err) {
      if (err?.response?.data?.message) {
        window.$vueInstance.$appToast({ message: err?.response?.data?.message })
      } else {
        window.$vueInstance.$appToast({type:'error', message: 'Something went wrong, please try again.' })
      }
    }
  }

  static update(policy_id, payload, config) {
    return Api.post(`/api/policies/update/${policy_id}`, payload, config)
      .then(({ data }) => data )
      .catch(err => {
        if (err?.response?.data?.message) {
          window.$vueInstance.$appToast({ message: err?.response?.data?.message })
        } else {
          window.$vueInstance.$appToast({type:'error', message: 'Something went wrong, please try again.' })
        }
      })
  }

  static delete(policy_id) {
    return Api.delete(`/api/policies/delete/${policy_id}`)
  }

  static attachToUser({ user_policies_ids, user_shared_policies_ids }) {
    if(!Store.getters['getterLoggedUser']) return new Promise((res, rej) => rej(ERRORS.USER_NOT_LOGGED_IN))
    return Api.post(`/api/policies/attach_to_user`, { user_policies_ids, user_shared_policies_ids }, { ignoreFeedback: true  })
  }

  static autoGenerate({ type_prototype_ids, policy_id }, config) {
    UserEventApiService.log({ event: 'POLICY_AUTO_GENERATE', table: 'policies', table_item_id: policy_id })
    return Api.post('/api/policy/auto_generate', { policy_id, type_prototype_ids }, config)
  }

  static get(policy_id) {
    return Api.get(`/api/policy/show/${policy_id}`).then(({ data }) => data )
  }

  static get_summary_data(policy_id) {
    return Api.get(`/api/policy/get_summary_data/${policy_id}`).then(({ data }) => data )
  }

  static getUserPolicies() {
    const key = `PolicyApiService.getUserPolicies:${sha1(getUserPoliciesQueryArgs('policies'))}`
    return asyncCacheResolver(key, () => {
      return Graphql({ query: getUserPoliciesQueryArgs('policies'), caller: 'PolicyApiService.getUserPolicies' })
        .then(({ data }) => {
          if(!data.currentUser) return []
          return data.currentUser.policies.map((policy) => new Policy(policy))
        })
    }, 3)
  }
  
  static getUserSharedPolicies() {    
    return Graphql({ query : getUserPoliciesQueryArgs('shared_policies'), caller: 'PolicyApiService.getUserSharedPolicies' })
      .then(({ data }) => {
        if(!data.currentUser) return []
        return data.currentUser.shared_policies.map((policy) => new Policy(policy))
      })
  }

  static getPolicyMap({policy_id}) {
    return Api.get(`/api/policy/get_policy_map/${policy_id}`).then(({ data }) => data )
  }

  static getNewPolicy(policy_id, forceGet=false) {
    if (policy_id === -1) return Promise.resolve(null)
    const query = `
        {
          policy ( findBy: { column: "id", value: "${ policy_id }" } ) {
            ${myPolicyArgs}
          }
        }
      `
    const getData = () => {
      return Graphql({ query, caller: 'PolicyApiService.getNewPolicy' })
          .then(({ data }) => {
            return new Policy(data.policy || {})
          })
    }

    if (forceGet) {
      return getData()
    }

    const key = `PolicyApiService.getNewPolicy:${sha1(query)}`
    return asyncCacheResolver(key, getData, 3)
  }

  static getByPolicyIds(policy_ids) {
    const query = `
        {
          policies ( whereIn: [{ column: "id", value: [${policy_ids.map(m => `"${m}"`).join(',')}] }] ) {
            ${myPolicyArgs}
          }
        }
      `
    return Graphql({ query, caller: 'PolicyApiService.getMultiplePoliciesByIds' })
      .then(({ data }) => {
        return Array.isArray(data?.policies) ? data.policies.map((p) => new Policy(p)) : []
      }).catch(() => {
        return []
      })
  }

  static duplicate({ policy_id, title }) {
    return Api.post(`/api/policies/duplicate/${ policy_id }`, { title })
    .then(({ data }) => {
      return data.policy
    })
  }

  static getJurisdictionsAndPoliciesCreatedFromNotTeamMembers() {
    const query = `
      {
        policies_count: policies(
          where: [{column: "exclude_in_report", value: "false"}]
          count: "id"
          orderBy: [{column: "count_total", order: "DESC"}]
        ) {
          count_total
        }
        jurisdictions_count: policies(
          where: [{column: "exclude_in_report", value: "false"}]
          count: "jurisdiction_id"
          orderBy: [{column: "count_total", order: "DESC"}]
        ) {
          count_total
        }
      }
    `

    return Graphql({ query })
      .then(({ data }) => {
        return data
      })
  }
}



function getUserPoliciesQueryArgs(policy_type = 'policies') {
  return `
      {
        currentUser {
          id
          email
          ${policy_type} (
            whereNull: { columns: ["deleted_at"] }
          ) {
            ${myPolicyArgs}
          }
        }
      }
    `
    }

    const myPolicyArgs = `
    id
    user_id
    user_device_id
    jurisdiction_id
    title
    start_at
    annual_penetration_rate
    applicability_rate
    active_policy_duration
    years
    delay_installation
    likelihood_installations_first_third
    likelihood_installations_second_third
    likelihood_installations_final_third
    grid_max_renewables_year
    grid_max_renewables_level
    current_kwh_emissions_factor
    current_therms_emissions_factor
    current_grid_renewable_level
    all_electric_shares_without_policy
    all_electric_shares_with_policy
    created_at
    updated_at
    deleted_at
    is_auto_generated
    is_flexible_path
    user {
      id
      email
    }
    jurisdiction {
      id
      title
      type
      title_type
      titles
      county
      slug
      is_collection
      climate_zones (
        orderBy: [ { column: "prefix", order: "ASC" } ]
      ) {
        id
        raw
        prefix
        suffix
      }
    }
    policy_containers {
      id
      title
      type_prototype_id
      study_type_id
      policy_id
      study_type {
        id
        slug
        title
      }
      custom_combinations {
        id
        user_id
        policy_container_id
        policy_id
        jurisdiction_id
        climate_zone_raw
        prototype_id
        vintage_id
        fuel_id
        meta
        prototype {
          id
          fuel_switch_package_measure_id
          title
          slug
          study_id
        }
        fuel {
          title
          id
          study_id
          slug
          type_fuel_id
        }
        vintage {
          title
          id
          study_id
          slug
          type_vintage_id
        }
        measures {
          id
          title
          order
        }
      }
    }
    code_cycle {
      id
      title
      slug
      start_at
      end_at
    }
    policy_option {
      id
      alert_text
    }
    `
