import CustomBuildingStock from '@/models/CustomBuildingStock'
import DataAndObjectFunctions from '@/util/DataAndObjectFunctions'

export default {

  setJurisdictionBuildingStocks(state, { jurisdiction_id, building_stocks }) {
    const currentJurisdictionId = [...building_stocks]?.pop()?.jurisdiction_id || jurisdiction_id
    state.jurisdiction_building_stocks = state.jurisdiction_building_stocks.removeAllInArray({ jurisdiction_id }).filter((i) => {
      return i.jurisdiction_id === currentJurisdictionId
    })
    state.jurisdiction_building_stocks = [ ...state.jurisdiction_building_stocks, ...building_stocks]
  },
  setJurisdictionBpsBuildingStocks(state, { jurisdiction_id, bps_building_stocks }) {
    const currentJurisdictionId = [...bps_building_stocks]?.pop()?.jurisdiction_id || jurisdiction_id
    state.bps_jurisdiction_building_stocks = state.bps_jurisdiction_building_stocks.removeAllInArray({ jurisdiction_id }).filter((i) => {
      return i.jurisdiction_id === currentJurisdictionId
    })
    state.bps_jurisdiction_building_stocks = [ ...state.bps_jurisdiction_building_stocks, ...bps_building_stocks]
  },
  deleteCustomBuildingStockOfPolicy(state, policy_id) {
    state.custom_building_stocks = state.custom_building_stocks.filter((cbs) => cbs.policy_id !== policy_id)
  },

  setCustomBuildingStocks(state, { custom_building_stocks, policy_id_selected = null }) {

    const stateCustomBuildingStocks = [...state.custom_building_stocks]
    const payloadCustomBuildingStocks = custom_building_stocks.map(pcbs => new CustomBuildingStock(pcbs))
    
    payloadCustomBuildingStocks.forEach(cbs => {
      const filterPayload = DataAndObjectFunctions.filterObjectKeys(CustomBuildingStock.filtrableKeys, cbs)
      filterPayload.policy_id = policy_id_selected

      const buildingStockExists = stateCustomBuildingStocks.findInArray(filterPayload)
      if (buildingStockExists) {
        buildingStockExists.id = cbs.id
        buildingStockExists.units = (!cbs.units) ? 0 : cbs.units
        buildingStockExists.user_id = cbs.user_id
      } else {
        stateCustomBuildingStocks.push(cbs)
      }      
      
    })

    state.custom_building_stocks = [ ...stateCustomBuildingStocks ]
  },

  clearAllBuildingStocks(state) {
    state.jurisdiction_building_stocks = []
    state.custom_building_stocks = []
  },
  
  clearCustomBuildingStocks(state, cbe_to_clear = null) {
    if (cbe_to_clear?.length) {
      state.custom_building_stocks = [...state.custom_building_stocks].filter(cbs => {
        return !cbe_to_clear.findInArray({
          climate_zone_prefix: cbs.climate_zone_prefix,
          type_prototype_id: cbs.type_prototype_id,
          type_vintage_id: cbs.type_vintage_id,
          jurisdiction_id: cbs.jurisdiction_id,
          policy_id: cbs.policy_id,
        })
      })
    } else {
      state.custom_building_stocks = []
    }
  },

  buildingStocksInit(state) {
    state.is_updating = true
  },
  
  buildingStocksFinish(state) {
    state.is_updating = false
  },
  
  buildingStocksFail(state) {
    state.is_updating = false
  },

  buildingStockStoreInit(state) {
    state.is_updating = true
  },
  
  buildingStockStoreFinish(state) {
    state.is_updating = false
  },
  
  buildingStockStoreFail(state) {
    state.is_updating = false
  },

  buildingStocksStoreInit(state) {
    state.is_updating = true
  },
  
  buildingStocksStoreFinish(state) {
    state.is_updating = false
  },
  
  buildingStocksStoreFail(state) {
    state.is_updating = false
  },

}
