<template>
  <div ref="jurisdiction-wide-assumption">
    <div
      v-if="$route.name === 'BuildingEstimates'"
      class="psui-shadow-elevation-10"
    >
      <h1 class="psui-text-xsmall psui-text-gray-40 psui-font-bold pb-4/5 psui-ml-6 pt-4">
        Building Estimates Assumptions
      </h1>
      <PsDropdown
        ref="PSDropdown"
        class="ml-3"
      >
        <template #dropdownTrigger>
          <div class="title-elem flex cursor-pointer items-center psui-text-h6 psui-font-bold psui-text-gray-80 hover:opacity-50">
            {{ getPathSelected.title }}
            <PsIcon 
              icon="expand_more"
              size="20"
              icon-classes="psui-text-blue-60"
            />
          </div>
        </template>
        <template #items>
          <ul class="py-3 w-full">
            <li
              v-for="assumptionPath in getAllAssumptionsPathBaseValue"
              :key="assumptionPath.slug"
              class="px-5 py-1/2 psui-text-gray-60 psui-text-small psui-font-bold psui-cursor-pointer hover:opacity-30"
              @click="setPathQueryBuildingType(assumptionPath)"
            >
              {{ assumptionPath.title }}
            </li>
          </ul>
        </template>
      </PsDropdown>
      <PsTabHeader
        v-if="path.slug !== ASSUMPTIONS_PATHS.IMPACT_RESULTS.slug"
        layout="underline"
        :items="getStudyTypeModified"
        :selected.sync="getTabSelected"
        key-label="title"
        key-value="slug"
        class="mx-6 border-none"
      />
    </div>
    <div
      v-else-if="$route.name === 'PolicyShow'"
      class="psui-shadow-elevation-10 pt-4 psui-px-6"
      :class="{'pb-5/6':!getShowTabHeader}"
    >
      <span
        class="flex items-center psui-text-gray-40 psui-text-xsmall psui-font-bold cursor-pointer"
        @click="onClickBreadcrumb"
      >
        <div 
          v-if="path.slug !== ASSUMPTIONS_PATHS.IMPACT_RESULTS.slug"
          v-tooltip="{ 
            content: getPolicySelected.title, 
            classes: 'tooltip-breadcrumb-title tooltip-bg-gray'
          }"
          class="psui-inline-flex"
        >
          <span class="title-truncate">
            {{ getPolicySelected.title }}
          </span>
        </div>

        <span v-else>
          {{ getPolicySelected.title }}
        </span>

        <div
          v-if="path.slug !== ASSUMPTIONS_PATHS.IMPACT_RESULTS.slug"
          class="psui-flex psui-items-center"
        >
          <PsIcon
            icon="chevron_right"
            size="16"
            icon-classes="psui-text-gray-40"
          />
          <span 
            :class="{'psui-text-blue-60':path.slug !== ASSUMPTIONS_PATHS.IMPACT_RESULTS.slug}"
          >
            {{ 'Policy Assumptions' | policyTextRephrase }}
          </span>
        </div>
      </span>
      <h1 class="psui-text-h6 psui-text-gray-80 psui-font-bold pt-4">
        {{ path.slug !== ASSUMPTIONS_PATHS.IMPACT_RESULTS.slug ? 'Building Estimates' :'Policy assumptions' | policyTextRephrase }}
      </h1>
      <PsTabHeader
        v-if="getShowTabHeader && path.slug !== ASSUMPTIONS_PATHS.IMPACT_RESULTS.slug" 
        layout="underline"
        :items="getBuildingTypeItemsFiltered"
        key-label="title"
        key-value="slug"
        :selected.sync="getPathbyBuildingType"
      />
    </div>
    <div
      v-else-if=" $route.name === 'StudyResults'"
      class="flex flex-col pt-4 px-6 psui-shadow-elevation-10 psui-font-bold psui-text-gray-40 psui-text-xsmall"
    >
      <div
        class="flex items-center cursor-pointer"
        @click="onClickBreadcrumb"
      >
        <span>
          {{ `${lastJurisdictionVisited.type}-wide Assumptions` }}
        </span>
        <div
          v-if="path.slug != ASSUMPTIONS_PATHS.IMPACT_RESULTS.slug"
          class="flex items-center"
        >
          <PsIcon
            icon="chevron_right"
            size="15"
            icon-classes="psui-text-gray-40 psui-font-bold"
          />
          <span :class="{'psui-text-blue-60':path.slug != ASSUMPTIONS_PATHS.IMPACT_RESULTS.slug}">
            {{ $route.query.only_study_type | replaceAllHyphenAndAddUppercaseToFirstLetter }}
          </span>
        </div>
      </div>
      <h1
        class="pt-4 psui-text-gray-80 psui-text-h6"
        :class="{'mb-7':path.slug == ASSUMPTIONS_PATHS.IMPACT_RESULTS.slug}"
      >
        {{ path.slug == ASSUMPTIONS_PATHS.IMPACT_RESULTS.slug ? 
          ($route.query.only_study_type.replace(/-/g," ").replace(/(^\w|\s\w)/g, m => m.toUpperCase()) ) : 
          'Building Estimates' }}
      </h1>
      <PsTabHeader
        v-if="path.slug !== ASSUMPTIONS_PATHS.IMPACT_RESULTS.slug"
        layout="underline"
        :items="building_types"
        data-test-id="jurisdiction-wide-assumptions-ps-tab-header"
        key-label="title"
        key-value="slug"
        :selected.sync="getPathbyBuildingType"
      />
    </div>
    <transition
      :name="transitionName"
    >
      <div
        :is="getComponent"
        class="min-h-screen"
        :building_types="building_types"
        :building_type="building_type"
        :type_vintages="getTypeVintages"
        :type_prototypes="getTypePrototypes"
        :path.sync="path"
        :get-tab-selected="getTabSelected"
      />
    </transition>
  </div>
</template>
 
<script>
import AssumptionsBreadcrumb from '@/modules/app/assumptions/shared/AssumptionsBreadcrumb.vue'
import AssumptionsResidential from '@/modules/app/assumptions/building-estimates/residential/AssumptionsResidential.vue'
import AssumptionsNonResidential from '@/modules/app/assumptions/building-estimates/non-residential/AssumptionsNonResidential.vue'
import AssumptionsImpacts from '@/modules/app/assumptions/impacts/AssumptionsImpacts.vue'
import { BUILDING_TYPES_DB_SLUGS }from '@/models/BuildingType.js'
import { ASSUMPTIONS_PATHS, STUDY_TYPES_DB_SLUGS } from '@/util/Enums.js'
import { mapGetters } from 'vuex'
import StudyGroupApiService from '@/services/api/StudyGroupApiService'
import StudyGroup from '@/models/StudyGroup'
import Study from '@/models/Study'

export default {
  name: 'JurisdictionWideAssumptions',
  components: { 
    AssumptionsBreadcrumb, 
    AssumptionsResidential, 
    AssumptionsNonResidential, 
    AssumptionsImpacts
  },
  data:()=>({
    ASSUMPTIONS_PATHS,
    studyTypes: [],
    buildingTypeTab: false,
    transitionName: 'jurisdiction-in'
  }),
  computed: {
    path: {
      get() {
        return this.$store.state.assumptions.drawerOptions.path
      },
      set(path) {
        this.$store.commit('assumptions/updateDrawerPath', path)
      }
    },
    getPathbyBuildingType:{
      get(){
        return this.$store.state.assumptions.drawerOptions.path
      },
      set(path){
        const getPathByBuildingType = path.slug == BUILDING_TYPES_DB_SLUGS.RESIDENTIAL ?
        ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS :
        ASSUMPTIONS_PATHS.NON_RESIDENTIAL_BUILDINGS
        this.$store.commit('assumptions/updateDrawerPath', getPathByBuildingType)
      }
    }, 
    building_types() {
      return this.$store.getters['globalEntities/BuildingType/getterBuildingTypesWithTypePrototypes']
    },
    study_types(){
      return this.$store.getters['globalEntities/StudyType/getterGlobalStudyTypes']()
    },
    building_type() {
      return this.building_types.find((bt) => bt.slug === this.path.slug) ?? false
    },
    getComponent() {
        return this.path?.component
    },
    getTypeVintages() {
      return this.$store.getters['globalEntities/TypeVintage/getterGlobalTypeVintages']({ hide_in_building_estimates: false })
    },
 
    getTypePrototypes() {
      return this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototypes']()
    },
     getAllAssumptionsPathBaseValue(){
      return Object.values(ASSUMPTIONS_PATHS).reduce((acc,curr)=>{
        if(Object.values(BUILDING_TYPES_DB_SLUGS).includes(curr.slug)) acc.push(curr)
        return acc
      },[])
    },
    getPathSelected(){
      return this.$route.query.only_building_type === ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS.slug  || !this.$route.query.only_building_type ? 
      ASSUMPTIONS_PATHS.RESIDENTIAL_BUILDINGS :
      ASSUMPTIONS_PATHS.NON_RESIDENTIAL_BUILDINGS 
    },
    getStudyTypeModified(){
      return this.study_types.map( study_type => 
      {
        return {
          ...study_type, 
          title: study_type.slug == STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS ? study_type.title :
          study_type.title.replace('Buildings','Construction')
        }
      })
    },
    getShowTabHeader(){
      return this.$store.getters['policy/getterUserPolicy']({id: this.$route.params.policy_id}).policy_containers
      .map(container => this.getBuildingTypeIdByTypePrototype(container.type_prototype_id))
      .sort((a,b) => a-b)
      .reduce((acc,curr)=>{
        if(curr != acc){
          acc.push(curr)
        }
        return acc
      },[]).length > 1
    },
    getTabSelected: {
      get(){
        return this.$store.getters['assumptions/getterAssumptionsDrawerOptions'].tab ? this.$store.getters['assumptions/getterAssumptionsDrawerOptions'].tab : this.study_types[0]
      },
      set(tab){
        return this.$store.commit('assumptions/updateDrawerTab', tab)
      }
    },
    getBuildingTypeItemsFiltered(){
      return this.building_types.map(building_type => {
        
        if(this.$route.query.only_study_type == STUDY_TYPES_DB_SLUGS.EXISTING_BUILDINGS && building_type.slug == BUILDING_TYPES_DB_SLUGS.NON_RESIDENTIAL 
         || building_type.slug == BUILDING_TYPES_DB_SLUGS.NON_RESIDENTIAL && this.$route.name == 'BuildingEstimates') {
          return {...building_type, disabled: true, tooltip:'Not available.'}
        }
        return building_type
      })
    },
    getPolicySelected(){
      return this.$store.getters['assumptions/getterPolicySelected']
    },
    ...mapGetters(
      {
        lastJurisdictionVisited: 'lastJurisdictionVisited',
      })
  },
  mounted(){
    StudyGroupApiService.byStudyType()
      .then(study_types => { 
        this.studyTypes = study_types.map(study_type => {
          study_type.study_groups = study_type.study_groups.map(study_group => {            
            study_group.studies = study_group.studies.map(study => new Study(study))
            study_group.studies = study_group.studies.filter(study => study.is_upcoming ? false : true)
            return new StudyGroup(study_group)
          })
          return study_type
        }) 
      })

    this.$eventBus.$on('removeTransition', () => {
      this.transitionName = 'jurisdiction-in'
      setTimeout(() =>{
        this.transitionName = 'notransition'
      },400)
    })

  },
  beforeDestroy() {
    this.$eventBus.$off('removeTransition')
  },
  methods:{ 
    setPathQueryBuildingType(path){
      this.$store.commit('assumptions/updateDrawerPath', path)
      this.transitionName = 'notransition'
      this.setQueryValue('only_building_type', path.slug)
      this.$refs.PSDropdown.close()
    },
    getBuildingTypeIdByTypePrototype(type_prototype_id){
      return this.$store.getters['globalEntities/TypePrototype/getterGlobalTypePrototype']({id:type_prototype_id}).building_type_id
    },
    onClickBreadcrumb(){
      this.$store.commit('assumptions/updateDrawerPath',ASSUMPTIONS_PATHS.IMPACT_RESULTS)
      this.transitionName = 'jurisdiction-out' 
    }
  }
}
</script>
 
<style lang="scss" scoped>

  ::v-deep .psui-el-tooltip {
    .psui-el-tooltip-trigger {
      button {
        cursor: pointer;
      }
    }
  }

  .jurisdiction-out-enter-active {
    transition: all 0.3s ease-out;
  }
  .jurisdiction-out-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .jurisdiction-out-enter-from,
  .jurisdiction-out-leave-to {
    transform: translateX(100%);
    // opacity: 0;
  }
  
  .jurisdiction-in-enter-active {
    transition: all 0.3s ease-out;
  }
  .jurisdiction-in-leave-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .jurisdiction-in-enter-from,
  .jurisdiction-in-leave-to {
    transform: translateX(-100%);
    // opacity: 0;
  }

  .notransition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }

  .title-elem {
    margin-left: calc(1.5rem - 13px);
  }

  .title-truncate {
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
